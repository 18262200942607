import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ButtonGroup,
  Container,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  Trash,
  PencilFill,
  FileExcel,
  PlusCircleDotted,
  Printer,
} from 'react-bootstrap-icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

import XLSX from 'sheetjs-style';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import 'assets/css/tableGar.css';
import dateformat from 'dateformat';
import common from '../../commonData';

function MyAwesomeTable({ rows, isLoading, onDelete, setIds, title, setAdds }) {
  const [gridRef, setGridRef] = useState(null);

  const filterValue = [
    { name: 'chassis', operator: 'contains', type: 'string', value: '' },
    { name: 'immatr_eng', operator: 'contains', type: 'string', value: '' },
    { name: 'libellemarq', operator: 'contains', type: 'string', value: '' },
    { name: 'model', operator: 'contains', type: 'string', value: '' },
    { name: 'energie', operator: 'contains', type: 'string', value: '' },
  ];


  const Couleur = ({ data }) => {
    return (
      <Link
        to="#"
        id="actionbtns"
        title="Voir l'historique"
      >
        <div style={{ background: data.couleur }}>
          {data.couleur ? getCouleur( data.couleur.toUpperCase()) : ""}
        </div>
      </Link>
    );
  };
  const columns = [
    {
      name: 'id',
      header: 'Id',
      defaultVisible: false,
      defaultWidth: 80,
      type: 'number',
    },
    {
      header: 'Action',
      defaultWidth: 90,
      type: 'string',
      editable: false,
      render: ({ value, data }) => (
        <div className="menudrops">
          <ButtonGroup aria-label="Basic example">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Modifier la ligne</Tooltip>
              }
            >
              <PencilFill
                id="actionbtn"
                onClick={() => setIds(data.id)}
                size={25}
                color="green"
              />
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Supprimer la ligne</Tooltip>
              }
            >
              <Trash
                id="actionbtn"
                onClick={() => onDelete(data.id)}
                color="red"
                size={25}
              />
            </OverlayTrigger>
          </ButtonGroup>
        </div>
      ),
    },
    {
      name: 'chassis',
      header: 'Chassis',
      defaultWidth: 160,
      type: 'string',
      textAlign: 'center',
      render: ({ data }) => (
        <Link
          to={`/prestadmin/historyvehicule/${data.id}`}
          id="actionbtns"
          title="Voir l'historique"
        >
          <div style={{ borderBottom: '4px solid ' + data.couleur }}>
            {data.chassis}
          </div>
        </Link>
      ),
    },
    {
      name: 'immatr_eng',
      header: 'Immatriculation',
      defaultWidth: 150,
      type: 'string',
    },{
      header: 'Couleurs',
      defaultWidth: 90,
      type: 'string',
      render: ({ data }) => <Couleur data={data} />,
    },
    {
      name: 'nb',
      header: 'Nbre. reception',
      defaultWidth: 120,
      type: 'string',
    },
    {
      name: 'libellemarq',
      header: 'Marques',
      defaultWidth: 120,
      type: 'string',
      render: ({ data }) => (data.marque ? data.marque.libellemarq : 'Neant'),
    },
    {
      name: 'model',
      header: 'Models',
      defaultWidth: 120,
      type: 'string',
      render: ({ data }) => (data.model ? data.model.libellemodel : 'Neant'),
    },
    {
      name: 'date_mise_circul',
      header: 'En circul. le',
      defaultWidth: 120,
      textAlign: 'center',
      type: 'string',
      render: ({ data }) =>
        data.date_mise_circul
          ? dateformat(data.date_mise_circul, 'dd-mm-yyyy HH:MM')
          : 'néant',
    },
    {
      name: 'energie',
      header: 'Energie',
      defaultWidth: 100,
      type: 'string',
      render: ({ data }) => (data.energie === 0 ? 'Essence' : 'Gasoil'),
    },
    
    {
      name: 'username',
      header: 'Crée par',
      defaultWidth: 120,
      type: 'string',
      render: ({ data }) => (data.user ? data.user.name : 'Neant'),
    },
    {
      name: 'created_at',
      header: 'Crée le',
      defaultWidth: 130,
      type: 'string',
      render: ({ data }) =>
        data.created_at
          ? dateformat(data.created_at, 'dd-mm-yyyy HH:MM')
          : 'néant',
    },
  ];

  function getCouleur(color){
    switch (color) {
      case '#F78DA7':
        return 'Rose';
        case '#FFFFFF':
            return 'Blanc';
        case '#CCCCCC':
            return 'Gainsboro';
           
        case '#B3B3B3':
            return 'Gris';
           
        case '#808080':
            return 'Gris';
           
        case '#000000':
            return 'Noir';
           
        case '#333333':
            return 'Noir';
           
        case '#4D4D4D':
            return 'Gris noir';
           
        case '#666666':
            return 'Gris';
           
        case '#999999':
            return 'Gris';
           
        case '#F44E3B':
            return 'OrangeRed';
           
        case '#F44336':
            return 'Rouge';
            case '#D33115':
            return 'Rouge';
        case '#9F0500':
            return 'Rouge bordeau';
           
        case '#FE9200':
            return 'Orange clair';
            case '#FF9800':
              return 'Orange ';  
        
            case '#E27300':
                return 'Orange ';
               
        case '#FFEB3B':
                  return 'Jaune';
                 
            case '#C45100':
                return 'Orange rouge';
               
            case '#FCDC00':
                return 'Jaune';
               
            case '#FCC400':
                return 'Jaune foncé';
               
            case '#FB9E00':
                return 'Jaune orangé';
               
            case '#DBDF00':
                return 'Khaki';
               
            case '#B0BC00':
                return 'Khaki foncé';
               
            case '#808900':
                return 'Olive';
               

                case '#4CAF50':
                  return 'Vert';
                 
                case '#A4DD00':
                    return 'Vert Jaune';
                   
                case '#68BC00':
                    return 'Vert';
                   
                case '#194D33':
                    return 'Vert foncé';
                   
                case '#68CCCA':
                    return 'MediumTurquoise';
                   
                case '#73D8FF':
                    return 'LightSkyBlue';
                   
                case '#16A5A5':
                    return 'LightSeaGreen';
                   
                case '#0C797D':
                    return 'Cyan noir';
                   
                case '#009CE0':
                    return 'DodgerBlue';
                   
                case '#0062B1':
                    return 'Blue';
                   
                    case '#004DCF':
                        return 'Blue';                   
                case '#7B64FF':
                    return 'MediumPurple';
                   
                case '#653294':
                    return 'Indogo';
                   
                case '#FDA1FF':
                    return 'Violet';
                   
                    case '#9900EF':
                        return 'Violet';
                       
                    
                    case '#FA28FF':
                        return 'Magenta';
                       
                    case '#AB149E':
                        return 'Viole foncé';
                       
                    case '#FFF':
                        return 'Blanc';
                       
                    case '#000':
                        return 'Noir';
                       
                        case '#795548':
                          return 'Marron';
                         
                    
        default:
            break;
    }
}

  const gridStyle = { minHeight: window.screen.height - 200 };

  async function printListe() {
    if (gridRef.current.data.length <= 0) {
      return;
    }
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    });
    var width = doc.internal.pageSize.getWidth();
    const data = [];
    
    doc.addImage(common.logo, 'jpg', 14, 10, 30, 30);
    doc.setFontSize(16);
    doc.text('HR MOTORS GARAGE', 90, 14);
    doc.setFontSize(12);
    doc.setFillColor(0, 0, 0);
    doc.rect(65, 15, width - 80, 10, 'F');
    doc.setTextColor(255, 255, 255);
    doc.text('LISTE DES VEHICULES', 90, 22);
    doc.setTextColor(0, 0, 0);
    gridRef.current.data.forEach((row, index) => {
      data.push([
        index,
        row.chassis,
        row.immatr_eng,
        row.nb,
        row.marque ? row.marque.libellemarq : 'Neant',
        row.model ? row.model.libellemodel : 'Neant',
        {content: row.couleur ? getCouleur(row.couleur.toUpperCase()) : "",styles: {fontStyle: 'bold',textColor: row.couleur ==='#FFF' || row.couleur === '#FFFFFF' || row.couleur ==='#fff' || row.couleur === '#ffffff' ? '#000' : '#FFF', fillColor: row.couleur !=='null' ? row.couleur : ""}},
        row.date_mise_circul
          ? dateformat(row.date_mise_circul, 'dd-mm-yyyy')
          : 'néant',
        row.energie === 0 ? 'Essence' : 'Gasoil',
      ]);
    });
    doc.autoTable({
      styles: {
        fillColor: [0, 0, 0],
        textColor: [255, 255, 255],
        halign: 'center',
        fontSize: 10,
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        1: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        2: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        3: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        4: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        5: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        6: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        7: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        8: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
      },
      theme: 'grid',
      startY: 35,
      fontSize: 6,
      head: [
        [
          'Code',
          'Chassis',
          'Immatriculation',
          'Nbr. reception',
          'Marques',
          'Model',
          'Couleur',
          'Mise en circul.',
          'Energie',
        ],
      ],
      body: data,
    });
    const pageCount = doc.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        'Page ' + String(i) + ' sur ' + String(pageCount),
        210 - 55,
        200,
        null,
        null,
        'right'
      );
    }
    doc.save('vehicule.pdf');
  }

  //--------------- EXPORT VERS EXCEL ------------------

  const exportToExcel = async () => {
    const raw_data = gridRef.current.data;
    /* flatten objects */
    const rows = raw_data.map((row, index) => ({
      code: index,
      chassis: row.chassis,
      immatr: row.immatr_eng,
      nb_reception: row.nb,
      marque: row.marque ? row.marque.libellemarq : 'Neant',
      model: row.model ? row.model.libellemodel : 'Neant',
      couleur: row.couleur ? getCouleur(row.couleur.toUpperCase()) : '',
      date_mise_circul: row.date_mise_circul
        ? dateformat(row.date_mise_circul, 'dd-mm-yyyy')
        : 'néant',
      energie: row.energie === 0 ? 'Essence' : 'Gasoil',
    }));

    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes véhicule');
    /* fix headers */
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
        ],
      ],
      { origin: 'A1' }
    );
    /* calculate column width */
    const max_width = rows.reduce((w, r) => Math.max(w, r.chassis.length), 10);
    worksheet['!cols'] = [{ wch: max_width }];
    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, 'véhicule.xlsx');
  };

  async function saveAsExcel() {
    exportToExcel();
  }

  return (
    <div>
      <Navbar expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand href="#/admin/articles">{title}</Navbar.Brand>
          <Nav className="me-auto">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-disabled">
                  Ajouter un nouveau véhicule
                </Tooltip>
              }
            >
              {setAdds !== undefined ? (
                <Nav.Link
                  onClick={() => setAdds(true)}
                  title="Ajouter un nouveau véhicule"
                >
                  <PlusCircleDotted color="green" size={30} /> Ajouter véhicule
                </Nav.Link>
              ) : (
                <></>
              )}
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>
              }
            >
              <Nav.Link
                title="Exporter vers excel"
                onClick={() => saveAsExcel()}
              >
                <FileExcel color="#0080ff" size={30} /> Excel
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>
              }
            >
              <Nav.Link onClick={() => printListe()} title="Exporter vers pdf">
                <Printer color="#0080ff" size={30} /> Imprimer
              </Nav.Link>
            </OverlayTrigger>
          </Nav>
        </Container>
      </Navbar>
      <ReactDataGrid
        idProperty="id"
        handle={setGridRef}
        style={gridStyle}
        loading={isLoading}
        reorderColumns={false}
        pagination
        pageSizes={[200, 400, 600, 800, rows.length]}
        defaultLimit={400}
        showHoverRows={true}
        editable={true}
        showColumnMenuTool={false}
        defaultFilterValue={filterValue}
        columns={columns}
        dataSource={rows}
      />
    </div>
  );
}

export default MyAwesomeTable;
