import React, { useState } from 'react';
import { Form, InputGroup, Spinner, ButtonGroup, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { BackspaceFill, Save2Fill, Star } from 'react-bootstrap-icons';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';

import common from '../../commonData';
import dateformat from 'dateformat';

var count = 0;
function AddBonSortie({
  data,
  setBonLivModal,
  show,
  setIsSave,
  setData,
  rows,
  enreparat,
  setEnRepa,
  setSort,
  sortie,
  setMsg,
  setMsgravity,
  vue,
}) {
  var ladate = new Date();
  // const [validated, setValidated] = useState(false);
  const [states, setStates] = useState({
    datebon:dateformat(data.datebon,
      'yyyy-mm-dd'
    ),
    nobon: data.nobon,
  });
  const {datebon,nobon} = states;
  // const [datebon, setdateBon] = useState(
  //   dateformat(
  //     ladate.getMonth() +
  //     1 +
  //     '/' +
  //     ladate.getDate() +
  //     '/' +
  //     ladate.getFullYear(),
  //     'yyyy-mm-dd'
  //   )
  // );
  // const [nobon, setNoBon] = useState('');
  const [load, setLoad] = useState(false);

  function saveSortie(values) {
    setLoad(true);
    var formData = new FormData();
    formData.append('datebon', values.datebon);
    formData.append('nobon', values.nobon);
    var url = '';
    vue === 'marque'
      ? (url = common.ipapi + '/api/reception/updatebon/' + data.idre)
      : (url = common.ipapi + '/api/reception/updatebon/' + data.id);
    Axios({
      url: url,
      method: 'POST',
      headers: {
        'Content-Type': 'form-data',
      },
      data: formData,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setMsg(res.data.msg);
          setMsgravity('info');
          setIsSave(true);
          
          if (values.datebon == '' && values.nobon == '') {
            enreparat++;
            sortie--;
          }else{
            enreparat--;
            sortie++;
          }
          setEnRepa(enreparat);
          setSort(sortie);
          const isLargeNumber = (element) => element.id == data.id;
          const cloneProducts = [...rows];
          cloneProducts[rows.findIndex(isLargeNumber)].datebon = values.datebon;
          cloneProducts[rows.findIndex(isLargeNumber)].nobon = values.nobon;
          setData(cloneProducts);
          setLoad(false);
          setBonLivModal(false);
        } else {
          setMsg(res.data.msg);
          setMsgravity('danger');
          setIsSave(true);
          
          setLoad(false);
        }
      })
      .catch((error) => {
        setIsSave(true);
        setMsg(error);
        setMsgravity('danger');
        setLoad(false);
      });
  }

  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     setValidated(true);
  //   } else {
  //     saveSortie();
  //   }
  // };

  const handleClose = () => {
    setBonLivModal(false);
    setIsSave(false);
  };
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      <Formik
                initialValues={{
                  datebon:datebon,
                  nobon: nobon,
    
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                //   datebon: Yup.date().required('Veuillez saisir cette donnée'),
                // nobon: Yup.string().required('Veuillez saisir cette donnée'),
               
               })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    saveSortie(values)
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
               
      
      <Modal show={show} size="md">
        <Modal.Header>{'BON DE SORTIE'}</Modal.Header>
          <Form noValidate onSubmit={handleSubmit}>
            
            <Modal.Body>
              <Form.Group as={Row} className="mb-3" >
                <Form.Label column sm={4}>N° de bon: <Star color="red" size={10} /></Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="N° de bon sortie"
                    aria-describedby="inputGroupPrepend"
                    size="sm"
                    id='nobon'
                    value={values.nobon}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.nobon && <div id="feedback">{errors.nobon}</div>}
                </Col>
                <Form.Control.Feedback>
                  Veuillez saisir le numero de bon
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" >
                <Form.Label column sm={4}>Date sortie: <Star color="red" size={10} /></Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="date"
                    placeholder="Date de sortie"
                    aria-describedby="inputGroupPrepend"
                    size="sm"
                    id='datebon'
                    value={values.datebon}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.datebon && <div id="feedback">{errors.datebon}</div>}
                </Col>
              </Form.Group> 
            </Modal.Body>
            <Modal.Footer>
              <ButtonGroup aria-label="Basic example" size="sm">
                <Button color="secondary" onClick={handleClose}>
                  <BackspaceFill color="red" size={20} />
                </Button>
                <Button type="submit" color="default">
                  <Save2Fill color="#000" size={20} /> Enregister
                </Button>
              </ButtonGroup>
            </Modal.Footer>
          </Form>
       
      </Modal>
                )}
                </Formik>
    </>
  );
}

export default AddBonSortie;
