import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Axios from 'axios';
// import dateformat from 'dateformat';
import MyAwesomeTable from './gridtables';
import common from '../../commonData';
import AddMarque from './addMarque';
// import EditMarque from './editMarque';
import { Modal, Spinner, Toast } from 'react-bootstrap';

var dta = [];
function Marques() {
  const [isSave, setIsSave] = useState(false);
  const [data, setDataMarque] = useState([]);
  const history = useHistory();
  const [load, setLoad] = useState(true);
  const [idMarq, setId] = useState(0);
  const [msg, setMsg] = useState('');
  const [msgGravity, setMsgravity] = useState('danger');
  const [libMarq, setLibelleMarq] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [showAddMarque, setShowAddMarque] = useState(false);
  const [actualise, setActualise] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('user-info-g')) {
      (async function anyNameFunction() {
        await getMarque();
      })();
    } else {
      history.push('/login');
    }
  }, [history,actualise]);

  async function getMarque() {
    setLoad(true);
    Axios.get(common.ipapi + '/api/marque/indexs')
      .then((response) => {
        setDataMarque(response.data);
        dta = response.data;
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }

  function onDelete(id) {
    if (window.confirm('Voulez-vous supprimer cette marque ?')) {
      Axios.delete(common.ipapi + '/api/marque/destroy/' + id).then(
        (response) => {
          if (response.data.status == 1) {
            const del = dta.filter((d) => d.id !== id);
            dta = del;
            setDataMarque(del);
            setMsg(response.data.msg);
            setIsSave(true);
          } else {
            setMsg(response.data.msg);
            setIsSave(true);
          }
        }
      );
    }
  }

  function setAdds() {
    setId(0);
    // setAddMarque(true);
    setShowAddMarque(true);
  }

  async function setIds(id) {
    setLoad(true);
    await Axios.get(common.ipapi + '/api/marque/edit/' + id)
        .then((responses) => {
            setLibelleMarq(responses.data.libellemarq);
            setLoad(false);
            setId(id);
            setShowAddMarque(true);
        })
        .catch((errors) => {
            setLoad(false);
        });
  }

  return (
    <>
      <Toast
        id="toast"
        onClose={() => setIsSave(false)}
        show={isSave}
        delay={3000}
        className={'bg-' + msgGravity}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Info</strong>
        </Toast.Header>
        <Toast.Body className="dark">{msg}</Toast.Body>
      </Toast>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="danger" />
        </Modal.Body>
      </Modal>
      {showAddMarque ? (
        <AddMarque
        id={idMarq}
          setShowAddFamille={setShowAddMarque}
          show={showAddMarque}
          setIsSave={setIsSave}
          setDataMarque={setDataMarque}
          libMarq={libMarq}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          setActualise={setActualise}
          actualise={actualise}
        />
      ) : (
        <></>
      )}
      {/* {showEdit ? (
        <EditMarque
          id={idMarq}
          setShowEdit={setShowEdit}
          show={showEdit}
          setIsSave={setIsSave}
          isSave={false}
          setDataMarque={setDataMarque}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
        />
      ) : (
        <></>
      )} */}
      <div className="content">
        <div id="tablecontent">
          <MyAwesomeTable
            rows={data}
            isLoading={loading}
            onDelete={onDelete}
            setIds={setIds}
            setAdds={setAdds}
          />
        </div>
      </div>
    </>
  );
}

export default Marques;
