import { useHistory, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Spinner, Modal, Toast } from 'react-bootstrap';
import 'jspdf-autotable';
import commondata from '../../commonData';
import MyAwesomeTable from './gridtables';
import AddVehicule from './addLight';
// import EditVehicule from './editLight';

function Vehicules() {
  const history = useHistory();
  const [datas, setVehiculeData] = useState([]);
  const [load, setLoad] = useState(true);
  const [showAddVehicule, setShowAddVehicule] = useState(false);
  // const [showEdit, setShowEdit] = useState(false);
  const [id, setId] = useState(0);
  const [isSave, setIsSave] = useState(false);
  const [msgGravity, setMsgravity] = useState('success');
  const [msg, setMsg] = useState('');

  useEffect(() => {
    if (localStorage.getItem('user-info-g')) {
      (async function anyNameFunction() {
        await getVehicule();
      })();
    } else {
      history.push('/login');
    }
  }, [history]);

  async function getVehicule() {
    setLoad(true);
    Axios.get(commondata.ipapi + '/api/vehicule/index')
      .then((response) => {
        setVehiculeData(response.data.data);
        //count = 0;
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }
  function onDelete(id) {
    if (window.confirm('Voulez-vous supprimer cette ligne ?')) {
      setLoad(true);
      Axios.delete(commondata.ipapi + '/api/vehicule/destroy/' + id).then(
        (response) => {
          if (response.data.status === 1) {
            const del = datas.filter((d) => d.id !== id);
            setVehiculeData(del);
            setLoad(false);
          } else {
            setMsg(response.data.msg);
            setMsgravity('danger');
            setIsSave(true);
            setLoad(false);
          }
        }
      );
    }
  }

  function setAdds(val) {
    setId(0)
    setShowAddVehicule(val);
  }

  function setIds(id) {
    setId(id);
    setShowAddVehicule(true);
    // setShowEdit(true);
  }
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="danger" />
        </Modal.Body>
      </Modal>
      <Toast
        id="toast"
        onClose={() => setIsSave(false)}
        show={isSave}
        delay={3000}
        className={'bg-' + msgGravity}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Info</strong>
        </Toast.Header>
        <Toast.Body className="dark">{msg}</Toast.Body>
      </Toast>
      {showAddVehicule ? (
        <AddVehicule
        id={id}
          setShowAddVehicule={setShowAddVehicule}
          show={showAddVehicule}
          setIsSave={setIsSave}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          getVehicule={getVehicule}
        />
      ) : (
        <></>
      )}
      {/* {showEdit ? (
        <EditVehicule
          id={id}
          setShowEdit={setShowEdit}
          show={showEdit}
          setIsSave={setIsSave}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          getVehicule={getVehicule}
        />
      ) : (
        <></>
      )} */}
      <div className="content">
        <div id="tablecontent">
          <MyAwesomeTable
            rows={datas}
            isLoading={load}
            onDelete={onDelete}
            setIds={setIds}
            setAdds={setAdds}
          />
        </div>
      </div>
    </>
  );
}

export default Vehicules;
