import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Form,
    Button,
    Spinner,
    ButtonGroup,
    Modal
} from 'react-bootstrap';
import {  Input, Row, Col} from 'reactstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Axios from 'axios';
import {
    Backspace,
    Search,
    Star,
} from 'react-bootstrap-icons';
import common from '../../commonData';

function FilterModale({
    showFilterModal,
    setMsg,
    setIsSave,
    setMsgravity,
    setLoad,
    load, handleClose,
    setData,
    setTotal,
    setEnRepa,
    setDataAll,
    setEntre,
    setSort
}) {

    async function SearchData(values) {
        setLoad(true);
        await Axios({
            url: common.ipapi + '/api/reception/filterplus/' + values.typeOption + '/' + values.dateDebut + '/' + values.dateFin,
            method: 'GET',
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('info');
                    setIsSave(false);
                    return;
                }
                if (res.data.status === 1) {
                    localStorage.setItem('datedebut', values.dateDebut);
                    localStorage.setItem('datefin', values.dateFin);
                    localStorage.setItem('filteroption', values.typeOption);
                    setDataAll(res.data.datas);
                    setData(res.data.datas.data);
                    setTotal(res.data.datas.total);
                    setEnRepa(res.data.datas.immo);
                    // setTotalliv(res.data.datas.totallivre);
                    setEntre(res.data.datas.entrejour);
                    setSort(res.data.datas.sortiejour);
                    setLoad(false);
                    handleClose();
                } else {
                    setIsSave(true);
                    setMsg(res.data.msg);
                    setMsgravity('danger');
                    setLoad(false);
                }

            })
            .catch((error) => {
                setIsSave(true);
                setMsg('Erreur');
                setMsgravity('danger');
                setLoad(false);
            });
    }



    

    return (
        <>
            <Modal
                show={load}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="warning" />
                </Modal.Body>
            </Modal>
            
        <Formik
            initialValues={{
                dateDebut: localStorage.getItem('datedebut'),
                dateFin: localStorage.getItem('datefin'),
                typeOption: localStorage.getItem('filteroption') || 1
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
                dateDebut: Yup.date().required('Veuillez saisir cette donnée'),
                dateFin: Yup.date().required('Veuillez saisir cette donnée'),
                typeOption: Yup.number().required('Veuillez saisir cette donnée')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  SearchData(values)
                    setSubmitting(false);
                } catch (err) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                

            <Modal show={showFilterModal} onHide={handleClose}>
               
                    <Modal.Header>
                        FILTRE DE SELECTION <Backspace onClick={handleClose} color="red" size={30} />
                    </Modal.Header>
                    <Form noValidate onSubmit={handleSubmit}>
                    <Modal.Body>
                      
                        <Form.Group as={Row}  error={Boolean(touched.typeOption && errors.typeOption)} className="mb-3">
              <Form.Label column sm={5}> Options: </Form.Label>
              <Col sm={7}>
                <Input
                  type="select"
                  value={values.typeOption}
                //   onChange={(e) => setType(e.target.value)}
                name='typeOption'
                  onBlur={handleBlur}
                    onChange={handleChange}
                >
                  <option key={1} value="1">
                    Par date entrée
                  </option>
                  <option key={2} value="2">
                    Par date sortie
                  </option>
                  <option key={3} value="3">
                    Reception gratuites
                  </option>
                  <option key={4} value="4">
                    Reception payante
                  </option>
                  <option key={7} value="7">
                    Reprise travaux
                  </option>
                  <option key={5} value="5">
                    Reception facturée
                  </option>
                  <option key={6} value="6">
                    Reception non facturée
                  </option>
                  
                </Input>
                {errors.typeOption && <div id="feedback">{errors.typeOption}</div>}
              </Col>
            </Form.Group>
            
                                <Form.Group
                                error={Boolean(touched.dateDebut && errors.dateDebut)}
                      as={Row}
                      className="mb-3"
                    ><Form.Label column sm={5}>
                    Date début période: <Star color="red" size={10} />
                  </Form.Label>
                      <Col sm={7}>
                                <Form.Control
                                    type="date"
                                    placeholder="Date début période"
                                    size="sm"
                                    id='dateDebut'
                                    value={values.dateDebut}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                 {errors.dateDebut && <div id="feedback">{errors.dateDebut}</div>}
                            </Col>
                        </Form.Group>
                                <Form.Group
                      as={Row}
                      className="mb-3"
                      error={Boolean(touched.dateFin && errors.dateFin)}
                    ><Form.Label column sm={5}>
                     Date fin période: <Star color="red" size={10} />
                  </Form.Label>
                      <Col sm={7}>
                                <Form.Control
                                    type="date"
                                    placeholder="Date fin période"
                                    aria-describedby="inputGroupPrepend"
                                    id='dateFin'
                                    size="sm"
                                    value={values.dateFin}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                   {errors.dateFin && <div id="feedback">{errors.dateFin}</div>}
                            </Col>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="ml-auto">
                            <ButtonGroup size="sm">
                                <Button type="submit" color="secondary" value='Rechercher' disabled={isSubmitting}>
                                    <Search color="#000" size={20} /> Rechercher
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            )}
            </Formik>
        </>);
}
export default FilterModale;
