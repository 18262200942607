import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconButtons from './actionButton';
import DataGrid from './dataGrid';
import config from '../../../commonData';
import { Card, Modal, Placeholder, Spinner, Toast } from 'react-bootstrap';
import AjouterTypeDepense from './ajType';


const TypeDepense = () => {
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [load, setLoad] = useState(false);
    const [openSnak, setOpenSnak] = useState(false);
    const [typeDepense, setTypeDepense] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [idModif, setIdModif] = React.useState(0);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('success');
    const [selected, setSelected] = React.useState({});

    const [state, setState] = React.useState({
        vertical: 'bottom',
        horizontal: 'right'
    });
    const { vertical, horizontal } = state;
    const handleClickOpen = (param, idModif) => {
        setIdModif(idModif);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnak(false);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        setLoading(true);
        async function fetchProduct() {
            const response = await fetch(config.ipapi + '/api/liste-typedep');
            const json = await response.json();
            setTypeDepense(json);
            setLoading(false);
        }
        fetchProduct();
    }, [history, load]);

    return (
        <div className="content contents">
            <IconButtons
                selected={selected}
                handleClickOpen={handleClickOpen}
                setLoading={setLoading}
                setOpenSnak={setOpenSnak}
                setLoad={setLoad}
                load={load}
                idModif={idModif}
                setMsg={setMsg}
                setSeverity={setSeverity}
            />
            <Toast id="toast" onClose={() => handleClose(false)} show={openSnak} delay={3000} className={'bg-' + severity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>

            <Modal show={isLoading} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            {/* <Card sx={{ overflow: 'hidden' }}> */}
                {typeDepense ? (
                    <DataGrid setSelected={setSelected} selected={selected} typeDepense={typeDepense} isLoading={isLoading} />
                ) : (
                    <></>
                )}
            {/* </Card> */}

            {open ? (
                <AjouterTypeDepense
                    open={open}
                    handleCloseModal={handleCloseModal}
                    setOpenSnak={setOpenSnak}
                    setLoad={setLoad}
                    load={load}
                    idModif={idModif}
                    setMsg={setMsg}
                    setSeverity={setSeverity}
                />
            ) : (
                <></>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default TypeDepense;
