import {useHistory,Link,useParams} from 'react-router-dom'
import React,{useEffect,useState} from 'react'
import Axios from 'axios';
import dateformat from 'dateformat';
import {Spinner,Modal, Dropdown, ListGroup } from "react-bootstrap";
import { Card,CardBody,Form, Row, Col} from "reactstrap";
import { Printer, Trash, Truck, Tools, TruckFlatbed, Pencil, PencilSquare, Eye } from 'react-bootstrap-icons';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import commondata from '../../commonData'
import { MyAwesomeTablemin} from '../../tableDataGar'
import AddReceptions from '../receptions/addLight';
import EditReceptions from '../receptions/editLight';
import AddBonSortie from '../receptions/addbonsortieLight';
import EditBonSortie from '../receptions/editbonsortieLight';
import ViewReceptions from '../receptions/viewLight';
// import ViewLight from './viewLight';

function HistoriqueVehicule() {
  const history = useHistory();
  const [datas,setData]=useState([])
  const [dataFacture,setDataFacture]=useState([])
  const [dataPiece,setDataPiece]=useState([])
  var datafact = []
  var datapiec = []
  const [load,setLoad]=useState(true)

  const [immatriculation,setImmatr]=useState('')
  const [chassis,setChassis]=useState('')
  const [famille,setFamille]=useState('')
  const [marques,setMarque]=useState('')
  const [datecircul,setDateCirc] = useState('')
  const [typeEnerg,setTypeEnerg]=useState('0')
  const [add,setAdd] = useState(false)
  const [showAdd,setShowAdd] = useState(false)
  const [showEdit,setShowEdit] = useState(false)
  const [showView,setShowView] = useState(false)
  const [showBon,setShowBon] = useState(false)
  const [showBonEdit,setShowEditBon] = useState(false)
  const [ids,setId]=useState(0)
  const [idrecep,setIdRecep]=useState(0)
  const [dataselect,setDataSelect]=useState([])
  const [isSave,setIsSave]=useState(false)
  const [msgGravity,setMsgravity]=useState("success")
  const [msg,setMsg]=useState("")

  let { id } = useParams();
  useEffect( ()=>{
   if (localStorage.getItem("user-info-g")) {
        
        (async function anyNameFunction() {
          await Axios.get(commondata.ipapi+'/api/reception/engin/'+id)
          .then(response=>{
            setData(response.data.datas)
            response.data.datas.forEach(element => {
              setChassis(element.vehicule.chassis)
              setImmatr(element.vehicule.immatr_eng)
              setDateCirc(element.vehicule.date_mise_circul)
              setTypeEnerg(element.vehicule.energie == 0 ? 'Essence' : 'Gasoil')
              setMarque(element.vehicule.marque.libellemarq)
              setFamille(element.vehicule.model.libellemodel)
              element.proforma.forEach(proforma => {
                proforma.ligne.forEach((ligne,index) => {
                  datafact.push(ligne)
                })
              })
              
            })
            setDataFacture(datafact)
            setLoad(false)
          }).catch(errors => {
            setLoad(false)
          })
        })();
    }else{
      history.push("/login")
    }
  },[history])

  function setAdds(val){
    setAdd(val)
    setShowAdd(true)
  }

  function setIds(id){
    setId(id)
    setShowEdit(true)
  }

  function setView(bool,id){
    setShowView(bool)
    setId(id)
  }

  function setIdReceps(d){
    setShowBon(true)
    setIdRecep(d)
  }
  function setUpdateBon(d){
    setShowEditBon(true)
    setDataSelect(d)
  }

  function  onDelete (id) {
    if(window.confirm("Voulez-vous supprimer ce client ?")){
      
      Axios.delete(commondata.ipapi+'/api/client/destroy/'+id)
      .then(response=>{
        if(response.data.status==1){
            const del = datas.filter(d=>d.id !== id)
            setData(del)
        }else{
         
        }
      })
    }
  }

  const clt = ({ data }) => {
    return (
      data.client ? data.client.raisonsocial : "Neant"
    )
  }
  const Operation = ({data}) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary"  key={data.id}  drop="start"  id="dropdown-basic"></Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={()=>setView(true,data.id)} title="Voir les détails de cette ligne"><Eye color="blue" size={20} /> Voir les détails</Dropdown.Item>
          {
            data.datebon ?  
              <Dropdown.Item onClick={()=>setUpdateBon(data)}  title="Modifier ce bon de sortie"><Pencil  size={20}  color='green'/> Modifier bon sortie</Dropdown.Item>
            :
              <Dropdown.Item onClick={()=>setIdReceps(data)}  title="Faire un bon de sortie"><Pencil  size={20}  color='orange'/> Bon de sortie</Dropdown.Item>
          }
          <Dropdown.Item onClick={()=>setIds(data.id)}  title="Modifier cette ligne"><PencilSquare  size={20}  color='green'/> Modifier ligne</Dropdown.Item>
          { data.datebon ?  <Dropdown.Item  title="Imprimer le bon"><Printer  size={20}  color='brown'/> Imprimer le bon</Dropdown.Item> : "" }
          <Dropdown.Item onClick={()=>onDelete(data.id)} title="Supprimer cette ligne"><Trash color="red" size={20} /> Supprimer ligne</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
  const columns = [
        
    {
      id: 1, 
      field: 'nofiche', 
      label: 'N° fiche',
      width: '80px',
  }, 
  {
      id: 2, 
      field: 'client.raisonsocial', 
      label: 'Clients',
      cellRenderer: clt
  },
  {
    id: 3, 
    field: 'index_compt', 
    label: 'Index compt.',
    width: '120px'
  },
  {
    id: 4, 
    field: 'date_entre_garage', 
    label: 'Reçu le',
    width: '120px'
  },
  {
    id: 5, 
    field: 'datebon', 
    label: 'Sortie le',
    width: '120px'
  },
  {
    id: 6, 
    field: 'point_choc', 
    label: 'Point choc',
    width: '120px'
  },
  {
    id: 7, 
    field: 'objet_visite', 
    label: 'Objet visite',
    width: '120px'
  },
  {
    id: 8, 
    field: 'panne_constat', 
    label: 'Panne constat.',
    width: '120px'
  },
  {
    id: 9, 
    field: 'travaux', 
    label: 'Travaux',
    width: '120px'
  },
  {
    id: 10, 
    field: 'created_at', 
    label: 'Crée le',
    width: '120px'
  },
  {
    id: 11,  
    label: 'Actions',
    cellRenderer: Operation,
    pinned: true,
    width: '70px',
    minResizeWidth: 90,
  },
  ];
    

  const columnsreparation = [
      
    {
        id: 1, 
        field: 'categorie', 
        label: 'Type interv.',
        width:'100px'
    }, 
    {
        id: 2, 
        field: 'libprestation', 
        label: 'Désignation',
    },
    {
      id: 3, 
      field: 'prixprestation', 
      label: 'Prix unitaire',
      width:'100px'
    },
    {
      id: 4, 
      field: 'remise', 
      label: 'Remise %',
      width: '100px'
    },
    // {
    //   id: 5,  
    //   label: 'Actions',
    //   //cellRenderer: Operations,
    //   pinned: true,
    //   width: '70px',
    //   minResizeWidth: 90,
    // }, 
];


const columnspiece = [
      
  {
      id: 1, 
      field: 'qrbarre', 
      label: 'Code',
      width:'100px'
  }, 
  {
      id: 2, 
      field: 'descriptionart', 
      label: 'Description',
  },
  {
    id: 3, 
    field: 'pivot.prix', 
    label: 'Prix unitaire',
    width:'100px'
  },
  {
    id: 4, 
    field: 'pivot.quantite', 
    label: 'Quantité',
    width: '70px'
  },
  {
    id: 5,  
    label: 'Actions',
    //cellRenderer: Operations,
    pinned: true,
    width: '70px',
    minResizeWidth: 90,
  }, 
];


  //-------------IMPRIMER LES LISTE ----------------
  function printListe(){
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts:true
    })
    var nofact
    var clt 
    var ttc
    var montpayer = 0
    var immtr
    var model
    var ttcg=0
    var tpaie=0
    
    const data = [];
    Axios.get(commondata.ipapi+'/api/reception/pointjour')
        .then(response=>{
            response.data.map((row) => {
                clt = row.client.raisonsocial
                immtr = row.immatr_eng
                model = row.model_eng
                row.proforma.map((l) => {
                    montpayer = 0
                    ttc = l.prix_ttc
                    ttcg += l.prix_ttc
                    nofact = l.nofact
                    l.reglement.map((d) => {
                          montpayer+=d.montant
                          tpaie += d.montant
                    })
                })
                data.push(
                    [row.nofiche,clt,nofact,immtr,model, new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(ttc), new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(montpayer), new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(ttc-montpayer)]
                )
              })
              data.push(
                ["Total", '','', '', '',new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(ttcg),new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(tpaie),new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(ttcg-tpaie)]
              )
              var width = doc.internal.pageSize.getWidth();
              doc.addImage(commondata.logo1, 'png', 14, 10, 30, 30);
              doc.setFontSize(16)
              doc.text('UNITEDMOTORS GARAGE',90,14)
              doc.setFontSize(12)
              doc.setFillColor(0, 0, 0);
              doc.rect(65, 15, width-80, 10,'F')
              doc.setTextColor(255,255,255)
              doc.text("LISTE DES RECEPTION DE VEHICULE",90,22)
              doc.setTextColor(0,0,0)
              var ladate=new Date()
              doc.text("Reception du: "+ladate.getDate()+"-"+(ladate.getMonth()+1)+"-"+ladate.getFullYear() ,65,32)
             
              doc.autoTable({
                styles: { fillColor: [0, 0, 0],textColor:[255,255,255],halign: 'center',fontSize:10 },
                columnStyles: {
                    0: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0], fontSize:8},
                    1: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    2: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    3: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    4: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    5: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    6: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    7: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                },
                theme: 'grid',
                startY: 35,
                fontSize: 10,
                head: [["N° fiche", "Client", "N° facture", 'Immatr.','Model', "Montant TTC", "Mont. payer","Reste"]],
                body: data,
              })
              const pageCount = doc.internal.getNumberOfPages();
              for(var i = 1; i <= pageCount; i++) {
                  doc.setPage(i);
                  doc.setFontSize(10)
                  doc.text('Page ' + String(i) + ' sur ' + String(pageCount),210-55,200,null,null,"right");
              }
              doc.save('pointdujour-'+ladate.getDate()+"-"+(ladate.getMonth()+1)+"-"+ladate.getFullYear()+'.pdf');
              setLoad(false)
    })
    
    
  }

    return (     
      <>
      <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>Veuillez patienter.......<Spinner animation="grow" variant="success"/></Modal.Body>
      </Modal>
      { showView != false ? <ViewReceptions id={ids} setShowView={setShowView}  show={showView} setIsSave={setIsSave} isSave={false}/> : <></> }
      { add != false ? <AddReceptions setShowAdd={setShowAdd}  show={showAdd} setIsSave={setIsSave} isSave={false} setData={setData} setMsg={setMsg} setMsgravity={setMsgravity}/> : <></> }
      { showEdit != false ? <EditReceptions id={ids} setShowEdit={setShowEdit}  show={showEdit} setIsSave={setIsSave} setId={setId} setData={setData} setMsg={setMsg} setMsgravity={setMsgravity} vue='reception'/> : <></> }
      { idrecep != 0 ? <AddBonSortie id={idrecep} setShowBon={setShowBon}  show={showBon} setIsSave={setIsSave} setIdRecep={setIdRecep} setData={setData} setMsg={setMsg} setMsgravity={setMsgravity} vue='reception'/> : <></> }
      { dataselect ? <EditBonSortie data={dataselect} setShowEditBon={setShowEditBon}  show={showBonEdit} setIsSave={setIsSave} setDataSelect={setDataSelect} setData={setData} setMsg={setMsg} setMsgravity={setMsgravity} vue='reception'/> : <></> }
      
      {/* { add != false ? <ViewLight id={id} setShowAdd={setShowAdd}  show={showAdd} setIsSave={setIsSave} isSave={false}/> : <></> } */}
      <div className="content">
      {/* <Card>
        <Form className="ml-auto">
          <ButtonGroup size="sm">
            <Button onClick={()=>setAdds(true)} ><a id='btn-secondary'><PlusCircleDotted color="#807f7e" size={20}/> Ajouter</a></Button>
            <Button><a id='btn-secondary'><FileExcel color="#807f7e" size={20}/> Excel</a></Button>
            <Button><a id='btn-secondary'><Printer color="#807f7e" size={20}/> Imprimer</a></Button>
          </ButtonGroup>
        </Form>
        </Card> */}
        <Row>
          <Col md='1'>
          <Truck color='aqua' size={60}/>
          </Col>
          <Col md='2'>
          <ListGroup variant="flush">
            <ListGroup.Item><b>Chassis:</b></ListGroup.Item>
            <ListGroup.Item><b>Immatriculation:</b>  </ListGroup.Item>
            <ListGroup.Item><b>Mise en circulation:</b></ListGroup.Item>
          </ListGroup>
          </Col>
          <Col md='3'>
          <ListGroup variant="flush">
            <ListGroup.Item>{chassis || 'Néant'}</ListGroup.Item>
            <ListGroup.Item>{immatriculation || 'Néant'} </ListGroup.Item>
            <ListGroup.Item> {datecircul || 'Néant'}</ListGroup.Item>
          </ListGroup>
          </Col>
          <Col md='2'>
          <ListGroup variant="flush">
            <ListGroup.Item><b>Marque:</b> </ListGroup.Item>
            <ListGroup.Item><b>Model: </b></ListGroup.Item>
            <ListGroup.Item><b>Energie: </b></ListGroup.Item>
          </ListGroup>
          </Col>
          <Col md='4'>
          <ListGroup variant="flush">
            <ListGroup.Item>{marques || 'Néant'}</ListGroup.Item>
            <ListGroup.Item>{famille || 'Néant'}</ListGroup.Item>
            <ListGroup.Item>{typeEnerg || 'Néant'}</ListGroup.Item>
          </ListGroup>
          </Col>
          <hr></hr>
          <Col md="12">
              <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <TruckFlatbed color='red' size={45}/> HISTORIQUE RECEPTIONS
                        <MyAwesomeTablemin columns={columns} rows={datas} isLoading={load}/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <Tools color='black' size={45}/> HISTORIQUES
                        <MyAwesomeTablemin columns={columnsreparation} rows={dataFacture} isLoading={load}/>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* <Col md={6}>
                    <Card>
                      <CardBody>
                        <Sliders color='orange' size={45}/> HISTORIQUE PIECES
                        <MyAwesomeTablemin columns={columnspiece} rows={dataPiece} isLoading={load}/>
                      </CardBody>
                    </Card>
                  </Col> */}
              </Row>
            
        </Col>
        </Row>
        </div>
        </>
    );
  }
  
  export default HistoriqueVehicule;