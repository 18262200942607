import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Form,
    InputGroup,
    Button,
    Spinner,
    ButtonGroup,
    Modal
} from 'react-bootstrap';
import { Card, Input, Row, Col, CardBody, Label, CardHeader } from 'reactstrap';
// import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Axios from 'axios';
import dateformat from 'dateformat';
import {
    Backspace,
    Search,
    Star,
    Trash,
} from 'react-bootstrap-icons';
import common from '../../commonData';

function FilterModale({
    showFilterModal,
    setMsg,
    setIsSave,
    setMsgravity,
    setLoad,
    load, handleClose,
    setDataAll,
    setDatas,
    setTotal,
    setNbfact,
    setNbprof, 
    setType,
    setTitle
}) {
    var ladate = new Date();
    const [validated, setValidated] = useState(false);
    const [dateDebut, setDateDebut] = useState(localStorage.getItem('datedebut'));
    const [dateFin, setDateFin] = useState(localStorage.getItem('datefin'));
    const [typeOption, setTypes] = useState(localStorage.getItem('filteroption') || 1);

  
   
    async function SearchData(values) {
        setLoad(true);
        await Axios({
            url: common.ipapi + '/api/receptionfact/filterplus/' + values.typeOption + '/' + values.dateDebut + '/' + values.dateFin,
            method: 'GET',
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('info');
                    setIsSave(false);
                    return;
                }
                if (res.data.status == 1) {
                    localStorage.setItem('fdatedebut', values.dateDebut);
                    localStorage.setItem('fdatefin', values.dateFin);
                    localStorage.setItem('ffilteroption', values.typeOption);
                    setDataAll(res.data.datas);
                    setNbprof(res.data .datas.nbprof);
                    setNbfact(res.data.datas.nbfact);
                    setTotal(res.data.datas.data.length);
                    setDatas(res.data.datas.data);
                    setType(1)
                    setTitle("FACTURATIONS")
                    setLoad(false);
                    handleClose();
                } else {
                    setIsSave(true);
                    setMsg(res.data.msg);
                    setMsgravity('danger');
                    setLoad(false);
                }

            })
            .catch((error) => {
                setIsSave(true);
                setMsg('Erreur');
                setMsgravity('danger');
                setLoad(false);
            });
    }

    


    return (
        <>
            <Modal
                show={load}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="warning" />
                </Modal.Body>
            </Modal>
            <Formik
            initialValues={{
                dateDebut: localStorage.getItem('fdatedebut'),
                dateFin: localStorage.getItem('fdatefin'),
                typeOption: localStorage.getItem('ffilteroption') || 1
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
                dateDebut: Yup.date().required('Veuillez saisir cette donnée'),
                dateFin: Yup.date().required('Veuillez saisir cette donnée'),
                typeOption: Yup.number().required('Veuillez saisir cette donnée')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  SearchData(values)
                    setSubmitting(false);
                } catch (err) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                

            <Modal show={showFilterModal}>
                    <Modal.Header>
                        FILTRE DE SELECTION <Backspace onClick={handleClose} color="red" size={30} />
                    </Modal.Header>
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>  
                <Modal.Body>
                    
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={5}> Options: </Form.Label>
              <Col sm={7}>
                <Input
                    type="select"
                    value={values.typeOption}
                    name='typeOption'
                    onBlur={handleBlur}
                    onChange={handleChange}
                >
                  <option key={1} value="1">
                    Tous par date devis
                  </option>
                  <option key={2} value="2">
                    Tous par date facture
                  </option>
                  <option key={3} value="3">
                    Par devis non facturé
                  </option>
                  <option key={4} value="4">
                    Par devis facturé
                  </option>
                  <option key={5} value="5">
                  Par facture validée
                  </option>
                  <option key={6} value="6">
                    Facture non soldée
                  </option>
                  <option key={7} value="7">
                    Facture soldée
                  </option>
                  {/* <option key={5} value="5">
                    Reception facturée
                  </option>
                  <option key={6} value="6">
                    Reception non facturée
                  </option> */}
                </Input>
              </Col>
            </Form.Group>
            
                                <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    ><Form.Label column sm={5}>
                    Date début période: <Star color="red" size={10} />
                  </Form.Label>
                      <Col sm={7}>
                      <Form.Control
                                    type="date"
                                    placeholder="Date début période"
                                    size="sm"
                                    id='dateDebut'
                                    value={values.dateDebut}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                 {errors.dateDebut && <div id="feedback">{errors.dateDebut}</div>}
                            </Col>
                        </Form.Group>
                                <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    ><Form.Label column sm={5}>
                     Date fin période: <Star color="red" size={10} />
                  </Form.Label>
                      <Col sm={7}>
                      <Form.Control
                                    type="date"
                                    placeholder="Date fin période"
                                    aria-describedby="inputGroupPrepend"
                                    id='dateFin'
                                    size="sm"
                                    value={values.dateFin}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                   {errors.dateFin && <div id="feedback">{errors.dateFin}</div>}
                            </Col>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="ml-auto">
                            <ButtonGroup size="sm">
                                <Button type="submit" variant="ligth">
                                    <Search color="#000" size={20} /> Rechercher
                                    </Button>
                            </ButtonGroup>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            )}
            </Formik>
        </>);
}
export default FilterModale;
