/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React,{ useEffect } from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch, useLocation,useHistory } from 'react-router-dom';


import DemoNavbar from 'components/Navbars/DemoNavbarg.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/SidebarPrest';

import routes from 'routesr.js';

import AddClient from '../views/prestations/clients/addClient';
import EditClient from '../views/prestations/clients/editClient';
import AddFacture from '../views/prestations/factures/addFacture';
import UpdateFactures from '../views/prestations/factures/updateFacture';
import Config from '../views/Config';
import User from '../views/User';
import UserAdd from '../views/UserAdd';
import ListeMarqueReception from '../views/prestations/marques/listeMarqueReception';
import HistoriqueVehicule from '../views/prestations/vehicules/historyVehicule';
import DetailClients from '../views/prestations/clients/detailClients';

var ps;

function Dashboard(props) {

  const history = useHistory();
  const checkActivityTime = () =>{
    const expireTime = localStorage.getItem('expireTime');
    if(expireTime < Date.now()){
      localStorage.clear();
      history.push('/login');
    }

  }
  
  const UpdateActivity = () => {
    const expireTime = Date.now() + 1200000;
    localStorage.setItem('expireTime', expireTime);
  }


  useEffect(()=>{
    const interval = setInterval(() => {
      checkActivityTime();
    }, 5000);
    return () => {
      clearInterval(interval);
    }
  }, [])


  useEffect(()=>{

    UpdateActivity();
    window.addEventListener('click',UpdateActivity);
    window.addEventListener('keypress',UpdateActivity);
    window.addEventListener('scroll',UpdateActivity);
    window.addEventListener('mousemove',UpdateActivity);
    return () => {
      window.removeEventListener('click',UpdateActivity);
      window.removeEventListener('keypress',UpdateActivity);
      window.removeEventListener('scroll',UpdateActivity);
      window.removeEventListener('mousemove',UpdateActivity);
    }
  }, []);





  const mainPanel = React.useRef();
  const location = useLocation();
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      // document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        // document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <div className="wrapper">
      <Sidebar {...props} routes={routes} bgColor="black" activeColor="info" />
      <div className="main-panel" ref={mainPanel}>
        <DemoNavbar {...props} />
        <Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
          <Route path="/admin/usersup/:id" component={User} key={14}></Route>
          <Route path="/admin/usersadd" component={UserAdd} key={15}></Route>
          <Route path="/admin/config" component={Config} key={16}></Route>
          <Route
            path="/prestadmin/addClient"
            component={AddClient}
            key={17}
            exact
          ></Route>
          <Route
            path="/prestadmin/client/edit/:id"
            component={EditClient}
            key={18}
          ></Route>
          <Route
            path="/prestadmin/client/:id/content"
            component={DetailClients}
            key={18}
          ></Route>
          <Route
            path="/prestadmin/addfacture"
            component={AddFacture}
            key={19}
          ></Route>
          <Route
            path="/prestadmin/historyvehicule/:id"
            component={HistoriqueVehicule}
            key={19}
          ></Route>
          <Route
            path="/prestadmin/facture/edit/:id"
            component={UpdateFactures}
            key={20}
          ></Route>
          <Route
            path="/prestadmin/marque/receptionbymarque/:ids"
            component={ListeMarqueReception}
            key={20}
          ></Route>
        </Switch>
        <Footer fluid />
      </div>
      {/* <FixedPlugin
        bgColor={backgroundColor}
        activeColor={activeColor}
        handleActiveClick={handleActiveClick}
        handleBgClick={handleBgClick}
      /> */}
    </div>
  );
}

export default Dashboard;
