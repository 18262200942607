import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  InputGroup,
  Spinner,
  ButtonGroup,
  Modal,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Button,Row, Col, Label } from 'reactstrap';
import Axios from 'axios';
import { BackspaceFill, Save2Fill, Star } from 'react-bootstrap-icons';
import common from '../../commonData';
import { Typeahead } from 'react-bootstrap-typeahead';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import dateformat from 'dateformat';
var count = 0;
function AddVehicule({
  id,
  setShowAddVehicule,
  show,
  setIsSave,
  setMsg,
  setMsgravity,
  getVehicule,
}) {
  const [famille, setfamilleData] = useState([]);
  const [familles, setfamilleDatas] = useState([]);
  const [marque, setMarque] = useState([]);

  const [states, setState] = useState({
    immatriculation:'',
    chassis: '',
    model: 0,
    marque_id: 0,
    datecircul: '',
    energie: '0',
    couleur: 'BLEU'
});
const { immatriculation, chassis, model, marque_id, datecircul, energie, couleur } = states;

  const [load, setLoad] = useState(false);
  const history = useHistory();
  const typeaheadMarq = useRef(null);
  const typeaheadModel = useRef(null);
 
  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/dashboard');
    }
    (async function anyNameFunction() {
      setLoad(true);
      const getMarque = Axios.get(common.ipapi + '/api/marque/index');
      const getFamille = Axios.get(common.ipapi + '/api/model/index');
      const getReception = Axios.get(common.ipapi + '/api/vehicule/show/' + id);
      if(id){
        await Axios.all([getMarque, getFamille,getReception])
        .then(
          Axios.spread((...responses) => {
            setfamilleData(responses[1].data);
            setfamilleDatas(responses[1].data);
            setMarque(responses[0].data);
            setState({
              immatriculation:responses[2].data.immatr_eng,
              chassis: responses[2].data.chassis,
              model: responses[2].data.model ? responses[2].data.model.id : 0,
              marque_id: responses[2].data.marque_id,
              datecircul:  dateformat(responses[2].data.date_mise_circul, 'yyyy-mm-dd'),
              energie: responses[2].data.energie,
              couleur: responses[2].data.couleur
          });
            setLoad(false);
          })
        )
        .catch((errors) => {
          setLoad(false);
        });
      }else{
        await Axios.all([getMarque, getFamille])
          .then(
            Axios.spread((...responses) => {
              setfamilleData(responses[1].data);
              setfamilleDatas(responses[1].data);
              setMarque(responses[0].data);
              setLoad(false);
            })
          )
          .catch((errors) => {
            setLoad(false);
          });
      }
    })();
  }, [history]);
  const handleClose = () => {
    if (count !== 0) {
      getVehicule();
    }
    setShowAddVehicule(false);
    setIsSave(false);
  };
 
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      
      <Formik
                initialValues={{
                  immatriculation:immatriculation,
                  chassis: chassis,
                  model: model,
                  marque_id: marque_id,
                  datecircul: datecircul,
                  energie: energie,
                  couleur: couleur,user_id: common.userid,
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  immatriculation: Yup.string().required('Veuillez saisir cette donnée'),
                  chassis: Yup.string().required('Veuillez saisir cette donnée'),
                  couleur: Yup.string().required('Veuillez saisir cette donnée'),
                  datecircul: Yup.date().required('Veuillez saisir cette donnée'),
                  model: Yup.number().typeError('Aucune correspondance').required('Veuillez saisir cette donnée'),
                  marque_id: Yup.number().typeError('Aucune correspondance').required('Veuillez saisir cette donnée'),
                  energie: Yup.number().required('Veuillez saisir cette donnée')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        setLoad(true);
                        let url = id ? common.ipapi + '/api/vehicule/update/' + id : common.ipapi + '/api/vehicule/store';
                        await Axios.post(url, values)
                            .then((res) => {
                                if (res.data.status) {
                                    setMsg(res.data.msg);
                                    setMsgravity('success');
                                    setIsSave(true);
                                    count++;
                                    handleClose();
                                    setLoad(false);
                                } else {
                                    setMsg(res.data.msg);
                                    setMsgravity('warning');
                                    setIsSave(true);
                                    setLoad(false);
                                }
                            })
                            .catch(() => {
                                // setStatus({ success: false });
                            });
                        setSubmitting(false);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    
      <Modal show={show} size="md">
        <Form noValidate  onSubmit={handleSubmit}>
          <Modal.Header>
            {id ? 'MODIFICATION VEHICULE' : "CREATION D'UN VEHICULE"}
            <BackspaceFill onClick={handleClose} color="red" size={30} />
          </Modal.Header>
          <Modal.Body>
            <div className="lightbody">
            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>Immatriculation: <Star color="red" size={10} /></Form.Label>
                <Col sm={8}>
            
                  <Form.Control
                  id='immatriculation'
                    type="text"
                    placeholder="Immatriculation"
                    aria-describedby="inputGroupPrepend"
                    size="sm"
                    value={values.immatriculation}
                    onBlur={handleBlur}
                                            onChange={handleChange}
                  />
                  
                </Col>{errors.immatriculation && <div id="feedback">{errors.immatriculation}</div>}
              </Form.Group>
                  <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalEmail"
                        >
                          <Form.Label column sm={4}>
                          Chassis: <Star color="red" size={10} />
                      </Form.Label>
                          <Col sm={8}>
                          <InputGroup>
                  <Form.Control
                  id='chassis'
                    type="text"
                    placeholder="Chassis"
                    aria-describedby="inputGroupPrepend"
                    size="sm"
                    minLength={5}
                    maxLength={17}
                    value={values.chassis}
                    onBlur={handleBlur}
                                            onChange={handleChange}
                  />
                  <InputGroup.Text>{values.chassis.length}</InputGroup.Text>
                  </InputGroup>
                  
                </Col>{errors.chassis && <div id="feedback">{errors.chassis}</div>}
              </Form.Group>
              
                  <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalEmail"
                        >
                          <Form.Label column sm={4}>
                          En circulation le: <Star color="red" size={10} />
                      </Form.Label>
                          <Col sm={8}>
                  <Form.Control
                  id='datecircul'
                    type="date"
                    placeholder="Date de mise en circulation"
                    aria-describedby="inputGroupPrepend"
                    size="sm"
                    value={values.datecircul}
                    onBlur={handleBlur}
                                            onChange={handleChange}
                  />
                
                </Col>  {errors.datecircul && <div id="feedback">{errors.datecircul}</div>}
              </Form.Group>
             
                  <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalEmail"
                        >
                          <Form.Label column sm={3}>
                          Marques: <Star color="red" size={10} />
                      </Form.Label>
                          <Col sm={9}>
                  <Typeahead
                    ref={typeaheadMarq}
                    inputProps={{ required: true }}
                    id="marque_id"
                    labelKey={(option) => `${option.libellemarq}`}
                    filterBy={['libellemarq']}
                    options={marque}
                    multiple={false}
                    onChange={(selected) => {
                        const value = selected.length > 0 ? selected[0].id : 0;
                        setFieldValue('marque_id', value);
                        if (selected.length > 0) {
                          const res = famille.filter((element) => element.marque_id == selected[0].id);
                          setfamilleData(res);
                        } else {
                          setfamilleData(familles);
                        }
                    }}
                    onInputChange={(text, event) => setFieldValue('marque_id', text)}
                    onBlur={(e) => setFieldTouched('marque_id', true)}
                    selected={marque.slice(
                      marque.findIndex((element) => element.id === values.marque_id),
                      marque.findIndex((element) => element.id === values.marque_id) + 1
                    )}
                  />
                 
                </Col> {errors.marque_id && <div id="feedback">{errors.marque_id}</div>}
              </Form.Group>
              <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalEmail"
                        >
                          <Form.Label column sm={3}>
                          Models: <Star color="red" size={10} />
                      </Form.Label>
                          <Col sm={9}>
                  <Typeahead
                    ref={typeaheadModel}
                    id="model"
                    labelKey={(option) => `${option.libellemodel}`}
                    filterBy={['libellemodel']}
                    inputProps={{ required: true }}
                    options={famille}
                    multiple={false}
                    onChange={(selected) => {
                        const value = selected.length > 0 ? selected[0].id : 0;
                        setFieldValue('model', value);
                    }}
                    onInputChange={(text, event) => setFieldValue('model', text)}
                    onBlur={(e) => setFieldTouched('model', true)}
                    selected={famille.slice(
                      famille.findIndex((element) => element.id === values.model),
                      famille.findIndex((element) => element.id === values.model) + 1
                    )}
                  />
                 
                </Col>{errors.model && <div id="feedback">{errors.model}</div>}
              </Form.Group>
              <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalEmail"
                        >
                          <Form.Label column sm={5}>
                          Type énergie: <Star color="red" size={10} />
                      </Form.Label>
                          <Col sm={7}>
                          <div role="group" aria-labelledby="my-radio-group">
                                            <label className="radio">
                                                <Field type="radio" name="energie" value="0" />
                                                Essence
                                            </label>
                                            <label className="radio">
                                                <Field type="radio" name="energie" value="1" />
                                                Gasoil
                                            </label>
                                        </div>
                                        
                 
                </Col>
              </Form.Group>
              <Form.Group 
                                    as={Row}
                                    className="mb-3"
                                    controlId="formHorizontalEmail"
                                >
                                    <Form.Label column sm={3}>
                                        Couleur:<Star color="red" size={10} />
                                    </Form.Label>
                                    <Col sm={9}>
                                    <Form.Select
                                        labelId="demo-select-small"
                                        id="couleur"
                                        // name="typeid"
                                        value={values.couleur}
                                        label="Couleur"
                                        onBlur={handleBlur}
                                            onChange={handleChange}
                                        style={{background: couleur}}
                                    >
                                        {
                                             common.nomcouleur.map((prt, index) => {
                                                return (
                                                    <option key={index} id="parentid" value={common.codecouleur[index]} style={{ background: common.codecouleur[index]}}>
                                                        {prt}
                                                    </option>
                                                );
                                            })
                                          }
                                    </Form.Select>
                                    
                                    </Col>{errors.couleur && <div id="feedback">{errors.couleur}</div>}
                                </Form.Group>
         
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup aria-label="Basic example" size="sm">
              <Button color="secondary" onClick={handleClose}>
                <BackspaceFill color="red" size={20} />
              </Button>
              <Button type="submit" color="default">
                <Save2Fill color="#ff7300" size={20} /> Enregister
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Form>
      </Modal>
                )}
                </Formik>
    </>
  );
}

export default AddVehicule;
