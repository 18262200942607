import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Spinner, ButtonGroup, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { BackspaceFill, Save2Fill, Star } from 'react-bootstrap-icons';
import common from '../../commonData';
import dateformat from 'dateformat';

var count = 0;
function EditBonSortie({
  data,
  rows,
  setUpdateBon,
  show,
  setIsSave,
  setData,
  enreparat,
  setEnRepa,
  setSort,
  sortie,
  setMsg,
  setMsgravity,
  vue,
}) {
  const [validated, setValidated] = useState(false);
  const [datebon, setdateBon] = useState('');
  const [nobon, setNoBon] = useState('');
  const [load, setLoad] = useState(false);
  let { ids } = useParams();
  useEffect(() => {
    setNoBon(data.nobon);
    setdateBon(dateformat(data.datebon, 'yyyy-mm-dd'));
  }, [data.nobon, data.datebon]);

  function saveSortie(e) {
    setLoad(true);

    var formData = new FormData();
    formData.append('datebon', datebon);
    formData.append('nobon', nobon);
    Axios({
      url: common.ipapi + '/api/reception/updatebon/' + data.id,
      method: 'POST',
      headers: {
        'Content-Type': 'form-data',
      },
      data: formData,
    })
      .then((res) => {
        if (res.data.status == 1) {
          if (datebon == '' && nobon == '') {
            enreparat++;
            sortie--;
            setEnRepa(enreparat);
            setSort(sortie);
          }
          const isLargeNumber = (element) => element.id == data.id;
          const cloneProducts = [...rows];
          cloneProducts[rows.findIndex(isLargeNumber)].datebon = datebon;
          cloneProducts[rows.findIndex(isLargeNumber)].nobon = nobon;
          setData(cloneProducts);
          setNoBon('');
          setdateBon('');
          setMsg(res.data.msg);
          setMsgravity('info');
          setIsSave(true);
          setLoad(false);
          setUpdateBon(false);
        } else {
          setMsg(res.data.msg);
          setMsgravity('danger');
          setIsSave(true);
          setLoad(false);
        }
      })
      .catch((error) => {
        setIsSave(true);
        setMsg(error);
        setMsgravity('danger');
        setLoad(false);
      });
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      saveSortie();
    }
  };

  const handleClose = () => {
    setUpdateBon(false);
    setIsSave(false);
  };
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      <Modal show={show} size="md">

        <Modal.Header>{'MODIFIER CE BON DE SORTIE'}</Modal.Header>
        <Form noValidate validated={validated} onSubmit={handleSubmit}><Modal.Body>
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={4}>N° de bon sortie: <Star color="red" size={10} /></Form.Label>
                <Col sm={8}>
              <Form.Control
                type="text"
                placeholder="N° de bon"
                aria-describedby="inputGroupPrepend"
                size="sm"
                onChange={(e) => setNoBon(e.target.value)}
                value={nobon}
              />
            </Col>
            <Form.Control.Feedback>
              Veuillez saisir le numero de bon
                </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={4}>Date sortie: <Star color="red" size={10} /></Form.Label>
                <Col sm={8}>
              <Form.Control
                type="date"
                placeholder="Date de sortie"
                aria-describedby="inputGroupPrepend"
                size="sm"
                onChange={(e) => setdateBon(e.target.value)}
                value={datebon}
              />
            </Col>
          </Form.Group>
          <Modal.Footer>
          <ButtonGroup aria-label="Basic example" size="sm">
            <Button color="danger" onClick={handleClose}>
              <BackspaceFill color="#000" size={20} />
            </Button>
            <Button type="submit" color="default">
              <Save2Fill color="#ff7300" size={20} /> Enregister
                </Button>
          </ButtonGroup>
        </Modal.Footer>
        </Modal.Body>
        </Form>

      </Modal>
    </>
  );
}

export default EditBonSortie;
