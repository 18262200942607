import React, { useEffect, useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Form, InputGroup, ButtonGroup, Spinner, Modal } from 'react-bootstrap';
import { Button, Row, Col, Label } from 'reactstrap';
import Axios from 'axios';
import {
  BackspaceFill,
  Images,
  Save2Fill,
  Star,
  Trash,
} from 'react-bootstrap-icons';
import common from '../../commonData';
import { Typeahead } from 'react-bootstrap-typeahead';
var count = 0;

function AddFamille({
  setShowAddFamille,
  show,
  setIsSave,
  setDataFamille,
  setMsg,
  setMsgravity,
}) {
  const [validated, setValidated] = useState(false);
  const [imgs, setImage] = useState('');
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const [marque_id, setMarqueId] = useState('');
  const [marque, setMarque] = useState([]);
  const [libellefam, setLibellefam] = useState('');
  const [file, setFile] = useState('');
  const [imgsTf, setImageTf] = useState('');
  const typeaheadmarref = useRef(null);

  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/login');
    }
    (async function anyNameFunction() {
      setLoad(true);
      await Axios.get(common.ipapi + '/api/marque/index')
        .then((response) => {
          setMarque(response.data);
          setLoad(false);
        })
        .catch((errors) => {
          setLoad(false);
        });
    })();
  }, [history]);

  function saveArticle(e) {
    setLoad(true);
    var formData = new FormData();
    formData.append('libellemodel', libellefam);
    formData.append('user_id', common.userid);
    formData.append('marque_id', marque_id);
    formData.append('photo', imgsTf);
    Axios({
      url: common.ipapi + '/api/model/store',
      method: 'POST',
      headers: {
        'Content-Type': 'form-data',
      },
      data: formData,
    })
      .then((res) => {
        setLibellefam('');
        setMsg(res.data.msg);
        setMsgravity('info');
        typeaheadmarref.current.clear();
        count++;
        setIsSave(true);
        setLoad(false);
      })
      .catch((error) => {
        setMsg(error);
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
      });
  }
  function getfamille() {
    Axios.get(common.ipapi + '/api/model/index')
      .then((response) => {
        setDataFamille(response.data);
        count = 0;
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      saveArticle();
    }
  };

  const handleClose = () => {
    if (count !== 0) {
      getfamille();
    }
    setShowAddFamille(false);
    setIsSave(false);
  };

  function setMarqueIds(d) {
    setMarqueId(d.length > 0 ? d[0].id : 0);
  }
  function onChangeImage(e) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }
    setFile(e.target.value);
    setImage(URL.createObjectURL(e.target.files[0]));
    createImage(files[0]);
  }
  function createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      setImageTf(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  function onDeleteImg() {
    setImage('');
  }
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      <Modal show={show} size="md">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header>
            AJOUTER UN NOUVEAU MODEL
            <BackspaceFill onClick={handleClose} color="red" size={30} />
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    Libellé model:
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Libellé model"
                    aria-describedby="inputGroupPrepend"
                    required
                    size="sm"
                    value={libellefam}
                    onChange={(e) =>
                      setLibellefam(e.target.value.toUpperCase())
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Veuillez renseigner le Libellé model
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="client">
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    Marques:
                    <Star color="red" size={10} />
                  </InputGroup.Text>
                  <Typeahead
                    id="my-typeahead-id"
                    labelKey={(option) => `${option.libellemarq}`}
                    filterBy={['libellemarq']}
                    value={marque_id}
                    ref={typeaheadmarref}
                    inputProps={{ required: true }}
                    // selected = {marque.slice(marque.findIndex((element) => element.id == marque_id), marque.findIndex((element) => element.id == marque_id)+1)}
                    options={marque}
                    onChange={(cli) => setMarqueIds(cli)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Veuillez selectionnez la marque.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Label className="custom-file-upload" title="Ajouter des images.">
                {' '}
                Cliquer pour ajouter une image
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg"
                  value={file}
                  onChange={(e) => onChangeImage(e)}
                />
                <Images color="orange" size={50} />
              </Label>
              {imgs ? (
                <Col md="12">
                  <Trash onClick={() => onDeleteImg()} color="red" size={15} />
                  <img
                    alt="index"
                    className="imgartUpload rounded"
                    src={imgs}
                  ></img>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup size="sm">
              <Button color="secondary" onClick={handleClose}>
                <BackspaceFill color="red" size={20} />
              </Button>
              <Button type="submit" color="default">
                <Save2Fill color="#807f7e" size={20} /> Enregister
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddFamille;
