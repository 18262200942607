import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
  Button,
} from 'react-bootstrap';
import {
  Trash,
  FileExcel,
  PlusCircleDotted,
  Printer,
  Check2,
  LayersFill,
  CheckLg,
  FileArrowDown,
  BorderWidth,
  CursorFill,
  Check2All,
  CashStack,
  Cursor,
  PencilSquare,
  Backspace,
  Search,
  ReplyAll,
} from 'react-bootstrap-icons';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import XLSX from 'sheetjs-style';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import 'assets/css/tableGar.css';
import dateformat from 'dateformat';
import common from '../../commonData';
import { useRef } from 'react';




// const toArray = (selected, dataMap) => {
//   const keysObj = selected === true ? dataMap : selected;
//   return Object.keys(keysObj).map(key => Number(key))
// }


function MyAwesomeTable({
  rows,
  isLoading,
  onvalideDir,
  setIds,
  title,
  setAdds,
  handleShow,
  DuplicateFacture,
  viewSortie,
  printFacture,
  download,
  tab,
  setShowFilterModals,
  setInitialiseData
}) {
  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }
  window.moment = moment;
  
  // const gridRef = useRef(null);
  const [gridRef, setGridRef] = useState(null);
  const [selected, setSelected] = useState({});
  const filterValue = [
    { name: 'nofiche', operator: 'contains', type: 'string', value: '' },
    { name: 'marque', operator: 'contains', type: 'string', value: '' },
    { name: 'model', operator: 'contains', type: 'string', value: '' },
    { name: 'chassis', operator: 'contains', type: 'string', value: '' },
    { name: 'client', operator: 'contains', type: 'string', value: '' },
    { name: 'clientf', operator: 'contains', type: 'string', value: '' },
    { name: 'immatr_eng', operator: 'contains', type: 'string', value: '' },
    { name: 'datereception', operator: 'inrange', type: 'date', value: '' },
    { name: 'dateprof', operator: 'inrange', type: 'date', value: '' },
    { name: 'datefact', operator: 'inrange', type: 'date', value: '' },
    { name: 'dateecheance', operator: 'inrange', type: 'date', value: '' },
    { name: 'noprof', operator: 'contains', type: 'string', value: '' },
    { name: 'nofact', operator: 'contains', type: 'string', value: '' },
  ];
  const Nofacture = ({ data }) => {
    var color = 'secondary';
    if (data.etat == 3) {
      color = 'secondary';
    } else if (data.etat == 4) {
      color = 'info';
    } else if (data.etat == 5) {
      color = 'success';
    } else if (data.etat == 7) {
      color = 'danger';
    } else if (data.etat == 8) {
      color = 'danger';
    } else {
      // color = 'secondary';
    }

    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="tooltip-disabled">Cliquer pour plus de détails</Tooltip>
        }
      >
        {
          <Button
            variant={color}
            id={color}
            size="sm"
            onClick={() => viewSortie(data.id, 1)}
          >
            {data.nofact}
          </Button>
        }
      </OverlayTrigger>
    );
  };

  const Noprofs = ({ data }) => {
    var color = 'secondary';
    if (data.etat == 1) {
      color = 'primary';
    } else if (data.etat == 2) {
      color = 'orange';
    } else if (data.etat == 6) {
      color = 'danger';
    } else if (data.etat == 8) {
      color = 'danger';
    } else if (data.etat == 3) {
      color = 'success';
    } else if (data.etat == 4) {
      color = 'info';
    } else if (data.etat == 5) {
      color = 'success';
    } else {
      //color = 'warning';
    }

    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="tooltip-disabled">Cliquer pour plus de détails</Tooltip>
        }
      >
        {
          <Button
            variant={color}
            id={color}
            size="sm"
            onClick={() => viewSortie(data.id, 0)}
          >
            {data.noprof}
          </Button>
        }
      </OverlayTrigger>
    );
  };

  const Reglement = ({ data }) => {
    var regl = 0;
    if (data.reglement.length > 0) {
      data.reglement.forEach((element) => {
        regl += element.pivot.montant;
      });
    }
    return new Intl.NumberFormat().format(regl);
  };
  const Reste = ({ data }) => {
    var regl = 0;
    if (data.reglement.length > 0) {
      data.reglement.forEach((element) => {
        regl += element.pivot.montant;
      });
    }
    return new Intl.NumberFormat().format(data.prix_ttc - regl);
  };

  const Immatr = ({ data }) => {
    return data.reception_id ? (
      <Link
        to={`/prestadmin/historyvehicule/${data.vehicule_id}`}
        id="actionbtns"
        title="Voir l'historique"
      >
        {data.immatr_eng}
      </Link>
    ) : (
        <>{data.immatr_eng}</>
      );
  };

  const Actions = ({ data }) => {
    return (
      <div className="tooltips">
        <BorderWidth size={24} />
        <span className={tab == 0 ? 'tooltiptext' : 'tooltiptexts'}>
          {data.nofact === 'neant' && data.etat == 0 ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Valider le devis</Tooltip>
              }
            >
              <Check2All
                color="blue"
                size={25}
                id="actionbtn"
                onClick={(e) => onvalideDir(data, 1)}
              />
            </OverlayTrigger>
          ) : data.etat == 1 ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  Anuuler la validation le devis
                </Tooltip>
              }
            >
              <Check2
                color="red"
                size={25}
                id="actionbtn"
                onClick={(e) => onvalideDir(data, 0)}
              />
            </OverlayTrigger>
          ) : (
                ''
              )}
          {data.nofact === 'neant' && data.etat == 2 ? (
            <>
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Annuler l'envoie au client
                  </Tooltip>
                }
              >
                <Cursor
                  color="red"
                  size={25}
                  id="actionbtn"
                  onClick={(e) => onvalideDir(data, 1)}
                />
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">Facturer la ligne</Tooltip>
                }
              >
                <CashStack
                  color="blue"
                  size={25}
                  id="actionbtn"
                  onClick={(e) => handleShow(data)}
                />
              </OverlayTrigger>
            </>
          ) : data.etat == 1 ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  Valider l'envoie au client
                </Tooltip>
              }
            >
              <CursorFill
                color="orange"
                size={25}
                id="actionbtn"
                onClick={(e) => onvalideDir(data, 2)}
              />
            </OverlayTrigger>
          ) : (
                ''
              )}
          {data.nofact === 'neant' &&
            data.etat != 1 &&
            data.etat != 2 &&
            data.etat != 3 &&
            data.etat != 4 &&
            data.etat != 5 &&
            data.etat != 6 &&
            data.etat != 7 &&
            data.etat != 8 &&
            data.reglement.length == 0 ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">Modifier le devis</Tooltip>
                }
              >
                <PencilSquare
                  color="green"
                  size={25}
                  id="actionbtn"
                  onClick={(e) => setIds(data.id)}
                />
              </OverlayTrigger>
            ) : data.nofact !== 'neant' && data.etat == 3 && tab == 1 ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Modifier l'entête de la facture
                </Tooltip>
                }
              >
                <PencilSquare
                  color="orange"
                  size={25}
                  id="actionbtn"
                  onClick={(e) => setIds(data.id)}
                />
              </OverlayTrigger>
            ) : (
                ''
              )}

          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">Dupliquer la ligne</Tooltip>
            }
          >
            <LayersFill
              color="royalblue"
              size={23}
              id="actionbtn"
              onClick={(e) => DuplicateFacture(data)}
            />
          </OverlayTrigger>
          {data.etat < 6 ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Imprimer la ligne</Tooltip>
              }
            >
              <Printer
                color="royalblue"
                size={23}
                id="actionbtn"
                onClick={(e) => printFacture(data.id)}
              />
            </OverlayTrigger>
          ) : (
              ''
            )}

          {data.etat == 3 && data.nofact !== 'neant' ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Valider la facture</Tooltip>
              }
            >
              <CheckLg
                color="green"
                size={23}
                onClick={() => onvalideDir(data, 4)}
              />
            </OverlayTrigger>
          ) : data.etat == 4 && data.nofact !== 'neant' ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  Annuler la validation de la facture
                </Tooltip>
              }
            >
              <CheckLg
                color="red"
                size={23}
                onClick={() => onvalideDir(data, 3)}
              />
            </OverlayTrigger>
          ) : (
                ''
              )}
          {data.etat < 3 && data.etat != 6 && tab == 0 && data.etat != 8 ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Supprimer le devis</Tooltip>
              }
            >
              <Trash
                id="actionbtn"
                onClick={() => onvalideDir(data, 6)}
                color="red"
                size={25}
              />
            </OverlayTrigger>
          ) : data.nofact !== 'neant' &&
            data.etat != 7 &&
            data.etat != 8 &&
            tab == 1 ? (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">Supprimer la facture</Tooltip>
                  }
                >
                  <Trash
                    id="actionbtn"
                    onClick={() => onvalideDir(data, 7)}
                    color="red"
                    size={25}
                  />
                </OverlayTrigger>
              ) : (
                ''
              )}
        </span>
      </div>
    );
  };

  const columns = [
    {
      name: 'id',
      header: 'Id',
      defaultVisible: false,
      defaultWidth: 80,
      type: 'number',
    },
    {
      header: 'Action',
      defaultWidth: 40,
      type: 'string',
      textAlign: 'start',
      editable: false,
      render: ({ data }) => <Actions data={data} tab={tab} />,
    },
    {
      name: 'nofiche',
      header: 'N° fiche',
      defaultWidth: 80,
      type: 'string',
      textAlign: 'center',
    },
    
    {
      name: 'datereception',
      header: 'Date recep.',
      textAlign: 'center',
      defaultWidth: 100,
      dateFormat: 'DD-MM-YYYY',
      type: 'date',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'DD-MM-YYYY',
          placeholder: index === 1 ? 'Date fin' : 'Date debut',
        };
      },
    },
    {
      name: 'client',
      header: 'Clients prof.',
      defaultWidth: 200,
      type: 'string',
    },
    {
      name: 'noprof',
      header: 'N° proforma',
      defaultWidth: 85,
      type: 'string',
      textAlign: 'center',
      render: ({ data }) => <Noprofs data={data} />,
    },
    {
      name: 'dateprof',
      header: 'Date prof.',
      textAlign: 'center',
      defaultWidth: 100,
      dateFormat: 'DD-MM-YYYY',
      type: 'date',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'DD-MM-YYYY',
          placeholder: index === 1 ? 'Date fin' : 'Date debut',
        };
      },
      render: ({ value, cellProps: { dateFormat } }) =>
        moment(value).format(dateFormat),
    },
    {
      name: 'immatr_eng',
      header: 'Immatriculation',
      defaultWidth: 95,
      textAlign: 'center',
      type: 'string',
      render: ({ data }) => <Immatr data={data} />,
    },
    {
      name: 'prix_ttc',
      header: 'Prix TTC',
      defaultWidth: 90,
      textAlign: 'start',
      type: 'number',
      render: ({ value }) => new Intl.NumberFormat().format(value),
    },
    {
      name: 'paye',
      header: 'Mont. payé',
      defaultWidth: 90,
      textAlign: 'start',
      type: 'number',
      render: ({ data }) => <Reglement data={data} />,
    },
    {
      name: 'rest',
      header: 'Reste',
      defaultWidth: 90,
      textAlign: 'start',
      type: 'number',
      render: ({ data }) => <Reste data={data} />,
    },
    { name: 'marque', header: 'Marques', defaultWidth: 120, type: 'string' },
    { name: 'model', header: 'Models', defaultWidth: 120, type: 'string' },
    { name: 'chassis', header: 'Chassis', defaultWidth: 170, type: 'string' },
    {
      name: 'username',
      header: 'Crée par',
      defaultWidth: 120,
      type: 'string',
      render: ({ data }) => data.user || 'Neant',
    },
    {
      name: 'created_at',
      header: 'Crée le',
      defaultWidth: 130,
      type: 'string',
      render: ({ data }) =>
        data.created_at
          ? dateformat(data.created_at, 'dd-mm-yyyy HH:MM')
          : 'neant',
    },
  ];


  const columnss = [
    {
      name: 'id',
      header: 'Id',
      defaultVisible: false,
      defaultWidth: 80,
      type: 'number',
    },
    {
      header: 'Action',
      defaultWidth: 40,
      type: 'string',
      textAlign: 'start',
      editable: false,
      render: ({ data }) => <Actions data={data} />,
    },
    {
      name: 'nofiche',
      header: 'N° fiche',
      defaultWidth: 80,
      type: 'string',
      textAlign: 'center',
    },
    {
      name: 'datereception',
      header: 'Date recep.',
      textAlign: 'center',
      defaultWidth: 100,
      dateFormat: 'DD-MM-YYYY',
      type: 'date',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'DD-MM-YYYY',
          placeholder: index === 1 ? 'Date fin' : 'Date debut',
        };
      },
    },
    {
      name: 'clientf',
      defaultVisible: true,
      header: 'Clients fact.',
      defaultWidth: 200,
      type: 'string',
    },
    {
      name: 'nofact',
      header: 'N° facture',
      defaultWidth: 80,
      type: 'string',
      render: ({ data }) => <Nofacture data={data} />,
    },
    {
      name: 'datefact',
      defaultVisible: true,
      type: 'date',
      header: 'Date fact.',
      textAlign: 'center',
      defaultWidth: 100,
      dateFormat: 'DD-MM-YYYY',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'DD-MM-YYYY',
          placeholder: index === 1 ? 'Date fin' : 'Date debut',
        };
      },
      render: ({ value, cellProps: { dateFormat } }) =>
        moment(value).format(dateFormat),
      // render: ({ data }) => <Dateentr data={data} />,
    },
    {
      name: 'client',
      header: 'Clients prof.',
      defaultWidth: 200,
      type: 'string',
    },
    
    {
      name: 'dateecheance',
      defaultVisible: true,
      type: 'date',
      header: 'Date écheance',
      textAlign: 'center',
      defaultWidth: 100,
      dateFormat: 'DD-MM-YYYY',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'DD-MM-YYYY',
          placeholder: index === 1 ? 'Date fin' : 'Date debut',
        };
      },
      render: ({ value, cellProps: { dateFormat } }) =>
        moment(value).format(dateFormat),
      // render: ({ data }) => <Dateentr data={data} />,
    },

    {
      name: 'noprof',
      header: 'N° proforma',
      defaultWidth: 85,
      type: 'string',
    },

    {
      name: 'dateprof',
      header: 'Date prof.',
      textAlign: 'center',
      type: 'date',
      defaultWidth: 100,
      dateFormat: 'DD-MM-YYYY',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'DD-MM-YYYY',
          placeholder: index === 1 ? 'Date fin' : 'Date debut',
        };
      },
      render: ({ value, cellProps: { dateFormat } }) =>
        moment(value).format(dateFormat),
    },
    {
      name: 'immatr_eng',
      header: 'Immatriculation',
      defaultWidth: 90,
      type: 'string',
      render: ({ data }) => <Immatr data={data} />,
    },
    {
      name: 'prix_ttc',
      header: 'Prix TTC',
      defaultWidth: 90,
      textAlign: 'start',
      type: 'number',
      render: ({ value }) => new Intl.NumberFormat().format(value),
    },
    {
      name: 'paye',
      header: 'Mont. payé',
      defaultWidth: 75,
      textAlign: 'start',
      type: 'number',
      render: ({ data }) => <Reglement data={data} />,
    },
    {
      name: 'rest',
      header: 'Reste',
      defaultWidth: 75,
      textAlign: 'start',
      type: 'number',
      render: ({ data }) => <Reste data={data} />,
    },
    { name: 'marque', header: 'Marques', defaultWidth: 120, type: 'string' },
    { name: 'model', header: 'Models', defaultWidth: 120, type: 'string' },
    { name: 'chassis', header: 'Chassis', defaultWidth: 170, type: 'string' },
    {
      name: 'username',
      header: 'Crée par',
      defaultWidth: 120,
      type: 'string',
      render: ({ data }) => data.user || 'Neant',
    },
    {
      name: 'created_at',
      header: 'Crée le',
      defaultWidth: 130,
      type: 'string',
      render: ({ data }) =>
        data.created_at
          ? dateformat(data.created_at, 'dd-mm-yyyy HH:MM')
          : 'neant',
    },
  ];

  const gridStyle = { minHeight: window.screen.height - 200 };

  async function printListe() {
    console.log(gridRef.current.data);
    if (gridRef.current.data.length <= 0) {
      return;
    }
    var doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    });
    var width = doc.internal.pageSize.getWidth();
    var data= [];
    var datas= [];
    let mont = 0;
    let totalTtc = 0;
    let tregl = 0;
    doc.addImage(common.logo, 'jpg', 14, 10, 30, 30);
    doc.setFontSize(16);
    doc.text('HR MOTORS GARAGE', 90, 14);
    doc.setFontSize(12);
    doc.setFillColor(255, 206, 154);
    doc.rect(65, 15, width - 80, 10, 'F');
    doc.setTextColor(0, 0, 0);
    doc.text('LISTE DES DEVIS ET FACTURES', 90, 22);
    doc.setTextColor(0, 0, 0);

    gridRef.current.data.forEach((row) => {
      totalTtc += row.prix_ttc;
      var regl = 0;
      if (row.reglement.length > 0) {
        row.reglement.forEach((element) => {
          regl += element.pivot.montant;
        });
      }
      tregl += regl;
      data.push([
        row.nofiche,
        dateformat(row.datereception, 'dd-mm-yyyy'),
        row.client,
        row.marque,
        row.model,
        row.immatr_eng,
        // row.chassis,
        row.noprof,
        dateformat(row.dateprof, 'dd-mm-yyyy'),
        row.nofact,
        row.nofact !== 'neant' ? dateformat(row.datefact, 'dd-mm-yyyy') : 'neant',
        new Intl.NumberFormat('de-DE').format(row.prix_ttc),
        new Intl.NumberFormat('de-DE').format(regl),
        new Intl.NumberFormat('de-DE').format(row.prix_ttc - regl),
      ]);
      console.log(regl);
    });
    datas.push([
      {content: new Intl.NumberFormat('de-DE').format(totalTtc), styles:{fosntStyle: 'bold', fontSize:'12'}},
      {content: new Intl.NumberFormat('de-DE').format(tregl), styles:{fosntStyle: 'bold', fontSize:'12'}},
      {content: new Intl.NumberFormat('de-DE').format(totalTtc - tregl), styles:{fosntStyle: 'bold', fontSize:'12'}},
    ]);
  

    doc.autoTable({
      styles: {
        fillColor: [0, 0, 0],
        textColor: [255, 255, 255],
        halign: 'center',
        fontSize: 10,
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        1: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        2: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        3: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        4: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        5: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        6: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        7: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        8: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        9: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        10: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        11: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        12: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        // 13: {
        //   halign: 'left',
        //   fillColor: [255, 255, 255],
        //   textColor: [0, 0, 0],
        //   fontSize: 8,
        // },
      },
      theme: 'grid',
      startY: 35,
      fontSize: 6,
      head: [
        [
          'N° fiche',
          'Reçu le'
,          'Clients',
          'Marques',
          'Models',
          'Immatr.',
          // 'Chassis',
          'N° devis',
          'Date devis',
          'N° facture',
          'Date fact.',
          'Mont. TTC',
          'Mont. payé',
          'Reste',
        ],
      ],
      body: data,
    });

       
  doc.autoTable({
    styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 1,
        lineColor: [0, 0, 0],
        halign: 'center',
        fontSize: 8
    },
    columnStyles: {
        0: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12 },
        1: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12 },
        2: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12 },
    },
    theme: 'grid',
    startY: doc.lastAutoTable.finalY + 4,
    fontSize: 9,
    head: [['TOTAL TTC','TOTAL REGLE','RESTE A REGELER']],
    body: datas
});
    const pageCount = doc.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        'Page ' + String(i) + ' sur ' + String(pageCount),
        210 - 55,
        200,
        null,
        null,
        'right'
      );
    }
    doc.text(
      'Edité par ' + common.user + ' le: ' + dateformat(new Date(), 'dd-mm-yyyy'),
      16,
      200,
      null,
      null,
      'left'
  );
    doc.save('liste facture.pdf');
  }

  //--------------- EXPORT VERS EXCEL ------------------

  const exportToExcel = async () => {
    const raw_data = gridRef.current.data;
    
    const rows = raw_data.map((row, index) => ({
      index: index+1,
      nofiche: row.nofiche,
      datereception: dateformat(row.datereception, 'dd-mm-yyyy'),
      client: row.client,
      marque: row.marque,
      model: row.model,
      immatr_eng: row.immatr_eng,
      chassis: row.chassis,
      noprof: row.noprof,
      dateprof: dateformat(row.dateprof, 'dd-mm-yyyy'),
      nofact: row.nofact,
      datefact: row.nofact !== 'neant' ? dateformat(row.datefact, 'dd-mm-yyyy') : 'neant',
      prix_ttc: new Intl.NumberFormat('de-DE').format(row.prix_ttc),
      regl: '',//new Intl.NumberFormat('de-DE').format(regl),
      reste: '',//new Intl.NumberFormat('de-DE').format(row.prix_ttc - regl),
    }));
    
   

    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes factures');
    /* fix headers */
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          'Ordre',
          'N° fiche',
          'Reçu le'
,         'Clients',
          'Marques',
          'Models',
          'Immatr.',
          'Chassis',
          'N° devis',
          'Date devis',
          'N° facture',
          'Date fact.',
          'Mont. TTC',
          'Mont. payé',
          'Reste',
        ],
      ],
      { origin: 'A1' }
    );
    /* calculate column width */
    const max_width = rows.reduce((w, r) => Math.max(w, r.nofiche.length), 10);
    worksheet['!cols'] = [{ wch: max_width }];
    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, 'factures.xlsx');
  };

  async function saveAsExcel() {
    exportToExcel();
  }

  // const onFilterValueChange = useCallback((filterValue) => {
  //   setFilterDate(filterValue[2].value);
  // }, []);

  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const dataMap = gridRef && gridRef.current && gridRef.current.dataMap ? gridRef.current.dataMap : null;

    const toArrays = (selected, dataMap) => {
      const keysObj = selected === true ? dataMap : selected;
      return Object.keys(keysObj).map(key => Number(key))
    }
  //const toArray = (selected) => Object.keys(selected).map((id) => id * 1);
 
  return (
    <div>
      <Navbar expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand href="#/prestadmin/factures">{title}</Navbar.Brand>
          <Nav className="me-auto">
            {!tab ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">Editer un devis</Tooltip>
                }
              >
                <Nav.Link
                  href="#/prestadmin/factures"
                  onClick={() => setAdds(true)}
                >
                  <PlusCircleDotted color="#ee1111" size={30} /> Créer
                </Nav.Link>
              </OverlayTrigger>
            ) : (
                ''
              )}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>
              }
            >
              <Nav.Link
                href="#/prestadmin/factures"
                onClick={() => saveAsExcel()}
              >
                <FileExcel color="#0080ff" size={30} /> Excel
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-disabled">Exporter vers PDF</Tooltip>
              }
            >
              <Nav.Link
                href="#/prestadmin/factures"
                onClick={() => printListe()}
              >
                <Printer color="#0080ff" size={30} /> Imprimer
              </Nav.Link>
            </OverlayTrigger>
            {tab ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Fichier d'integration vers SAGE
                  </Tooltip>
                }
              >
                <Nav.Link
                  href="#/prestadmin/factures"
                  onClick={() => download(JSON.stringify(toArrays(selected,dataMap)))}
                >
                  <FileArrowDown color="#0080ff" size={30} /> Export sage
                </Nav.Link>
              </OverlayTrigger>
            ) : (
                ''
              )}
          </Nav>
          <Nav>
          <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                 Recherche filtre
                </Tooltip>
              }
            >
              <Nav.Link
               href="#/prestadmin/factures"
                onClick={() => setShowFilterModals(true)}
              >
                <Search color="#0080ff" size={30} />
              </Nav.Link>
            </OverlayTrigger>
            
            {
              localStorage.getItem('fdatedebut') !== null ? (
                <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                   Annuler le filtre
                  </Tooltip>
                }
              >
                <Nav.Link
                 href="#/prestadmin/factures"
                  onClick={() => setInitialiseData()}
                >
                  <ReplyAll color="red" size={30} />
                </Nav.Link>
              </OverlayTrigger>
               ) : (
                <></>
              )
              
            }
          </Nav>
        </Container>
      </Navbar>

      <ReactDataGrid
        idProperty="id"
        handle={setGridRef}
        style={gridStyle}
        loading={isLoading}
        reorderColumns={false}
        pagination
        selected={selected}
        checkboxColumn={tab ? true : false}
        onSelectionChange={onSelectionChange}
        pageSizes={[200, 400, 600, 800, rows.length]}
        defaultLimit={400}
        showHoverRows={true}
        editable={true}
        showColumnMenuTool={false}
        defaultFilterValue={filterValue}
        columns={tab ? columnss : columns}
        dataSource={rows}
      />
       <p>Selected rows: {JSON.stringify(toArrays(selected, dataMap))}.</p>
    </div>
  );
}

export default MyAwesomeTable;
