import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, ButtonGroup, Spinner, Modal,Row } from 'react-bootstrap';
import { Button, Input, Col } from 'reactstrap';
import Axios from 'axios';
import { BackspaceFill, Save2Fill,Star } from 'react-bootstrap-icons';
import * as Yup from 'yup';
import { Formik } from 'formik';

import common from '../../commonData';
var count = 0;
function AddPrestation({
  id,
  setShowAddprest,
  show,
  setIsSave,
  setDataPrestation,
  setMsg,
  setMsgravity,
}) {
  const [states, setStates] = useState({
    libprestation:'',
    typeprestation: 'Prestations Fréquentes',
    categorie: 'Prestation',
    prixprestation: '',
});
const { libprestation, typeprestation, categorie, prixprestation } = states;



  const history = useHistory();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/login');
    }
    if(id){
      Axios.get(common.ipapi + '/api/prestation/edit/' + id)
        .then((response) => {
          setStates({
            libprestation: response.data.libprestation,
            typeprestation: response.data.typeprestation,
            categorie: response.data.categorie,
            prixprestation: response.data.prixprestation,
        });
          // setLibPrest(response.data.libprestation);
          // setTypePrest(response.data.typeprestation);
          // setPrixPrest(response.data.prixprestation);
          // setCategorie(response.data.categorie);
          setLoad(false);
        })
        .catch((errors) => {
          // react on errors.
        });
    }
  },[id]);

  // function saveClient(values) {
  //   setLoad(true);
  //   var formData = new FormData();
  //   formData.append('libprestation', values.libprest);
  //   formData.append('typeprestation', values.typeprest);
  //   formData.append('categorie', values.categorie);
  //   formData.append('prixprestation', values.prixprest);
  //   formData.append('user_id', common.userid);
  //   Axios({
  //     url: common.ipapi + '/api/prestation/store',
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'form-data',
  //     },
  //     data: formData,
  //   })
  //     .then((res) => {
  //       if (res.data.status == 1) {
  //         count += 1;
  //         setMsg(res.data.msg);
  //         setLibPrest('');
  //         setPrixPrest(0);
  //         setMsgravity('success');
  //         setIsSave(true);
  //         setLoad(false);
  //       } else {
  //         setMsg(res.data.msg);
  //         setMsgravity('danger');
  //         setIsSave(true);
  //         setLoad(false);
  //       }
  //     })
  //     .catch((error) => {
  //       setMsg('Une erreur à eu lieu');
  //       setMsgravity('danger');
  //       setIsSave(true);
  //       setLoad(false);
  //     });
  // }

  function getClient() {
    Axios.get(common.ipapi + '/api/prestation/index')
      .then((response) => {
        setDataPrestation(response.data);
        count = 0;
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }
  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     setValidated(true);
  //   } else {
  //     saveClient();
  //   }
  // };

  const handleClose = () => {
    if (count !== 0) {
      getClient();
    }
    setShowAddprest(false);
    setIsSave(false);
  };

  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      
      <Formik
                initialValues={{
                  libprestation:libprestation,
                  typeprestation: typeprestation,
                  categorie: categorie,
                  prixprestation: prixprestation,
                  user_id: common.userid,
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  libprestation: Yup.string().required('Veuillez saisir cette donnée'),
                  typeprestation: Yup.string().required('Veuillez saisir cette donnée'),
                  categorie: Yup.string().required('Veuillez saisir cette donnée'),
                  prixprestation: Yup.number().required('Veuillez saisir cette donnée'),
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        setLoad(true);
                        let url = id ? common.ipapi + '/api/prestation/update/' + id : common.ipapi + '/api/prestation/store';
                        await Axios.post(url, values)
                            .then((res) => {
                                if (res.data.status) {
                                    setMsg(res.data.msg);
                                    setMsgravity('info');
                                    setIsSave(true);
                                    setStates({
                                      prixprestation: '',
                                      libprestation:''
                                    })
                                    count++;
                                    setLoad(false);
                                    if(id){
                                      setTimeout(handleClose, 600);
                                    }
                                } else {
                                    setMsg(res.data.msg);
                                    setMsgravity('warning');
                                    setIsSave(true);
                                    setLoad(false);
                                }
                            })
                            .catch(() => {
                                // setStatus({ success: false });
                            });
                        setSubmitting(false);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
               
      <Modal show={show} size="md">
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
            {id ? 'MODIFICATION UNE PRESTATION' : 'AJOUTER UNE NOUVELLE PRESTATION'}
            <BackspaceFill onClick={handleClose} color="red" size={30} />
          </Modal.Header>
          <Modal.Body>
          <Form.Group as={Row} className="mb-3" >
          <Form.Label column sm={4}>Libellé prestation: <Star color="red" size={10} /></Form.Label>
                <Col sm={8}>
                <Form.Control
                id='libprestation'
                  type="text"
                  placeholder="Libellé prestation"
                  aria-describedby="inputGroupPrepend"
                  required
                  size="sm"
                  value={values.libprestation}
                  onBlur={handleBlur}
                  onChange={handleChange}
/>
{errors.libprestation && <div id="feedback">{errors.libprestation}</div>}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
            <Form.Label column sm={4}>Types prestations: <Star color="red" size={10} /></Form.Label>
                <Col sm={8}>
                <Input
                // id='typeprestation'
                  type="select"
                  placeholder="Agents"
                  value={values.typeprestation}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name='typeprestation'
                >
                  <option value={0}></option>
                  {[
                    {
                      value: 'Batterie, alternateur et démarreur',
                    },
                    {
                      
                      value: 'Climatisation & Chauffage',
                    },
                    {  value: 'Diagnostics' },
                    {
                      
                      value: 'Direction & Transmission',
                    },
                    {  value: 'Électricité/Vision' },
                    {  value: 'Échappement' },
                    {  value: 'Embrayage' },
                    {  value: 'Freinage' },
                    {  value: 'Moteur' },
                    {
                      
                      value: 'Prestations Fréquentes ',
                    },
                    {  value: 'Pneus/Roues' },
                    {  value: 'Suspension' },
                  ].map((d, index) => {
                    return (
                      <option key={index} value={d.id}>
                        {d.value}
                      </option>
                    );
                  })}
                </Input>
                {errors.typeprestation && <div id="feedback">{errors.typeprestation}</div>}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
            <Form.Label column sm={4}>Catégorie: <Star color="red" size={10} /></Form.Label>
                <Col sm={8}>
                <Input
                id='categorie'
                  type="select"
                  placeholder="Agents"
                  value={values.categorie}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {[
                    { value: 'Prestation' },
                    {  value: 'Pièce' },
                    {  value: 'Debours' },
                  ].map((d, index) => {
                    return (
                      <option key={index} value={d.id}>
                        {d.value}
                      </option>
                    );
                  })}
                </Input>
                {errors.categorie && <div id="feedback">{errors.categorie}</div>}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
            <Form.Label column sm={4}>Prix prestation:<Star color="red" size={10} /></Form.Label>
                <Col sm={8}>
                <Form.Control
                id='prixprestation'
                  type="number"
                  placeholder="Prix prestation"
                  aria-describedby="inputGroupPrepend"
                  min={0}
                  size="sm"
                  value={values.prixprestation}
                  onBlur={handleBlur}
                                            onChange={handleChange}
                  />
                  {errors.prixprestation && <div id="feedback">{errors.prixprestation}</div>}
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup aria-label="Basic example" size="sm">
              <Button color="secondary" onClick={handleClose}>
                <BackspaceFill color="red" size={20} />
              </Button>
              <Button type="submit" color="default">
                <Save2Fill color="#ff7300" size={20} /> Enregister
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Form>
      </Modal>
                )}
                </Formik>
    </>
  );
}

export default AddPrestation;
