import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Spinner, Modal, Toast } from 'react-bootstrap';
import commondata from '../../commonData';
import MyAwesomeTable from './gridtables';
import AddAgent from './addAgent';
// import EditAgent from './editAgent';

function Agents() {
  const history = useHistory();
  const [datas, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgGravity, setMsgravity] = useState('success');
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [ids, setId] = useState(0);

  const [isSave, setIsSave] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('user-info-g')) {
      (async function anyNameFunction() {
        await getAgent();
      })();
    } else {
      history.push('/login');
    }
  }, [history]);

  async function getAgent() {
    setLoad(true);
    Axios.get(commondata.ipapi + '/api/agent/index')
      .then((response) => {
        setData(response.data.data);
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }
  function setAdds(val) {
    setId(0);
    setShowAdd(val);
  }
  function setIds(id) {
    setId(id);
    setShowAdd(true);
  }

  function onDelete(id) {
    if (window.confirm('Voulez-vous supprimer cette ligne ?')) {
      setLoad(true);
      Axios.delete(commondata.ipapi + '/api/agent/destroy/' + id).then(
        (response) => {
          if (response.data.status == 1) {
            const del = datas.filter((d) => d.id !== id);
            setData(del);
            setLoad(false);
          } else {
          }
        }
      );
    }
  }

  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      <Toast
        id="toast"
        onClose={() => setIsSave(false)}
        show={isSave}
        delay={3000}
        className={'bg-' + msgGravity}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Info</strong>
        </Toast.Header>
        <Toast.Body>{msg}</Toast.Body>
      </Toast>
      {showAdd ? (
        <AddAgent
        id={ids}
          setShowAdd={setShowAdd}
          show={showAdd}
          setIsSave={setIsSave}
          isSave={false}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          getAgent={getAgent}
          setLoad={setLoad}
        />
      ) : (
        <></>
      )}
      {/* {showEdit ? (
        <EditAgent
          id={ids}
          setShowEdit={setShowEdit}
          show={showEdit}
          setIsSave={setIsSave}
          isSave={false}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          getAgent={getAgent}
          setLoad={setLoad}
          load={load}
        />
      ) : (
        <></>
      )} */}

      <div className="content">
        <div id="tablecontent">
          <MyAwesomeTable
            rows={datas}
            isLoading={load}
            onDelete={onDelete}
            setIds={setIds}
            setAdds={setAdds}
          />
        </div>
      </div>
    </>
  );
}

export default Agents;
