import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Spinner, Toast, Modal } from 'react-bootstrap';
import { Card, CardBody, Row, Col, CardTitle, CardText } from 'reactstrap';
import MyAwesomeTable from './gridtables';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import NumberToLetter from '../../converter';
import Axios from 'axios';
import dateformat from 'dateformat';
import common from '../../commonData';
import AddFacture from './addFacture';
// import UpdateFacture from './updateFacture';
import Facturer from './facturer';
import ViewLight from './viewLight';
import { TriangleHalf } from 'react-bootstrap-icons';
import FilterModale from './filterModal';

function ListeFacture() {
  const history = useHistory();

  const [datas, setDatas] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [shows, setShows] = useState(false);
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState('');
  const [isSave, setIsSave] = useState(false);
  const [type, setType] = useState('Proforma');
  const [msgGravity, setMsgravity] = useState('success');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [addfact, setAddfact] = useState(false);
  const [factdata, setFactData] = useState([]);
  const [showAddfact, setShowAddfact] = useState(false);
  const [ids, setId] = useState(0);
  const [factOrProf, setFactOrProf] = useState(0);
  const [title, setTitle] = useState('MES DEVIS');
  const [nbfact, setNbfact] = useState(0);
  const [nbprof, setNbprof] = useState(0);
  const [total, setTotal] = useState(0);
  const [editData, setEditData] = useState([]);
  
  const log = common.search(common.userdroit, "SAD");
  const log1 = common.search(common.userdroit, "GFA");
 
  useEffect(() => {
    if (localStorage.getItem('user-info-g')) {
      localStorage.removeItem('fdatedebut');
      localStorage.removeItem('fdatefin');
      localStorage.removeItem('ffilteroption');
      (async function anyNameFunction() {
        getFacture();
      })();
    } else {
      history.push('/login');
    }
  }, [history]);

  function getFacture() {
    setLoad(true);
    var url = localStorage.getItem('filteroption') ? 
      url = common.ipapi + '/api/receptionfact/filterplus/' + localStorage.getItem('filteroption')  + '/' + localStorage.getItem('datedebut')  + '/' + localStorage.getItem('datefin') 
      :
      url = common.ipapi + '/api/receptionfact/index'
    Axios.get(url)
      .then((response) => {
        setDataAll(response.data);
        setTotal(response.data.data.length);
        setNbfact(response.data.nbfact);
        setNbprof(response.data.nbprof);

        if (type === 'Facture') {
          const res = response.data.data.filter(
            (element) =>
              element.nofact !== 'neant' &&
              element.etat != 7 &&
              element.etat != 8
          );
          setDatas(res.sort(GetSortOrder('datefact')));
        } else {
          setDatas(response.data.data);
        }
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }
  
  async function setInitialiseData(){
    localStorage.removeItem('datedebut');
    localStorage.removeItem('datefin');
    localStorage.removeItem('filteroption');
    await getFacture();
  }
  function setAdds(val) {
    const log = common.search(common.userdroit, "SAD");
    const log1 = common.search(common.userdroit, "ADF");
    if(log == undefined){
      if(log1 == undefined){
        setMsg("Vous n'avez pas le droit de supprimer cette information");
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
        return;
      }  
    }
    // setAdd(val);
    setId(0);
    setEditData([])
    setShowAdd(val);
  }
  function setIds(id) {
    setLoad(true);
    const log = common.search(common.userdroit, "SAD");
    const log1 = common.search(common.userdroit, "MOF");
    if(log == undefined){
      if(log1 == undefined){
        setMsg("Vous n'avez pas le droit de supprimer cette information");
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
        return;
      }  
    }
    Axios.get(common.ipapi + '/api/receptionfact/edit/' + id)
      .then((response) => {
                setEditData(response.data);
                setLoad(false);
                setId(id);
                setShowAdd(true);
            })
            .catch((errors) => {
                setLoad(false);
            });
  }
  function setShowFilterModals(bool) {
    setShowFilterModal(true)
  }
  
  const handleClose = () => {
    setShowFilterModal(false)
  };
  function viewSortie(ids, val) {
    setFactOrProf(val);
    setId(ids);
    setShows(true);
    setLoad(false);
  }
  async function DuplicateFacture(data) {
    const log = common.search(common.userdroit, "SAD");
    const log1 = common.search(common.userdroit, "DUF");
    if(log == undefined){
      if(log1 == undefined){
        setMsg("Vous n'avez pas le droit de supprimer cette information");
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
        return;
      }  
    }
    var ladate = new Date();
    if (data.ligne.length == 0) {
      return 'Veuillez saisir au moin une prestation ou une pièce à vendre';
    }

    let text = 'Voulez-vous dupliquer en créant une nouvelle proforma';
    let res = window.confirm(text);
    if (res === false) {
      return;
    }
    setLoad(true);
    var formData = new FormData();
    formData.append(
      'date',
      dateformat(
        ladate.getMonth() +
          1 +
          '/' +
          ladate.getDate() +
          '/' +
          ladate.getFullYear(),
        'yyyy-mm-dd'
      )
    );
    formData.append('type', 'Proforma');
    formData.append('numero', '');
    formData.append('lignedata', JSON.stringify(data.ligne));
    formData.append('user_id', common.userid);
    formData.append('chassis', data.chassis);
    formData.append('immatr_eng', data.immatr_eng);
    formData.append('client_id', data.client_id);
    formData.append('sitva', data.sitva);
    formData.append('model_eng', data.model_eng);
    formData.append('remise', data.remise);
    formData.append('reception_id', data.reception_id ? data.reception_id : 0);
    formData.append('marque_id', data.marque_id);
    formData.append('assure', data.assure);
    formData.append('nosinistre', data.nosinistre);
    formData.append('bondecommande', data.bondecommande);
    formData.append('nomexpert', data.nomexpert);
    formData.append('commentaire', data.commentaire);
    formData.append('sifournitur', data.sifournitur);
    formData.append('entrepriseid', data.entreprise_id);
    formData.append('montptifourn', data.petitfourn);
    formData.append('arrondi', data.arrondi);
    Axios({
      url: common.ipapi + '/api/receptionfact/store',
      method: 'POST',
      data: formData,
    })
      .then((res) => {
        if (res.data.status === 1) {
          getFacture();
          setMsg('Opération de duplication réussie');
          setMsgravity('info');
          setIsSave(true);
        } else {
          setLoad(false);
          return 'Une erreur à  eu lieu lors de la duplication';
        }
      })
      .catch((error) => {
        setLoad(false);
        return 'Une erreur à  eu lieu lors de la duplication';
      });
  }

  function onvalideDir(d, etat) {
    
    if (window.confirm('Voulez-vous confirmer cette action ?')) {
      setLoad(true);
      var formData = new FormData();
      if ((d.etat == 6 && etat == 7) || (d.etat == 7 && etat == 6)) {
        formData.append('etat', 8);
      } else {
        formData.append('etat', etat);
      }
      Axios({
        url: common.ipapi + '/api/receptionfact/valdir/' + d.id,
        method: 'POST',
        headers: {
          'Content-Type': 'form-data',
        },
        data: formData,
      }).then((response) => {
        if (response.data.status === 1) {
          setMsg(response.data.msg);
          setMsgravity('info');
          setIsSave(true);
          getFacture();
        } else {
          setIsSave(true);
          setMsg(response.data.msg);
          setMsgravity('danger');
          setLoad(false);
        }
      });
    }
  }

  function handleShow(d) {
    setFactData(d);
    setAddfact(true);
    setShowAddfact(true);
  }

  //------- IMPRIMER FACTURE -----------------
  async function printFacture(id) {
    let text =
      'Voulez-vous imprimer avec ou sans entête ? \n Ok si vous voulez avec entête \n Annuler si vous ne voulez pas avec entête';
    let res = window.confirm(text);
    var doc = new jsPDF();
    var width = doc.internal.pageSize.getWidth();
    var dataresponses = [];
    var dataFactures = [];
    var dataobject = [];
    var listePiece = [];
    var listeprest = [];
    var debours = [];
    var thtprestation = 0,
      thtpieces = 0,
      thtdebours = 0;
    var remisprestation = 0,
      remispieces = 0,
      vetuste = 0,
      vetust = 0;
    var start = 0,
      tvapiece = 0,
      tvaprest = 0;
    let i = 0,
      t = 0;
    var finalY = 0;
    var siremise = false,
      sivetuste = false;
    var montttc,
      remis = 0;
    setLoad(true);

    const resp = await Axios.get(common.ipapi + '/api/receptionfact/edit/' + id)
      .then((responses) => {
        dataresponses = dataresponses.concat(responses.data);
        dataresponses[0].ligne.forEach((row) => {
          if (row.remise != 0) {
            siremise = true;
          }
          if (row.vetuste != 0) {
            sivetuste = true;
          }
          if (row.categorie == 'Pièce') {
            listePiece.push({
              libprestation: row.libprestation,
              qte: row.qte,
              prixprestation: row.prixprestation,
              tva: row.tva,
              remise: row.remise,
              vetuste: row.vetuste,
            });
          } else if (row.categorie == 'Prestation') {
            listeprest.push({
              libprestation: row.libprestation,
              qte: row.qte,
              prixprestation: row.prixprestation,
              tva: row.tva,
              remise: row.remise,
              vetuste: row.vetuste,
            });
          } else {
            debours.push({
              libprestation: row.libprestation,
              qte: row.qte,
              prixprestation: row.prixprestation,
              tva: row.tva,
              remise: row.remise,
              vetuste: row.vetuste,
            });
          }
        });
        if (dataresponses[0].reception) {
          if (
            dataresponses[0].reception.objet_visite !== null &&
            dataresponses[0].reception.objet_visite !== ''
          ) {
            dataobject.push([
              { content: 'Objet visite' },
              { content: dataresponses[0].reception.objet_visite, colSpan: 4 },
            ]);
          }
        }
        if (dataresponses[0].commentaire !== null) {
          dataobject.push([
            { content: 'Commentaire' },
            { content: dataresponses[0].commentaire, colSpan: 4 },
          ]);
        }

        listePiece.forEach((row) => {
          remis = row.prixprestation * row.qte * (row.remise / 100);
          remispieces += row.prixprestation * row.qte * (row.remise / 100);
          vetuste += row.prixprestation * row.qte * (row.vetuste / 100);
          vetust = row.prixprestation * row.qte * (row.vetuste / 100);
          if (row.tva == 1) {
            tvapiece += (row.prixprestation * row.qte - remis - vetust) * 0.18;
          }
          i++;
          thtpieces += row.prixprestation * row.qte - remis - vetust;
          t = row.prixprestation * row.qte - remis - vetust;
          siremise && sivetuste
            ? dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                { content: Math.round(row.remise) },
                { content: Math.round(row.vetuste) },
                {
                  content: new Intl.NumberFormat('de-DE').format(Math.round(t)),
                },
              ])
            : siremise && !sivetuste
            ? dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                { content: Math.round(row.remise) },
                {
                  content: new Intl.NumberFormat('de-DE').format(Math.round(t)),
                },
              ])
            : !siremise && sivetuste
            ? dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                { content: Math.round(row.vetuste) },
                {
                  content: new Intl.NumberFormat('de-DE').format(Math.round(t)),
                },
              ])
            : dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                {
                  content: new Intl.NumberFormat('de-DE').format(Math.round(t)),
                },
              ]);
        });
        if (listePiece.length > 0) {
          dataFactures.push([
            {
              content: 'MONTANT HT (PIECES)',
              colSpan:
                siremise && sivetuste
                  ? 5
                  : siremise && !sivetuste
                  ? 4
                  : !siremise && sivetuste
                  ? 4
                  : 3,
            },
            {
              content: new Intl.NumberFormat('de-DE').format(
                Math.round(thtpieces)
              ),
            },
          ]);
        }

        listeprest.forEach((row) => {
          remis = row.prixprestation * row.qte * (row.remise / 100);
          remisprestation += row.prixprestation * row.qte * (row.remise / 100);
          vetuste += row.prixprestation * row.qte * (row.vetuste / 100);
          vetust = row.prixprestation * row.qte * (row.vetuste / 100);
          if (row.tva == 1) {
            tvaprest += (row.prixprestation * row.qte - remis - vetust) * 0.18;
          }
          i++;
          thtprestation += row.prixprestation * row.qte - remis - vetust;
          t = row.prixprestation * row.qte - remis - vetust;
          siremise && sivetuste
            ? dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                { content: Math.round(row.remise) },
                { content: Math.round(row.vetuste) },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(Math.round(t))
                  ),
                },
              ])
            : siremise && !sivetuste
            ? dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                { content: Math.round(row.remise) },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(Math.round(t))
                  ),
                },
              ])
            : !siremise && sivetuste
            ? dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                { content: Math.round(row.vetuste) },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(Math.round(t))
                  ),
                },
              ])
            : dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                { content: new Intl.NumberFormat('de-DE').format(parseInt(t)) },
              ]);
        });

        if (listeprest.length > 0) {
          dataFactures.push([
            {
              content: 'MONTANT HT (PRESTATIONS)',
              colSpan:
                siremise && sivetuste
                  ? 5
                  : siremise && !sivetuste
                  ? 4
                  : !siremise && sivetuste
                  ? 4
                  : 3,
            },
            {
              content: new Intl.NumberFormat('de-DE').format(
                Math.round(thtprestation)
              ),
            },
          ]);
        }

        debours.forEach((row) => {
          thtdebours += row.prixprestation * row.qte;
          siremise && sivetuste
            ? dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                { content: row.remise },
                { content: row.vetuste },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
              ])
            : siremise && !sivetuste
            ? dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                { content: row.remise },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
              ])
            : !siremise && sivetuste
            ? dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                { content: row.vetuste },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
              ])
            : dataFactures.push([
                { content: row.qte },
                { content: row.libprestation },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
                {
                  content: new Intl.NumberFormat('de-DE').format(
                    parseInt(row.prixprestation)
                  ),
                },
              ]);
        });
        if (debours.length > 0) {
          dataFactures.push([
            {
              content: 'MONTANT HT (DEBOURS)',
              colSpan:
                siremise && sivetuste
                  ? 5
                  : siremise && !sivetuste
                  ? 4
                  : !siremise && sivetuste
                  ? 4
                  : 3,
            },
            {
              content: new Intl.NumberFormat('de-DE').format(
                Math.round(thtdebours)
              ),
            },
          ]);
        }
      })
      .catch((errors) => {
        setLoad(false);
      });

    if (res === true) {
      if (dataobject.length > 0) {
        doc.autoTable({
          styles: {
            fillColor: [225, 206, 154],
            textColor: [255, 0, 0],
            cellPadding: 0.7,
            lineColor: [0, 0, 0],
            halign: 'center',
          },
          columnStyles: {
            0: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              cellWidth: 25,
              fontSize: 9,
              fontStyle: 'bold',
            },
            1: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 9,
            },
          },
          theme: 'grid',
          startY:
            dataresponses[0].assure != null && dataresponses[0].assure != ''
              ? 75
              : 75,
          body: dataobject,
        });
      }
      doc.setFillColor(255, 127, 0);
      doc.addImage(dataresponses[0].entreprise_id ===1 ? common.logo1 : common.logo, 'jpg', 14, 10, 30, 30);
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      doc.setFillColor(225, 206, 154);
      doc.rect(65, 19, width - 80, 10, 'F');
      doc.text(type == 'Facture' ? 'FACTURE CLIENT' : 'DEVIS CLIENT', 70, 26);
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(10);
      doc.text(
        112,
        38,
        'Abidjan le: ' +
          dateformat(
            dataresponses[0].datefact || dataresponses[0].dateprof,
            'dd-mm-yyyy'
          )
      );
      doc.setDrawColor(0);
      // doc.rect(110, 40, 86,31)
      type == 'Facture'
        ? doc.text('Facture N°: ' + dataresponses[0].nofact, 15, 45)
        : doc.text('Devis N°: ' + dataresponses[0].noprof, 15, 45);

      dataresponses[0].reception_id != 0
        ? doc.text(
            'Fiche N°: ' +
              dataresponses[0].reception.nofiche +
              ' du ' +
              dateformat(
                dataresponses[0].reception.date_entre_garage,
                'dd-mm-yyyy'
              ),
            15,
            50
          )
        : doc.text('Fiche N°: Néant', 15, 50);
      var model = dataresponses[0].model
        ? dataresponses[0].model.libellemodel
        : 'néant';
      dataresponses[0].marque
        ? doc.text(
            'Engin: ' +
              dataresponses[0].immatr_eng +
              ' / ' +
              dataresponses[0].marque.libellemarq +
              ' / ' +
              model,
            15,
            55
          )
        : doc.text(
            'Engin: ' +
              dataresponses[0].immatr_eng +
              ' / ' +
              dataresponses[0].marque.libellemarq +
              ' / ' +
              dataresponses[0].model_eng,
            15,
            55
          );
      dataresponses[0].reception_id != 0
        ? doc.text(
            'Kilometrage: ' + dataresponses[0].reception.index_compt,
            15,
            60
          )
        : doc.text('Kilométrage: néant', 15, 60);

      type == 'Facture'
        ? doc.text('Nom: ' + dataresponses[0].clientf.raisonsocial, 112, 45)
        : doc.text('Nom: ' + dataresponses[0].client.raisonsocial, 112, 45);
      type == 'Facture'
        ? doc.text('Contact: ' + dataresponses[0].clientf.telcli, 112, 50)
        : doc.text('Contact: ' + dataresponses[0].client.telcli, 112, 50);
      type == 'Facture'
        ? doc.text(
            'Type client: ' + dataresponses[0].clientf.sisociete,
            112,
            55
          )
        : doc.text(
            'Type client: ' + dataresponses[0].client.sisociete,
            112,
            55
          );
      type == 'Facture'
        ? doc.text(
            'Compte contribuable: ' + dataresponses[0].clientf.nocomptec,
            112,
            60
          )
        : doc.text(
            'Compte contribuable: ' + dataresponses[0].client.nocomptec,
            112,
            60
          );
      if (
        (dataresponses[0].nomexpert != null &&
          dataresponses[0].nomexpert != '') ||
        (dataresponses[0].autoentre != null && dataresponses[0].autoentre != '')
      ) {
        doc.rect(110, 40, 86, 33);
        doc.text(
          "Bon d'entrée: " + dataresponses[0].autoentre,
          112,
          dataresponses[0].entreprise_id !== 3 ? 65 : 92
        );
        doc.text(
          'Autorisation sortie: ' + dataresponses[0].nomexpert,
          112,
          dataresponses[0].entreprise_id !== 3 ? 70 : 92
        );
      } else {
        if (dataresponses[0].assure != null && dataresponses[0].assure != '') {
          doc.rect(110, 40, 86, 32);
          dataresponses[0].assure != null && dataresponses[0].assure != ''
            ? doc.text('Assuré: ' + dataresponses[0].assure, 112, 65)
            : doc.text('Assuré: néant', 112, 65);
          dataresponses[0].nosinistre != null &&
          dataresponses[0].nosinistre != ''
            ? doc.text('N° sinistre: ' + dataresponses[0].nosinistre, 112, 70)
            : doc.text('N° sinistre: néant', 112, 70);
        } else {
          doc.rect(110, 40, 86, 26);
          dataresponses[0].bondecommande != null &&
          dataresponses[0].bondecommande != ''
            ? doc.text('B/C N°: ' + dataresponses[0].bondecommande, 112, 65)
            : doc.text('B/C N°: néant', 112, 65);
        }
        // doc.text(
        //   'Compte contribuable: ' + dataresponses[0].client.nocomptec,
        //   112,
        //   65
        // );
      }

      if (doc.lastAutoTable.finalY !== undefined) {
        finalY = doc.lastAutoTable.finalY;
      } else {
        finalY = 75;
      }
      doc.autoTable({
        styles: {
          fillColor: [2, 5, 5],
          textColor: [255, 255, 255],
          cellPadding: 0.7,
          lineColor: [0, 0, 0],
          halign: 'center',
        },
        columnStyles:
          siremise && sivetuste
            ? {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 10,
                  fontSize: 9,
                },
                1: {
                  halign: 'left',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                5: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
              }
            : siremise && !sivetuste
            ? {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 10,
                  fontSize: 9,
                },
                1: {
                  halign: 'left',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
              }
            : !siremise && sivetuste
            ? {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 10,
                  fontSize: 9,
                },
                1: {
                  halign: 'left',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
              }
            : {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 10,
                  fontSize: 9,
                },
                1: {
                  halign: 'left',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
              },
        showFoot: 'lastPage',
        showHead: 'firstPage',
        theme: 'grid',
        startY: finalY + 2,
        head:
          siremise && sivetuste
            ? [
                [
                  'Qté',
                  'Designations',
                  'P. unitaire',
                  'Remise',
                  'Vétuste',
                  'Montant',
                ],
              ]
            : siremise && !sivetuste
            ? [['Qté', 'Designations', 'P. unitaire', 'Remise', 'Montant']]
            : !siremise && sivetuste
            ? [['Qté', 'Designations', 'P. unitaire', 'Vétuste', 'Montant']]
            : [['Qté', 'Designations', 'P. unitaire', 'Montant']],
        body: dataFactures,
        didParseCell: function (cell) {
          if (
            cell.cell.text[0].indexOf('PRESTATION') > -1 ||
            cell.cell.text[0].indexOf('PIECES') > -1 ||
            cell.cell.text[0].indexOf('DEBOURS') > -1
          ) {
            cell.cell.styles.fontStyle = 'bold';
          }
          if (
            cell.cell.text[0] ==
              new Intl.NumberFormat('de-DE').format(Math.round(thtdebours)) ||
            cell.cell.text[0] ==
              new Intl.NumberFormat('de-DE').format(Math.round(thtpieces)) ||
            cell.cell.text[0] ==
              new Intl.NumberFormat('de-DE').format(Math.round(thtprestation))
          ) {
            cell.cell.styles.fontStyle = 'bold';
          }
        },
        // foot: siremise ? [['',responses[0].data.client.sitva=='1' ? 'Montant H TVA' : 'Montant TTC','','',new Intl.NumberFormat("de-DE").format(Math.round(montttc))]] : [['',responses[0].data.client.sitva=='1' ? 'Montant H TVA' : 'Montant TTC','',new Intl.NumberFormat("de-DE").format(Math.round(montttc))]],
      });

      var totalremise = remispieces + remisprestation + vetuste;
      var totalht = thtdebours + thtpieces + thtprestation + totalremise;
      var totalnet = totalht - thtdebours - totalremise;
      var ptifouni = 0;
      var totaltva = 0;
      if (dataresponses[0].sifournitur == 0) {
        ptifouni = 0;
        totaltva = totalnet * 0.18;
      } else if (dataresponses[0].sifournitur == 2) {
        ptifouni = dataresponses[0].petitfourn;
        totaltva = totalnet * 0.18 + ptifouni * 0.18;
      } else {
        ptifouni = totalnet * 0.02;
        totaltva = totalnet * 0.18 + ptifouni * 0.18;
      }
      var totalttc = totalnet + totaltva + ptifouni + thtdebours;
      if (dataresponses[0].sitva == '1') {
        totalttc = totalnet + ptifouni + thtdebours;
      }
      if (dataresponses[0].arrondi === '0') {
        totaltva = Math.round(totaltva);
        totalttc = Math.round(totalttc);
        totalht = Math.round(totalht);
        totalnet = Math.round(totalnet);
        ptifouni = Math.round(ptifouni);
        totalremise = Math.round(totalremise);
      } else if (dataresponses[0].arrondi === '1') {
        totaltva = Math.floor(totaltva);
        totalttc = Math.floor(totalttc);
        totalht = Math.floor(totalht);
        totalnet = Math.floor(totalnet);
        ptifouni = Math.floor(ptifouni);
        totalremise = Math.floor(totalremise);
      } else {
        totaltva = Math.ceil(totaltva);
        totalttc = Math.ceil(totalttc);
        totalht = Math.ceil(totalht);
        totalnet = Math.ceil(totalnet);
        ptifouni = Math.ceil(ptifouni);
        totalremise = Math.ceil(totalremise);
      }
      doc.autoTable({
        styles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 0.8,
          lineColor: [0, 0, 0],
          halign: 'center',
        },
        columnStyles:
          totalremise != 0 && thtdebours != 0
            ? {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 25,
                  fontSize: 9,
                },
                1: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                5: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                6: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                  fontStyle: 'bold',
                },
              }
            : totalremise != 0 && thtdebours == 0
            ? {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 25,
                  fontSize: 9,
                },
                1: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                5: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                  fontStyle: 'bold',
                },
              }
            : {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 25,
                  fontSize: 9,
                },
                1: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                5: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                  fontStyle: 'bold',
                },
              },
        margin: { bottom: 35 },
        theme: 'grid',
        startY: doc.lastAutoTable.finalY + 3,
        head:
          totalremise != 0 && thtdebours != 0
            ? [
                [
                  'TOTAL BRUT',
                  'REMISE(%)',
                  'DEBOURS',
                  'TOTAL NET',
                  'PETITE FOURN.',
                  dataresponses[0].sitva == '1'
                    ? 'TVA EXONERE (18%)'
                    : 'TVA (18%)',
                  dataresponses[0].sitva == '1' ? 'TOTAL HTVA' : 'TOTAL TTC',
                ],
              ]
            : totalremise != 0 && thtdebours == 0
            ? [
                [
                  'TOTAL BRUT',
                  'REMISE',
                  'TOTAL NET',
                  'PETITE FOURN.',
                  dataresponses[0].sitva == '1'
                    ? 'TVA  EXONERE (18%)'
                    : 'TVA (18%)',
                  dataresponses[0].sitva == '1' ? 'TOTAL HTVA' : 'TOTAL TTC',
                ],
              ]
            : [
                [
                  'TOTAL BRUT',
                  'DEBOURS',
                  'TOTAL NET',
                  'PETITE FOURN.',
                  dataresponses[0].sitva == '1'
                    ? 'TVA EXONERE (18%)'
                    : 'TVA (18%)',
                  dataresponses[0].sitva == '1' ? 'TOTAL HTVA' : 'TOTAL TTC',
                  ,
                ],
              ],
        body:
          totalremise != 0 && thtdebours != 0
            ? [
                [
                  new Intl.NumberFormat('de-DE').format(totalht),
                  new Intl.NumberFormat('de-DE').format(totalremise),
                  new Intl.NumberFormat('de-DE').format(thtdebours),
                  new Intl.NumberFormat('de-DE').format(totalnet),
                  new Intl.NumberFormat('de-DE').format(ptifouni),
                  new Intl.NumberFormat('de-DE').format(totaltva),
                  new Intl.NumberFormat('de-DE').format(totalttc),
                ],
              ]
            : totalremise != 0 && thtdebours == 0
            ? [
                [
                  new Intl.NumberFormat('de-DE').format(totalht),
                  new Intl.NumberFormat('de-DE').format(totalremise),
                  new Intl.NumberFormat('de-DE').format(totalnet),
                  new Intl.NumberFormat('de-DE').format(ptifouni),
                  new Intl.NumberFormat('de-DE').format(totaltva),
                  new Intl.NumberFormat('de-DE').format(totalttc),
                ],
              ]
            : [
                [
                  new Intl.NumberFormat('de-DE').format(totalht),
                  new Intl.NumberFormat('de-DE').format(thtdebours),
                  new Intl.NumberFormat('de-DE').format(totalnet),
                  new Intl.NumberFormat('de-DE').format(ptifouni),
                  new Intl.NumberFormat('de-DE').format(totaltva),
                  new Intl.NumberFormat('de-DE').format(totalttc),
                ],
              ],
      });

      finalY = doc.lastAutoTable.finalY;
      if (finalY > 217) {
        doc.addPage();
      }
      const pageCount = doc.internal.getNumberOfPages();
      for (var u = 1; u <= pageCount; u++) {
        doc.setPage(u);
        doc.setFontSize(10);
        doc.text(
          'Page ' + String(u) + ' sur ' + String(pageCount),
          210 - 18,
          292,
          null,
          null,
          'right'
        );
      }
      doc.text('Arrêté à la somme de:', 15, 232);
      doc.setFontSize(8);
      doc.text(
        NumberToLetter(Math.round(totalttc)).toUpperCase() + ' FCFA',
        15,
        238
      );
      doc.setFontSize(10);
      doc.setDrawColor(0);
      doc.rect(14, 234, width - 28, 6);
      doc.text('Visa direction:', 15, 249);
      doc.setDrawColor(0);
      // doc.rect(14, 250, 60, 22);
      doc.text('Visa client:', 135, 249);
      doc.setDrawColor(0);
      // doc.rect(135, 250, 60, 22);
      doc.setFontSize(8);

      doc.setFontSize(8);
      doc.setLineWidth(1.5);
      doc.setDrawColor(0, 0, 0);
      doc.line(0, 275, width, 275);
      doc.text(
        'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
        105,
        280,
        null,
        'center'
      );
      doc.text(
        'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 05 44 00 00 00 /  Email: vicaso11@hotmail.fr ',
        105,
        285,
        null,
        'center'
      );
      setLoad(false);
      doc.save('facture.pdf');

      ////////////////////////////// SANS ENTETE /////////////////////////
    } else {
      if (dataobject.length > 0) {
        doc.autoTable({
          styles: {
            fillColor: [225, 206, 154],
            textColor: [255, 0, 0],
            cellPadding: 0.7,
            lineColor: [0, 0, 0],
            halign: 'center',
          },
          columnStyles: {
            0: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              cellWidth: 25,
              fontSize: 9,
              fontStyle: 'bold',
            },
            1: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 9,
            },
          },
          theme: 'grid',
          startY:
            dataresponses[0].assure != null && dataresponses[0].assure != ''
              ? dataresponses[0].entreprise_id !== 3
                ? 86
                : 104
              : dataresponses[0].entreprise_id !== 3
              ? 82
              : 100,
          body: dataobject,
        });
      }

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(10);
      doc.text(
        'Abidjan le: ' +
          dateformat(
            dataresponses[0].datefact || dataresponses[0].dateprof,
            'dd-mm-yyyy'
          ),
        112,
        dataresponses[0].entreprise_id !== 3 ? 48 : 70
      );
      doc.setDrawColor(0);
      dataresponses[0].reception_id != 0
        ? doc.text(
            'Fiche N°: ' +
              dataresponses[0].reception.nofiche +
              ' du ' +
              dateformat(
                dataresponses[0].reception.date_entre_garage,
                'dd-mm-yyyy'
              ),
            15,
            dataresponses[0].entreprise_id !== 3 ? 65 : 87
          )
        : doc.text(
            'Fiche N°: Néant',
            15,
            dataresponses[0].entreprise_id !== 3 ? 65 : 87
          );
      var model = dataresponses[0].model
        ? dataresponses[0].model.libellemodel
        : 'néant';
      dataresponses[0].marque
        ? doc.text(
            'Engin: ' +
              dataresponses[0].immatr_eng +
              ' / ' +
              dataresponses[0].marque.libellemarq +
              ' / ' +
              model,
            15,
            dataresponses[0].entreprise_id !== 3 ? 70 : 92
          )
        : doc.text(
            'Engin: ' +
              dataresponses[0].immatr_eng +
              ' / ' +
              dataresponses[0].marque.libellemarq +
              ' / ' +
              dataresponses[0].model_eng,
            15,
            dataresponses[0].entreprise_id !== 3 ? 70 : 92
          );
      dataresponses[0].reception_id != 0
        ? doc.text(
            'Kilometrage: ' + dataresponses[0].reception.index_compt,
            15,
            dataresponses[0].entreprise_id !== 3 ? 75 : 97
          )
        : doc.text(
            'Kilométrage: néant',
            15,
            dataresponses[0].entreprise_id !== 3 ? 75 : 97
          );

      type == 'Facture'
        ? doc.text(
            'Nom: ' + dataresponses[0].clientf.raisonsocial,
            112,
            dataresponses[0].entreprise_id !== 3 ? 55 : 77
          )
        : doc.text(
            'Nom: ' + dataresponses[0].client.raisonsocial,
            112,
            dataresponses[0].entreprise_id !== 3 ? 55 : 77
          );
      type == 'Facture'
        ? doc.text(
            'Contact: ' + dataresponses[0].clientf.telcli,
            112,
            dataresponses[0].entreprise_id !== 3 ? 60 : 82
          )
        : doc.text(
            'Contact: ' + dataresponses[0].client.telcli,
            112,
            dataresponses[0].entreprise_id !== 3 ? 60 : 82
          );
      type == 'Facture'
        ? doc.text(
            'Type client: ' + dataresponses[0].clientf.sisociete,
            112,
            dataresponses[0].entreprise_id !== 3 ? 65 : 87
          )
        : doc.text(
            'Type client: ' + dataresponses[0].client.sisociete,
            112,
            dataresponses[0].entreprise_id !== 3 ? 65 : 87
          );
      type == 'Facture'
        ? doc.text(
            'Compte contribuable: ' + dataresponses[0].clientf.nocomptec,
            112,
            dataresponses[0].entreprise_id !== 3 ? 70 : 92
          )
        : doc.text(
            'Compte contribuable: ' + dataresponses[0].client.nocomptec,
            112,
            dataresponses[0].entreprise_id !== 3 ? 70 : 92
          );
      if (
        (dataresponses[0].nomexpert != null &&
          dataresponses[0].nomexpert != '') ||
        (dataresponses[0].autoentre != null && dataresponses[0].autoentre != '')
      ) {
        doc.rect(110, dataresponses[0].entreprise_id !== 3 ? 51 : 75, 86, 31);
        doc.text(
          "Bon d'entrée: " + dataresponses[0].autoentre,
          112,
          dataresponses[0].entreprise_id !== 3 ? 75 : 97
        );
        doc.text(
          'Autorisation de sortie: ' + dataresponses[0].nomexpert,
          112,
          dataresponses[0].entreprise_id !== 3 ? 80 : 97
        );
      } else {
        if (dataresponses[0].assure != null && dataresponses[0].assure != '') {
          doc.rect(110, dataresponses[0].entreprise_id !== 3 ? 49 : 72, 86, 32);
          doc.text(
            'Assuré: ' + dataresponses[0].assure,
            112,
            dataresponses[0].entreprise_id !== 3 ? 75 : 97
          );
          dataresponses[0].nosinistre != null &&
          dataresponses[0].nosinistre != ''
            ? doc.text(
                'N° sinistre: ' + dataresponses[0].nosinistre,
                112,
                dataresponses[0].entreprise_id !== 3 ? 80 : 102
              )
            : doc.text(
                'N° sinistre: néant',
                112,
                dataresponses[0].entreprise_id !== 3 ? 80 : 102
              );
        } else {
          doc.rect(110, dataresponses[0].entreprise_id !== 3 ? 49 : 72, 86, 28);
          dataresponses[0].bondecommande != null &&
          dataresponses[0].bondecommande != ''
            ? doc.text(
                'B/C N°: ' + dataresponses[0].bondecommande,
                112,
                dataresponses[0].entreprise_id !== 3 ? 75 : 97
              )
            : doc.text(
                'B/C N°: néant',
                112,
                dataresponses[0].entreprise_id !== 3 ? 75 : 97
              );
        }
      }
      if (doc.lastAutoTable.finalY !== undefined) {
        finalY = doc.lastAutoTable.finalY;
      } else {
        finalY = dataresponses[0].entreprise_id !== 3 ? 82 : 104;
      }
      doc.autoTable({
        styles: {
          fillColor: [2, 5, 5],
          textColor: [255, 255, 255],
          cellPadding: 0.7,
          lineColor: [0, 0, 0],
          halign: 'center',
        },
        columnStyles:
          siremise && sivetuste
            ? {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 10,
                  fontSize: 9,
                },
                1: {
                  halign: 'left',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                5: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
              }
            : siremise && !sivetuste
            ? {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 10,
                  fontSize: 9,
                },
                1: {
                  halign: 'left',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
              }
            : !siremise && sivetuste
            ? {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 10,
                  fontSize: 9,
                },
                1: {
                  halign: 'left',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
              }
            : {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 10,
                  fontSize: 9,
                },
                1: {
                  halign: 'left',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
              },
        showFoot: 'lastPage',
        showHead: 'firstPage',
        theme: 'grid',
        startY: finalY + 2,
        head:
          siremise && sivetuste
            ? [
                [
                  'Qté',
                  'Article',
                  'P. unitaire',
                  'Remise',
                  'Vétusté',
                  'Montant',
                ],
              ]
            : siremise && !sivetuste
            ? [['Qté', 'Article', 'P. unitaire', 'Remise', 'Montant']]
            : !siremise && sivetuste
            ? [['Qté', 'Article', 'P. unitaire', 'Vétusté', 'Montant']]
            : [['Qté', 'Article', 'P. unitaire', 'Montant']],
        body: dataFactures,
        didParseCell: function (cell) {
          if (
            cell.cell.text[0].indexOf('PRESTATION') > -1 ||
            cell.cell.text[0].indexOf('PIECES') > -1 ||
            cell.cell.text[0].indexOf('DEBOURS') > -1
          ) {
            cell.cell.styles.fontStyle = 'bold';
          }
          if (
            cell.cell.text[0] ==
              new Intl.NumberFormat('de-DE').format(Math.round(thtdebours)) ||
            cell.cell.text[0] ==
              new Intl.NumberFormat('de-DE').format(Math.round(thtpieces)) ||
            cell.cell.text[0] ==
              new Intl.NumberFormat('de-DE').format(Math.round(thtprestation))
          ) {
            cell.cell.styles.fontStyle = 'bold';
          }
        },
        // foot: siremise ? [['',responses[0].data.client.sitva=='1' ? 'Montant H TVA' : 'Montant TTC','','',new Intl.NumberFormat("de-DE").format(Math.round(montttc))]] : [['',responses[0].data.client.sitva=='1' ? 'Montant H TVA' : 'Montant TTC','',new Intl.NumberFormat("de-DE").format(Math.round(montttc))]],
      });

      var totalremise = remispieces + remisprestation + vetuste;
      var totalht = thtdebours + thtpieces + thtprestation + totalremise;
      var totalnet = totalht - thtdebours - totalremise;
      var ptifouni = 0;
      var totaltva = 0;
      if (dataresponses[0].sifournitur == 0) {
        ptifouni = 0;
        totaltva = totalnet * 0.18;
      } else if (dataresponses[0].sifournitur == 2) {
        ptifouni = dataresponses[0].petitfourn;
        totaltva = totalnet * 0.18 + ptifouni * 0.18;
      } else {
        ptifouni = totalnet * 0.02;
        totaltva = totalnet * 0.18 + ptifouni * 0.18;
      }

      var totalttc = totalnet + totaltva + ptifouni + thtdebours;
      if (dataresponses[0].sitva == '1') {
        totalttc = totalnet + ptifouni + thtdebours;
      }

      if (dataresponses[0].arrondi === '0') {
        totaltva = Math.round(totaltva);
        totalttc = Math.round(totalttc);
        totalht = Math.round(totalht);
        totalnet = Math.round(totalnet);
        ptifouni = Math.round(ptifouni);
        totalremise = Math.round(totalremise);
      } else if (dataresponses[0].arrondi === '1') {
        totaltva = Math.floor(totaltva);
        totalttc = Math.floor(totalttc);
        totalht = Math.floor(totalht);
        totalnet = Math.floor(totalnet);
        ptifouni = Math.floor(ptifouni);
        totalremise = Math.floor(totalremise);
      } else {
        totaltva = Math.ceil(totaltva);
        totalttc = Math.ceil(totalttc);
        totalht = Math.ceil(totalht);
        totalnet = Math.ceil(totalnet);
        ptifouni = Math.ceil(ptifouni);
        totalremise = Math.ceil(totalremise);
      }
      doc.autoTable({
        styles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 0.75,
          lineColor: [0, 0, 0],
          halign: 'center',
        },
        columnStyles:
          totalremise != 0 && thtdebours != 0
            ? {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 25,
                  fontSize: 9,
                },
                1: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                5: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                6: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                  fontStyle: 'bold',
                },
              }
            : totalremise != 0 && thtdebours == 0
            ? {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 25,
                  fontSize: 9,
                },
                1: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                5: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                  fontStyle: 'bold',
                },
              }
            : {
                0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 25,
                  fontSize: 9,
                },
                1: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                4: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                },
                5: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 9,
                  fontStyle: 'bold',
                },
              },
        margin: { bottom: 35 },
        theme: 'grid',
        startY: doc.lastAutoTable.finalY + 3,
        head:
          totalremise != 0 && thtdebours != 0
            ? [
                [
                  'TOTAL BRUT',
                  'REMISE (%)',
                  'DEBOURS',
                  'TOTAL NET',
                  'PETITE FOURN.',
                  dataresponses[0].sitva == '1'
                    ? 'TVA  EXONERE (18%)'
                    : 'TVA (18%)',
                  dataresponses[0].sitva == '1' ? 'TOTAL HTVA' : 'TOTAL TTC',
                ],
              ]
            : totalremise != 0 && thtdebours == 0
            ? [
                [
                  'TOTAL BRUT',
                  'REMISE',
                  'TOTAL NET',
                  'PETITE FOURN.',
                  dataresponses[0].sitva == '1'
                    ? 'TVA  EXONERE (18%)'
                    : 'TVA (18%)',
                  dataresponses[0].sitva == '1' ? 'TOTAL HTVA' : 'TOTAL TTC',
                ],
              ]
            : [
                [
                  'TOTAL BRUT',
                  'DEBOURS',
                  'TOTAL NET',
                  'PETITE FOURN.',
                  dataresponses[0].sitva == '1'
                    ? 'TVA  EXONERE (18%)'
                    : 'TVA (18%)',
                  dataresponses[0].sitva == '1' ? 'TOTAL HTVA' : 'TOTAL TTC',
                ],
              ],
        body:
          totalremise != 0 && thtdebours != 0
            ? [
                [
                  new Intl.NumberFormat('de-DE').format(totalht),
                  new Intl.NumberFormat('de-DE').format(totalremise),
                  new Intl.NumberFormat('de-DE').format(thtdebours),
                  new Intl.NumberFormat('de-DE').format(totalnet),
                  new Intl.NumberFormat('de-DE').format(ptifouni),
                  new Intl.NumberFormat('de-DE').format(totaltva),
                  new Intl.NumberFormat('de-DE').format(totalttc),
                ],
              ]
            : totalremise != 0 && thtdebours == 0
            ? [
                [
                  new Intl.NumberFormat('de-DE').format(totalht),
                  new Intl.NumberFormat('de-DE').format(totalremise),
                  new Intl.NumberFormat('de-DE').format(totalnet),
                  new Intl.NumberFormat('de-DE').format(ptifouni),
                  new Intl.NumberFormat('de-DE').format(totaltva),
                  new Intl.NumberFormat('de-DE').format(totalttc),
                ],
              ]
            : [
                [
                  new Intl.NumberFormat('de-DE').format(totalht),
                  new Intl.NumberFormat('de-DE').format(thtdebours),
                  new Intl.NumberFormat('de-DE').format(totalnet),
                  new Intl.NumberFormat('de-DE').format(ptifouni),
                  new Intl.NumberFormat('de-DE').format(totaltva),
                  new Intl.NumberFormat('de-DE').format(totalttc),
                ],
              ],
      });

      finalY = doc.lastAutoTable.finalY;
      if (finalY > 217) {
        doc.addPage();
      }
      const pageCount = doc.internal.getNumberOfPages();
      for (var u = 1; u <= pageCount; u++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(
          'Page ' + String(u) + ' sur ' + String(pageCount),
          210 - 18,
          292,
          null,
          null,
          'right'
        );
      }
      doc.text('Arrêté à la somme de:', 15, 232);
      doc.setFontSize(8);
      doc.text(
        NumberToLetter(Math.round(totalttc)).toUpperCase() + ' FCFA',
        15,
        238
      );
      doc.setFontSize(10);
      doc.setDrawColor(0);
      doc.rect(14, 234, width - 28, 6);
      doc.text('Visa direction:', 15, 249);
      doc.setDrawColor(0);
      // doc.rect(14, 250, 60, 22);
      doc.text('Visa client:', 135, 249);
      doc.setDrawColor(0);
      // doc.rect(135, 250, 60, 22);
      doc.setFontSize(8);
      doc.setLineWidth(1.5);
      setLoad(false);
      doc.save('facture.pdf');
    }
  }

  // ===================EXPORT VERS EXCEL DE LA LISTE CLIENTS ===============================

  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : '';
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
  async function saveAsExcel() {
    // if(datas.length<=0){
    //   setMsg("Aucune donnée à imprimer")
    //   setIsSave(true)
    //   setMsgravity("danger")
    //   setLoad(false)
    //   return
    // }
    // const data = [];
    // datas.forEach((row) => {
    //   var regl = 0
    //   if(row.reglement.length > 0){
    //     row.reglement.forEach(element => {
    //       regl += element.montant
    //     })
    //   }
    //     data.push(
    //       {
    //         code: row.reception ? row.reception.nofiche : "Néant",
    //         client:  row.client,
    //         nofact: row.noprof,
    //         noprof: row.nofact,
    //         datefact: dateformat(row.datefact,'dd-mm-yyyy'),
    //         engin: row.immatr_eng,
    //         montant: row.prix_ttc,
    //         regle: regl,
    //         reste: row.prix_ttc-regl
    //       }
    //     )
    // })
    // let header = ["N° fiche", "Clients",'N° proforma' ,'N° facture','Date facture', 'Engin', 'Mont. TTC', 'Mont. payé', 'Reste'];
    // XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    //   const sheet1 = workbook.sheet(0);
    //   const sheetData = getSheetData(data, header);
    //   const totalColumns = sheetData[0].length;
    //   sheet1.cell("A1").value(sheetData);
    //   const range = sheet1.usedRange();
    //   const endColumn = String.fromCharCode(64 + totalColumns);
    //   sheet1.row(1).style("bold", true);
    //   sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
    //   range.style("border", true);
    //   return workbook.outputAsync().then((res) => {
    //     saveAs(res, "liste-facture-prestation.xlsx");
    //   });
    // });
  }

  function download(donne) {
    var text = '';
    var i = '';
    var htpiece = 0;
    var htprestation = 0;
    var remis = 0;
    var da = JSON.parse(donne);
    var data = datas.filter(function (item) {
      for (var key in da) {
        if (item.id === da[key]) return true;
      }
      return false;
    });
    data.forEach((row) => {
      row.ligne.forEach((lign) => {
        lign.categorie === 'Pièce'
          ? (htpiece +=
              lign.prixprestation * lign.qte -
              lign.prixprestation * lign.qte * (lign.remise / 100) -
              lign.prixprestation * lign.qte * (lign.vetuste / 100))
          : (htprestation +=
              lign.prixprestation * lign.qte -
              lign.prixprestation * lign.qte * (lign.remise / 100) -
              lign.prixprestation * lign.qte * (lign.vetuste / 100));
      });
      if (row.nofact !== 'neant') {
        i++;
        if (row.sisociete === 'Societé') {
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';41100000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';' +
            row.prix_ttc +
            ';;VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';44320000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';;' +
            row.prix_tva +
            ';VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';70610000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';;' +
            Math.round(htpiece) +
            ';VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';70611000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';' +
            Math.round(htprestation) +
            ';;VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';70616000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';;' +
            row.petitfourn +
            ';VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';47113000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';;' +
            row.debours +
            ';VTE' +
            '\n';
        } else if (row.sisociete === 'Personne physique') {
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';41101000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';' +
            row.prix_ttc +
            ';;VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';44320000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';;' +
            row.prix_tva +
            ';VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';70612000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';;' +
            Math.round(htpiece) +
            ';VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';70613000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';' +
            Math.round(htprestation) +
            ';;VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';70616000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';;' +
            row.petitfourn +
            ';VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';47113000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            ';;' +
            row.debours +
            ';VTE' +
            '\n';
        } else {
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';41102000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            '-' +
            row.nosinistre +
            ';' +
            row.prix_ttc +
            ';;VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';44320000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            '-' +
            row.nosinistre +
            ';;' +
            row.prix_tva +
            ';VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';70614000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            '-' +
            row.nosinistre +
            ';;' +
            Math.round(htpiece) +
            ';VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';70615000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            '-' +
            row.nosinistre +
            ';' +
            Math.round(htprestation) +
            ';;VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';70616000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            '-' +
            row.nosinistre +
            ';;' +
            row.petitfourn +
            ';VTE' +
            '\n';
          text +=
            dateformat(row.datefact, 'dd-mm-yyyy') +
            ';' +
            i +
            ';' +
            row.nofact +
            ';' +
            row.client +
            ';47113000' +
            ';' +
            row.nocompte +
            ';' +
            row.client +
            '-' +
            row.nofact +
            '-' +
            row.model +
            '-' +
            row.immatr_eng +
            '-' +
            row.nosinistre +
            ';;' +
            row.debours +
            ';VTE' +
            '\n';
        }
        htprestation = 0;
        htpiece = 0;
      }
    });
    if (text != '') {
      var blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'export.txt');
    } else {
      setIsSave(true);
      setMsg('Rien à exporter');
      setMsgravity('danger');
    }
  }

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  async function getAll() {
    setLoad(true);
    const cloneProducts = [...dataAll.data];
    setDatas(cloneProducts);
    setTitle('MES DEVIS');
    setType('Proforma');
    setLoad(false);
  }

  function getProformaFact() {
    setLoad(true);
    const cloneProducts = [...dataAll.data];
    const res = cloneProducts.filter((element) => element.nofact === 'neant');
    setDatas(res);
    setType('Proforma');
    setTitle('DEVIS NON FACTURES');
    setLoad(false);
  }
  async function getFact() {
    setLoad(true);
    const cloneProducts = [...dataAll.data];
    const res = cloneProducts.filter(
      (element) =>
        element.nofact !== 'neant' && element.etat != 7 && element.etat != 8
    );
    setDatas(res.sort(GetSortOrder('datefact')));
    setType('Facture');
    setTitle('MES FACTURES');
    setLoad(false);
  }
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="danger" />
        </Modal.Body>
      </Modal>
      {showAdd ? (
        <AddFacture
          ids={ids}
          editData={editData}
          setAdds={setAdds}
          show={showAdd}
          setIsSave={setIsSave}
          setDatas={setDatas}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          setLoad={setLoad}
          getFacture={getFacture}
          types={type}
        />
      ) : (
        <></>
      )}
      {addfact != false ? (
        <Facturer
          factdata={factdata}
          setAddfact={setAddfact}
          show={showAddfact}
          setIsSave={setIsSave}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          getFacture={getFacture}
        />
      ) : (
        <></>
      )}
      {shows ? (
        <ViewLight
          ids={ids}
          setShows={setShows}
          show={shows}
          handleShow={handleShow}
          setLoad={setLoad}
          load={load}
          factOrProf={factOrProf}
        />
      ) : (
        <></>
      )}

      {/* {ids != 0 && showEdit ? (
        <UpdateFacture
          ids={ids}
          setShowEdit={setShowEdit}
          show={showEdit}
          setIsSave={setIsSave}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          handleShow={handleShow}
          setLoad={setLoad}
          load={load}
          getFacture={getFacture}
          types={type}
        />
      ) : (
        <></>
      )} */}
      {showFilterModal ? (
      <FilterModale
        setShowFilterModals={setShowFilterModals}
        showFilterModal={showFilterModal}
        setMsg={setMsg}
        setIsSave={setIsSave}
        setMsgravity={setMsgravity}
        setLoad={setLoad}
        load={load}
        handleClose={handleClose}
        setDataAll={setDataAll}
        setDatas={setDatas}
        setTotal={setTotal}
        setNbfact={setNbfact}
        setNbprof={setNbprof}
        setType={setType}
        setTitle={setTitle}
      />
    ) : (
      <></>
    )}
  
      <Toast
        id="toast"
        onClose={() => setIsSave(false)}
        show={isSave}
        delay={3000}
        className={'bg-' + msgGravity}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Info</strong>
        </Toast.Header>
        <Toast.Body className="dark">{msg}</Toast.Body>
      </Toast>
      {log !== undefined || log1 !== undefined ? (
      <div className="content">
         <Card className="card-stats" color="dark" text="light">
                                                    <CardBody>
        <Row>
          <Col lg="4" md="6" sm="6">
            <Card
              className="card-stats"
              onClick={() => getAll()}
              title="Cliquer pour plus de détail"
            >
              <CardBody>
                <Row>
                  <Col md="3" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col md="9" xs="7">
                    <div className="numbers">
                      <p className="card-category">MES DEVIS</p>
                      <CardTitle tag="p">{total}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg="4" md="6" sm="6">
            <Card
              className="card-stats"
              onClick={() => getFact()}
              title="Cliquer pour plus de détail"
            >
              <CardBody>
                <Row>
                  <Col md="3" xs="5">
                    <div className="icon-big text-center icon-info">
                      <i className="nc-icon nc-money-coins text-info" />
                    </div>
                  </Col>
                  <Col md="9" xs="7">
                    <div className="numbers">
                      <p className="card-category">MES FACTURES</p>
                      <CardTitle tag="p">{nbfact}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="6">
            <Card
              className="card-stats"
              onClick={() => getProformaFact()}
              title="Cliquer pour plus de détail"
            >
              <CardBody>
                <Row>
                  <Col md="3" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bullet-list-67 text-warning" />
                    </div>
                  </Col>
                  <Col md="9" xs="7">
                    <div className="numbers">
                      <p className="card-category">DEVIS NON FACTURES</p>
                      <CardTitle tag="p">{nbprof}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
</CardBody>
</Card>
        <Row>
          <Col md="12">
            <div id="tablecontent">
              {type === 'Proforma' ? (
                <MyAwesomeTable
                  rows={datas}
                  isLoading={load}
                  onvalideDir={onvalideDir}
                  setIds={setIds}
                  setAdds={setAdds}
                  viewSortie={viewSortie}
                  DuplicateFacture={DuplicateFacture}
                  printFacture={printFacture}
                  download={download}
                  handleShow={handleShow}
                  title={title}
                  tab={0}
                  setShowFilterModals={setShowFilterModals}
                  setInitialiseData={setInitialiseData}
                />
              ) : (
                <MyAwesomeTable
                  rows={datas}
                  isLoading={load}
                  onvalideDir={onvalideDir}
                  setIds={setIds}
                  setAdds={setAdds}
                  viewSortie={viewSortie}
                  DuplicateFacture={DuplicateFacture}
                  printFacture={printFacture}
                  download={download}
                  handleShow={handleShow}
                  title={title}
                  tab={1}
                  setShowFilterModals={setShowFilterModals}
                  setInitialiseData={setInitialiseData}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
            ) : (
              <Card style={{ width: '25rem'}} className="centerCard">
                <CardBody>
                  <CardTitle tag="h5">
                    <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                  </CardTitle>
                  <CardText>
                    Vous n'êtes pas autoriser à utiliser cette section. Merci de
                    contacter votre administrateur
                  </CardText>
                </CardBody>
              </Card>
            )}
    </>
  );
}

export default ListeFacture;
