import * as React from 'react';

import config from '../../../commonData';

import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { Button, ButtonGroup, Form, Modal, Placeholder, Row } from 'react-bootstrap';
import { Backspace, Save2Fill } from 'react-bootstrap-icons';

export default function AjouterDepense({ open, handleCloseModal, setOpenSnak,  setActualise,actualise, idModif, setMsg, setSeverity }) {
    const [isLoading, setLoading] = React.useState(false);

    const [type, setTypeListe] = React.useState(null);
    const [state, setState] = React.useState({
        montantdepense: '',
        motifdep: '',
        datedep: '',
        typeid: 1
    });

    React.useEffect(() => {
        setLoading(true);
        async function fetchTypeDepense() {
            const liste = await fetch(config.ipapi + '/api/liste-typedep');
            const listetype = await liste.json();
            setTypeListe(listetype);
            setLoading(false);
        }
        fetchTypeDepense();
        async function fetchDepense() {
            const response = await fetch(config.ipapi + '/api/depense/' + idModif);
            const json = await response.json();

            setState((previousState) => {
                return {
                    ...previousState,
                    montantdepense: json.montant_depense,
                    motifdep: json.motif_dep,
                    datedep: json.date_dep,
                    typeid: json.type_id
                };
            });
            setLoading(false);
        }
        if (idModif) {
            fetchDepense();
        } else {
            setState((previousState) => {
                return {
                    ...previousState,
                    montantdepense: '',
                    motifdep: '',
                    datedep: '',
                    typeid: 1
                };
            });
            setLoading(false);
        }
    }, [idModif]);
    const { montantdepense, motifdep, datedep, typeid } = state;

    return (
        <Formik
            initialValues={{
                montantdepense: montantdepense || '',
                motifdep: motifdep || '',
                datedep: datedep || '',
                typeid: typeid || '',
                iduser: config.userid,
                app: 2,
                submit: null
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
                montantdepense: Yup.number().required('Veuillez cette donnée'),
                motifdep: Yup.string().max(255).required('Veuillez saisir cette donnée'),
                datedep: Yup.date().required('Veuillez saisir cette donnée'),
                typeid: Yup.number().required('Veuillez saisir cette donnée')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    let url = idModif ? config.ipapi + '/api/update-depense/' + idModif : config.ipapi + '/api/add-depense';
                    await axios
                        .post(url, values)
                        .then((res) => {
                            if (res.data.status) {
                                setStatus({ success: true });
                                setOpenSnak(true);
                                setMsg(res.data.msg);
                                setSeverity('success');
                                setActualise(!actualise);
                                handleCloseModal();
                            } else {
                                setOpenSnak(true);
                                setStatus({ success: false });
                                setMsg(res.data.msg);
                                setSeverity('error');
                            }
                        })
                        .catch(() => {
                            setStatus({ success: false });
                        });
                    setSubmitting(false);
                } catch (err) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <Modal show={open} onHide={handleCloseModal}>
                    <Modal.Header closeButton>{idModif ? 'MODIFIER LES DONNEES' : 'ENREGISTRER UNE NOUVELLE DEPENSES'}</Modal.Header>
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            {isLoading ? (
                                <Placeholder xs={12} bg="secondary" />
                            ) : (
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    // controlId="formHorizontalEmail"
                                    error={Boolean(touched.typeid && errors.typeid)}
                                >
                                    <Form.Label column sm={4}>
                                        Type de dépense
                                    </Form.Label>
                                    <Form.Select
                                        labelId="demo-select-small"
                                        id="typeid"
                                        name="typeid"
                                        value={values.typeid}
                                        label="Type de dépense"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        {type ? (
                                            type.map((prt, index) => {
                                                return (
                                                    <option key={index} id="parentid" value={prt.id}>
                                                        {' '}
                                                        {prt.lib_type_dep}
                                                    </option>
                                                );
                                            })
                                        ) : (
                                            <option>Pas de donnée</option>
                                        )}
                                    </Form.Select>
                                    {touched.typeid && errors.typeid && (
                                        <Form.Control.Feedback error type="invalid">
                                            {errors.typeid}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            )}
                            {isLoading ? (
                                <Placeholder xs={12} bg="secondary" />
                            ) : (
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    // controlId="formHorizontalEmail"
                                    error={Boolean(touched.montantdepense && errors.montantdepense)}
                                >
                                    <Form.Label column sm={4}>
                                        Montant dépense
                                    </Form.Label>
                                    <Form.Control
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="montantdepense"
                                        value={values.montantdepense}
                                        label="Montant dépense"
                                        type="number"
                                        size="small"
                                        variant="standard"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        
                                    />
                                    {touched.montantdepense && errors.montantdepense && (
                                        <Form.Control.Feedback error type="invalid">
                                            {errors.montantdepense}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            )}
                            {isLoading ? (
                                <Placeholder xs={12} bg="secondary" />
                            ) : (
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    // controlId="datedep"
                                    error={Boolean(touched.datedep && errors.datedep)}
                                >
                                    <Form.Label column sm={4}>
                                        Date dépense
                                    </Form.Label>
                                    <Form.Control
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="datedep"
                                        value={values.datedep}
                                        label="Date de dépense"
                                        type="date"
                                        size="small"
                                        variant="standard"
                                    />
                                    {touched.datedep && errors.datedep && (
                                        <Form.Control.Feedback error type="invalid">
                                            {errors.datedep}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            )}
                            {isLoading ? (
                                <Placeholder xs={12} bg="secondary" />
                            ) : (
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    // controlId="motifdep"
                                    error={Boolean(touched.motifdep && errors.motifdep)}
                                >
                                    <Form.Label column sm={4}>
                                        Motifs
                                    </Form.Label>
                                    <Form.Control
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        id="motifdep"
                                        value={values.motifdep}
                                        label="Motifs"
                                        type="text"
                                        size="small"
                                        rows={4}
                                        variant="standard"
                                        inputProps={{}}
                                    
                                    />
                                    {touched.motifdep && errors.motifdep && (
                                        <Form.Control.Feedback error type="invalid">
                                            {errors.motifdep}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <input id="iduser" name="iduser" value={values.iduser} type="hidden" />
                            <input id="app" name="app" value={values.app} type="hidden" />
                            <div className="ml-auto">
                                <ButtonGroup size="sm">
                                    <Button variant="danger" onClick={handleCloseModal}>
                                        <Backspace color="red" size={20} />
                                    </Button>
                                    <Button type="submit" variant="ligth" disabled={isSubmitting}>
                                        <Save2Fill color="#807f7e" size={20} /> {idModif ? 'Modifier' : 'Ajouter'}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )}
        </Formik>
    );
}
