import React from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import 'assets/css/tableGar.css';
function MyAwesomeTable({ columns, rows, isLoading }) {
  const config = {
    search: 'Recherche:',
    totalRows: 'Total ligne:',
    rows: 'Ligne:',
    selected: 'Selected',
    rowsPerPage: 'Ligne par page:',
    page: 'Page:',
    of: 'sur',
    prev: 'Prec',
    next: 'Suiv',
    columnVisibility: 'Colonne visible',
  };
  return (
    <div id="table1">
      <GridTable
        columns={columns}
        texts={config}
        isLoading={isLoading}
        isVirtualScroll={false}
        pageSizes={[200, 500, 1000]}
        // onRowClick={({ rowIndex, data, column, isEdit, event }, tableManager) =>
        // (
        //     !isEdit &&
        // tableManager.rowSelectionApi.getIsRowSelectable(data.id) &&
        // tableManager.rowSelectionApi.toggleRowSelection(data.id) && console.log(tableManager.rowSelectionApi.selectedRowsIds)
        // )

        // }
        //onRowsRequest={getArticle}
        rows={rows}
        // onRowsChange={setRows}
        // totalRows={totalRows}
        // onTotalRowsChange={setTotalRows}
        minColumnResizeWidth={30}
      />
    </div>
  );
}

export default MyAwesomeTable;
