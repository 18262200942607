import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Spinner,
  Form,
  InputGroup,
  Toast,
  Modal,
  Dropdown,
  Navbar,
  Container,
  Nav,
} from 'react-bootstrap';
import { Button, Card, CardBody, Row, Col, CardTitle, CardText } from 'reactstrap';
import {
  PencilSquare,
  Trash,
  Printer,
  FileExcel,
  Save,
  BackspaceFill,
  PlusCircleDotted,
  TriangleHalf,
} from 'react-bootstrap-icons';
import MyAwesomeTable from './gridtables';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import NumberToLetter from '../../converter';
import Axios from 'axios';
import dateformat from 'dateformat';
import common from '../../commonData';
import AddReglement from './addReglement';
import UpdateReglement from './updateReglement';

var dta = [];
function ListeFacture() {
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [noprof, setNoprof] = useState('');
  const [datep, setDateprof] = useState('');
  const [datas, setDatas] = useState([]);
  const [datal, setDatal] = useState([]);
  const [load, setLoad] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [datet, setDate] = useState('');
  const [nobon, setNobon] = useState(0);
  const [factid, setFactid] = useState(0);
  const [msg, setMsg] = useState('');
  const [msgGravity, setMsgravity] = useState('success');
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [ids, setId] = useState(0);
  const [client, setClient] = useState('');
  const [datesort, setDatesort] = useState('');
  const [nofact, setFact] = useState(0);
  const [actualise, setActualise] = useState(false);
  const log = common.search(common.userdroit, "SAD");
  const log1 = common.search(common.userdroit, "REG");
  useEffect(() => {
    setLoad(true);
    if (localStorage.getItem('user-info-g')) {
      (async function anyNameFunction() {
        await Axios.get(common.ipapi + '/api/reglementprest/index')
          .then((response) => {
            setDatas(response.data.data);
            dta = response.data.data;
            setLoad(false);
          })
          .catch((errors) => {
            setLoad(false);
          });
      })();
    } else {
      history.push('/login');
    }
  }, [history,actualise]);

  function setAdds(val){
    setShowAdd(true);
  }
  function setIds(id) {
    setId(id);
    setShowEdit(true);
  }

  const Operation = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div className="menudropg">
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            key={data.id}
            drop="start"
            id="dropdown-basic"
          ></Dropdown.Toggle>
          <Dropdown.Menu>
            {/* {<Dropdown.Item onClick={(e)=>viewSortie(data.id,1)} title="Voir tous les détails de la sortie"><Eye color="royalblue" size={23} />Voir détail</Dropdown.Item>} */}
            {/* <Dropdown.Item onClick={()=>printRecu(data.id)} title="Imprimer cette facture"><Printer color="royalblue" size={20} />Imprimer ligne</Dropdown.Item> */}
            <Dropdown.Item
              onClick={() => setIds(data.id)}
              title="Modifier cette ligne"
            >
              <PencilSquare size={20} color="green" />
              Modifier ligne
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => onDelete(data.id)}
              title="Supprimer cette ligne"
            >
              <Trash color="red" size={20} />
              Supprimer ligne
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };
  const clt = ({ data }) => {
    return data.proforma[0] ? data.proforma[0].client.raisonsocial : 'néant';
  };
  const user = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return data.user ? data.user.name : '';
  };
  const datef = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return data.proforma[0]
      ? dateformat(data.proforma[0].datefact, 'dd-mm-yyyy')
      : 'néant';
  };
  const nofacts = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return data.proforma.nofact;
  };
  const date1 = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return dateformat(data.created_at, 'dd-mm-yyyy HH:MM');
  };
  const dateReg = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return dateformat(data.date_regl, 'dd-mm-yyyy');
  };

  const immtrs = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return data.proforma[0] ? data.proforma[0].immatr_eng : 'néant';
  };

  const chassis = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return data.proforma[0] ? data.proforma[0].chassis : 'néant';
  };

  const prixttc = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'CFA',
    }).format(data.montant);
  };

  const columns = [
    {
      id: 1,
      label: 'N° reçu',
      field: 'norecu',
      width: '100px',
    },
    {
      id: 2,
      label: 'Clients',
      field: 'proforma.client.raisonsocial',
      cellRenderer: clt,
    },
    {
      id: 3,
      label: 'N° facture',
      field: 'proforma.nofact',
      cellRenderer: nofacts,
      width: '100px',
    },
    {
      id: 4,
      label: 'Date fact.',
      field: 'proforma.datefact',
      cellRenderer: datef,
      width: '120px',
    },
    {
      id: 5,
      label: 'N° chèque',
      field: 'nocheq',
      width: '150px',
    },

    {
      id: 6,
      label: 'Prix TTC',
      field: 'prix_ttc',
      cellRenderer: prixttc,
      width: '115px',
    },
    {
      id: 7,
      label: 'Date regl.',
      field: 'date_regl',
      cellRenderer: dateReg,
      width: '120px',
    },

    {
      id: 8,
      label: 'Immatr.',
      field: 'proforma.immatr_eng',
      cellRenderer: immtrs,
      width: '100px',
    },
    {
      id: 9,
      label: 'Chassis',
      field: 'chassis',
      cellRenderer: chassis,
      width: '140px',
    },

    {
      id: 10,
      cellRenderer: user,
      field: 'user.name',
      label: 'Créer par',
    },
    {
      id: 11,
      cellRenderer: date1,
      label: 'Créer le',
      // getValue: ({value, column}) => value.x + value.y
    },
    {
      id: 12,
      label: 'Actions',
      cellRenderer: Operation,
      width: '30px',
      pinned: true,
      minResizeWidth: 20,
    },
  ];

  const handleClose = () => {
    setShow(false);
    setShows(false);
  };

  function saveFact(e) {
    if (window.confirm('Voulez-vous facturer cette ligne?')) {
      setLoad(true);
      var formData = new FormData();
      formData.append('date', datet);
      formData.append('numero', nobon);
      Axios({
        url: common.ipapi + '/api/receptionfact/updatefact/' + factid,
        method: 'POST',
        headers: {
          'Content-Type': 'form-data',
        },
        data: formData,
      }).then((response) => {
        if (response.data.status === 1) {
          setFactid(0);
          setNobon(0);
          setDate('');
          setShow(false);
          setMsg(response.data.msg);
          setMsgravity('success');
          //setIsSave(true)
          setLoad(false);
        } else {
          setMsg(response.data.msg);
          //setIsSave(true)
          setMsgravity('danger');
          setLoad(false);
        }
      });
    }
  }
  function onDelete(id) {
    
    if (window.confirm('Voulez-vous supprimer cette ligne ?')) {
      setLoad(true);
      Axios.delete(common.ipapi + '/api/reglementprest/destroy/' + id).then(
        (response) => {
          if (response.data.status == 1) {
            const del = dta.filter((d) => d.id !== id);
            dta = del;
            setDatas(del);
            setMsg(response.data.msg);
            setMsgravity('success');
            //setIsSave(true)
            setLoad(false);
          } else {
            setMsg(response.data.msg);
            setIsSave(true);
            setMsgravity('success');
            setLoad(false);
          }
        }
      );
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    saveFact();
  };
  //------- IMPRIMER LA LISTE DE FACTURES -----------------
  function printListeFacture() {
    let text =
      'Voulez-vous imprimer avec ou sans entête ? \n Ok si vous voulez avec entête \n Annuler si vous ne voulez pas avec entête';
    let res = window.confirm(text);
    const doc = new jsPDF();
    doc.text('LISTE DES FACTURES ET PROFORMAS', 105, 20, null, null, 'center');
    // <table id="my-table"><!-- ... --></table>
    doc.autoTable({
      styles: {
        fillColor: [255, 127, 0],
        textColor: [0, 0, 0],
        halign: 'center',
      },
      theme: 'grid',
      margin: { top: 30 },
      html: '#table',
      columnStyles: {
        0: { halign: 'center', fillColor: [255, 255, 255] },
        1: { halign: 'center', fillColor: [255, 255, 255] },
        2: { halign: 'center', fillColor: [255, 255, 255] },
        3: { halign: 'center', fillColor: [255, 255, 255] },
        4: { halign: 'center', fillColor: [255, 255, 255] },
        5: { halign: 'left', fillColor: [255, 255, 255] },
        6: { halign: 'center', fillColor: [255, 255, 255] },
        7: { halign: 'center', fillColor: [255, 255, 255] },
      },
    });
    //doc.autoPrint({variant: 'non-conform'});
    doc.save('facture.pdf');
  }

  //------- IMPRIMER RECU -----------------
  function printRecu(id) {
    let text =
      'Voulez-vous imprimer avec ou sans entête ? \n Ok si vous voulez avec entête \n Annuler si vous ne voulez pas avec entête';
    let res = window.confirm(text);
    var doc = new jsPDF();
    var width = doc.internal.pageSize.getWidth();
    const dataFactures = [];
    var i = 0;
    let mont = 0,
      t = 0;
    let h = 0;
    setLoad(true);
    if (res === true) {
      Axios.get(common.ipapi + '/api/reglementprest/show/' + id)
        .then((response) => {
          let nbl = response.data.ligne.length;
          response.data.ligne.forEach((row) => {
            i++;
            mont += Math.round(row.prix_unitaire * row.qte);
            t = Math.round(row.prix_unitaire * row.qte);
            dataFactures.push([
              { content: i },
              { content: row.designation },
              { content: row.qte },
              { content: row.prix_unitaire * row.qte },
              { content: t },
            ]);
          });
          dataFactures.push([
            { content: 'Montant HT', colSpan: 4 },
            { content: mont },
          ]);
          dataFactures.push([
            { content: 'Montant TVA', colSpan: 4 },
            { content: mont * 0.18 },
          ]);
          doc.setFillColor(255, 127, 0);
          doc.addImage(common.logo1, 'jpg', 14, 10, 30, 30);
          doc.setFillColor(0, 0, 0);
          doc.rect(65, 19, width - 80, 10, 'F');
          doc.setFontSize(16);
          doc.text('CLUB AUTO ENTREPOT', 90, 17);
          doc.setFontSize(12);
          doc.setTextColor(255, 255, 255);
          doc.text(
            response.data.nofact !== 0 ? 'FACTURE CLIENT' : 'PROFORMA CLIENT',
            90,
            26
          );
          doc.setFontSize(12);
          doc.setTextColor(0, 0, 0);
          doc.text(
            146,
            50,
            'Imprimer le: ' + dateformat(new Date(), 'dd-mm-yyyy')
          );
          doc.text(146, 60, 'Engin: ' + response.data.engin);
          doc.text(
            response.data.client
              ? 'Non: ' + response.data.client.raisonsocial
              : 'Nom',
            15,
            40
          );
          doc.text(
            response.data.client
              ? 'Télephone: ' + response.data.client.telcli
              : 'Télephone:',
            15,
            50
          );
          doc.text(
            'Date livraison: ' +
              dateformat(response.data.datefact, 'dd-mm-yyyy'),
            146,
            40
          );
          response.data.nofact !== 0
            ? doc.text('N° facture: ' + response.data.nofact, 15, 60)
            : doc.text('N° proforma: ' + response.data.noprof, 15, 60);
          doc.autoTable({
            styles: {
              fillColor: [0, 0, 0],
              textColor: [255, 255, 255],
              halign: 'center',
            },
            columnStyles: {
              0: {
                halign: 'center',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
              },
              1: {
                halign: 'left',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
              },
              2: {
                halign: 'center',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
              },
              3: {
                halign: 'center',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
              },
              4: {
                halign: 'center',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
              },
            },
            didDrawCell: function (data) {
              h = data.cell.y + 20;
            },
            theme: 'grid',
            startY: 68,
            fontSize: 9,
            margin: { bottom: 35 },
            head: [['N°', 'Article', 'Qté', 'Montant']],
            body: dataFactures,
            foot: [['Montant TTC', '', '', mont + mont * 0.18]],
          });
          doc.text('Arrêté à la somme de:', 15, 224);
          doc.text(
            NumberToLetter(mont + mont * 0.18).toUpperCase() + ' FCFA',
            15,
            232
          );
          doc.rect(14, 226, width - 28, 8);

          doc.text('Visa direction:', 15, 241);
          doc.rect(14, 242, 60, 22);
          doc.text('Visa client:', 135, 241);
          doc.rect(135, 242, 60, 22);
          doc.setFontSize(8);

          doc.setLineWidth(1.5);
          doc.setFontSize(8);
          doc.setLineWidth(1.5);
          doc.setDrawColor(0, 0, 0);
          doc.line(0, 270, width, 270);
          doc.text(
            'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
            105,
            275,
            null,
            'center'
          );
          doc.text(
            'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 05 44 00 00 00 /  Email: vicaso11@hotmail.fr ',
            105,
            280,
            null,
            'center'
          );
          setLoad(false);
          //doc.autoPrint({variant: 'non-conform'});
          doc.save('facture.pdf');
        })
        .catch((errors) => {
          setLoad(false);
        });
    } else {
      Axios.get(common.ipapi + '/api/reglementprest/show/' + id)
        .then((response) => {
          response.data.lignefacture.forEach((row) => {
            i++;
            mont += Math.round(
              row.prixachat * row.coeficient * row.pivot.quantite -
                row.prixachat *
                  row.coeficient *
                  row.pivot.quantite *
                  (row.pivot.remise / 100)
            );
            t = Math.round(
              row.prixachat * row.coeficient * row.pivot.quantite -
                row.prixachat *
                  row.coeficient *
                  row.pivot.quantite *
                  (row.pivot.remise / 100)
            );
            dataFactures.push([
              { content: i },
              { content: row.pivot.descriptionart || row.descriptionart },
              { content: row.pivot.quantite },
              { content: row.prixachat * row.coeficient * row.pivot.quantite },
              { content: row.pivot.remise },
              { content: t },
            ]);
          });
          dataFactures.push([
            { content: 'Montant HT', colSpan: 5 },
            { content: mont },
          ]);
          dataFactures.push([
            { content: 'Montant TVA', colSpan: 5 },
            { content: mont * 0.18 },
          ]);
          doc.setFillColor(255, 127, 0);
          //doc.addImage(common.logo, 'jpg', 14, 10, 30, 30);
          doc.setFillColor(0, 0, 0);
          //doc.rect(65, 15, width-80, 10,'F')
          doc.setTextColor(255, 255, 255);
          // doc.text('FACTURE CLIENT',90,22)
          doc.setFontSize(12);
          doc.setTextColor(0, 0, 0);
          doc.text('INFORMATIONS CLIENT', 15, 70);
          doc.text(
            146,
            40,
            'Imprimer le: ' + dateformat(new Date(), 'dd-mm-yyyy')
          );
          doc.line(14, 72, width - 16, 72);
          doc.text('Non: ' + response.data.client.raisonsocial, 15, 80);
          doc.text('Télephone: ' + response.data.client.telcli, 130, 80);
          doc.text(
            'Date facture: ' + dateformat(response.data.datefact, 'dd-mm-yyyy'),
            130,
            85
          );
          response.data.nofact !== 0
            ? doc.text('N° facture: ' + response.data.nofact, 15, 85)
            : doc.text('N° proforma: ' + response.data.noprof, 15, 85);
          doc.text('DETAIL FACTURE', 15, 97);
          doc.line(14, 100, width - 16, 100);

          doc.autoTable({
            styles: {
              fillColor: [0, 0, 0],
              textColor: [255, 255, 255],
              halign: 'center',
            },
            columnStyles: {
              0: {
                halign: 'center',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
              },
              1: {
                halign: 'left',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
              },
              2: {
                halign: 'center',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
              },
              3: {
                halign: 'center',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
              },
              4: {
                halign: 'center',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
              },
              5: {
                halign: 'center',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
              },
            },
            didDrawCell: function (data) {
              h = data.cell.y + 20;
            },
            margin: { bottom: 35 },
            theme: 'grid',
            startY: 102,
            head: [
              [
                'N°',
                'Article',
                'Qté',
                'P. sans remise',
                'Remise (%)',
                'Montant',
              ],
            ],
            body: dataFactures,
            foot: [['Montant TTC', '', '', '', '', mont + mont * 0.18]],
          });
          doc.text('Arrêté à la somme de:', 15, h + 10);
          doc.text('Visa direction:', 15, h + 25);
          doc.rect(14, h + 26, 45, 25);
          doc.text('Visa client:', 150, h + 25);
          doc.rect(150, h + 26, 45, 25);
          doc.setFontSize(8);
          doc.text(
            NumberToLetter(mont + mont * 0.18).toUpperCase() + ' FCFA',
            15,
            h + 16
          );
          doc.rect(14, h + 11, width - 28, 8);
          doc.setLineWidth(1.5);
          setLoad(false);
          doc.autoPrint({ variant: 'non-conform' });
          doc.save('facture.pdf');
        })
        .catch((errors) => {
          setLoad(false);
        });
    }
  }
  function viewSortie(ids) {
    setLoad(true);
    Axios.get(common.ipapi + '/api/receptionfact/edit/' + ids).then(
      (response) => {
        setDatal(response.data.ligne);
        setFact(
          response.data.nofact === 0
            ? response.data.noprof
            : response.data.nofact
        );
        setClient(response.data.client.raisonsocial);
        setDatesort(
          response.data.datefact == null
            ? dateformat(response.data.dateprof, 'yyyy-mm-dd')
            : dateformat(response.data.datefact, 'yyyy-mm-dd')
        );
        setShows(true);
        setLoad(false);
      }
    );
  }

  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="danger" />
        </Modal.Body>
      </Modal>
      {showAdd !== false ? (
        <AddReglement
          setShowAdd={setShowAdd}
          show={showAdd}
          setIsSave={setIsSave}
          isSave={false}
          setDatas={setDatas}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          setActualise={setActualise}
          actualise={actualise}
        />
      ) : (
        <></>
      )}
      {showEdit ? (
        <UpdateReglement
          ids={ids}
          setShowEdit={setShowEdit}
          show={showEdit}
          setIsSave={setIsSave}
          isSave={false}
          setDatas={setDatas}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
        />
      ) : (
        <></>
      )}

      <Toast
        id="toast"
        onClose={() => setIsSave(false)}
        show={isSave}
        delay={3000}
        className={'bg-' + msgGravity}
        autohide={true}
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Info</strong>
        </Toast.Header>
        <Toast.Body className="dark">{msg}</Toast.Body>
      </Toast>
      {log != undefined || log1 != undefined ? (
      <div className="content">
        {/* <Navbar expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand href="#/prestadmin/reglements">
              LISTE DES REGLEMENTS CLIENTS
            </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link
                href="#/prestadmin/reglements"
                onClick={() => setAdds(true)}
                title="Faire un règlement"
              >
                <PlusCircleDotted color="#ee1111" size={20} /> Créer
              </Nav.Link>
              <Nav.Link
                href="#/prestadmin/reglements"
                title="Exporter vers excel"
              >
                <FileExcel color="#0080ff" size={20} /> Excel
              </Nav.Link>
              <Nav.Link
                href="#/prestadmin/reglements"
                onClick={() => printRecu()}
                title="Exporter vers pdf"
              >
                <Printer color="#0080ff" size={20} /> Imprimer
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar> */}
        <Row>
          <Col md="12">
            <Modal show={show} onHide={handleClose} size="md">
              <Modal.Header closeButton>
                <Modal.Title>Facturation proforma</Modal.Title>
              </Modal.Header>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustomUsername"
                  >
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        N° proforma:
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="N° proforma"
                        aria-describedby="inputGroupPrepend"
                        required
                        size="sm"
                        value={noprof}
                        onChange={(e) => setNoprof(e.target.value)}
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        Veuillez saisir le code article
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <br />
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustomUsername"
                  >
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        Date proforma:
                      </InputGroup.Text>
                      <Form.Control
                        type="date"
                        placeholder="Date proforma"
                        aria-describedby="inputGroupPrepend"
                        required
                        disabled
                        size="sm"
                        value={datep}
                        onChange={(e) => setDateprof(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Veuillez saisir le code article
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <br />
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustomUsername"
                  >
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        N° de pièce:
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="N° de pièce"
                        aria-describedby="inputGroupPrepend"
                        required
                        size="sm"
                        value={nobon}
                        onChange={(e) => setNobon(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Veuillez saisir le code article
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <br />
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustomUsername"
                  >
                    <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend">
                        Date édition:
                      </InputGroup.Text>
                      <Form.Control
                        type="date"
                        placeholder="Date édition"
                        aria-describedby="inputGroupPrepend"
                        required
                        size="sm"
                        value={datet}
                        onChange={(e) => setDate(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Veuillez saisir le code article
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="success">
                    <Save color="white" size={20} /> Valider
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
            <div id="tablecontent">
              <MyAwesomeTable
                rows={datas}
                isLoading={load}
                setIds={setIds}
                setAdds={setAdds}
                viewSortie={viewSortie}
                printRecu={printRecu}
                onDelete={onDelete}
              />
            </div>
          </Col>
        </Row>
      </div>
                ) : (
                  <Card style={{ width: '25rem' }} className="centerCard">
                      <CardBody>
                          <CardTitle tag="h5">
                              <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                          </CardTitle>
                          <CardText>Vous n'êtes pas autorisé à utiliser cette section. Merci de contacter votre administrateur</CardText>
                      </CardBody>
                  </Card>
              )}

      <Modal show={shows} onHide={handleClose} size="lg">
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Info facture client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <CardBody>
                <Form.Group md="12" controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">
                      N° facture:
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="N° facture"
                      aria-describedby="inputGroupPrepend"
                      disabled
                      size="sm"
                      value={nofact}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group md="12" controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">
                      Client :
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="inputGroupPrepend"
                      disabled
                      size="sm"
                      value={client}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group md="12" controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">
                      Date facture:
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      placeholder="Date sortie"
                      aria-describedby="inputGroupPrepend"
                      disabled
                      size="sm"
                      value={datesort}
                    />
                  </InputGroup>
                </Form.Group>
              </CardBody>
            </Card>

            <table className="table table-striped-hover table-sm">
              <thead>
                <tr>
                  <th scope="col">Descr. art.</th>
                  <th scope="col">Qté</th>
                  <th scope="col">P. unitaire</th>
                  <th scope="col">Si - tva</th>
                  <th scope="col">P. HT</th>
                  <th scope="col">P. TTC</th>
                  {/* <th scope="col">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {datal.map((d, index) => {
                  return (
                    <tr key={index} hover="true">
                      <td>{d.designation}</td>
                      <td>{d.qte}</td>
                      <td>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'CFA',
                        }).format(d.prix_unitaire)}
                      </td>
                      <td>{d.tva}</td>
                      <td>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'CFA',
                        }).format(d.qte * d.prix_unitaire)}
                      </td>
                      <td>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'CFA',
                        }).format(
                          d.qte * d.prix_unitaire * 0.18 +
                            d.qte * d.prix_unitaire
                        )}
                      </td>
                      {/* <td>{dateformat(d.created_at,"yyyy-MM-dd")}</td> */}
                      {/* <td>
                                      <ButtonGroup aria-label="Basic example">
                                          <Link to={`/admin/article/fiche/${d.id}`} title="Voir tous les détails de l'article"><Eye  size={20}  color='royalblue'/></Link>
                                          </ButtonGroup>
                                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            {/* {
                  typeModal === 2 ?
                  <Button type="submit" variant="ligth"><Save2Fill color="#ff7300" size={20}/> Enregister</Button>
                  :
                  ""
                } */}
            <Button type="reset" variant="ligth" onClick={handleClose}>
              <BackspaceFill color="#000" size={20} /> Annuler
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default ListeFacture;
