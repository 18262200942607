import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { Form, ButtonGroup, Modal, Row } from 'react-bootstrap';
import { Button, Input, Col } from 'reactstrap';
import Axios from 'axios';
import { BackspaceFill, Save2Fill, Star } from 'react-bootstrap-icons';
import * as Yup from 'yup';
import { Formik } from 'formik';
import common from '../../commonData';
var count = 0;
function AddAgent({id,
  setShowAdd,
  show,
  setIsSave,
  setMsg,
  setMsgravity,
  getAgent,
  setLoad,
}) {
 
  const [states, setState] = useState({
    nom : '',
    prenom: '',
    contact: '',
    fonction: '',
});
const { nom, prenom, contact, fonction } = states;
 
  useEffect(() => {
    if(id){
    setLoad(true);
    Axios.get(common.ipapi + '/api/agent/edit/' + id)
        .then((response) => {
            setState({
                nom: response.data.nom,
                prenom: response.data.prenoms,
                contact: response.data.contact,
                fonction: response.data.fonction,
            });
            setLoad(false);
        })
        .catch((errors) => {
            setLoad(false);
        });
      }
}, [id]);





  const handleClose = () => {
    if (count !== 0) {
      getAgent();
    }
    setShowAdd(false);
    setIsSave(false);
  };

  return (
    <>
    <Formik
                initialValues={{
                  nom : nom,
                  prenom: prenom,
                  contact: contact,
                  fonction: fonction,
                  user_id: common.userid,
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    nom: Yup.string().required('Veuillez saisir cette donnée'),
                    prenom: Yup.string().required('Veuillez saisir cette donnée'),
                    fonction: Yup.string().required('Veuillez saisir cette donnée')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        setLoad(true);
                        let url = id ? common.ipapi + '/api/agent/update/' + id : common.ipapi + '/api/agent/store';
                        await Axios.post(url, values)
                            .then((res) => {
                                if (res.data.status) {
                                    setMsg(res.data.msg);
                                    setMsgravity('info');
                                    setIsSave(true);
                                    count++;
                                    if (id) {
                                      setTimeout(handleClose, 600);
                                  }
                                    setLoad(false);
                                } else {
                                    setMsg(res.data.msg);
                                    setMsgravity('warning');
                                    setIsSave(true);
                                    setLoad(false);
                                }
                            })
                            .catch(() => {
                                // setStatus({ success: false });
                            });
                        setSubmitting(false);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
            
      <Modal show={show} size="md">
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
            {id ? 'MODIFICATION EMPLOYE' : 'AJOUTER UN NOUVEL AGENT'}
            <BackspaceFill onClick={handleClose} color="red" size={30} />
          </Modal.Header>
          <Modal.Body>
          <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        Nom employé: <Star color="red" size={10} />
                                    </Form.Label>
                                    <Col sm={8}>
                <Form.Control
                id='nom'
                  type="text"
                  placeholder="Nom de l'employé "
                  aria-describedby="inputGroupPrepend"
                  required
                  size="sm"
                  value={values.nom}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.nom && <div id="feedback">{errors.nom}</div>}
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        Prénoms: <Star color="red" size={10} />
                                    </Form.Label>
                                    <Col sm={8}>
                <Form.Control
                id='prenom'
                  type="text"
                  placeholder="Prenom de l'agent "
                  aria-describedby="inputGroupPrepend"
                  required
                  size="sm"
                  value={values.prenom}
                  onBlur={handleBlur}
                                            onChange={handleChange}
                />
                {errors.prenom && <div id="feedback">{errors.prenom}</div>}
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        Téléphone:
                                    </Form.Label>
                                    <Col sm={8}>
                <Form.Control
                id='contact'
                  type="text"
                  placeholder="Téléphone"
                  aria-describedby="inputGroupPrepend"
                  required
                  size="sm"
                  value={values.contact}
                  onBlur={handleBlur}
                                            onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        Fonction:  <Star color="red" size={10} />
                                    </Form.Label>
                                    <Col sm={8}>
                <Input
                id='fonction'
                  type="select"
                  value={values.fonction}
                  onBlur={handleBlur}
                                            onChange={handleChange}
                >
                   <option value="">
                    
                  </option>
                  <option value="Agents de reception">
                    Agents de reception
                  </option>
                  <option value="Agents de saisie">Agents de saisie</option>
                  <option value="Electricien">Electricien</option>
                  <option value="Mécanicien">Mécanicien</option>
                  <option value="Tolier">Tolier</option>
                  <option value="Peintre">Peintre</option>
                </Input>
                {errors.fonction && <div id="feedback">{errors.fonction}</div>}
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup size="sm">
              <Button color="secondary" onClick={handleClose}>
                <BackspaceFill color="red" size={20} />
              </Button>
              <Button type="submit" color="default">
                <Save2Fill color="#807f7e" size={20} /> Enregister
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Form>
      </Modal>
                )}
                </Formik>
    </>
  );
}

export default AddAgent;
