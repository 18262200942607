import CryptoJS from 'crypto-js';
// var obj = JSON.parse(localStorage.getItem('user-info'));
// var iduser, user, usertof, userdroit;

// if (obj) {
//   iduser = obj.id;
//   user = obj.name;
//   usertof = obj.photo;
//   userdroit = obj.list_droit_user ? obj.list_droit_user : [];
// }
var image = new Image();
image.src = process.env.PUBLIC_URL + '/logo.png';
var image1 = new Image();
image1.src = process.env.PUBLIC_URL + '/logohr.jpg';

const secretPass = 'XkhZG4fW2t2W';
let USERNAME;
let USERID;
let ACCES_TOKEN;
let USER_PHOTO; 
let USER_DROIT;
const decryptData = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
};
if (localStorage.getItem('user-info-g')) {
    var descrypt = decryptData(localStorage.getItem('user-info-g'));
    USERNAME = descrypt.name;
    USERID = descrypt.id;
    ACCES_TOKEN = descrypt.access_token;
    USER_PHOTO = descrypt.photo;
    USER_DROIT = descrypt.list_droit_user ? descrypt.list_droit_user : [];
}

var data = {
  //ipapi:'https://192.168.8.109:81',
  //ipapi: 'https://192.168.75.128:81',
  //ipapi: 'https://192.168.1.100:81',
  ipapi: 'https://apium.tracky-entrepot.com',
  //ipapi: 'http://localhost:8000',
  logo: image,
  logo1: image1,
  defaultimg: process.env.PUBLIC_URL + '/icon.png',
  userid: USERID,
  user: USERNAME,
  usertof: USER_PHOTO,
  userdroit: USER_DROIT,
  sortly_link: 'https://api.sortly.co/api/v1/',
  sortly_secret_key: 'sk_sortly_L_yMNqAa_jLrdRzxTEAB',
  search: function search(obj, key) {
    if (typeof obj !== "object" || obj === null) {
        return obj === key ? obj : undefined;
    }
    for (const [k, v] of Object.entries(obj)) {
        const result = search(v, key);
        if (result !== undefined) {
            return result;
        }
    }
    return undefined;
 },
  nomcouleur :['BLEU','VIOLET','ROSE','ROUGE','ORANGE','JAUNE','VERT','NOIR','MARRON','GRIS','BLANC'],
  codecouleur : ['#004DCF','#9900EF','#F78DA7','#F44336','#FF9800','#FFEB3B','#4CAF50','#000','#795548','#B3B3B3','#FFF']
};
// function myCallback(){
//     delais++;
//     if(delais==20){
//       alert(10);
//     }
// }

//   var intervalID = setInterval(myCallback, 600);
export default data;