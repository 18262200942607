import * as React from 'react';

import config from '../../../commonData';

import * as Yup from 'yup';
import { Formik } from 'formik';

import axios from 'axios';
import { Button, ButtonGroup, Col, Form, Modal, Placeholder, Row } from 'react-bootstrap';
import { Backspace, Save2Fill } from 'react-bootstrap-icons';

export default function AjouterTypeDepense({ open, handleCloseModal, setOpenSnak, setLoad, load, idModif, setMsg, setSeverity }) {
    const [isLoading, setLoading] = React.useState(false);
    const [state, setState] = React.useState({
        libtype: '',
        observation: ''
    });

    React.useEffect(() => {
        setLoading(true);

        async function fetchType() {
            const response = await fetch(config.ipapi + '/api/typedep/' + idModif);
            const json = await response.json();

            setState((previousState) => {
                return {
                    ...previousState,
                    libtype: json.lib_type_dep,
                    observation: json.observation
                };
            });
            setLoading(false);
        }
        if (idModif) {
            fetchType();
        } else {
            setState((previousState) => {
                return {
                    ...previousState,
                    libtype: '',
                    observation: ''
                };
            });
            setLoading(false);
        }
    }, [idModif]);
    const { libtype, observation } = state;

    return (
        <Formik
            initialValues={{
                libtype: libtype || '',
                observation: observation || '',
                iduser: config.userid,
                submit: null
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
                libtype: Yup.string().max(100).required('Veuillez saisir le libellé')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    let url = idModif ? config.ipapi + '/api/update-typedep/' + idModif : config.ipapi + '/api/add-typedep';
                    await axios
                        .post(url, values)
                        .then((res) => {
                            if (res.data.status) {
                                setStatus({ success: true });
                                setOpenSnak(true);
                                setMsg(res.data.msg);
                                setSeverity('success');
                                setLoad(!load);
                                handleCloseModal();
                            } else {
                                setOpenSnak(true);
                                setStatus({ success: false });
                                setMsg(res.data.msg);
                                setSeverity('error');
                            }
                        })
                        .catch(() => {
                            setStatus({ success: false });
                        });
                    setSubmitting(false);
                } catch (err) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <Modal show={open} onHide={handleCloseModal}>
                    <Modal.Header closeButton>{idModif ? 'MODIFIER LES DONNEES' : 'ENREGISTRER UN TYPDE DE DEPENSE'}</Modal.Header>
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            {isLoading ? (
                                <Placeholder xs={12} bg="secondary" />
                            ) : (
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    // controlId="libtype"
                                    error={Boolean(touched.libtype && errors.libtype)}
                                >
                                    <Form.Label column sm={4}>
                                        Libellé type dépense
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.libtype}
                                            label="libellé type dépense"
                                            id="libtype"
                                            type="text"
                                            variant="standard"
                                            size="small"
                                        />
                                    </Col>
                                    {touched.libtype && errors.libtype && (
                                        <Form.Control.Feedback error type="invalid">
                                            {errors.libtype}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            )}
                            {isLoading ? (
                                <Placeholder xs={12} bg="secondary" />
                            ) : (
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    // controlId="observation"
                                    error={Boolean(touched.observation && errors.observation)}
                                >
                                    <Form.Label column sm={4}>
                                        Observation
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            id="observation"
                                            value={values.observation}
                                            label="Observation"
                                            type="text"
                                            rows={5}
                                            size="small"
                                            variant="standard"
                                        />
                                    </Col>
                                    {touched.observation && errors.observation && (
                                        <Form.Control.Feedback error type="invalid">
                                            {errors.observation}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <input id="iduser" name="iduser" value={values.iduser} type="hidden" />
                            <div className="ml-auto">
                                <ButtonGroup size="sm">
                                    <Button variant="light" onClick={handleCloseModal}>
                                        <Backspace color="red" size={20} />
                                    </Button>
                                    <Button type="submit" variant="success" disabled={isSubmitting}>
                                        <Save2Fill color="#807f7e" size={20} /> {idModif ? 'Modifier' : 'Ajouter'}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )}
        </Formik>
    );
}
