import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Form, Button, Card, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Eye, EyeSlash, LockFill} from 'react-bootstrap-icons';
import CryptoJS from 'crypto-js';

import common from './commonData';

function Login() {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alertMsg, setAlertMsg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
  const secretPass = 'XkhZG4fW2t2W';

    const encryptData = (text) => {
        const data = CryptoJS.AES.encrypt(JSON.stringify(text), secretPass).toString();
        return data;
    };

  useEffect(() => {
    if (localStorage.getItem('user-info-g')) {
      history.push('/dashboard');
    }
  });
  function signIn(e) {
    e.preventDefault();
    const values = {
      email: email,
      password: password,
    };
    Axios.post(common.ipapi + '/api/login', values)
      .then((res) => {
        if (!res.data.Error) {
          setAlertMsg(true);
          localStorage.setItem('user-info-g', encryptData(res.data));
          history.push('/dashboard');
          window.location.reload();
        } else {
          alert(res.data.Error);
        }
      })
      .catch((error) => {
        setAlertMsg(false);
      });
  }

  return (
    <Card style={{ width: '25rem' }} className="centerCard">
            <Card.Body>
                <Card.Title>Connexion utilisateur</Card.Title>
                <Form>
                    <Form.Group md="12" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter email" />
                    </Form.Group>
                    <Form.Group md="12" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                            />
                            {showPassword ? (
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Cacher le mot de passe</Tooltip>}>
                                    <EyeSlash size={35} color="orange" onClick={handleClickShowPassword} />
                                </OverlayTrigger>
                            ) : (
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Afficher le mot de passe</Tooltip>}>
                                    <Eye size={35} color="orange" onClick={handleClickShowPassword} />
                                </OverlayTrigger>
                            )}
                        </InputGroup>
                    </Form.Group>
                    <Form.Group md="12" controlId="formBasicPassword">
                        <Button type="submit" variant="primary" onClick={signIn}>
                            <LockFill color="green" size={20} /> Se connecter
                        </Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
  );
}

export default Login;
