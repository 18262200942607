import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import dateformat from 'dateformat';
import {
  Spinner,
  ButtonGroup,
  Modal,
  Toast,
  OverlayTrigger,
  Tooltip,
  InputGroup,
  Form,
} from 'react-bootstrap';
import { Button, Card, CardBody, Row, Col, CardTitle } from 'reactstrap';
import {
  Trash,
  PencilFill,
  Tools,
  EyeFill,
  Backspace,
  ListCheck,
} from 'react-bootstrap-icons';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import commondata from '../../commonData';
import { MyAwesomeTable } from '../../tableDataGar';
import { dateDiff } from '../receptions/functions';
import ViewReceptions from '../receptions/viewLight';

function DetailClients() {
  const history = useHistory();
  const [datas, setData] = useState([]);
  const [datal, setDatal] = useState([]);
  const [shows, setFactView] = useState(false);
  const [datafact, setDataFacture] = useState([]);
  const [load, setLoad] = useState(true);
  const [msg, setMsg] = useState('');
  const [msgGravity, setMsgravity] = useState('success');
  const [ids, setId] = useState(0);
  const [client, setClient] = useState('');
  const [datesort, setDatesort] = useState('');
  const [nofact, setFact] = useState(0);
  const [showView, setShowView] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [devis, setDevis] = useState(0);
  const [chaffs, setChaff] = useState(0);
  const [facturee, setFacturee] = useState(0);
  let { id } = useParams();

  useEffect(() => {
    if (localStorage.getItem('user-info-g')) {
      (async function anyNameFunction() {
        await getDetail();
      })();
    } else {
      history.push('/login');
    }
  }, [history]);

  async function getDetail() {
    Axios.get(commondata.ipapi + '/api/client/' + id + '/content')
      .then((response) => {
        var tab = [];
        var dev = 0;
        var fact = 0;
        var chaff = 0;
        response.data[1].map((row) => {
          setClient(row.client ? row.client.raisonsocial : 'néant');
          tab.push({
            id: row.id,
            nofact: row.nofact || 'neant',
            noprof: row.noprof || 'neant',
            client: row.client ? row.client.raisonsocial : 'néant',
            client_id: row.client ? row.client.id : null,
            sitva: row.client ? row.client.sitva : null,
            reception_id: row.reception_id || 0,
            marque: row.marque ? row.marque.libellemarq : 'néant',
            marque_id: row.marque_id || 0,
            model: row.model ? row.model.libellemodel : 'néant',
            model_eng: row.model_eng,
            reception: row.reception,
            reglement: row.reglement,
            ligne: row.ligne,
            chassis: row.chassis || 'néant',
            immatr_eng: row.immatr_eng || 'néant',
            date_mise_circul: row.reception
              ? row.reception.date_mise_circul
              : 'néant',
            index_compt: row.reception ? row.reception.index_compt : 0,
            created_at: row.created_at,
            datefact: row.datefact,
            dateprof: row.dateprof,
            prix_ht: row.prix_ht,
            prix_ttc: row.prix_ttc,
            prix_tva: row.prix_tva,
            remise: row.remise,
            user: row.user.name,
            nofiche: row.reception ? row.reception.nofiche : 'néant',
          });
          if (row.nofact != null && row.nofact != 0) {
            fact++;
            chaff += row.prix_ttc;
          }
          if (row.noprof != null && row.noprof != 0) {
            dev++;
          }
          setChaff(chaff);
          setFacturee(fact);
          setDevis(dev);
        });
        setDataFacture(tab);

        var tabrecep = [];
        var date2, date1, diff;
        response.data[0].map((row) => {
          row.nobon ? (date1 = new Date(row.datebon)) : (date1 = new Date());
          date2 = new Date(row.date_entre_garage);
          diff = dateDiff(date2, date1);
          tabrecep.push({
            id: row.id,
            nofiche: row.nofiche || 'neant',
            client: row.client ? row.client.raisonsocial : 'néant',
            marque: row.vehicule
              ? row.vehicule.marque
                ? row.vehicule.marque.libellemarq
                : 'néant'
              : 'néant',
            model: row.vehicule
              ? row.vehicule.model
                ? row.vehicule.model.libellemodel
                : 'néant'
              : 'néant',
            proforma: row.proforma,
            chassis: row.vehicule ? row.vehicule.chassis : 'néant',
            immatr_eng: row.vehicule ? row.vehicule.immatr_eng : 'néant',
            date_mise_circul: row.vehicule
              ? row.vehicule.date_mise_circul
              : 'néant',
            index_compt: row.index_compt,
            created_at: row.created_at,
            datebon: row.datebon,
            date_entre_garage: row.date_entre_garage,
            duregarage: diff.day,
            nobon: row.nobon,
            user: row.user.name,
            objet_visite: row.objet_visite,
            point_choc: row.point_choc,
          });
        });
        setData(tabrecep);
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }

  const datef = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return data.datefact ? dateformat(data.datefact, 'dd-mm-yyyy') : 'Néant';
  };
  const datepp = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return data.dateprof ? dateformat(data.dateprof, 'dd-mm-yyyy') : 'Néant';
  };
  const date1 = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return dateformat(data.created_at, 'dd-mm-yyyy HH:MM');
  };

  const prixttc = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return new Intl.NumberFormat().format(data.prix_ttc);
  };

  const reglement = ({ data }) => {
    var regl = 0;
    if (data.reglement.length > 0) {
      data.reglement.forEach((element) => {
        regl += element.montant;
      });
    }
    return new Intl.NumberFormat().format(regl);
  };
  const reste = ({ data }) => {
    var regl = 0;
    if (data.reglement.length > 0) {
      data.reglement.forEach((element) => {
        regl += element.montant;
      });
    }
    return new Intl.NumberFormat().format(data.prix_ttc - regl) || 0;
  };
  const Operations = ({ data }) => {
    return (
      <EyeFill
        color="royalblue"
        size={23}
        onClick={(e) => viewSortie(data.id)}
      />
    );
  };

  const columnsFact = [
    {
      id: 1,
      label: 'N° fiches',
      field: 'nofiche',
      width: '90px',
      pinned: true,
    },
    {
      id: 2,
      field: 'client',
      label: 'Clients',
    },
    {
      id: 3,
      label: 'N° facture',
      field: 'nofact',
      width: '90px',
    },
    {
      id: 4,
      label: 'Date fact.',
      field: 'datefact',
      cellRenderer: datef,
      width: '100px',
    },
    {
      id: 5,
      label: 'N° proforma',
      field: 'noprof',
      width: '90px',
    },
    {
      id: 6,
      label: 'Date prof.',
      field: 'dateprof',
      cellRenderer: datepp,
      width: '100px',
    },
    {
      id: 7,
      label: 'Immatr.',
      field: 'immatr_eng',
      width: '100px',
    },
    {
      id: 8,
      label: 'Prix TTC',
      field: 'prix_ttc',
      cellRenderer: prixttc,
      width: '100px',
    },
    {
      id: 9,
      label: 'Mt payé',
      cellRenderer: reglement,
      width: '100px',
    },
    {
      id: 10,
      label: 'Reste à payer',
      cellRenderer: reste,
      width: '100px',
    },
    {
      id: 11,
      label: 'Marques',
      field: 'marque',
      width: '100px',
    },
    {
      id: 12,
      label: 'Models',
      field: 'model',
      width: '100px',
    },
    {
      id: 13,
      label: 'Chassis',
      field: 'chassis',
    },

    {
      id: 14,
      field: 'user',
      label: 'Créer par',
    },
    {
      id: 15,
      cellRenderer: date1,
      label: 'Créer le',
    },
    {
      id: 16,
      label: 'Actions',
      cellRenderer: Operations,
      width: '30px',
      pinned: true,
      minResizeWidth: 20,
    },
  ];

  function viewSortie(idl) {
    setLoad(true);
    setFactView(true);
    Axios.get(commondata.ipapi + '/api/receptionfact/edit/' + idl).then(
      (response) => {
        setDatal(response.data.ligne);
        setFact(
          response.data.nofact == 0
            ? response.data.noprof
            : response.data.nofact
        );
        setClient(response.data.client.raisonsocial);
        setDatesort(
          response.data.datefact == null
            ? dateformat(response.data.dateprof, 'yyyy-mm-dd')
            : dateformat(response.data.datefact, 'yyyy-mm-dd')
        );

        setLoad(false);
      }
    );
  }
  function setIds(id) {
    setId(id);
  }
  function setView(bool, id) {
    setId(id);
    setShowView(bool);
  }
  const handleClose = () => {
    setFactView(false);
  };

  function onDelete(id) {
    if (window.confirm('Voulez-vous supprimer ce client ?')) {
      setLoad(true);
      Axios.delete(commondata.ipapi + '/api/client/destroy/' + id).then(
        (response) => {
          if (response.data.status == 1) {
            Axios.get(commondata.ipapi + '/api/client/index')
              .then((response) => {
                setData(response.data.data);
                setLoad(false);
              })
              .catch((errors) => {
                setLoad(false);
              });
          } else {
            setMsg(response.data.msg);
            setMsgravity('danger');
            setIsSave(true);
            setLoad(false);
          }
        }
      );
    }
  }

  const Operation = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <ButtonGroup aria-label="Basic example">
        <a onClick={() => setIds(data.id)} id="actionbtn">
          <PencilFill size={20} color="#66615B" />
        </a>
        <a id="actionbtn" onClick={() => onDelete(data.id)}>
          <Trash color="red" size={20} />
        </a>
      </ButtonGroup>
    );
  };

  const users = ({ data }) => {
    return data.user || 'néant';
  };
  const datecirc = ({ data }) => {
    return dateformat(data.date_mise_circul, 'dd-mm-yyyy') || 'néant';
  };
  const datesor = ({ data }) => {
    return data.datebon ? dateformat(data.datebon, 'dd-mm-yyyy') : 'néant';
  };
  const dateentr = ({ data }) => {
    return dateformat(data.date_entre_garage, 'dd-mm-yyyy') || 'néant';
  };

  const nofiche = ({ data }) => {
    return (
      <OverlayTrigger
        overlay={<Tooltip id="tooltip-disabled">Consulter la fiche</Tooltip>}
      >
        {data.proforma.length > 0 ? (
          data.proforma[0].nofact !== null ? (
            <Button id="successs" onClick={() => setView(true, data.id)}>
              {data.nofiche}
            </Button>
          ) : (
            <Button id="success" onClick={() => setView(true, data.id)}>
              {data.nofiche}
            </Button>
          )
        ) : (
          <Button id="light" onClick={() => setView(true, data.id)}>
            {data.nofiche}
          </Button>
        )}
      </OverlayTrigger>
    );
  };

  const diffdate = ({ data }) => {
    return data.duregarage < 10 ? (
      data.nobon ? (
        <p id="successs">{data.duregarage + ' jour/s'}</p>
      ) : (
        <p id="light">{data.duregarage + ' jour/s'}</p>
      )
    ) : data.nobon ? (
      <p id="successs">{data.duregarage + ' jour/s'}</p>
    ) : (
      <p id="success">{data.duregarage + ' jour/s'}</p>
    );
  };

  const columnsrecep = [
    {
      id: 1,
      field: 'nofiche',
      label: 'N° fiche',
      cellRenderer: nofiche,
      pinned: true,
      width: '90px',
    },
    {
      id: 2,
      field: 'client',
      label: 'Clients',
      // cellRenderer: clt
    },
    {
      id: 3,
      field: 'marque',
      label: 'Marques',
      // cellRenderer: marque,
      width: '120px',
    },
    {
      id: 4,
      field: 'model',
      label: 'Models',
      // cellRenderer: model,
      width: '120px',
    },
    {
      id: 5,
      field: 'chassis',
      label: 'Chassis',
      // cellRenderer: chassis,
      width: '120px',
    },
    {
      id: 6,
      field: 'immatr_eng',
      // cellRenderer: immatr,
      label: 'Immatriculation',
      width: '120px',
    },
    {
      id: 7,
      field: 'date_mise_circul',
      cellRenderer: datecirc,
      label: 'En circul. le',
      width: '120px',
    },
    {
      id: 8,
      field: 'index_compt',
      label: 'Index compt.',
      width: '120px',
    },
    {
      id: 9,
      field: 'date_entre_garage',
      label: 'Reçu le',
      cellRenderer: dateentr,
      width: '120px',
    },
    {
      id: 10,
      field: 'datebon',
      label: 'Sortie le',
      cellRenderer: datesor,
      width: '120px',
    },
    {
      id: 11,
      field: 'duregarage',
      label: 'Temps immo.',
      cellRenderer: diffdate,
      width: '120px',
    },
    {
      id: 12,
      field: 'nobon',
      label: 'N° bon de sortie',
      width: '120px',
    },
    {
      id: 13,
      field: 'point_choc',
      label: 'Remaques',
      width: '120px',
    },
    {
      id: 14,
      field: 'objet_visite',
      label: 'Objet visite',
      width: '120px',
    },
    {
      id: 15,
      field: 'user',
      label: 'Crée par',
      cellRenderer: users,
      width: '120px',
    },
    {
      id: 16,
      field: 'created_at',
      label: 'Crée le',
      width: '120px',
    },
  ];

  // ===================EXPORT VERS EXCEL DE LA LISTE CLIENTS ===============================

  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : '';
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
  async function saveAsExcel() {
    // if(datas.length<=0){
    //   setMsg("Aucune donnée à imprimer")
    //   setIsSave(true)
    //   setMsgravity("danger")
    //   setLoad(false)
    //   return
    // }
    // const data = [];
    // datas.forEach((row) => {
    //     data.push(
    //       { code: row.codecli, client: row.raisonsocial, telcli: row.telcli,sisociete: row.sisociete, bp: row.bp, sitva: row.sitva,remise: row.remise ? row.remise.pourcentage : 0, typereglement: row.typereglement }
    //     )
    // })
    // let header = ["Code", "Noms", "Contacts", "Type", "Boite postal", "Si exoneré","Remise %","Type règlement"];
    // XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    //   const sheet1 = workbook.sheet(0);
    //   const sheetData = getSheetData(data, header);
    //   const totalColumns = sheetData[0].length;
    //   sheet1.cell("A1").value(sheetData);
    //   const range = sheet1.usedRange();
    //   const endColumn = String.fromCharCode(64 + totalColumns);
    //   sheet1.row(1).style("bold", true);
    //   sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
    //   range.style("border", true);
    //   return workbook.outputAsync().then((res) => {
    //     saveAs(res, "Listeclient.xlsx");
    //   });
    // });
  }

  // ====================== EXPORT PDF =============================

  async function printListe() {
    if (datas.length <= 0) {
      setMsg('Aucune donnée à imprimer');
      setIsSave(true);
      setMsgravity('danger');
      setLoad(false);
      return;
    }
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    });
    var width = doc.internal.pageSize.getWidth();
    const data = [];
    let mont = 0;
    let qte = 0;
    doc.addImage(commondata.logo, 'jpg', 14, 10, 30, 30);
    doc.setFontSize(16);
    doc.text('CLUB AUTO ENTREPOT', 90, 14);
    doc.setFontSize(12);
    doc.setFillColor(0, 0, 0);
    doc.rect(65, 15, width - 80, 10, 'F');
    doc.setTextColor(255, 255, 255);
    doc.text('LISTE DES CLIENTS', 90, 22);
    doc.setTextColor(0, 0, 0);

    datas.forEach((row) => {
      data.push([
        row.codecli,
        row.raisonsocial,
        row.telcli,
        row.sisociete,
        row.bp,
        row.sitva,
        row.remise ? row.remise.pourcentage : 0,
        row.typereglement,
      ]);
    });
    doc.autoTable({
      styles: {
        fillColor: [0, 0, 0],
        textColor: [255, 255, 255],
        halign: 'center',
        fontSize: 10,
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        1: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        2: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        3: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        4: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        5: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        6: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        7: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
      },
      theme: 'grid',
      startY: 35,
      fontSize: 6,
      head: [
        [
          'Code',
          'Noms',
          'Contacts',
          'Type',
          'Boite postal',
          'Si exoneré',
          'Remise %',
          'Type règlement',
        ],
      ],
      body: data,
    });
    const pageCount = doc.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        'Page ' + String(i) + ' sur ' + String(pageCount),
        210 - 55,
        200,
        null,
        null,
        'right'
      );
    }
    doc.save('Listeclient.pdf');
  }
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      <Toast
        id="toast"
        onClose={() => setIsSave(false)}
        show={isSave}
        delay={3000}
        bg={msgGravity}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Info</strong>
        </Toast.Header>
        <Toast.Body>{msg}</Toast.Body>
      </Toast>

      {showView != false ? (
        <ViewReceptions
          id={ids}
          setShowView={setShowView}
          show={showView}
          setIsSave={setIsSave}
          isSave={false}
        />
      ) : (
        <></>
      )}

      <div className="content">
        <h4>{client}</h4>
        <hr></hr>
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats" title="Cliquer pour plus de détail">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bus-front-12 text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total recetion engin</p>
                      <CardTitle tag="p">{datas.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Total Véhicules receptionnés
                </div>
              </CardFooter> */}
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats" title="Cliquer pour plus de détail">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-paper text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total devis/facture</p>
                      <CardTitle tag="p">
                        {datafact.length + '/' + facturee}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats" title="Cliquer pour plus de détail">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total factuée</p>
                      <CardTitle tag="p">{chaffs}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats" title="Cliquer pour plus de détail">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-delivery-fast text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total reglement</p>
                      <CardTitle tag="p">{}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <div id="tablecontent">
              <Tools color="blue" size={45} /> HISTORIQUE RECEPTIONS ENGINS
              <MyAwesomeTable
                columns={columnsrecep}
                rows={datas}
                isLoading={load}
              />
            </div>
          </Col>
          <Col md={6}>
            <div id="tablecontent">
              <ListCheck color="orange" size={45} /> LISTE DES FACTURES FACTURES
              <MyAwesomeTable
                columns={columnsFact}
                rows={datafact}
                isLoading={load}
              />
            </div>
          </Col>
        </Row>
      </div>

      <Modal show={shows} onHide={handleClose} size="lg">
        <Form noValidate>
          <Modal.Header closeButton>
            <Modal.Title>Info facture client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <CardBody>
                <Form.Group md="12" controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">
                      N° facture:
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="N° facture"
                      aria-describedby="inputGroupPrepend"
                      disabled
                      size="sm"
                      value={nofact}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group md="12" controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">
                      Client :
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      aria-describedby="inputGroupPrepend"
                      disabled
                      size="sm"
                      value={client}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group md="12" controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">
                      Date facture:
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      placeholder="Date sortie"
                      aria-describedby="inputGroupPrepend"
                      disabled
                      size="sm"
                      value={datesort}
                    />
                  </InputGroup>
                </Form.Group>
              </CardBody>
            </Card>

            <table className="table table-striped-hover table-sm">
              <thead>
                <tr>
                  <th scope="col">Descr. art.</th>
                  <th scope="col">Qté</th>
                  <th scope="col">P. unitaire</th>
                  <th scope="col">Si - tva</th>
                  <th scope="col">P. HT</th>
                  <th scope="col">P. TTC</th>
                </tr>
              </thead>
              <tbody>
                {datal.map((d, index) => {
                  return (
                    <tr key={index} hover="true">
                      <td>{d.libprestation}</td>
                      <td>{d.qte}</td>
                      <td>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'CFA',
                        }).format(d.prixprestation)}
                      </td>
                      <td>{d.tva}</td>
                      <td>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'CFA',
                        }).format(d.qte * d.prixprestation)}
                      </td>
                      <td>
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'CFA',
                        }).format(
                          d.qte * d.prixprestation * 0.18 +
                            d.qte * d.prixprestation
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button type="reset" variant="ligth" onClick={handleClose}>
              <Backspace color="red" size={20} />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default DetailClients;
