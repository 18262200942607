
import React from "react";
import { NavLink} from "react-router-dom";
import { Nav } from "reactstrap";
// import common from '../../views/commonData'
// import {FileLock2Fill,Paragraph,Gear} from 'react-bootstrap-icons';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// import logo from "logo.svg";

var ps;

function Sidebar(props) {
  const sidebar = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    var extactrout = props.location.pathname.split('/');
   return props.location.pathname.indexOf(routeName) > -1 ||
      routeName.includes(extactrout[2]) === true ||
      extactrout[2].includes(routeName) === true
      ? 'active'
      : '';
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  const ColoredLine = ({ color }) => (
    <div
        style={{
            color: color,
            backgroundColor: color,
            height: 1,
            width: "100%",
            // boxShadow: '1px 2px 10px #F4AAB9',
        }}
    />
);
  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
      <a
          href="#/prestadmin/dashboard"
          className="simple-text logo-mini"
        >
          <div className="logo-img">
          <img alt="react-logo" src="/logohr.jpg" />
          </div>
        </a>
        <a href="#/prestadmin/dashboard" className="simple-text logo-normal">I-GARAGES</a>
        
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {props.routes.map((prop, key) => {
            return (
              <li
                className={
                  activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                }
                key={key}
              >
                <NavLink
                  to={prop.layout + prop.path}
                  className="nav-links"
                  activeClassName="actives"
                >
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                  
                </NavLink>{prop.separe ? <ColoredLine color="#333" /> : <></>}
              </li>
              
            );
          })}
          <hr/>
        
        </Nav>
        
      </div>
    </div>
  );
}

export default Sidebar;
