import React, { useEffect, useState } from 'react';
// react plugin used to create charts
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Pie, Bar } from 'react-chartjs-2';

//import faker from 'faker';
import { useHistory } from 'react-router-dom';

import {
  CardHeader,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardFooter,
  CardText,
} from 'reactstrap';

import { Spinner, Modal } from 'react-bootstrap';
import common from '../commonData';
import Axios from 'axios';
import { Printer, TriangleHalf } from 'react-bootstrap-icons';
import jsPDF from 'jspdf';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [stat, setArtStat] = useState([]);
  const [total, setTotal] = useState(0);
  const [enreparat, setEnRepa] = useState([]);
  const [datamois, setDataMois] = useState([]);
  const [sortmois, setSortMois] = useState([]);
  const [dataTfact, setDataTFMois] = useState([]);
  const [dataTNfact, setDataTNFMois] = useState([]);
  const history = useHistory();
  const [labels, setLabel] = useState([]);
  const [qte, setQte] = useState([]);
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState('');
  const [isSave, setIsSave] = useState(false);
  const [type, setType] = useState('Proforma');
  const [msgGravity, setMsgravity] = useState('success');
  var tabdata = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  var tabdatas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  var tfact = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  var tnfact = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  var label = [];
  const log = common.search(common.userdroit, "SAD");
  const log1 = common.search(common.userdroit, "DAS");
 
  useEffect(() => {
   
    if (localStorage.getItem('user-info-g')) {
      setLoad(true);
      const getartStat = Axios.get(
        common.ipapi + '/api/reception/totalreception'
      );
      Axios.all([getartStat])
        .then(
          Axios.spread((...responses) => {
            setArtStat(responses[0].data);
            setTotal(responses[0].data[0]);
            setEnRepa(responses[0].data[1]);
            responses[0].data[5].forEach((element) => {
              label.push(element.libellemarq);
              qte.push(element.nb);
            });
            setLabel(label);
            setQte(qte);
            responses[0].data[2].forEach((element) => {
              var ladate = new Date(element);
              switch (ladate.getMonth()) {
                case 0:
                  tabdata[0] += 1;
                  break;
                case 1:
                  tabdata[1] += 1;
                  break;
                case 2:
                  tabdata[2] += 1;
                  break;
                case 3:
                  tabdata[3] += 1;
                  break;
                case 4:
                  tabdata[4] += 1;
                  break;
                case 5:
                  tabdata[5] += 1;
                  break;
                case 6:
                  tabdata[6] += 1;
                  break;
                case 7:
                  tabdata[7] += 1;
                  break;
                case 8:
                  tabdata[8] += 1;
                  break;
                case 9:
                  tabdata[9] += 1;
                  break;
                case 10:
                  tabdata[10] += 1;
                  break;
                default:
                  tabdata[11] += 1;
                  break;
              }
            });
            setDataMois(tabdata);
            responses[0].data[6].forEach((element) => {
              var ladate = new Date(element);
              switch (ladate.getMonth()) {
                case 0:
                  tabdatas[0] += 1;
                  break;
                case 1:
                  tabdatas[1] += 1;
                  break;
                case 2:
                  tabdatas[2] += 1;
                  break;
                case 3:
                  tabdatas[3] += 1;
                  break;
                case 4:
                  tabdatas[4] += 1;
                  break;
                case 5:
                  tabdatas[5] += 1;
                  break;
                case 6:
                  tabdatas[6] += 1;
                  break;
                case 7:
                  tabdatas[7] += 1;
                  break;
                case 8:
                  tabdatas[8] += 1;
                  break;
                case 9:
                  tabdatas[9] += 1;
                  break;
                case 10:
                  tabdatas[10] += 1;
                  break;
                default:
                  tabdatas[11] += 1;
                  break;
              }
            });
            setSortMois(tabdatas);

            responses[0].data[8].forEach((element) => {
              switch (element.month) {
                case 1:
                  tfact[0] += parseInt(element.prix_ttc);
                  break;
                case 2:
                  tfact[1] += parseInt(element.prix_ttc);
                  break;
                case 3:
                  tfact[2] += parseInt(element.prix_ttc);
                  break;
                case 4:
                  tfact[3] += parseInt(element.prix_ttc);
                  break;
                case 5:
                  tfact[4] += parseInt(element.prix_ttc);
                  break;
                case 6:
                  tfact[5] += parseInt(element.prix_ttc);
                  break;
                case 7:
                  tfact[6] += parseInt(element.prix_ttc);
                  break;
                case 8:
                  tfact[7] += parseInt(element.prix_ttc);
                  break;
                case 9:
                  tfact[8] += parseInt(element.prix_ttc);
                  break;
                case 10:
                  tfact[9] += parseInt(element.prix_ttc);
                  break;
                case 11:
                  tfact[10] += parseInt(element.prix_ttc);
                  break;
                default:
                  tfact[11] += parseInt(element.prix_ttc);
                  break;
              }
            });
            setDataTFMois(tfact);
            responses[0].data[9].forEach((element) => {
              switch (element.month) {
                case 1:
                  tnfact[0] += parseInt(element.prix_ttc);
                  break;
                case 2:
                  tnfact[1] += parseInt(element.prix_ttc);
                  break;
                case 3:
                  tnfact[2] += parseInt(element.prix_ttc);
                  break;
                case 4:
                  tnfact[3] += parseInt(element.prix_ttc);
                  break;
                case 5:
                  tnfact[4] += parseInt(element.prix_ttc);
                  break;
                case 6:
                  tnfact[5] += parseInt(element.prix_ttc);
                  break;
                case 7:
                  tnfact[6] += parseInt(element.prix_ttc);
                  break;
                case 8:
                  tnfact[7] += parseInt(element.prix_ttc);
                  break;
                case 9:
                  tnfact[8] += parseInt(element.prix_ttc);
                  break;
                case 10:
                  tnfact[9] += parseInt(element.prix_ttc);
                  break;
                case 11:
                  tnfact[10] += parseInt(element.prix_ttc);
                  break;
                default:
                  tnfact[11] += parseInt(element.prix_ttc);
                  break;
              }
            });
            setDataTNFMois(tnfact);
            setLoad(false);
          })
        )
        .catch((errors) => {
          setLoad(false);
        });
      setLoad(true);
    } else {
      history.push('/login');
    }
  }, [history]);

  const labelsTTC = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  const dashboardNASDAQCharts = {
    data: (canvas) => {
      return {
        labels: labelsTTC,
        datasets: [
          {
            label: 'Mes receptions',
            data: datamois,
            fill: true,
            borderColor: '#fd7e14',
            // backgroundColor: 'rgba(255, 108, 0, 0.5)',
            pointBorderColor: '#fd7e14',
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBorderWidth: 8,
            tension: 0.4,
          },
          {
            label: 'Mes sorties',
            data: sortmois,
            fill: true,
            borderColor: '#000',
            // backgroundColor: 'rgba(0, 0, 0, 0.5)',
            pointBorderColor: '#000',
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBorderWidth: 8,
            tension: 0.4,
          },
        ],
      };
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'RECEPTION ET LIVRAISON DE VEHICULES PAR MOIS (année en cours)',
        },
      },
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'RECEPTION DE VEHICULES PAR MARQUES (année en cours)',
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        label: 'Marques',
        data: qte,
        backgroundColor: 'rgba(222, 49, 99, 0.5)',
      },
    ],
  };
  const optionsTTC = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'MONTANT TTC DEVIS FACTURE ET NON FACTURE  (année en cours)',
      },
    },
  };

  const dataTTC = {
    labels: labelsTTC,
    datasets: [
      {
        label: 'Devis facturé',
        data: dataTfact,
        backgroundColor: 'rgba(104, 255, 0, 0.8)',
      },
      {
        label: 'Devis non facturé',
        data: dataTNfact,
        backgroundColor: 'rgba(245, 0, 0, 0.8)',
      },
    ],
  };

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
    } else if (num < 999) {
      return num; // if value < 1000, nothing to do
    }
  }
  function formatCurrency(value) {
    return new Intl.NumberFormat().format(Math.round(value));
  }

  function printData() {
    const canvas = document.getElementById('mychart1');
    const canvasImg = canvas.toDataURL('image/png', 1.0);
    let pdf = new jsPDF();
    var width = pdf.internal.pageSize.getWidth();
    pdf.addImage(canvasImg, 'png', 15, 15, width-35, 90);
    pdf.save('data.pdf');
  }

  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
     
      {log !== undefined || log1 !== undefined ? (
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-bus-front-12 text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Véhicules</p>
                        <CardTitle tag="p">{formatCurrency(total)}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Total Véhicules
                    receptionnés
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-settings text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Véhicules</p>
                        <CardTitle tag="p">{enreparat}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-calendar" /> Véhicules en cours de
                    reparation
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-delivery-fast text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Véhicules sorties</p>
                        <CardTitle tag="p">
                          {formatCurrency(total - enreparat)}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Véhicules sorties
                  </div>
                </CardFooter>
              </Card>
            </Col>

            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-money-coins text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Chiffre affaire</p>
                        <CardTitle tag="p">{numFormatter(stat[3])}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-clock" /> Total chiffre affaire
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card className="card-chart">
                <CardBody>
                  <Printer onClick={() => printData()} size={24}></Printer>
                  <canvas id="canvas" width="5" height="5"></canvas>
                  <Line
                    data={dashboardNASDAQCharts.data}
                    options={dashboardNASDAQCharts.options}
                    width={400}
                    height={205}
                    id="mychart1"
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardBody>
                  <Bar
                    options={optionsTTC}
                    data={dataTTC}
                    width={400}
                    height={205}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <Bar options={options} data={data} width={400} height={115} />
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <Card style={{ width: '25rem'}} className="centerCard">
          <CardBody>
            <CardTitle tag="h5">
              <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
            </CardTitle>
            <CardText>
              Vous n'êtes pas autoriser à utiliser cette section. Merci de
              contacter votre administrateur
            </CardText>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default Dashboard;
