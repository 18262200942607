import Clients from './views/prestations/clients/listeClients';
import Dashboard from './views/prestations/Dashboard';
import Facture from './views/prestations/factures/listeFacture';
import Receptions from './views/prestations/receptions/listeReception';
import Reglement from './views//prestations/reglements/listeReglement';
import Familles from './views/prestations/familles/listeFamilles';
import Marques from './views/prestations/marques/listeMarque';
import Entrepots from './views/prestations/entrepots/listeEntrepot';
import Agents from './views/prestations/agents/listeAgent';
import Vehicules from './views/prestations/vehicules/listeVehicule';
import Prestations from './views/prestations/prestations/listePrestation';
import TypeDepense from 'views/prestations/depenses/typedepense/typeDepense';
import Depenses from 'views/prestations/depenses/depense/depense';
import Caisses from 'views/prestations/depenses/caisse/caisse';
// import Agenda from 'views/prestations/agenda/agendas';

var routes = [
  {
    path: '/dashboard',
    name: 'Tableau de bord',
    title: 'Tableau de bord',
    icon: 'nc-icon nc-bank',
    component: Dashboard,
    layout: '/prestadmin',
    separe: true,
  },
  {
    path: '/clients',
    name: 'Mes Clients',
    title: 'Gestion des clients',
    icon: 'nc-icon nc-single-02',
    component: Clients,
    layout: '/prestadmin',
  },
  {
    path: '/agents',
    name: 'Mes Agents',
    title: 'Gestion des agents',
    icon: 'nc-icon nc-single-02',
    component: Agents,
    layout: '/prestadmin',
    separe: true,
  },
  {
    path: '/marques',
    name: 'Marques',
    title: 'Gestion des marques de voiture',
    icon: 'nc-icon nc-bookmark-2',
    component: Marques,
    layout: '/prestadmin',
  },
  {
    path: '/familles',
    name: 'Models',
    title: 'Gestion des models de voiture',
    icon: 'nc-icon nc-vector',
    component: Familles,
    layout: '/prestadmin',
  },
  {
    path: '/entrepots',
    name: 'Entrepots stockage',
    title: 'Gestion des entrepots de stockage',
    icon: 'nc-icon nc-app',
    component: Entrepots,
    layout: '/prestadmin',
  },
  {
    path: '/prestation',
    name: 'Prestations',
    title: 'Gestion des prestations',
    icon: 'nc-icon nc-single-copy-04',
    component: Prestations,
    layout: '/prestadmin',
  },
  {
    path: '/vehicules',
    name: 'Mes véhicules',
    title: 'Gestion des véhicules',
    icon: 'nc-icon nc-delivery-fast',
    component: Vehicules,
    layout: '/prestadmin',
  },
  {
    path: '/receptions',
    name: 'Mes receptions',
    title: 'Gestion des receptions de véhicules',
    icon: 'nc-icon nc-settings',
    component: Receptions,
    layout: '/prestadmin',
    separe: true,
  },
  {
    path: '/factures',
    name: 'Mes factures',
    title: 'Gestion des factures',
    icon: 'nc-icon nc-paper',
    component: Facture,
    layout: '/prestadmin',
  },
  {
    path: '/reglements',
    name: 'Mes règlements',
    title: 'Gestion des règlements',
    icon: 'nc-icon nc-money-coins',
    component: Reglement,
    layout: '/prestadmin',
    separe: true,
  },
  {
      path: '/typedepense',
      name: 'Types dépenses',
      title: 'Gestion des types de dépenses',
      icon: 'nc-icon nc-paper',
      component: TypeDepense,
      layout: '/prestadmin'
  },
  {
      path: '/depenses',
      name: 'Dépenses',
      title: 'Gestion des dépenses',
      icon: 'nc-icon nc-money-coins',
      component: Depenses,
      layout: '/prestadmin'
  },
  {
      path: '/caisses',
      name: 'Trésorerie',
      title: 'Gestion de la trésorerie',
      icon: 'nc-icon nc-money-coins',
      component: Caisses,
      layout: '/prestadmin'
  }
  // {
  //   path: '/agenda',
  //   name: 'Mon agenda',
  //   title: 'Gestion des rendez-vous',
  //   icon: 'nc-icon nc-watch-time',
  //   component: Agenda,
  //   layout: '/prestadmin',
  // },
];
export default routes;
