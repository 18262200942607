import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Spinner, Modal, Toast } from 'react-bootstrap';
import 'jspdf-autotable';
import commondata from '../../commonData';
import MyAwesomeTable from './gridtables';
import AddClient from './addClient';
import EditClient from './editClient';

function Clients() {
  const history = useHistory();
  const [datas, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgGravity, setMsgravity] = useState('success');
  const [showAddclient, setShowAddclient] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [ids, setId] = useState(0);

  const [isSave, setIsSave] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('user-info-g')) {
      (async function anyNameFunction() {
        await getClient();
      })();
    } else {
      history.push('/login');
    }
  }, [history]);

  async function getClient() {
    setLoad(true);
    Axios.get(commondata.ipapi + '/api/client/index')
      .then((response) => {
        setData(response.data.datas);
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }

  function setAddsClient(val) {
    const log = commondata.search(commondata.userdroit, "SAD");
    const log1 = commondata.search(commondata.userdroit, "ADC");
    if(log == undefined){
      if(log1 == undefined){
        setMsg("Vous n'avez pas le droit de supprimer cette information");
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
        return;
      }  
    }
    setShowAddclient(val);
  }

  function setIds(id) {
    const log = commondata.search(commondata.userdroit, "SAD");
    const log1 = commondata.search(commondata.userdroit, "MOC");
    if(log == undefined){
      if(log1 == undefined){
        setMsg("Vous n'avez pas le droit de supprimer cette information");
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
        return;
      }  
    }
    setId(id);
    setShowAddclient(true);
  }

  function onDelete(id) {
    if (window.confirm('Voulez-vous supprimer ce client ?')) {
      setLoad(true);
      Axios.delete(commondata.ipapi + '/api/client/destroy/' + id).then(
        (response) => {
          if (response.data.status == 1) {
            Axios.get(commondata.ipapi + '/api/client/index')
              .then((response) => {
                setData(response.data);
                setLoad(false);
              })
              .catch((errors) => {
                setLoad(false);
              });
          } else {
            setMsg(response.data.msg);
            setMsgravity('danger');
            setIsSave(true);
            setLoad(false);
          }
        }
      );
    }
  }

  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      <Toast
        id="toast"
        onClose={() => setIsSave(false)}
        show={isSave}
        delay={3000}
        className={'bg-' + msgGravity}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Info</strong>
        </Toast.Header>
        <Toast.Body className="dark">{msg}</Toast.Body>
      </Toast>
      {showAddclient ? (
        <AddClient
        id={ids}
          setShowAddclient={setShowAddclient}
          show={showAddclient}
          setIsSave={setIsSave}
          isSave={false}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          getClient={getClient}
          setLoad={setLoad}
        />
      ) : (
        <></>
      )}
      {showEdit ? (
        <EditClient
          id={ids}
          setShowEdit={setShowEdit}
          show={showEdit}
          setIsSave={setIsSave}
          isSave={false}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          getClient={getClient}
          setLoad={setLoad}
        />
      ) : (
        <></>
      )}

      <div className="content">
        <div id="tablecontent">
          <MyAwesomeTable
            rows={datas}
            isLoading={load}
            onDelete={onDelete}
            setIds={setIds}
            setAddsClient={setAddsClient}
          />
        </div>
      </div>
    </>
  );
}

export default Clients;
