import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Modal, Placeholder, Spinner, Toast } from 'react-bootstrap';
import Axios from 'axios';

import IconButtons from './actionButton';
import DataGrid from './dataGrid';
import AjouterDepense from './ajDepense';
import config from '../../../commonData';

const Depenses = () => {
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [load, setLoad] = useState(false);
    const [openSnak, setOpenSnak] = useState(false);
    const [depenses, setDepense] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [idModif, setIdModif] = React.useState(0);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('success');
    const [selected, setSelected] = React.useState({});
    const [gridRef, setGridRef] = useState(null);
    const [actualise, setActualise] = useState(false);

    const [state, setState] = React.useState({
        vertical: 'bottom',
        horizontal: 'right'
    });
    const { vertical, horizontal } = state;
    const handleClickOpen = (param, idModif) => {
        if (idModif) {
            const log = config.search(config.userdroit, 'SAD');
            const log1 = config.search(config.userdroit, 'DMO');
            if (log == undefined) {
                if (log1 == undefined) {
                    setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                    setSeverity('danger');
                    setOpenSnak(true);
                    return;
                }
            }
        } else {
            const log = config.search(config.userdroit, 'SAD');
            const log1 = config.search(config.userdroit, 'DAJ');
            if (log == undefined) {
                if (log1 == undefined) {
                    setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                    setSeverity('danger');
                    setOpenSnak(true);
                    return;
                }
            }
        }
        setLoad(true);
        Axios.get(config.ipapi + '/api/check-ouverture-caisse/2')
            .then((response) => {
                if (response.data == 0) {
                    setMsg('Veillez ouvrir la caisse');
                    setSeverity('danger');
                    setOpenSnak(true);
                    setLoad(false);
                    return;
                } else {
                    setLoad(false);
                    setIdModif(idModif);
                    setOpen(true);
                }
            })
            .catch((errors) => {
                setLoading(false);
            });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnak(false);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        setLoading(true);
        (async function anyNameFunction() {
            await Axios.get(config.ipapi + '/api/liste-depense/2')
                .then((response) => {
                    setDepense(response.data.datas);
                    setLoading(false);
                })
                .catch((errors) => {
                    setLoading(false);
                });
        })();
    }, [history, actualise]);

    return (
        <div className="content contents">
            <IconButtons
                selected={selected}
                handleClickOpen={handleClickOpen}
                setLoading={setLoading}
                setLoad={setLoad}
                load={load}
                setOpenSnak={setOpenSnak}
                setMsg={setMsg}
                setSeverity={setSeverity}
                gridRef={gridRef}
            />

            <Toast id="toast" onClose={() => handleClose(false)} show={openSnak} delay={3000} className={'bg-' + severity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>

            <Modal show={isLoading} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            {/* <Card sx={{ overflow: 'hidden' }}> */}
                {depenses ? (
                    <DataGrid
                        setSelected={setSelected}
                        selected={selected}
                        depenses={depenses}
                        isLoading={isLoading}
                        setLoad={setLoad}
                        load={load}
                        setMsg={setMsg}
                        setSeverity={setSeverity}
                        setOpenSnak={setOpenSnak}
                        setGridRef={setGridRef}
                    />
                ) : (
                    <></>
                )}
            {/* </Card> */}

            {open ? (
                <AjouterDepense
                    open={open}
                    handleCloseModal={handleCloseModal}
                    setOpenSnak={setOpenSnak}
                    setActualise={setActualise}
                    actualise={actualise}
                    idModif={idModif}
                    setMsg={setMsg}
                    setSeverity={setSeverity}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default Depenses;
