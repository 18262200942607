import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { Card, CardBody, CardTitle } from 'reactstrap';


export function ProductList({ ligne, onChangeMontant, onRemoveProduct }) {
  var totalFact=0;
  var totalImput=0;
  return (
    // <Card>
    //   <CardBody>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">N° facture</th>
                          <th scope="col">Montant</th>
                          <th scope="col">Montant imputé</th>
                          <th scope="col">Reste imputation</th>
                          <th scope="col">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                          {
                            ligne.map((product,index)=> {
                                totalFact += product.montant;
                                totalImput+=product.reste || 0;
                                  return(
                                      <tr key={index}  hover="true">
                                          <td>{product.ordre}</td>
                                          <td>{product.nofact}</td>
                                          <td>{product.montant}</td>
                                          <td>
                                            <input
                                            type="number"
                                            className="prix"
                                            step="any"
                                            value={product.reste}
                                            onChange={(event) => onChangeMontant(index, event)}
                                            />
                                          </td>
                                          <td>{product.montant-product.reste}</td>
                                          <td>
                                            <X color='red' size={20} onClick={() => onRemoveProduct(index,product.idregl)}></X></td>
                                          
                                      </tr>
                                  )
                              })
                          }
                       
                      
                      </tbody>   
                      <tfoot>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">{totalFact}</th>
                            <th scope="col">{totalImput}</th>
                            <th scope="col">{totalFact-totalImput}</th>
                            <th scope="col">#</th>
                        </tr>
                          </tfoot>
                    </table>
    //     </CardBody>
      
    // </Card>
  );
}

export function Summary({
  subTotal,montantFact
}) {
    var totalttc = 0;
    var mtimp = 0;
  subTotal.forEach((element) => {
    element.montantimput >= 0 ?  mtimp = element.montantimput : mtimp = 0
    totalttc+=mtimp
  });
  const total = totalttc;

  return (
    
    <Row>
    <Col lg="4" md="6" sm="6">
      <Card className="card-stats">
        <CardBody>
          <Row>
            <Col md="12" xs="7">
              <div className="numbers p">
                <p className="card-category">Montant versement</p>
                <CardTitle tag="p">{ formatCurrency(montantFact)}</CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
    <Col lg="4" md="6" sm="6">
      <Card className="card-stats">
        <CardBody>
          <Row>
            <Col md="12" xs="7">
              <div className="numbers p">
                <p className="card-category">Montant imputé</p>
                <CardTitle tag="p">{ formatCurrency(total)}</CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
    <Col lg="4" md="6" sm="6">
      <Card className="card-stats">
        <CardBody>
          <Row>
            <Col md="12" xs="7">
              <div className="numbers p">
                <p className="card-category">Reste à imputer</p>
                <CardTitle tag="p">{ formatCurrency(montantFact-total)}</CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
    // <div>
    //     <p id="lightr">Montant imputer: <b>{formatCurrency(total)}</b></p>
    //     <p id="lightr">Reste à imputer: <b>{formatCurrency(montantFact-total)}</b></p>
    // </div>
      
  );
}
  function formatCurrency(value) {
    return new Intl.NumberFormat().format(Math.round(value))
  }