import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import config from '../../../commonData';
import { Alert, Card, Col, Modal, OverlayTrigger, Row, Spinner, Toast, Tooltip } from 'react-bootstrap';
import { Printer, TriangleHalf } from 'react-bootstrap-icons';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import dateformat from 'dateformat';
import AddReglement from 'views/prestations/reglements/addReglement';
import IconButtons from './actionButton';
import DataGrid from './dataGrid';
import AjouterCaisse from './aCaisse';
import AjouterDepense from '../depense/ajDepense';
import DataGridCaisse from './dataGridCaisse';

const Caisses = () => {
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [load, setLoad] = useState(false);
    const [openSnak, setOpenSnak] = useState(false);
    const [operation, setOperation] = useState([]);
    const [caisse, setCaisse] = useState([]);
    const [soldeinit, setSolde] = useState(0);
    const [recette, setRecette] = React.useState(0);
    const [depense, setDepense] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [openDep, setOpenDep] = React.useState(false);
    const [idModif, setIdModif] = React.useState(0);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('success');
    const [selected, setSelected] = React.useState({});
    const [gridRef, setGridRef] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [actualise, setActualise] = useState(false);

    const log = config.search(config.userdroit, 'SAD');
    const log1 = config.search(config.userdroit, 'GCA');
    var now = new Date();
    var mois = now.getMonth() + 1;

    const handleClickOpen = (idModif) => {
        if (idModif) {
            const log = config.search(config.userdroit, 'SAD');
            const log1 = config.search(config.userdroit, 'MCA');
            if (log == undefined) {
                if (log1 == undefined) {
                    setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                    setSeverity('danger');
                    setOpenSnak(true);
                    return;
                }
            }
            setIdModif(idModif);
            setOpen(true);
        } else {
            const log = config.search(config.userdroit, 'SAD');
            const log1 = config.search(config.userdroit, 'ACA');
            if (log == undefined) {
                if (log1 == undefined) {
                    setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                    setSeverity('danger');
                    setOpenSnak(true);
                    return;
                }
            }
            Axios.get(config.ipapi + '/api/check-unlocked/2')
                .then((response) => {
                    if (response.data > 0) {
                        setMsg('Veillez fermer la caisse ouverte');
                        setSeverity('danger');
                        setOpenSnak(true);
                        return;
                    } else {
                        setIdModif(idModif);
                        setOpen(true);
                    }
                })
                .catch((errors) => {
                    setLoading(false);
                });
        }
    };

    const handleClickOpenDep = () => {
        const log = config.search(config.userdroit, 'SAD');
        const log1 = config.search(config.userdroit, 'DAJ');
        if (log == undefined) {
            if (log1 == undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setSeverity('danger');
                setOpenSnak(true);
                return;
            }
        }
        setLoad(true);
        Axios.get(config.ipapi + '/api/check-ouverture-caisse/2')
            .then((response) => {
                if (response.data == 0) {
                    setMsg('Veillez ouvrir la caisse');
                    setSeverity('danger');
                    setOpenSnak(true);
                    setLoad(false);
                    return;
                } else {
                    setLoad(false);
                    setOpenDep(true);
                }
            })
            .catch((errors) => {
                setLoad(false);
            });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnak(false);
    };
    const handleCloseModal = () => {
        setOpen(false);
        setOpenDep(false);
    };

    function setAdds(val) {
        setLoad(true);
        Axios.get(config.ipapi + '/api/check-ouverture-caisse/2')
            .then((response) => {
                if (response.data == 0) {
                    setMsg('Veillez ouvrir la caisse');
                    setSeverity('danger');
                    setOpenSnak(true);
                    setLoad(false);
                    return;
                } else {
                    setLoad(false);
                    setShowAdd(true);
                }
            })
            .catch((errors) => {
                setLoading(false);
            });
    }

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }
    useEffect(() => {
        setLoading(true);

        (async function anyNameFunction() {
            await Axios.get(config.ipapi + '/api/liste-caisse/2')
                .then((response) => {
                    setCaisse(response.data.datas[4]);
                    setSolde(response.data.datas[0].length ? response.data.datas[0][0].solde_init : 0);
                    setOperation(response.data.datas[1].sort(GetSortOrder('created_at')));
                    setRecette(response.data.datas[2]);
                    setDepense(response.data.datas[3]);
                    setLoading(false);
                })
                .catch((errors) => {
                    setLoading(false);
                });
        })();
    }, [history, actualise]);

    async function handlePrintListe() {
        var datas = [];
        var total = 0;
        var totaldep = 0;
        setLoading(true);
        var doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth();

        gridRef.current.data.forEach((data, index) => {
            total += data.montantreg;
            totaldep += data.montantop;
            datas.push([
                { content: index + 1 },
                { content: data.type },
                { content: dateformat(data.dateop, 'dd-mm-yyyy') },
                { content: new Intl.NumberFormat('de-DE').format(data.montantreg) },
                { content: new Intl.NumberFormat('de-DE').format(data.montantop) }
            ]);
        });
        datas.push([
            { content: 'TOTAL', colSpan: 3,styles: { fontStyle: 'bold', fontSize: 12 } },
            { content: new Intl.NumberFormat('de-DE').format(total), styles: { fontStyle: 'bold', fontSize: 12 } },
            { content: new Intl.NumberFormat('de-DE').format(totaldep), styles: { fontStyle: 'bold', fontSize: 12 } }
        ]);

        // doc.addImage(config.logo, 'jpg', 14, 10, 40, 13);
        config.ipapi == 'https://192.168.8.109:81' || config.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.addImage(config.logo, 'jpg', 14, 5, 30, 30)
            : doc.addImage(config.logo1, 'jpg', 14, 5, 30, 30);
        doc.setFontSize(16);
        // doc.text('FOOTPRINT', 90, 17);
        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(65, 13, width - 80, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text('JOURNAL DE LA CAISSE', 90, 20);

        if (datas.length > 0) {
            doc.autoTable({
                styles: {
                    fillColor: [0, 0, 0],
                    textColor: [255, 255, 255],
                    cellPadding: 1.5,
                    halign: 'center'
                },
                columnStyles: {
                    0: {
                        halign: 'center',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    },
                    1: {
                        halign: 'left',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    },
                    2: {
                        halign: 'center',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    },
                    3: {
                        halign: 'center',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    },
                    4: {
                        halign: 'left',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    }
                },
                theme: 'grid',
                startY: 38,
                margin: { bottom: 15 },
                horizontalPageBreak: true,
                head: [['Ordre', 'Type', 'Date opération', 'reglements', 'Dépenses']],
                body: datas
            });
        }
        var now = new Date();
        var mois = now.getMonth() + 1;
        doc.text('Edité par ' + config.user + ' le ' + now.getDate() + '/' + mois + '/' + now.getFullYear(), 14, 292, null, null, 'left');
        const pageCount = doc.internal.getNumberOfPages();
        for (var u = 1; u <= pageCount; u++) {
            doc.setPage(u);
            doc.setFontSize(10);
            doc.text('Page ' + String(u) + ' sur ' + String(pageCount), 210 - 18, 292, null, null, 'right');
        }
        setLoading(false);
        doc.save('journal-caisse-' + now.getDate() + '/' + mois + '/' + now.getFullYear() + '.pdf');
    }

    return (
        <>
            {log != undefined || log1 != undefined ? (
                <div className="content contents">
                    {showAdd !== false ? (
                        <AddReglement
                            setShowAdd={setShowAdd}
                            show={showAdd}
                            setIsSave={setOpenSnak}
                            isSave={false}
                            // setDatas={setDatas}
                            setMsg={setMsg}
                            setMsgravity={setOpenSnak}
                            setActualise={setActualise}
                            actualise={actualise}
                        />
                    ) : (
                        <></>
                    )}
                    {openDep ? (
                        <AjouterDepense
                            open={openDep}
                            handleCloseModal={handleCloseModal}
                            setOpenSnak={setOpenSnak}
                            setLoad={setLoad}
                            load={load}
                            idModif={0}
                            setMsg={setMsg}
                            setSeverity={setSeverity}
                            setActualise={setActualise}
                            actualise={actualise}
                        />
                    ) : (
                        <></>
                    )}
                    {open ? (
                        <AjouterCaisse
                            open={open}
                            handleCloseModal={handleCloseModal}
                            setOpenSnak={setOpenSnak}
                            setLoad={setLoad}
                            load={load}
                            idModif={idModif}
                            setMsg={setMsg}
                            setSeverity={setSeverity}
                            setActualise={setActualise}
                            actualise={actualise}
                        />
                    ) : (
                        <></>
                    )}
                    <IconButtons
                        selected={selected}
                        handleClickOpen={handleClickOpen}
                        setLoading={setLoading}
                        setLoad={setLoad}
                        load={load}
                        setOpenSnak={setOpenSnak}
                        setMsg={setMsg}
                        setSeverity={setSeverity}
                        gridRef={gridRef}
                        setAdds={setAdds}
                        handleClickOpenDep={handleClickOpenDep}
                    />

                    <Toast id="toast" onClose={() => handleClose(false)} show={openSnak} delay={3000} className={'bg-' + severity} autohide>
                        <Toast.Header>
                            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                            <strong className="me-auto">Info</strong>
                        </Toast.Header>
                        <Toast.Body className="dark">{msg}</Toast.Body>
                    </Toast>

                    <Modal show={isLoading} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body>
                            Veuillez patienter.......
                            <Spinner animation="border" variant="success" />
                        </Modal.Body>
                    </Modal>
                    {operation ? (
                        <Row>
                            <Col sm="6">
                                <br></br>
                                <Card sx={{ overflow: 'hidden' }} bg="dark" text="light">
                                    <Card.Body>
                                        <Alert
                                            key="info"
                                            variant="light"
                                            style={{ color: '#000', textAlign: 'center', fontWeight: 'bold' }}
                                        >
                                            RECAPITULATIF GENERAL
                                        </Alert>
                                        <Row>
                                            <Col lg="12" md="12" sm="12">
                                                <Card className="card-stats" bg="dark" text="light">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col md="3" xs="5">
                                                                <div className="icon-big text-center icon-warning">
                                                                    <i className="nc-icon nc-money-coins text-warning" />
                                                                </div>
                                                            </Col>
                                                            <Col md="9" xs="7">
                                                                <div className="numbers">
                                                                    <p className="card-category">MONTANT DE LA CAISSE</p>
                                                                    {now.getDate() + '/' + mois + '/' + now.getFullYear()}
                                                                    <Card.Title tag="p" style={{ color: 'yellow' }}>
                                                                        {new Intl.NumberFormat('de-DE').format(
                                                                            recette + soldeinit - depense
                                                                        ) + ' '}
                                                                        FCFA
                                                                    </Card.Title>
                                                                    <p />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col lg="4" md="6" sm="6">
                                                <Card className="card-stats">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col md="12" xs="12">
                                                                <div className="numbers">
                                                                    <p className="card-category">SOLDE INITIAL</p>
                                                                    {new Intl.NumberFormat('de-DE').format(soldeinit)}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col lg="4" md="6" sm="6">
                                                <Card className="card-stats">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col md="12" xs="12">
                                                                <div className="numbers">
                                                                    <p className="card-category">TOTAL RECETTES</p>
                                                                    {new Intl.NumberFormat('de-DE').format(recette)}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col lg="4" md="6" sm="6">
                                                <Card className="card-stats">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col md="12" xs="12">
                                                                <div className="numbers">
                                                                    <p className="card-category">TOTAL DEPENSES</p>
                                                                    {new Intl.NumberFormat('de-DE').format(depense)}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col lg="12" md="12" sm="12">
                                                <Alert
                                                    key="info"
                                                    variant="light"
                                                    style={{ color: '#000', textAlign: 'center', fontWeight: 'bold' }}
                                                >
                                                    HISTORIQUE DE CAISSE
                                                </Alert>
                                                <DataGridCaisse
                                                    setSelected={setSelected}
                                                    selected={selected}
                                                    operation={caisse}
                                                    isLoading={isLoading}
                                                    setLoad={setLoad}
                                                    load={load}
                                                    setMsg={setMsg}
                                                    setSeverity={setSeverity}
                                                    setOpenSnak={setOpenSnak}
                                                    setGridRef={setGridRef}
                                                    handleClickOpen={handleClickOpen}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <br></br>

                                <Card sx={{ overflow: 'hidden' }} bg="dark" text="dark">
                                    <Card.Body>
                                        <Alert
                                            key="info"
                                            variant="light"
                                            style={{ color: '#000', textAlign: 'center', fontWeight: 'bold' }}
                                        >
                                            JOURNAL MOUVEMENT DE CAISSE{' '}
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-disabled">Imprimer le journal de la caisse</Tooltip>}
                                            >
                                                <Printer color="#0080ff" size={25} onClick={() => handlePrintListe()} />
                                            </OverlayTrigger>
                                        </Alert>
                                        <DataGrid
                                            setSelected={setSelected}
                                            selected={selected}
                                            operation={operation}
                                            isLoading={isLoading}
                                            setLoad={setLoad}
                                            load={load}
                                            setMsg={setMsg}
                                            setSeverity={setSeverity}
                                            setOpenSnak={setOpenSnak}
                                            setGridRef={setGridRef}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <Card.Body>
                        <Card.Title tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </Card.Title>
                        <Card.Text>Vous n'êtes pas autoriser à utiliser cette section. Merci de contacter votre administrateur</Card.Text>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

export default Caisses;
