import React, { useState } from 'react';
import {
  ButtonGroup,
  Container,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  Trash,
  PencilFill,
  FileExcel,
  PlusCircleDotted,
  Printer,
} from 'react-bootstrap-icons';
import Axios from 'axios';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

import XLSX from 'sheetjs-style';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import 'assets/css/tableGar.css';
import dateformat from 'dateformat';
import common from '../../commonData';
import NumberToLetter from '../../converter';

function MyAwesomeTable({ rows, isLoading, onDelete, setIds, title, setAdds }) {
  const [gridRef, setGridRef] = useState(null);

  const filterValue = [
    { name: 'raisonsocial', operator: 'contains', type: 'string', value: '' },
    { name: 'norecu', operator: 'contains', type: 'string', value: '' },
    { name: 'nofact', operator: 'contains', type: 'string', value: '' },
    { name: 'nocheq', operator: 'contains', type: 'string', value: '' },
    // { name: 'bp', operator: 'contains', type: 'string', value: '' },
  ];

  const Clt = ({ data }) => {
    return data.proforma[0] ? data.proforma[0].client.raisonsocial : 'néant';
  };
  const User = ({ data }) => {
    return data.user ? data.user.name : '';
  };
  const Datef = ({ data }) => {
    return data.proforma[0]
      ? dateformat(data.proforma[0].datefact, 'dd-mm-yyyy')
      : 'néant';
  };
  const Nofacts = ({ data }) => {
    var nofact='';
    data.proforma.forEach(element => {
      nofact+=element.nofact+',';
    });
    return nofact.slice(0,nofact.length-1);
  };
 
  const DateReg = ({ data }) => {
    return dateformat(data.date_regl, 'dd-mm-yyyy');
  };

  const Immtrs = ({ data }) => {
    return data.proforma[0] ? data.proforma[0].immatr_eng : 'néant';
  };

  const Chassis = ({ data }) => {
    return data.proforma[0] ? data.proforma[0].chassis : 'néant';
  };

  const Prixttc = ({ data }) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'CFA',
    }).format(data.montant);
  };
  const columns = [
    {
      name: 'id',
      header: 'Id',
      defaultVisible: false,
      defaultWidth: 80,
      type: 'number',
    },
    {
      header: 'Action',
      defaultWidth: 120,
      type: 'string',
      editable: false,
      render: ({ value, data }) => (
        <div className="menudrops">
          <ButtonGroup aria-label="Basic example">
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Imprimer</Tooltip>}
            >
              <Printer
                id="actionbtn"
                onClick={() => printFacture(data.id)}
                size={25}
                color="black"
              />
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Modifier la ligne</Tooltip>
              }
            >
              <PencilFill
                id="actionbtn"
                onClick={() => setIds(data.id)}
                size={25}
                color="green"
              />
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Supprimer la ligne</Tooltip>
              }
            >
              <Trash
                id="actionbtn"
                onClick={() => onDelete(data.id)}
                color="red"
                size={25}
              />
            </OverlayTrigger>
          </ButtonGroup>
        </div>
      ),
    },
    {
      name: 'norecu',
      header: 'N° reçu',
      defaultWidth: 110,
      type: 'string',
      textAlign: 'center',
    },
    {
      name: 'raisonsocial',
      header: 'Dénommination',
      defaultWidth: 200,
      type: 'string',
      render: ({ data }) => <Clt data={data} />,
    },
    {
      name: 'prix_ttc',
      header: 'Montant regl.',
      defaultWidth: 110,
      textAlign: 'start',
      type: 'string',
      render: ({ data }) => <Prixttc data={data} />,
    },
    {
      name: 'dateregl',
      header: 'Date regl.',
      defaultWidth: 90,
      textAlign: 'center',
      type: 'date',
      render: ({ data }) => <DateReg data={data} />,
    },
    {
      name: 'nofact',
      header: 'N° facture',
      defaultWidth: 120,
      type: 'string',
      render: ({ data }) => <Nofacts data={data} />,
    },
    {
      name: 'datefact',
      header: 'Date fact.',
      defaultWidth: 120,
      type: 'string',
      render: ({ data }) => <Datef data={data} />,
    },
    {
      name: 'nocheq',
      header: 'N° chèque',
      defaultWidth: 120,
      textAlign: 'start',
      type: 'string',
    },

    {
      name: 'immatr',
      header: 'Immatriculation',
      textAlign: 'center',
      defaultWidth: 100,
      type: 'string',
      render: ({ data }) => <Immtrs data={data} />,
    },
    {
      name: 'chassis',
      header: 'Chassis',
      textAlign: 'center',
      defaultWidth: 130,
      type: 'string',
      render: ({ data }) => <Chassis data={data} />,
    },
    {
      name: 'username',
      header: 'Crée par',
      defaultWidth: 120,
      type: 'string',
      render: ({ data }) => <User data={data} />,
    },
    {
      name: 'created_at',
      header: 'Crée le',
      defaultWidth: 130,
      type: 'string',
      render: ({ data }) =>
        data.created_at
          ? dateformat(data.created_at, 'dd-mm-yyyy HH:MM')
          : 'néant',
    },
  ];
  const gridStyle = { minHeight: window.screen.height - 200 };

  function printFacture(id) {
    const data = [];
    let text =
      'Voulez-vous imprimer avec ou sans entête ? \n Ok si vous voulez avec entête \n Annuler si vous ne voulez pas avec entête';
    let res = window.confirm(text);
    var doc = new jsPDF();
    var width = doc.internal.pageSize.getWidth();

    if (res === true) {
      Axios.get(common.ipapi + '/api/reglementprest/edit/' + id)
        .then((response) => {
          doc.setFillColor(255, 127, 0);
          common.ipapi === 'https://192.168.8.109:81' ||
          common.ipapi === 'https://api.tracky-entrepot.com'
            ? doc.addImage(common.logo, 'jpg', 14, 10, 30, 30)
            : doc.addImage(common.logo1, 'jpg', 14, 10, 30, 30);

          doc.setFillColor(0, 0, 0);
          doc.rect(65, 19, width - 80, 10, 'F');
          doc.setFontSize(16);
          common.ipapi === 'https://192.168.8.109:81' ||
          common.ipapi === 'https://api.tracky-entrepot.com'
            ? doc.text('CLUB AUTO ENTREPOT', 90, 17)
            : doc.text('HR MOTORS', 90, 17);
          doc.setFontSize(12);
          doc.setTextColor(255, 255, 255);
          doc.text('RECU DE REGLEMENT CLIENT', 90, 26);
          doc.setFontSize(10);
          doc.setTextColor(0, 0, 0);
          doc.setDrawColor(0);
          doc.rect(110, 40, 86, 21);
          doc.setTextColor(0, 0, 0);
          doc.text(
            response.data.proforma[0].client
              ? 'Non: ' + response.data.proforma[0].client.raisonsocial
              : 'Nom: Néant',
            112,
            45
          );
          doc.text(
            response.data.proforma[0].client
              ? 'Télephone: ' + response.data.proforma[0].client.telcli
              : 'Télephone: Néant',
            112,
            50
          );
          response.data.proforma[0].client
            ? doc.text(
                'Type client: ' + response.data.proforma[0].client.sisociete,
                112,
                55
              )
            : doc.text('Type client: Néant', 112, 55);

          doc.text('N° Reçu: ' + response.data.norecu, 15, 45);
          doc.text(
            'Date règlement: ' +
              dateformat(response.data.date_regl, 'dd/mm/yyyy'),
            15,
            50
          );
          doc.text(
            'Montant versement: ' +
            new Intl.NumberFormat('de-DE').format(response.data.montant),
            15,
            55
          );
        
          response.data.proforma.forEach((row, index) => {
            data.push([
                row.nofact,
                dateformat(row.datefact, 'dd/mm/yyyy'),
                new Intl.NumberFormat('de-DE').format(row.prix_ttc),
                new Intl.NumberFormat('de-DE').format(row.pivot.montant)
            ]);
        });
        doc.autoTable({
          styles: {
              fillColor: [206, 206, 206],
              textColor: [4, 4, 4],
              halign: 'center',
              fontSize: 10,
              cellPadding: 0.75
              // lineColor: [0, 0, 0]
          },
          columnStyles: {
              0: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  cellWidth: 20,
                  fontSize: 10
              },
              1: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 10
              },
              2: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 10
              },
              3: {
                  halign: 'center',
                  fillColor: [255, 255, 255],
                  textColor: [0, 0, 0],
                  fontSize: 10
              }
          },
          theme: 'grid',
          startY: 95,
          fontSize: 6,
          head: [['N° facture', 'Date factrure', 'Montant facture', 'Montant payé']],
          body: data
      });

        doc.text('Arrêté à la somme de', 15, 228).setFont(undefined, 'bold');
        doc.setFontSize(8);
        doc.text(
          NumberToLetter(response.data.montant).toUpperCase() + ' FCFA',
          15,
          234
        );
        doc.rect(14, 230, width - 28, 8);
        doc.setFontSize(10);
        doc.text('Visa direction', 15, 245);
        
          doc.setFontSize(8);

          doc.setLineWidth(1.5);
          doc.setFontSize(8);
          doc.setLineWidth(1.5);
          doc.setDrawColor(0, 0, 0);
          doc.line(0, 270, width, 270);
          doc.text(
            'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
            105,
            275,
            null,
            'center'
          );
          doc.text(
            'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 07 08 29 40 40 /  Email: vicaso11@hotmail.fr ',
            105,
            280,
            null,
            'center'
          );

          doc.save('reglement-' + response.data.norecu + '.pdf');
        })
        .catch((errors) => {});
    } else {
      Axios.get(common.ipapi + '/api/reglementprest/edit/' + id)
        .then((response) => {
          doc.setFontSize(10);
          doc.setTextColor(0, 0, 0);
          doc.setDrawColor(0);
          doc.text(
            119,
            53,
            'Abidjan le: ' + dateformat(new Date(), 'dd/mm/yyyy')
          );
          doc.rect(115, 58, 81, 24);
          doc.text(
            response.data.proforma[0].client
              ? 'Non: ' + response.data.proforma[0].client.raisonsocial
              : 'Nom: Néant',
            119,
            63
          );
          doc.text(
            response.data.proforma[0].client
              ? 'Télephone: ' + response.data.proforma[0].client.telcli
              : 'Télephone: Néant',
            119,
            68
          );
          response.data.proforma[0].client
            ? doc.text(
                'Type client: ' + response.data.proforma[0].client.sisociete,
                119,
                73
              )
            : doc.text('Type client: Néant', 119, 73);

          doc.text('N° Reçu: ' + response.data.norecu, 15, 63);
          doc.text(
            'Date règlement: ' +
              dateformat(response.data.date_regl, 'dd/mm/yyyy'),
            15,
            68
          );
          doc.text(
            'Montant versement: ' +
            new Intl.NumberFormat('de-DE').format(response.data.montant),
            15,
            55
          );
          response.data.proforma.forEach((row, index) => {
              data.push([
                  row.nofact,
                  dateformat(row.datefact, 'dd/mm/yyyy'),
                  new Intl.NumberFormat('de-DE').format(row.prix_ttc),
                  new Intl.NumberFormat('de-DE').format(row.pivot.montant)
              ]);
          });
          doc.autoTable({
            styles: {
                fillColor: [206, 206, 206],
                textColor: [4, 4, 4],
                halign: 'center',
                fontSize: 10,
                cellPadding: 0.75
                // lineColor: [0, 0, 0]
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellWidth: 20,
                    fontSize: 10
                },
                1: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 10
                },
                2: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 10
                },
                3: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 10
                }
            },
            theme: 'grid',
            startY: 95,
            fontSize: 6,
            head: [['N° facture', 'Date factrure', 'Montant facture', 'Montant payé']],
            body: data
        });

          doc.text('Arrêté à la somme de', 15, 228).setFont(undefined, 'bold');
          doc.setFontSize(8);
          doc.text(
            NumberToLetter(response.data.montant).toUpperCase() + ' FCFA',
            15,
            234
          );
          doc.rect(14, 230, width - 28, 8);
          doc.setFontSize(10);
          doc.text('Visa direction', 15, 245);
          doc.setFontSize(8);
          doc.save('reglement-' + response.data.norecu + '.pdf');
        })
        .catch((errors) => {});
    }
  }

  async function printListe() {
    if (gridRef.current.data.length <= 0) {
      return;
    }
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    });
    var width = doc.internal.pageSize.getWidth();
    const data = [];
    let mont = 0;
    let qte = 0;
    doc.addImage(common.logo, 'jpg', 14, 10, 30, 30);
    doc.setFontSize(16);
    doc.text('HR MOTORS GARAGE', 90, 14);
    doc.setFontSize(12);
    doc.setFillColor(0, 0, 0);
    doc.rect(65, 15, width - 80, 10, 'F');
    doc.setTextColor(255, 255, 255);
    doc.text('LISTE DES CLIENTS', 90, 22);
    doc.setTextColor(0, 0, 0);

    gridRef.current.data.forEach((row) => {
      data.push([
        row.codecli,
        row.raisonsocial,
        row.telcli,
        row.sisociete,
        row.bp,
        row.sitva,
        row.remise ? row.remise.pourcentage : 0,
        row.typereglement,
      ]);
    });
    doc.autoTable({
      styles: {
        fillColor: [0, 0, 0],
        textColor: [255, 255, 255],
        halign: 'center',
        fontSize: 10,
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        1: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        2: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        3: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        4: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        5: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        6: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        7: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
      },
      theme: 'grid',
      startY: 35,
      fontSize: 6,
      head: [
        [
          'Code',
          'Noms',
          'Contacts',
          'Type',
          'Boite postal',
          'Si exoneré',
          'Remise %',
          'Type règlement',
        ],
      ],
      body: data,
    });
    const pageCount = doc.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        'Page ' + String(i) + ' sur ' + String(pageCount),
        210 - 55,
        200,
        null,
        null,
        'right'
      );
    }
    doc.save('Listeclient.pdf');
  }

  //--------------- EXPORT VERS EXCEL ------------------

  const exportToExcel = async () => {
    const raw_data = gridRef.current.data;
    /* flatten objects */
    const rows = raw_data.map((row, index) => ({
      code: row.codecli,
      client: row.raisonsocial,
      telcli: row.telcli,
      sisociete: row.sisociete,
      bp: row.bp,
      sitva: row.sitva,
      remise: row.remise ? row.remise.pourcentage : 0,
      typereglement: row.typereglement,
    }));

    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes clients');
    /* fix headers */
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          'Code',
          'Noms',
          'Contacts',
          'Type',
          'Boite postal',
          'Si exoneré',
          'Remise %',
          'Type règlement',
        ],
      ],
      { origin: 'A1' }
    );
    /* calculate column width */
    const max_width = rows.reduce((w, r) => Math.max(w, r.code.length), 10);
    worksheet['!cols'] = [{ wch: max_width }];
    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, 'clients.xlsx');
  };

  async function saveAsExcel() {
    exportToExcel();
  }

  return (
    <div>
      <Navbar expand="lg" bg="light" variant="light">
        <Container>
          <Nav className="me-auto">
            <Nav.Link
              href="#/prestadmin/reglements"
              onClick={() => setAdds(true, 0)}
              title="Faire un règlement"
            >
              <PlusCircleDotted color="#ee1111" size={25} /> Créer
            </Nav.Link>
            <Nav.Link
              href="#/prestadmin/reglements"
              title="Exporter vers excel"
            >
              <FileExcel color="#0080ff" size={25} /> Excel
            </Nav.Link>
            <Nav.Link
              href="#/prestadmin/reglements"
              onClick={() => printListe()}
              title="Exporter vers pdf"
            >
              <Printer color="#0080ff" size={25} /> Imprimer
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <ReactDataGrid
        idProperty="id"
        handle={setGridRef}
        style={gridStyle}
        loading={isLoading}
        reorderColumns={false}
        pagination
        pageSizes={[200, 400, 600, 800, rows.length]}
        defaultLimit={400}
        showHoverRows={true}
        editable={true}
        showColumnMenuTool={false}
        defaultFilterValue={filterValue}
        columns={columns}
        dataSource={rows}
      />
    </div>
  );
}

export default MyAwesomeTable;
