import React, { useState,useEffect } from 'react';
import { Form, ButtonGroup, Spinner, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import Axios from 'axios';
import { BackspaceFill, Images, Save2Fill, Trash } from 'react-bootstrap-icons';
import * as Yup from 'yup';
import { Formik } from 'formik';

import common from '../../commonData';
var count = 0;

function AddMarque({ id, setShowAddFamille, show, setIsSave, setMsg, setMsgravity, setActualise, libMarq, actualise }) {
    const [imgs, setImage] = useState('');
    const [load, setLoad] = useState(false);
    const [file, setFile] = useState('');
    const [imgsTf, setImageTf] = useState('');
    const history = useHistory();

    useEffect(() => {
      if (!localStorage.getItem('user-info-g')) {
        history.push('/login');
      }
    }, [history]);
    
    function saveMarque(values) {
        if (window.confirm('Voulez-vous confirmer cette action') === false) {
            return;
        }
        setLoad(true);
        var formData = new FormData();
        formData.append('libellemarq', values.libellemarq);
        formData.append('user_id', common.userid);
        formData.append('photo', imgsTf);
        Axios({
            url: id ? common.ipapi + '/api/marque/update/' + id : common.ipapi + '/api/marque/store',
            method: 'POST',
            headers: {
                'Content-Type': 'form-data'
            },
            data: formData
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('info');
                    setIsSave(false);
                    return;
                }
                count++;
                setMsg(res.data.msg);
                setMsgravity('info');
                setIsSave(true);
                setLoad(false);
            })
            .catch((error) => {
                setMsg(error);
                setMsgravity('danger');
                setIsSave(false);
                setLoad(false);
            });
    }

    const handleClose = () => {
        if (count !== 0) {
            setActualise(!actualise);
        }
        setLoad(false);
        setShowAddFamille(false);
        setIsSave(false);
    };

    function onChangeImage(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
            return;
        }
        setFile(e.target.value);
        setImage(URL.createObjectURL(e.target.files[0]));
        createImage(files[0]);
    }
    function createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            setImageTf(e.target.result);
        };
        reader.readAsDataURL(file);
    }

    function onDeleteImg() {
        setImage('');
    }
    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="grow" variant="warning" />
                </Modal.Body>
            </Modal>

            <Formik
                initialValues={{
                    libellemarq: libMarq
                }}
                // enableReinitialize
                validationSchema={Yup.object().shape({
                    libellemarq: Yup.string().required('Veuillez saisir cette donnée')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        saveMarque(values);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={show} size="md">
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header>
                                {id ? 'MODIFIER UNE MARQUE' : 'AJOUTER UN NOUVEAU MARQUE'}
                                <BackspaceFill onClick={handleClose} color="red" size={30} />
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="mb-12">
                                    <Col md={12}>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={4}>
                                                Libellé marque:
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control
                                                    id="libellemarq"
                                                    type="text"
                                                    placeholder="Exp: BMW,CHANGAN..."
                                                    aria-describedby="inputGroupPrepend"
                                                    size="sm"
                                                    value={values.libellemarq}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errors.libellemarq && <div id="feedback">{errors.libellemarq}</div>}
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Label className="custom-file-upload" title="Ajouter des images.">
                                            {/* Ajouter une image */}
                                            <input type="file" accept=".jpg, .png, .jpeg" value={file} onChange={(e) => onChangeImage(e)} />
                                            <Images color="orange" size={50} />
                                        </Label>
                                        {imgs ? (
                                            <Col md="12">
                                                <Trash onClick={() => onDeleteImg()} color="red" size={15} />
                                                <img alt="index" className="imgartUpload rounded" src={imgs}></img>
                                            </Col>
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ButtonGroup size="sm">
                                    <Button color="secondary" onClick={handleClose}>
                                        <BackspaceFill color="red" size={20} />
                                    </Button>
                                    <Button type="submit" color="default">
                                        <Save2Fill color="#807f7e" size={20} /> Enregister
                                    </Button>
                                </ButtonGroup>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default AddMarque;
