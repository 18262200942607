import React, { useEffect, useRef, useState } from 'react';
import {
  Form,
  InputGroup,
  Spinner,
  ButtonGroup,
  Modal,
  ToggleButton,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  Card,
  Button,
  Input,
  Row,
  Col,
  Label,
  CardHeader,
  CardBody,
  CardSubtitle,
} from 'reactstrap';
import Axios from 'axios';
import {
  BackspaceFill,
  PlusCircle,
  Save2Fill,
  Star,
  Trash,
} from 'react-bootstrap-icons';
import common from '../../commonData';
import dateformat from 'dateformat';
import { Typeahead } from 'react-bootstrap-typeahead';
import AddVehicule from '../vehicules/addLight';
import AddClient from '../clients/addClient';
import Table from 'views/tableaddedit';
const initialState = {
  carte_grise: '',
  visite_technique: '',
  assurance: '',
  patente: '',
  cric: '',
  manivelle: '',
  cd: '',
  p_audio: '',
  radio: '',
  extincteur: '',
  cle_rou: '',
  enjoliveurs: '',
  roue_secours: '',
  tapis_sol: '',
  axe_remorq: '',
  carte_transport: '',
  carte_station: '',
  vignette: '',
};
var count = 0;
function EditReceptions({
  id,
  setShowEdit,
  show,
  setAdds,
  setShowAddVehicule,
  showAddVehicule,
  setAddVehicule,
  setIsSave,
  setMsg,
  setMsgravity,
  setEnRepa,
  enreparat,
  setTotal,
  total,
  getReception,
}) {
  var ladate = new Date();
  const [validated, setValidated] = useState(false);
  const [client_id, setClientid] = useState(0);
  const [client, setDataClient] = useState([]);
  const [agent_id, setAgentId] = useState(0);
  const [agent, setAgent] = useState([]);
  const [client2, setClient2] = useState('');
  const [client2tel, setClient2Tel] = useState('');
  const [famille, setFamille] = useState('');
  const [marque, setMarque] = useState('');
  const [vehicule_id, setVehiculeid] = useState(0);
  const [vehicule, setVehiculeData] = useState([]);
  const [chassis, setChassis] = useState(0);
  const [datecircul, setDateCirc] = useState('');
  const [datesent, setDateEnt] = useState(
    dateformat(
      ladate.getMonth() +
        1 +
        '/' +
        ladate.getDate() +
        '/' +
        ladate.getFullYear(),
      'yyyy-mm-dd'
    )
  );
  const [poinchoc, setPointChoc] = useState('');
  const [indexc, setIndex] = useState(0);
  const [sirevision, setRevision] = useState(0);
  const [typeEnerg, setTypeEnerg] = useState(0);
  const [entrepot_id, setEntrepotId] = useState(0);
  const [sipayent, setSipayant] = useState(0);
  const [motifgratuit, setMotif] = useState('');
  const [objet_visite, setObjet] = useState('');
  const [carburant, setCarburant] = useState('1/2');
  const [nofiche, setNofiche] = useState(0);
  const [entrepot, setEntrepot] = useState([]);
  const [motifretard, setMotifRetard] = useState([]);
  const [intervenant, setIntervenant] = useState([]);
  const [sireprisetravaux, setSireprisetravaux] = useState(false);
  // const [statut, setStatut] = useState(0);
  const [
    {
      carte_grise,
      visite_technique,
      assurance,
      patente,
      cric,
      manivelle,
      cd,
      p_audio,
      radio,
      extincteur,
      cle_rou,
      enjoliveurs,
      roue_secours,
      tapis_sol,
      axe_remorq,
      carte_transport,
      carte_station,
      vignette,
    },
    setState,
  ] = useState(initialState);
  const [load, setLoad] = useState(false);

  const history = useHistory();
  const [showAddclient, setShowAddclient] = useState(false);
  const [addclient, setAddclient] = useState(false);
  const typeaheadEntrepot = useRef(null);

  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/dashboard');
    }
    (async function anyNameFunction() {
      setLoad(true);
      const getCli = Axios.get(common.ipapi + '/api/client/indexmin');
      const getAgent = Axios.get(common.ipapi + '/api/agent/index');
      const getReceptionid = Axios.get(common.ipapi + '/api/reception/edit/' + id);
      const getReceptions = Axios.get(common.ipapi + '/api/vehicule/index');
      const getEntrepot = Axios.get(common.ipapi + '/api/entrepot/index/2');
      
      await Axios.all([getCli, getAgent, getReceptionid, getReceptions,getEntrepot])
        .then(
          Axios.spread((...responses) => {
            setVehiculeData(responses[3].data.data);
            setVehiculeid(responses[2].data.vehicule_id);
            setTypeEnerg(responses[2].data.energie);
            setDataClient(responses[0].data);
            setClientid(responses[2].data.client_id);
            setEntrepotId(responses[2].data.entrepot_id)
            setAgent(responses[1].data.data);
            setAgentId(responses[2].data.agent.id);
            setNofiche(responses[2].data.nofiche);
            // setStatut(responses[2].data.statut);
            setCarburant(responses[2].data.carburant);
            setSireprisetravaux(responses[2].data.si_reprisetravaux == 0 ? false : true)
            // setAccess(JSON.parse(responses[2].data.accessoires))
            setState(JSON.parse(responses[2].data.accessoires));
            setClient2(responses[2].data.remipar);
            setClient2Tel(responses[2].data.telremipar);
            setPointChoc(responses[2].data.point_choc || '');
            setFamille(
              responses[2].data.vehicule.model
                ? responses[2].data.vehicule.model.libellemodel
                : ''
            );
            setMarque(responses[2].data.vehicule.marque.libellemarq);
            setChassis(responses[2].data.vehicule.chassis);
            setTypeEnerg(responses[2].data.vehicule.energie);
            setDateCirc(
              dateformat(
                responses[2].data.vehicule.date_mise_circul,
                'yyyy-mm-dd'
              )
            );
            setDateEnt(
              dateformat(responses[2].data.date_entre_garage, 'yyyy-mm-dd')
            );
            setIndex(responses[2].data.index_compt);
            setMotif(responses[2].data.motifgratuit || '');
            setRevision(responses[2].data.si_revision);
            setSipayant(responses[2].data.si_payant);
            setObjet(responses[2].data.objet_visite || '');
            setMotifRetard(
              responses[2].data.motifretardliv
                ? JSON.parse(responses[2].data.motifretardliv)
                : []
            );
            setIntervenant(
              responses[2].data.intervenant
                ? JSON.parse(responses[2].data.intervenant)
                : []
            );
            setEntrepot(responses[4].data)
            setLoad(false);
          })
        )
        .catch((errors) => {
          setLoad(false);
        });
    })();
  }, [history, id]);

  async function getVehicule() {
    setLoad(true);
    await Axios.get(common.ipapi + '/api/vehicule/index')
      .then((response) => {
        setVehiculeData(response.data.data);
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }

  async function getClient() {
    setLoad(true);
    await Axios.get(common.ipapi + '/api/client/indexmin')
      .then((response) => {
        setDataClient(response.data);
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }

  function saveArticle() {
    setLoad(true);
    var accessoire = {
      carte_grise: carte_grise,
      visite_technique: visite_technique,
      assurance: assurance,
      patente: patente,
      cric: cric,
      manivelle: manivelle,
      cd: cd,
      p_audio: p_audio,
      radio: radio,
      extincteur: extincteur,
      cle_rou: cle_rou,
      enjoliveurs: enjoliveurs,
      roue_secours: roue_secours,
      tapis_sol: tapis_sol,
      axe_remorq: axe_remorq,
      carte_transport: carte_transport,
      carte_station: carte_station,
      vignette: vignette,
    };
    var formData = new FormData();
    formData.append('datecircul', datecircul);
    formData.append('objet_visite', objet_visite);
    formData.append('indexc', indexc);
    formData.append('datesent', datesent);
    formData.append('carburant', carburant);
    // formData.append('statut', statut);
    formData.append('poinchoc', poinchoc);
    formData.append('sirevision', sirevision);
    formData.append('user_id', common.userid);
    formData.append('sipayent', sipayent);
    formData.append('motifgratuit', motifgratuit);
    formData.append('sireprisetravaux', sireprisetravaux ? 1 : 0);
    formData.append('client2', client2);
    formData.append('client2tel', client2tel);
    formData.append('nofiche', nofiche);
    formData.append('entrepot_id', entrepot_id);
    formData.append('client_id', client_id);
    formData.append('agent_id', agent_id);
    formData.append('vehicule_id', vehicule_id);
    formData.append('accessoires', JSON.stringify(accessoire));
    formData.append('motifretardliv', JSON.stringify(motifretard));
    formData.append('intervenant', JSON.stringify(intervenant));
    Axios({
      url: common.ipapi + '/api/reception/update/' + id,
      method: 'POST',
      headers: {
        'Content-Type': 'form-data',
      },
      data: formData,
    })
      .then((res) => {
        if (res.data.status == 1) {
          enreparat++;
          total++;
          setTotal(total);
          setEnRepa(enreparat);
          setMsg(res.data.msg);
          setMsgravity('info');
          getReception();
          setIsSave(true);
          setLoad(false);
          setShowEdit(false);
        } else {
          setMsg(res.data.msg);
          setMsgravity('danger');
          setIsSave(true);
          setLoad(false);
        }
      })
      .catch((error) => {
        setIsSave(true);
        setMsg(error);
        setMsgravity('danger');
        setLoad(false);
      });
  }

  const handleSubmit = (event) => {
    if (datesent == '') {
      setMsg('Veuillez renseigner la date reception');
      setMsgravity('danger');
      setIsSave(true);
      return;
    }
    
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      saveArticle();
    }
  };
  const handleChange = (e) => {
    const { value } = e.target;
    if (value === 'carte_grise' && carte_grise === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'carte_grise') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }

    if (value === 'axe_remorq' && axe_remorq === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'axe_remorq') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'visite_technique' && visite_technique === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'visite_technique') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'assurance' && assurance === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'assurance') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'patente' && patente === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'patente') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'cric' && cric === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'cric') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'manivelle' && manivelle === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'manivelle') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'cd' && cd === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'cd') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'p_audio' && p_audio === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'p_audio') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'radio' && radio === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'radio') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'extincteur' && extincteur === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'extincteur') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'cle_rou' && cle_rou === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'cle_rou') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'enjoliveurs' && enjoliveurs === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'enjoliveurs') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'roue_secours' && roue_secours === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'roue_secours') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'tapis_sol' && tapis_sol === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'tapis_sol') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'carte_transport' && carte_transport === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'carte_transport') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'carte_station' && carte_station === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'carte_station') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
    if (value === 'vignette' && vignette === '') {
      setState((prevState) => ({ ...prevState, [value]: value }));
    } else if (value === 'vignette') {
      setState((prevState) => ({ ...prevState, [value]: '' }));
    }
  };

  function setEntrepotIds(d) {
    setEntrepotId(d.length > 0 ? d[0].id : 0);
  }

  function setVehiculeids(d) {
    setVehiculeid(d.length > 0 ? d[0].id : 0);
    setChassis(d.length > 0 ? d[0].chassis : '');
    setFamille(d.length > 0 ? (d[0].model ? d[0].model.libellemodel : '') : '');
    setMarque(d.length > 0 ? (d[0].marque ? d[0].marque.libellemarq : '') : '');
    setDateCirc(
      d.length > 0 ? dateformat(d[0].date_mise_circul, 'yyyy-mm-dd') : ''
    );
    setTypeEnerg(d.length > 0 ? d[0].energie : '');
  }

  const handleClose = () => {
    setShowEdit(false);
    setIsSave(false);
  };

  function setClientids(d) {
    setClientid(d.length > 0 ? d[0].id : 0);
  }
  function setAgentIds(d) {
    setAgentId(d.length > 0 ? d[0].id : 0);
  }

  function setAddClient() {
    setAddclient(true);
    setShowAddclient(true);
  }
  function setAddVehicules() {
    setAddVehicule(true);
    setShowAddVehicule(true);
  }

  function setMotifDure() {
    let t = [];
    let d = {
      date: '',
      motif: '',
    };
    t.push(d);
    t = t.concat(motifretard);
    setMotifRetard(t);
  }

  function selectIntervenant() {
    let t = [];
    let d = {
      id: intervenant.length,
      idagent: 0,
      agent: '',
      intervention: '',
      etat: false,
    };
    t.push(d);
    t = t.concat(intervenant);
    setIntervenant(t);
  }
  function onDelete() {}

  const DateM = ({ value: initialValue, row: { index } }) => {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.target.value);
    };
    const onBlur = () => {
      motifretard[index].date = value;
    };
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    return (
      <Form.Control
        type="date"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  };

  const MotifRet = ({ value: initialValue, row: { index } }) => {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.target.value);
    };
    const onBlur = () => {
      motifretard[index].motif = value;
    };
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    return (
      <Form.Control
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  };

  const InterventionType = ({ value: initialValue, row: { index } }) => {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.target.value);
    };
    const onBlur = () => {
      intervenant[index].intervention = value;
    };
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    return (
      <Form.Control
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  };

  const InterventionState = ({ value: initialValue, row: { index } }) => {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.target.checked);
    };
    const onBlur = () => {
      intervenant[index].etat = value;
    };
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    return (
      <ButtonGroup check inline>
        <Input
          value={value}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          type="checkbox"
        />
      </ButtonGroup>
    );
  };

  const AgentsInter = ({ value: initialValue, row: { index } }) => {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.length > 0 ? e[0].id : 0);
    };
    const onBlur = (e) => {
      intervenant[index].idagent = value;
      intervenant[index].agent = e.target.value;
    };
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    return (
      <Form.Group as={Col} md="12" controlId="agent">
        <InputGroup>
          <Typeahead
            id="my-typeahead-id"
            labelKey={(option) => `${option.nom} ${option.prenoms}`}
            filterBy={['nom', 'prenoms']}
            value={value}
            selected={agent.slice(
              agent.findIndex((element) => element.id === value),
              agent.findIndex((element) => element.id === value) + 1
            )}
            options={agent}
            onBlur={(e) => onBlur(e)}
            onChange={(e) => onChange(e)}
            inputProps={{ required: true }}
          />
        </InputGroup>
      </Form.Group>
    );
  };

  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: DateM,
    },
    {
      Header: 'Motif retard de livraison',
      accessor: 'motif',
      Cell: MotifRet,
    },
    {
      Header: (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">Ajouter un motif de retard</Tooltip>
          }
        >
          <PlusCircle
            color="#51cbce"
            onClick={() => setMotifDure()}
            size={35}
          />
        </OverlayTrigger>
      ),
      accessor: '[editButton]',
      Cell: (row) => (
        <ButtonGroup aria-label="Basic example">
          <Trash
            onClick={() => onDelete(row.row.original)}
            color="red"
            size={20}
          />
        </ButtonGroup>
      ),
    },
  ];

  const columnIntervenant = [
    {
      Header: 'Agents',
      accessor: 'idagent',
      Cell: AgentsInter,
    },
    {
      Header: 'Intervention',
      accessor: 'intervention',
      Cell: InterventionType,
    },
    {
      Header: 'Etat',
      accessor: 'etat',
      Cell: InterventionState,
    },
    {
      Header: (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              Ajouter des intervenants sur le projet
            </Tooltip>
          }
        >
          <PlusCircle
            color="#51cbce"
            onClick={() => selectIntervenant()}
            size={35}
          />
        </OverlayTrigger>
      ),
      accessor: '[editButton]',
      Cell: (row) => (
        <ButtonGroup aria-label="Basic example">
          <Trash
            onClick={() => onDelete(row.row.original)}
            color="red"
            size={20}
          />
        </ButtonGroup>
      ),
    },
  ];
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      {showAddVehicule ? (
        <AddVehicule
          setAdds={setAdds}
          show={showAddVehicule}
          setShowAddVehicule={setShowAddVehicule}
          setIsSave={setIsSave}
          isSave={false}
          setVehiculeData={setVehiculeData}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          getVehicule={getVehicule}
        />
      ) : (
        <></>
      )}
      {addclient !== false ? (
        <AddClient
          setShowAddclient={setShowAddclient}
          show={showAddclient}
          setIsSave={setIsSave}
          isSave={false}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          getClient={getClient}
          setLoad={setLoad}
        />
      ) : (
        <></>
      )}
      <Modal show={show} fullscreen={true}>
        <Modal.Header>
          {'MODIFICATION RECEPTION DE VEHICULE'}
          <BackspaceFill onClick={handleClose} color="red" size={30} />
        </Modal.Header>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <div id="lightbody">
              <Row>
                <Col md={4}>
                  <Card id="fixed-cards">
                    <CardBody>
                      <Form.Group as={Col} md="" controlId="fiche">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            N° de fiche: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="N° de fiche"
                            aria-describedby="inputGroupPrepend"
                            required
                            size="sm"
                            onChange={(e) => setNofiche(e.target.value)}
                            value={nofiche}
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir le N° de fiche
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      
                      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={5}>Position entreposage: <Star color="red" size={10} /></Form.Label>
                        <Col sm={7}>
                          <Typeahead
                            ref={typeaheadEntrepot}
                            id="my-client-id"
                            labelKey={(option) => `${option.libelleentrepot}`}
                            filterBy={['libelleentrepot']}
                            value={entrepot_id}
                            selected={entrepot.slice(
                              entrepot.findIndex(
                                (element) => element.id === entrepot_id
                              ),
                              entrepot.findIndex(
                                (element) => element.id === entrepot_id
                              ) + 1
                            )}
                            options={entrepot}
                            onChange={(cli) => setEntrepotIds(cli)}
                            inputProps={{ required: true }}
                          />
                        </Col>
                        
                      </Form.Group>
                      {/* <Form.Group
                                    as={Row}
                                    className="mb-3" controlId="formHorizontalEmail"
                                >
                                    <Form.Label column sm={4}>
                                        Statut fiche
                                    </Form.Label>
                                    <Col sm={8}>
                                    <Form.Select
                                        id="typeid"
                                        value={statut}
                                        label="Statut"
                                        onChange={(e) => setStatut(e.target.value)}
                                    >
                                        { 
                                            ['','En attente validation dévis', 'En attente fiche expertise','En cours de travaux', 'En attente BR','Véhicule livré'].map((prt, index) => {
                                                return (
                                                    <option key={index} id="parentid" value={index}>
                                                        {prt}
                                                    </option>
                                                );
                                            })}
                                         
                         
                                    </Form.Select> <Form.Control.Feedback type="invalid">
                            Veuillez saisir la receptionniste
                          </Form.Control.Feedback></Col>
                                </Form.Group>
                           */}
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      {' '}
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        information client
                      </CardSubtitle>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup>
                          <InputGroup.Text id="inputGroupPrepend">
                            Nom client:
                          </InputGroup.Text>
                          <Typeahead
                            id="my-typeahead-id"
                            labelKey={(option) => `${option.raisonsocial}`}
                            filterBy={['raisonsocial']}
                            value={client_id}
                            selected={client.slice(
                              client.findIndex(
                                (element) => element.id === client_id
                              ),
                              client.findIndex(
                                (element) => element.id === client_id
                              ) + 1
                            )}
                            options={client}
                            onChange={(cli) => setClientids(cli)}
                            inputProps={{ required: true }}
                          />
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Ajouter un nouveau client
                              </Tooltip>
                            }
                          >
                            <PlusCircle
                              color="#51cbce"
                              onClick={() => setAddClient()}
                              size={35}
                            />
                          </OverlayTrigger>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="agent">
                        <InputGroup>
                          <InputGroup.Text id="inputGroupPrepend">
                            Receptionné par: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Typeahead
                            id="my-typeahead-id"
                            labelKey={(option) =>
                              `${option.nom} ${option.prenoms}`
                            }
                            filterBy={['nom', 'prenoms']}
                            value={agent_id}
                            selected={agent.slice(
                              agent.findIndex(
                                (element) => element.id === agent_id
                              ),
                              agent.findIndex(
                                (element) => element.id === agent_id
                              ) + 1
                            )}
                            options={agent}
                            onChange={(cli) => setAgentIds(cli)}
                            inputProps={{ required: true }}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="" controlId="Conducteur">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Conducteur:{' '}
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Conducteur"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            onChange={(e) => setClient2(e.target.value)}
                            value={client2}
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir le conducteur du véhicule
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Tel. conducteur:
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Contact"
                            aria-describedby="inputGroupPrepend"
                            onChange={(e) => setClient2Tel(e.target.value)}
                            size="sm"
                            value={client2tel}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Date entrée: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            placeholder="Date entrée"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={datesent}
                            onChange={(e) => setDateEnt(e.target.value)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir la date d'entrée au garage
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </CardBody>
                  </Card>

                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        informations véhicule client
                      </CardSubtitle>
                      <Form.Group as={Col} md="12" controlId="immatr">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Vehicule: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Typeahead
                            id="my-typeahead-id"
                            labelKey={(option) => `${option.immatr_eng}`}
                            filterBy={['immatr_eng ', 'chassis']}
                            value={vehicule_id}
                            options={vehicule}
                            selected={vehicule.slice(
                              vehicule.findIndex(
                                (element) => element.id === vehicule_id
                              ),
                              vehicule.findIndex(
                                (element) => element.id === vehicule_id
                              ) + 1
                            )}
                            onChange={(cli) => setVehiculeids(cli)}
                            inputProps={{ required: true }}
                          />
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Ajouter un nouvel engin
                              </Tooltip>
                            }
                          >
                            <PlusCircle
                              color="#51cbce"
                              onClick={() => setAddVehicules()}
                              size={35}
                            />
                          </OverlayTrigger>
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir l'immatriculation
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Index compteur: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder="Index compteur"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={indexc}
                            onChange={(e) => setIndex(e.target.value)}
                            required
                            min={0}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Mise en circulation le:
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            placeholder="Date de mise en circulation"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={datecircul}
                            disabled
                            onChange={(e) => setDateCirc(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Marques:
                          </InputGroup.Text>
                          <Input type="text" value={marque} disabled />
                          <Form.Control.Feedback type="invalid">
                            Veuillez selectionnez la marque.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="famille">
                        <InputGroup>
                          <InputGroup.Text id="inputGroupPrepend">
                            Model:
                          </InputGroup.Text>
                          <Input type="text" value={famille} disabled />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Chassis:
                          </InputGroup.Text>
                          <Form.Control
                            disabled
                            type="text"
                            placeholder="Chassis"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            minLength={5}
                            value={chassis}
                            onChange={(e) => setChassis(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="Energie">
                        <InputGroup hasValidation>
                          <ButtonGroup>
                            {[
                              { name: 'Essence', value: '0' },
                              { name: 'Gasoil', value: '1' },
                            ].map((radio, idx) => (
                              <ToggleButton
                                disabled
                                key={idx}
                                id={`radioenergie-${idx}`}
                                size="sm"
                                type="radio"
                                variant={
                                  idx % 2 ? 'outline-danger' : 'outline-danger'
                                }
                                value={radio.value}
                                checked={typeEnerg === radio.value}
                                onChange={(e) =>
                                  setTypeEnerg(e.currentTarget.value)
                                }
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                          <Form.Control.Feedback type="invalid">
                            Veuillez selectionnez la revision.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={8}>
                  <Card id="fixed-cards">
                    <CardHeader className="mb-2 text-muted" tag="h6">
                      informations supplémentaire
                    </CardHeader>
                    <CardBody>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Objet visite:
                          </InputGroup.Text>
                          <Form.Control
                            as="textarea"
                            placeholder="Objet de la visite"
                            aria-describedby="inputGroupPrepend"
                            rows={2}
                            value={objet_visite}
                            onChange={(e) => setObjet(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Remarques:
                          </InputGroup.Text>
                          <Form.Control
                            as="textarea"
                            placeholder="Remarques"
                            aria-describedby="inputGroupPrepend"
                            rows={5}
                            value={poinchoc}
                            onChange={(e) => setPointChoc(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Row>
                        <Col md="3">
                          <Form.Group as={Col} md="12" controlId="revision">
                            <InputGroup hasValidation>
                              Etat 1:
                              <ButtonGroup>
                                {[
                                  { name: 'Révision', value: '0' },
                                  { name: 'Autres', value: '1' },
                                ].map((radio, idx) => (
                                  <ToggleButton
                                    key={idx}
                                    id={`radiorevision-${idx}`}
                                    type="radio"
                                    size="sm"
                                    variant={
                                      idx % 2
                                        ? 'outline-danger'
                                        : 'outline-danger'
                                    }
                                    value={radio.value}
                                    checked={sirevision === radio.value}
                                    onChange={(e) =>
                                      setRevision(e.currentTarget.value)
                                    }
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="5">
                          <Form.Group as={Col} md="12" controlId="gratuit">
                            <InputGroup hasValidation>
                              Etat 2:
                              <ButtonGroup>
                                {[
                                  { name: 'Payant', value: '0' },
                                  { name: 'Gratuit', value: '1' },
                                  // { name: 'Reprise travaux', value: '2' },
                                ].map((radio, idx) => (
                                  <ToggleButton
                                    key={idx}
                                    id={`radiogratuit-${idx}`}
                                    size="sm"
                                    type="radio"
                                    variant={
                                      idx % 2
                                        ? 'outline-danger'
                                        : 'outline-danger'
                                    }
                                    value={radio.value}
                                    checked={sipayent === radio.value}
                                    onChange={(e) =>
                                      setSipayant(e.currentTarget.value)
                                    }
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                              <ButtonGroup check="true" inline="true">
                              <Input
                                key='index'
                                onChange={(e)=> setSireprisetravaux(!sireprisetravaux)}
                                checked={sireprisetravaux}
                                value={sireprisetravaux}
                                type="checkbox"
                                id={`inline-checkbox`}
                              />
                              <Label check>Reprise travaux</Label>
                            </ButtonGroup>
                            </InputGroup>
                          </Form.Group>
                          {sipayent === '1' ? (
                            <Form.Group
                              as={Col}
                              md=""
                              controlId="validationCustomUsername"
                            >
                              <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">
                                  Motif gratuit:
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  placeholder="Motif gratuit"
                                  aria-describedby="inputGroupPrepend"
                                  size="sm"
                                  value={motifgratuit}
                                  onChange={(e) => setMotif(e.target.value)}
                                />
                              </InputGroup>
                            </Form.Group>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col md="4">
                          <Form.Group as={Col} md="12" controlId="revision">
                            <InputGroup hasValidation>
                              Niveau de carburant
                              <ButtonGroup>
                                {[
                                  { name: '1/4', value: '1/4' },
                                  { name: '1/3', value: '1/3' },
                                  { name: '1/2', value: '1/2' },
                                  { name: '1', value: '1' },
                                ].map((radio, idx) => (
                                  <ToggleButton
                                    key={idx}
                                    id={`niveau-${idx}`}
                                    type="radio"
                                    size="sm"
                                    variant={
                                      idx % 2
                                        ? 'outline-danger'
                                        : 'outline-danger'
                                    }
                                    value={radio.value}
                                    checked={carburant === radio.value}
                                    onChange={(e) =>
                                      setCarburant(e.currentTarget.value)
                                    }
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        Eléments du véhicule
                      </CardSubtitle>
                      <Row>
                        <Col md="3">
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={carte_grise || 'carte_grise'}
                              checked={carte_grise ? true : false}
                              type="checkbox"
                              id="inline-checkbox-arte_grise"
                            />
                            <Label check>{'carte grise'} </Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={visite_technique || 'visite_technique'}
                              checked={visite_technique ? true : false}
                              type="checkbox"
                              id="inline-checkbox-visite_technique"
                            />
                            <Label check>{'visite technique'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={assurance || 'assurance'}
                              checked={assurance ? true : false}
                              type="checkbox"
                              id="inline-checkbox-assurance"
                            />
                            <Label check>{'assurance'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={patente || 'patente'}
                              checked={patente ? true : false}
                              type="checkbox"
                              id={`inline-checkbox-${patente}`}
                            />
                            <Label check>{'patente'} </Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={cric || 'cric'}
                              checked={cric ? true : false}
                              type="checkbox"
                              id="inline-checkbox-cric"
                            />
                            <Label check>{'cric'}</Label>
                          </ButtonGroup>
                        </Col>
                        <Col md={3}>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={manivelle || 'manivelle'}
                              checked={manivelle ? true : false}
                              type="checkbox"
                              id="inline-checkbox-manivelle"
                            />
                            <Label check>{'manivelle'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={cd || 'cd'}
                              checked={cd ? true : false}
                              type="checkbox"
                              id="inline-checkbox-cd"
                            />
                            <Label check>{'Lecteur cd'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={p_audio || 'p_audio'}
                              checked={p_audio ? true : false}
                              type="checkbox"
                              id="inline-checkbox-p_audio"
                            />
                            <Label check>{'poste audio'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={radio || 'radio'}
                              checked={radio ? true : false}
                              type="checkbox"
                              id="inline-checkbox-radio"
                            />
                            <Label check>{'Poste radio'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={extincteur || 'extincteur'}
                              checked={extincteur ? true : false}
                              type="checkbox"
                              id="inline-checkbox-extincteur"
                            />
                            <Label check>{'extincteur'}</Label>
                          </ButtonGroup>
                        </Col>
                        <Col md={3}>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={cle_rou || 'cle_rou'}
                              checked={cle_rou ? true : false}
                              type="checkbox"
                              id="inline-checkbox-cle_rou"
                            />
                            <Label check>{'cle roue'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={enjoliveurs || 'enjoliveurs'}
                              checked={enjoliveurs ? true : false}
                              type="checkbox"
                              id="inline-checkbox-enjoliveurs"
                            />
                            <Label check>{'enjoliveurs'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={roue_secours || 'roue_secours'}
                              checked={roue_secours ? true : false}
                              type="checkbox"
                              id="inline-checkbox-${roue_secours"
                            />
                            <Label check>{'roue secours'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={tapis_sol || 'tapis_sol'}
                              checked={tapis_sol ? true : false}
                              type="checkbox"
                              id="inline-checkbox-tapis_sol"
                            />
                            <Label check>{'tapis sol'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={axe_remorq || 'axe_remorq'}
                              checked={axe_remorq ? true : false}
                              type="checkbox"
                              id="inline-checkbox-axe_remorq"
                            />
                            <Label check>{'axe remorq'}</Label>
                          </ButtonGroup>
                        </Col>
                        <Col md={3}>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={carte_transport || 'carte_transport'}
                              checked={carte_transport ? true : false}
                              type="checkbox"
                              id="inline-checkbox-carte_transport"
                            />
                            <Label check>{'carte transport'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={carte_station || 'carte station'}
                              checked={carte_station ? true : false}
                              type="checkbox"
                              id="inline-checkbox-carte_station"
                            />
                            <Label check>{'carte station'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={handleChange}
                              value={vignette || 'vignette'}
                              checked={vignette ? true : false}
                              type="checkbox"
                              id="inline-checkbox-vignette"
                            />
                            <Label check>{'vignette'}</Label>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        INETERVENANTS SUR LES TRAVAUX
                      </CardSubtitle>
                      <Table columns={columnIntervenant} data={intervenant} />
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardHeader>MOTIF DE RETARD DE LIVRAISON</CardHeader>
                      <Table columns={columns} data={motifretard} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup aria-label="Basic example" size="sm">
              <Button color="secondary" onClick={handleClose}>
                <BackspaceFill color="red" size={20} />
              </Button>
              <Button type="submit" color="default">
                <Save2Fill color="#ff7300" size={20} /> Enregister
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default EditReceptions;
