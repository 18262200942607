import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  InputGroup,
  Spinner,
  ButtonGroup,
  Modal,
  ToggleButton,
  Tooltip,
  OverlayTrigger,
  FormGroup,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';

import {
  Button,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardSubtitle,
} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Axios from 'axios';
import {
  BackspaceFill,
  PlusCircle,
  Save2Fill,
  Star,
  Trash,
} from 'react-bootstrap-icons';
import common from '../../commonData';
import dateformat from 'dateformat';
import AddClient from '../clients/addClient';
import AddVehicule from '../vehicules/addLight';
import Table from 'views/tableaddedit';


var count = 0;
function AddReceptions({
  id,
  setAdds,
  setShowAddVehicule,
  showAddVehicule,
  show,
  setIsSave,
  setMsg,
  setMsgravity,
  setEnRepa,
  enreparat,
  setTotal,
  total,
  getReception,
}) {
  var ladate = new Date();

  const [states, setStates] = useState({
    client_id:'',
    agent_id: '',
    entrepot_id: '',
    vehicule_id: '',
    client2:'',
    client2tel:'',
    chassis:'',
    famille:'',
    marque:'',
    datecircul:'',
    carburant:'1/3',
    datesent:  dateformat(
      ladate.getMonth() +
      1 +
      '/' +
      ladate.getDate() +
      '/' +
      ladate.getFullYear(),
      'yyyy-mm-dd'
    ), 
    poinchoc:'',
    indexc:0,
    sirevision:'1',
    typeEnergs:'',
    sipayent:'0',
    sireprisetravaux:false,
    motifgratuit:'',
    objet_visite:'',
    nofiche: '',
    type: []
});
const { client_id, agent_id, entrepot_id, vehicule_id ,client2,
  client2tel,chassis,famille,marque,datecircul,carburant,
  datesent,poinchoc,indexc,sirevision,typeEnergs,sipayent,
  sireprisetravaux,motifgratuit,objet_visite,nofiche,type} = states;
  const [showAddclient, setShowAddclient] = useState(false);

  const [motifretard, setMotifRetard] = useState([]);
  const [client, setDataClient] = useState([]);
  const [agent, setAgent] = useState([]);
  const [vehicule, getVehiculeData] = useState([]);
  const [entrepot, setEntrepot] = useState([]);
  const [intervenant, setIntervenant] = useState([]);
  
  const [load, setLoad] = useState(false);
  const typeaheadClient = useRef(null);
  const typeaheadEntrepot = useRef(null);
  const typeaheadAgent = useRef(null);
  const typeaheadVehi = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/dashboard');
    }
    (async function anyNameFunction() {
      setLoad(true);
      const getCli = Axios.get(common.ipapi + '/api/client/indexmin');
      const getvehicul = Axios.get(common.ipapi + '/api/vehicule/index');
      const getAgents = Axios.get(common.ipapi + '/api/agent/index');
      const getEntrepot = Axios.get(common.ipapi + '/api/entrepot/index/2');
      const getReceptionid = Axios.get(common.ipapi + '/api/reception/edit/' + id);
      var tab=[getCli, getvehicul, getAgents,getEntrepot];
      if(id>0){tab = [getCli, getvehicul, getAgents,getEntrepot,getReceptionid];}
      
      await Axios.all(tab)
        .then(
          Axios.spread((...responses) => {
            setDataClient(responses[0].data);
            getVehicule(responses[1].data.data);
            setAgent(responses[2].data.data);
            setEntrepot(responses[3].data);
            const accessoir = JSON.parse(responses[4].data.accessoires)
            setStates({
              client_id:responses[4].data.client_id,
              agent_id: responses[4].data.agent_id,
              entrepot_id: responses[4].data.entrepot_id,
              vehicule_id: responses[4].data.vehicule_id,
              client2:responses[4].data.remipar,
              client2tel:responses[4].data.telremipar,
              chassis:responses[4].data.vehicule.chassis,
              famille:responses[4].data.vehicule.model_eng,
              marque:responses[4].data.vehicule.marque_id,
              datecircul:responses[4].data.vehicule.date_mise_circul,
              carburant:responses[4].data.carburant,
              datesent:  dateformat(responses[4].data.date_entre_garage,'yyyy-mm-dd'), 
              poinchoc:responses[4].data.point_choc,
              indexc:responses[4].data.index_compt,
              sirevision:responses[4].data.si_revision,
              typeEnergs:responses[4].data.vehicule.energie,
              sipayent:responses[4].data.si_payant,
              sireprisetravaux:responses[4].data.si_reprisetravaux,
              motifgratuit:responses[4].data.motifgratuit,
              objet_visite:responses[4].data.objet_visite,
              nofiche: responses[4].data.nofiche,
              type: [ accessoir.carte_grise,
                accessoir.visite_technique,
                accessoir.assurance,
                accessoir.patente,
                accessoir.cric,
                accessoir.manivelle,
                accessoir.cd,
                accessoir.p_audio,
                accessoir.radio,
                accessoir.extincteur,
                accessoir.cle_rou,
                accessoir.enjoliveurs,
                accessoir.roue_secours,
                accessoir.tapis_sol,
                accessoir.axe_remorq,
                accessoir.carte_transport,
                accessoir.carte_station,
                accessoir.vignette,]
          });
          setMotifRetard(
            responses[4].data.motifretardliv
              ? JSON.parse(responses[4].data.motifretardliv)
              : []
          );
          setIntervenant(
            responses[4].data.intervenant
              ? JSON.parse(responses[4].data.intervenant)
              : []
          );
            // setEntrepotId(responses[3].data.length>0 ? responses[3].data[0].id : 0)
            setLoad(false);
          })
        )
        .catch((errors) => {
          setLoad(false);
        });
    })();
  }, [history]);

  async function getVehicule() {
    setLoad(true);
    await Axios.get(common.ipapi + '/api/vehicule/index')
      .then((response) => {
        getVehiculeData(response.data.data);
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }

  async function getClient() {
    setLoad(true);
    await Axios.get(common.ipapi + '/api/client/indexmin')
      .then((response) => {
        setDataClient(response.data);
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }

  function setMotifDure() {
    let t = [];
    let d = {
      date: '',
      motif: '',
    };
    t.push(d);
    t = t.concat(motifretard);
    setMotifRetard(t);
  }



  function savereception(values) {
    setLoad(true);
    var accessoire = {
      carte_grise: values.type.indexOf('carte_grise') === -1 ? '' : 'carte_grise',
      visite_technique: values.type.indexOf('visite_technique') === -1 ? '' : 'visite_technique',
      assurance: values.type.indexOf('assurance') === -1 ? '' : 'assurance',
      patente: values.type.indexOf('patente') === -1 ? '' : 'patente',
      cric: values.type.indexOf('cric') === -1 ? '' : 'cric',
      manivelle: values.type.indexOf('manivelle') === -1 ? '' : 'manivelle',
      cd: values.type.indexOf('cd') === -1 ? '' : 'cd',
      p_audio: values.type.indexOf('p_audio') === -1 ? '' : 'p_audio',
      radio: values.type.indexOf('radio') === -1 ? '' : 'radio',
      extincteur: values.type.indexOf('extincteur') === -1 ? '' : 'extincteur',
      cle_rou: values.type.indexOf('cle_rou') === -1 ? '' : 'cle_rou',
      enjoliveurs: values.type.indexOf('enjoliveurs') === -1 ? '' : 'enjoliveurs',
      roue_secours: values.type.indexOf('roue_secours') === -1 ? '' : 'roue_secours',
      tapis_sol: values.type.indexOf('tapis_sol') === -1 ? '' : 'tapis_sol',
      axe_remorq: values.type.indexOf('axe_remorq') === -1 ? '' : 'axe_remorq',
      carte_transport: values.type.indexOf('carte_transport') === -1 ? '' : 'carte_transport',
      carte_station: values.type.indexOf('carte_station') === -1 ? '' : 'carte_station',
      vignette: values.type.indexOf('vignette') === -1 ? '' : 'vignette',
    };
    var formData = new FormData();
    formData.append('datecircul', values.datecircul);
    formData.append('objet_visite', values.objet_visite);
    formData.append('indexc', values.indexc);
    formData.append('datesent', values.datesent);
    formData.append('carburant', values.carburant);
    formData.append('poinchoc', values.poinchoc);
    formData.append('sirevision', values.sirevision);
    formData.append('sireprisetravaux', values.sireprisetravaux ? 1 : 0);
    formData.append('user_id', common.userid);
    formData.append('sipayent', values.sipayent);
    formData.append('motifgratuit', values.motifgratuit);
    formData.append('client2', values.client2);
    formData.append('client2tel', values.client2tel);
    formData.append('nofiche', values.nofiche);
    formData.append('client_id', values.client_id);
    formData.append('entrepot_id', values.entrepot_id);
    formData.append('agent_id', values.agent_id);
    formData.append('vehicule_id', values.vehicule_id);
    formData.append('accessoires', JSON.stringify(accessoire));
    formData.append('intervenant', JSON.stringify(intervenant));
    formData.append('motifretardliv', JSON.stringify(motifretard));
    Axios({
      url: id ? common.ipapi + '/api/reception/update/' + id : common.ipapi + '/api/reception/store',
      method: 'POST',
      headers: {
        'Content-Type': 'form-data',
      },
      data: formData,
    })
      .then((res) => {
        if (res.data.status === 1) {
          enreparat++;
          total++;
          setMsg(res.data.msg);
          setMsgravity('info');
          setIsSave(true);
          setStates({
            client_id:'',
            agent_id: '',
            entrepot_id: '',
            vehicule_id: '',
            client2:'',
            client2tel:'',
            chassis:'',
            famille:'',
            marque:'',
            datecircul:'',
            carburant:'1/3',
            datesent:  dateformat(
              ladate.getMonth() +
              1 +
              '/' +
              ladate.getDate() +
              '/' +
              ladate.getFullYear(),
              'yyyy-mm-dd'
            ), 
            poinchoc:'',
            indexc:'0',
            sirevision:'1',
            typeEnergs:'',
            sipayent:'0',
            sireprisetravaux:false,
            motifgratuit:'',
            objet_visite:'',
            nofiche: '',
            type: []
          });
          
          setTotal(total);
          setEnRepa(enreparat);
          count++;
          setIntervenant([]);
          typeaheadAgent.current.clear();
          typeaheadClient.current.clear();
          typeaheadVehi.current.clear();
          typeaheadEntrepot.current.clear();
          
          setLoad(false);
          if (id) {
            setTimeout(handleClose, 600);
        }
        } else {
          setMsg(res.data.msg);
          setMsgravity('danger');
          setIsSave(true);
          setLoad(false);
        }
      })
      .catch((error) => {
        setIsSave(true);
        setMsg(error);
        setMsgravity('danger');
        setLoad(false);
      });
  }



  const handleClose = () => {
    if (count !== 0) {
      getReception();
    }
    setAdds(false);
    setIsSave(false);
  };
  
  function setAddClient() {
    setShowAddclient(true);
  }
  function setAddVehicules() {
    setShowAddVehicule(true);
  }
 
  function selectIntervenant() {
    let t = [];
    let d = {
      id: intervenant.length,
      idagent: 0,
      agent: '',
      intervention: '',
      etat: false,
    };
    t.push(d);
    t = t.concat(intervenant);
    setIntervenant(t);
  }

  const DateM = ({ value: initialValue, row: { index } }) => {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.target.value);
    };
    const onBlur = () => {
      motifretard[index].date = value;
    };
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    return (
      <Form.Control
        type="date"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  };

  const MotifRet = ({ value: initialValue, row: { index } }) => {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.target.value);
    };
    const onBlur = () => {
      motifretard[index].motif = value;
    };
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    return (
      <Form.Control
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  };

  const InterventionType = ({ value: initialValue, row: { index } }) => {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.target.value);
    };
    const onBlur = () => {
      intervenant[index].intervention = value;
    };
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    return (
      <Form.Control
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  };

  const InterventionState = ({ value: initialValue, row: { index } }) => {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.target.checked);
    };
    const onBlur = () => {
      intervenant[index].etat = value;
    };
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    return (
      <Form.Control
        type="checkbox"
        value={value || 'etat'}
        checked={value ? true : false}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  };

  const AgentsInter = ({ value: initialValue, row: { index } }) => {
    const [value, setValue] = React.useState(initialValue);
    const onChange = (e) => {
      setValue(e.length > 0 ? e[0].id : 0);
    };
    const onBlur = (e) => {
      intervenant[index].idagent = value;
      intervenant[index].agent = e.target.value;
    };
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    return (
      <Form.Group as={Col} md="12">
        <InputGroup>
          <Typeahead
            id="my-typeahead-id"
            labelKey={(option) => `${option.nom} ${option.prenoms}`}
            filterBy={['nom', 'prenoms']}
            value={value}
            selected={agent.slice(
              agent.findIndex((element) => element.id === value),
              agent.findIndex((element) => element.id === value) + 1
            )}
            options={agent}
            onBlur={(e) => onBlur(e)}
            onChange={(e) => onChange(e)}
            inputProps={{ required: true }}
          />
        </InputGroup>
      </Form.Group>
    );
  };

  
  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: DateM,
    },
    {
      Header: 'Motif retard de livraison',
      accessor: 'motif',
      Cell: MotifRet,
    },
    {
      Header: (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">Ajouter un motif de retard</Tooltip>
          }
        >
          <PlusCircle
            color="#000"
            onClick={() => setMotifDure()}
            size={35}
          />
        </OverlayTrigger>
      ),
      accessor: '[editButton]',
      Cell: (row) => (
        <ButtonGroup aria-label="Basic example">
          <Trash
            onClick={() => onDelete(row.row.original)}
            color="red"
            size={20}
          />
        </ButtonGroup>
      ),
    },
  ];


  const columnIntervenant = [
    {
      Header: 'Agents',
      accessor: 'idagent',
      Cell: AgentsInter,
    },
    {
      Header: 'Intervention',
      accessor: 'intervention',
      Cell: InterventionType,
    },
    {
      Header: 'Etat',
      accessor: 'etat',
      Cell: InterventionState,
    },
    {
      Header: (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              Ajouter des intervenants sur le projet
            </Tooltip>
          }
        >
          <PlusCircle
            color="#000"
            onClick={() => selectIntervenant()}
            size={35}
          />
        </OverlayTrigger>
      ),
      accessor: '[editButton]',
      Cell: (row) => (
        <ButtonGroup aria-label="Basic example">
          <Trash
            onClick={() => onDelete(row.row.original)}
            color="red"
            size={20}
          />
        </ButtonGroup>
      ),
    },
  ];
  function onDelete() { }
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      {showAddVehicule ? (
        <AddVehicule
          setAdds={setAdds}
          setShowAddVehicule={setShowAddVehicule}
          show={showAddVehicule}
          setIsSave={setIsSave}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          getVehicule={getVehicule}
        />
      ) : (
          <></>
        )}
      {showAddclient ? (
        <AddClient
          setShowAddclient={setShowAddclient}
          showAddclient={showAddclient}
          show={showAddclient}
          setIsSave={setIsSave}
          isSave={false}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          getClient={getClient}
          setLoad={setLoad}
        />
      ) : (
          <></>
        )}
              
      <Formik
          initialValues={{
              client_id:client_id,
              agent_id: agent_id,
              entrepot_id: entrepot_id,
              vehicule_id: vehicule_id,
              client2:client2,
              client2tel:client2tel,
              chassis:chassis,
              famille:famille,
              marque:marque,
              datecircul:datecircul,
              carburant:carburant,
              datesent:  datesent, 
              poinchoc:poinchoc,
              indexc:indexc,
              sirevision:sirevision,
              typeEnergs:typeEnergs,
              sipayent:sipayent,
              sireprisetravaux:sireprisetravaux,
              motifgratuit:motifgratuit,
              objet_visite:objet_visite,
              nofiche: nofiche,
              type:type,
          }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                client_id: Yup.number().typeError('Aucune correspondance').required('Veuillez saisir cette donnée'),
                agent_id: Yup.number().typeError('Aucune correspondance').required('Veuillez saisir cette donnée'),
                entrepot_id: Yup.number().typeError('Aucune correspondance').required('Veuillez saisir cette donnée'),
                  nofiche: Yup.number().typeError('Veuillez saisir des nombres').required('Veuillez saisir cette donnée'),
                  client2: Yup.string().required('Veuillez saisir cette donnée'),
                  datesent: Yup.date().required('Veuillez saisir cette donnée'),
                  vehicule_id: Yup.number().typeError('Aucune correspondance').required('Veuillez saisir cette donnée'),
                  indexc:Yup.number().typeError('Veuillez saisir des nombres').required('Veuillez saisir cette donnée'),
               })}
                onSubmit={async (values) => {
                    savereception(values)
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
               
      <Modal show={show} fullscreen={true}>
        <Modal.Header>
          {id ? 'MODIFIER UNE RECEPTION DE VEHICULE':'FAIRE UNE RECEPTION DE VEHICULE'}
          <BackspaceFill onClick={handleClose} color="red" size={30} />
        </Modal.Header>
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Body>
            <div id="lightbody">
              <Row>
                <Col md={4}>
                  <Card id="fixed-cards">
                    <CardBody>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={5}>N° de fiche: <Star color="red" size={10} /></Form.Label>
                        <Col sm={7}>
                          <Form.Control
                          id='nofiche'
                            type="text"
                            placeholder="N° de fiche"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={values.nofiche}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            
                          />
                         
                        </Col>
                         {errors.nofiche && <div id="feedback">{errors.nofiche}</div>}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={5}>Position entreposage: <Star color="red" size={10} /></Form.Label>
                        <Col sm={7}>
                          <Typeahead
                             id="entrepot_id"
                             ref={typeaheadEntrepot}
                             multiple={false}
                             onChange={(selected) => {
                                 const value = selected.length > 0 ? selected[0].id : 0;
                                 setFieldValue('entrepot_id', value);
                             }}
                             onInputChange={(text, event) => setFieldValue('entrepot_id', text)}
                             onBlur={(e) => setFieldTouched('entrepot_id', true)}
                             labelKey="libelleentrepot"
                             options={entrepot}
                             selected={entrepot.slice(
                              entrepot.findIndex((element) => element.id === values.entrepot_id),
                              entrepot.findIndex((element) => element.id === values.entrepot_id) +
                                     1
                             )}
                          
                          />
                         
                        </Col>
                         {errors.entrepot_id && <div id="feedback">{errors.entrepot_id}</div>}
                      </Form.Group>
                          
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        information client
                      </CardSubtitle>
                      <Form.Group as={Col} md="12">
                        <InputGroup>
                          <InputGroup.Text id="inputGroupPrepend">
                            Nom client: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Typeahead
                               id="client_id"
                               ref={typeaheadClient}
                               multiple={false}
                               onChange={(selected) => {
                                   const value = selected.length > 0 ? selected[0].id : 0;
                                   setFieldValue('client_id', value);
                               }}
                               onInputChange={(text, event) => setFieldValue('client_id', text)}
                               onBlur={(e) => setFieldTouched('client_id', true)}
                               labelKey="raisonsocial"
                               options={client}
                               selected={client.slice(
                                client.findIndex((element) => element.id === values.client_id),
                                client.findIndex((element) => element.id === values.client_id) +
                                       1
                               )}
                          />
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Ajouter un nouveau client
                              </Tooltip>
                            }
                          >
                            <PlusCircle
                              color="#000"
                              onClick={() => setAddClient()}
                              size={35}
                            />
                          </OverlayTrigger>
                          
                        </InputGroup>{errors.client_id && <div id="feedback">{errors.client_id}</div>}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={4}>Agent réception: <Star color="red" size={10} /></Form.Label>
                        <Col sm={8}>
                          <Typeahead
                          id="agent_id"
                          ref={typeaheadAgent}
                          multiple={false}
                          onChange={(selected) => {
                              const value = selected.length > 0 ? selected[0].id : 0;
                              setFieldValue('agent_id', value);
                          }}
                          onInputChange={(text, event) => setFieldValue('agent_id', text)}
                          onBlur={(e) => setFieldTouched('agent_id', true)}
                          filterBy={['nom', 'prenoms']}
                          labelKey="prenoms"
                          options={agent}
                          selected={agent.slice(
                            agent.findIndex((element) => element.id === values.agent_id),
                           agent.findIndex((element) => element.id === values.agent_id) +
                                  1
                          )}
                          />
                         
                        </Col> {errors.agent_id && <div id="feedback">{errors.agent_id}</div>}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={4}>Conducteur: <Star color="red" size={10} /></Form.Label>
                        <Col sm={8}>
                          <Form.Control
                          id='client2'
                            type="text"
                            placeholder="Conducteur"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            // onChange={(e) => setClient2(e.target.value)}
                            value={values.client2}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          
                        </Col>{errors.client2 && <div id="feedback">{errors.client2}</div>}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={4}>Tel. conducteur: </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                          id='client2tel'
                            type="text"
                            placeholder="Contact"
                            aria-describedby="inputGroupPrepend"
                            // onChange={(e) => setClient2Tel(e.target.value)}
                            size="sm"
                            value={values.client2tel}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={4}>Date reception: <Star color="red" size={10} /></Form.Label>
                        <Col sm={8}>
                          <Form.Control
                          id='datesent'
                            type="date"
                            placeholder="Date entrée"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={values.datesent}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          
                        </Col>{errors.datesent && <div id="feedback">{errors.datesent}</div>}
                      </Form.Group>
                    </CardBody>
                  </Card>

                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        informations véhicule client
                      </CardSubtitle>
                      <Form.Group as={Col} md="12">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Vehicule:
                            <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Typeahead
                            id="vehicule_id"
                          ref={typeaheadVehi}
                          multiple={false}
                          onChange={(selected) => {
                              setFieldValue('vehicule_id', selected.length > 0 ? selected[0].id : 0);
                              setFieldValue('chassis', selected.length > 0 ? selected[0].chassis : '');
                              setFieldValue('famille', selected.length > 0 ? (selected[0].model ? selected[0].model.libellemodel : '') : '');
                              setFieldValue('marque', selected.length > 0 ? (selected[0].marque ? selected[0].marque.libellemarq : '') : '');
                              setFieldValue('date_mise_circul', selected.length > 0 ? dateformat(selected[0].date_mise_circul, 'yyyy-mm-dd') : '');
                              setFieldValue('energie', selected.length > 0 ? selected[0].energie : '');
                          }}
                          onInputChange={(text, event) => {
                            setFieldValue('vehicule_id', text);}}
                          onBlur={(e) => setFieldTouched('vehicule_id', true)}
                          labelKey='immatr_eng'
                          options={vehicule}
                          selected={vehicule.slice(
                            vehicule.findIndex((element) => element.id === values.vehicule_id),
                            vehicule.findIndex((element) => element.id === values.vehicule_id) +
                                  1
                          )}
                          />
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Ajouter un nouvel engin
                              </Tooltip>
                            }
                          >
                            <PlusCircle
                              color="#000"
                              onClick={() => setAddVehicules()}
                              size={35}
                            />
                          </OverlayTrigger>
                      
                        </InputGroup>    {errors.vehicule_id && <div id="feedback">{errors.vehicule_id}</div>}
                      </Form.Group>
                          <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={4}> Index compteur: <Star color="red" size={10} /> </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                          id='indexc'
                            type="number"
                            placeholder="Index compteur"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={values.indexc}
                            min={0}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                        {errors.indexc && <div id="feedback">{errors.indexc}</div>}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={4}>En circulation le: </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                          id='date_mise_circul'
                            type="date"
                            placeholder="Date de mise en circulation"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={values.date_mise_circul}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={4}>Marque: </Form.Label>
                        <Col sm={8}>
                          <Input id='marque' type="text" value={values.marque} disabled />
                         
                        </Col> {errors.marque && <div id="feedback">{errors.marque}</div>}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={4}>Model: </Form.Label>
                        <Col sm={8}>
                          <Input id='famille' type="text" value={values.famille} disabled />
                          
                        </Col>{errors.famille && <div id="feedback">{errors.famille}</div>}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={4}>Chassis: </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                          id='chassis'
                            type="text"
                            placeholder="Chassis"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            minLength={5}
                            value={values.chassis}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={3}>Energie: </Form.Label>
                        <Col sm={9}>
                          <ButtonGroup>
                            {[
                              { name: 'Essence', value: '0' },
                              { name: 'Gasoil', value: '1' },
                            ].map((radio, idx) => (
                              <ToggleButton
                                disabled
                                key={idx}
                                id='typeEnergs'
                                size="sm"
                                type="radio"
                                variant={
                                  idx % 2 ? 'outline-danger' : 'outline-danger'
                                }
                                value={values.typeEnergs}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                        </Col>
                      </Form.Group>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={8}>
                  <Card id="fixed-cards">
                    <CardHeader className="mb-2 text-muted" tag="h6">
                      informations supplémentaire
                    </CardHeader>
                    <CardBody>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>Objet visite: </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                          id='objet_visite'
                            as="textarea"
                            placeholder="Objet de la visite"
                            aria-describedby="inputGroupPrepend"
                            rows={4}
                            value={values.objet_visite}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={2}>Remarque sur le véhicule: </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                          id='poinchoc'
                            as="textarea"
                            placeholder="Remarques"
                            aria-describedby="inputGroupPrepend"
                            rows={5}
                            value={values.poinchoc}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                      </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="4">
                        <Form.Group as={Row} className="mb-3" >
                        <Col sm={12}>
                              <ButtonGroup>
                                {[
                                  { name: 'Révision', value: '0' },
                                  { name: 'Autres', value: '1' },
                                ].map((radio, idx) => (
                                  <label key={idx}>
                                  <Field type="radio" name='sirevision' value={radio.value} />
                                  {radio.name}
                                </label>
                                ))}
                              </ButtonGroup>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                        <Form.Group as={Row} className="mb-3" >
                        <Col sm={12}>
                        <ButtonGroup>
                                {[
                                  { name: 'Payant', value: '0' },
                                  { name: 'Gratuit', value: '1' },
                                ].map((radio, idx) => (
                                  
                                  <label key={idx}>
                                    <Field type="radio" name='sipayent' value={radio.value} />
                                    {radio.name}
                                  </label>
                                ))}
                              </ButtonGroup>
                           
                            </Col>
                          </Form.Group>
                          {values.sipayent === '1' ? (
                            <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={4}>Motif gratuit: </Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                id='motifgratuit'
                                  type="text"
                                  placeholder="Motif gratuit"
                                  aria-describedby="inputGroupPrepend"
                                  size="sm"
                                  value={values.motifgratuit}
                                  onBlur={handleBlur}
                                onChange={handleChange}
                                />
                              </Col>
                            </Form.Group>
                          ) : (
                              <></>
                            )}
                        </Col>
                        <Col md="4">
                        <label>
              <Field type="checkbox" name="sireprisetravaux"  />
              Reprise travaux
            </label>
                        </Col>
                        <Col md="12">
                        <Form.Group as={Row} className="mb-3" >
                        {/* <Form.Label column sm={4}>Niveau carburant: </Form.Label> */}
                        <Col sm={12}>
                        <Form.Label column>Niveau carburant: </Form.Label>
                              <ButtonGroup>
                                {[
                                  { value: '1/4' },
                                  { value: '1/3' },
                                  { value: '1/2' },
                                  { value: '1' },
                                ].map((radio, idx) => (
                                  <label key={idx}>
                                  <Field type="radio" name='carburant' value={radio.value} />
                                  {radio.value}
                                </label>
                                  
                                ))}
                              </ButtonGroup>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        Eléments du véhicule
                      </CardSubtitle>
                      <Row>
                        {[
                          'carte_grise',
                          'visite_technique',
                          'assurance',
                          'patente',
                          'cric',
                          'manivelle',
                          'cd',
                          'p_audio',
                          'radio',
                          'extincteur',
                          'cle_rou',
                          'enjoliveurs',
                          'roue_secours',
                          'tapis_sol',
                          'axe_remorq',
                          'carte_transport',
                          'carte_station',
                          'vignette',
                        ].map((type, index) => (
                          <Col md={3} key={index}>
                                    <label>
              <Field type="checkbox" name="type" value={type} />
              {type.replace('_', ' ')}
            </label>
                          </Col>
                        ))}
                      </Row>
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        INETERVENANTS SUR LES TRAVAUX
                      </CardSubtitle>
                      <Table columns={columnIntervenant} data={intervenant} />
                    </CardBody>
                  </Card>
                  {
                    id ?  <Card>
                    <CardBody>
                      <CardHeader>MOTIF DE RETARD DE LIVRAISON</CardHeader>
                      <Table columns={columns} data={motifretard} />
                    </CardBody>
                  </Card>
                  :
                  <></>
                  }
                 
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup aria-label="Basic example" size="sm">
              <Button color="secondary" onClick={handleClose}>
                <BackspaceFill color="red" size={20} />
              </Button>
              <Button type="submit" color="default">
                <Save2Fill color="#000" size={20} /> Enregister
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Form>
      </Modal>
                )}
                </Formik>
    </>
  );
}

export default AddReceptions;
