import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  InputGroup,
  Modal,
  Tooltip,
  Spinner,
  ButtonGroup,
  OverlayTrigger,
  Card,
  Alert,
} from 'react-bootstrap';
import { Button, Row, Col, CardBody, CardSubtitle,Input } from 'reactstrap';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  Save2Fill,
  BackspaceFill,
  PlusCircle,
  Star,
  TriangleHalf,
} from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import common from '../../commonData';
import dateformat from 'dateformat';
import AddPrestation from '../prestations/addPrestation';
import { ProductList, Summary } from './card';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';

var count = 0;
var sidelete=0;
function AddFacture({
  ids,
  editData,
  setAdds,
  show,
  setIsSave,
  setMsg,
  setMsgravity,
  getFacture,
  types
}) {
 console.log(editData);
  var ladate = new Date();
  const [client, setClient] = useState([]);
  const [marque, setMarque] = useState([]);
  const [prestation, setDataPrestation] = useState([]);
  const [prestation_id, setPrestid] = useState([]);
  const [prestationselect, setPrestidSelect] = useState(editData.ligne || []);
  const [reception, setRecept] = useState([]);
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const typeaheadVehi = useRef(null);
  const typeaheadClient = useRef(null);
  const typeaheadModel = useRef(null);
  const typeaheadMarq = useRef(null);
  const [showAdd, setShowAddprest] = useState(false);
  const [famille, setfamilleData] = useState([]);
  const [familles, setfamilleDatas] = useState([]);
  const [states, setState] = useState({
    type: types === 'Facture' ? 'Facture' : 'Proforma',
    nobon: types === 'Facture'
    ? editData.nofact || ''
    : editData.noprof || '',
    datet: types === 'Facture'
    ? dateformat(editData.datefact, 'yyyy-mm-dd') || dateformat(
      ladate.getMonth() +
        1 +
        '/' +
        ladate.getDate() +
        '/' +
        ladate.getFullYear(),
      'yyyy-mm-dd'
    )
    : dateformat(editData.dateprof, 'yyyy-mm-dd') || dateformat(
      ladate.getMonth() +
        1 +
        '/' +
        ladate.getDate() +
        '/' +
        ladate.getFullYear(),
      'yyyy-mm-dd'
    ),
    dateecheance : editData.date_echeance ? dateformat(editData.date_echeance, 'yyyy-mm-dd') : '',
    remise: editData.remise || 0,
    montptifourn: editData.petitfourn || 0,
    ptifour: editData.sifournitur || '1',
    marque_id: editData.marque_id || 0,
    clientid:  types === 'Facture'
    ? editData.clientf_id || 0
    : editData.client_id,
    sitva: editData.sitva || '0',
    reception_id: editData.reception_id || 0,
    immatriculation: editData.immatr_eng || '',
    entreprise: editData.entreprise_id ? ''+editData.entreprise_id+'' : '1',
    arrondi: editData.arrondi || '0',
    chassis: editData.chassis || '',
    assure: editData.assure || '',
    nosinistre: editData.nosinistre || '',
    bondecommande: editData.bondecommande || '',
    autoentre: editData.autoentre || '',
    nomexpert: editData.nomexpert || '',
    famille_id: editData.model_eng ? parseInt(editData.model_eng) : 0 || 0,
    commentaire: editData.commentaire || ''
});

const { 
  type,nobon,datet,dateecheance, remise, montptifourn, ptifour, clientid, marque_id, sitva,
  reception_id, immatriculation,entreprise,arrondi,chassis,assure,
  nosinistre,bondecommande,autoentre,nomexpert,famille_id,commentaire 
} = states;


  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/login');
    }
    (async function anyNameFunction() {
      setLoad(true);
      await Axios.get(common.ipapi + '/api/receptionfact/adddata')
        .then((responses) => {
          setClient(responses.data[2]);
          setMarque(responses.data[0]);
          setfamilleData(responses.data[1]);
          setfamilleDatas(responses.data[1]);
          setRecept(responses.data[3]);
          setDataPrestation(responses.data[4]);
         // setState(responses.data[4]);
          setLoad(false);
        })
        .catch((errors) => {
          setLoad(false);
        });
    })();
  }, [history]);
  const handleClose = () => {
    if (count !== 0) {
      getFacture();
      count=0;
    }
    setAdds(false);
    setIsSave(false);
  };

  const subTotal = prestationselect;
  const onChangeProductQuantity = (index, event) => {
    if (types === 'Facture') {
      return;
    }
    const value = event.target.value;
    const cloneProducts = [...prestationselect];
    cloneProducts[index].qte = value;
    cloneProducts[index].simodif = true;
    setPrestidSelect(cloneProducts);
  };

  const onChangePrestation = (index, event) => {
    if (types === 'Facture') {
      return;
    }
    const value = event.target.value;
    const cloneProducts = [...prestationselect];
    cloneProducts[index].libprestation = value;
    cloneProducts[index].simodif = true;
    setPrestidSelect(cloneProducts);
  };

  const onChangeRemise = (index, event) => {
    if (types === 'Facture') {
      return;
    }
    const valueInt = event.target.value;
    const cloneProducts = [...prestationselect];
    cloneProducts[index].remise = valueInt;
    cloneProducts[index].simodif = true;
    setPrestidSelect(cloneProducts);
  };

  const onChangePrix = (index, event) => {
    if (types === 'Facture') {
      return;
    }
    const valueInt = parseInt(event.target.value);
    const cloneProducts = [...prestationselect];
    cloneProducts[index].prixprestation = valueInt;
    setPrestidSelect(cloneProducts);
  };
  const onChangeVetuste = (index, event) => {
    const valueInt = event.target.value;
    const cloneProducts = [...prestationselect];
    cloneProducts[index].vetuste = valueInt;
    cloneProducts[index].simodif = true;
    setPrestidSelect(cloneProducts);
  };

  const onRemoveProduct = (i, id, idprestation) => {
    if (types === 'Facture') {
      return;
    }
    if (idprestation != undefined) {
      if (window.confirm('Voulez-vous supprimer cette ligne ? ')) {
      Axios.delete(
        common.ipapi + '/api/receptionfact/destroylineprest/' + id
      ).then((response) => {
        if (response.data.status === 1) {
          sidelete = 1;
          const filteredProduct = prestationselect.filter((product, index) => {
            return index != i;
          });
          setMsgravity('info');
          setMsg('Ligne supprimée');
          setIsSave(true);
          setLoad(false);
          setPrestidSelect(filteredProduct);
        } else {
          setMsg(response.data.msg);
          setIsSave(true);
          setLoad(false);
        }
      });
    }
    } else {
    const filteredProduct = prestationselect.filter((product, index) => {
      return index !== i;
    });
    setPrestidSelect(filteredProduct);
    setMsg('Ligne supprimée');
    setMsgravity('info');
    setIsSave(true);
    setLoad(false);
  }
  };

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }
  function saveFacture(values) {
    setLoad(true);
    if (prestationselect.length == 0) {
      setMsg('Veuillez saisir au moin une prestation');
      setMsgravity('danger');
      setIsSave(true);
      setLoad(false);
      return;
    }
    let tab = prestationselect;
    tab.sort(GetSortOrder('ordre'));
    var formData = new FormData();
    if(ids){
      formData.append('echeance',values.dateecheance);
    }
    formData.append('date', values.datet);
    formData.append('type', types);
    formData.append('numero', values.nobon);
    formData.append('lignedata', types !== 'Facture' ? JSON.stringify(tab) : JSON.stringify([]));
    formData.append('user_id', common.userid);
    formData.append('chassis', values.chassis);
    formData.append('immatr_eng', values.immatriculation);
    formData.append('client_id', values.clientid);
    formData.append('sitva', values.sitva);
    formData.append('model_eng', values.famille_id);
    formData.append('reception_id', values.reception_id);
    formData.append('marque_id', values.marque_id);
    formData.append('remise', remise);
    formData.append('assure', values.assure);
    formData.append('nosinistre', values.nosinistre);
    formData.append('bondecommande', values.bondecommande);
    formData.append('nomexpert', values.nomexpert);
    formData.append('commentaire', values.commentaire);
    formData.append('autoentre', values.autoentre);
    formData.append('arrondi', values.arrondi);
    formData.append('sifournitur', values.ptifour);
    formData.append('entrepriseid', parseInt(values.entreprise));
    formData.append('montptifourn', values.montptifourn);
  
    Axios({
      url: ids ? common.ipapi + '/api/receptionfact/update/' + ids : common.ipapi + '/api/receptionfact/store',
      method: 'POST',
      data: formData,
    })
      .then((res) => {
        if (res.data.status === 1) {
          count++;
          setPrestidSelect([]);
          setfamilleData(famille);
          setState({
            type:'Facture',
            nobon: '',
            datet: dateformat(
              ladate.getMonth() +
                1 +
                '/' +
                ladate.getDate() +
                '/' +
                ladate.getFullYear(),
              'yyyy-mm-dd'
            ),
            remise: 0,
            montptifourn: 0,
            ptifour: '1',
            marque_id: 0,
            clientid: 0,
            sitva: '0',
            reception_id: 0,
            immatriculation: '',
            entreprise: '1',
            arrondi: '0',
            chassis: '',
            assure: '',
            nosinistre: '',
            bondecommande: '',
            autoentre: '',
            nomexpert: '',
            famille_id: 0,
            commentaire: ''
        });
          typeaheadClient.current.clear();
          typeaheadModel.current.clear();
          typeaheadMarq.current.clear();
          typeaheadVehi.current.clear();
          setMsg(res.data.msg);
          setMsgravity('info');
          setIsSave(true);
          setLoad(false);
          if(ids){
            setTimeout(handleClose, 600);
          }
        } else {
          setMsg(
            "Une erreur c'est produite veuillez verifier les données saisies"
          );
          setMsgravity('danger');
          setIsSave(true);
          setLoad(false);
        }
      })
      .catch((error) => {
        setMsg('error');
        setIsSave(true);
        setMsgravity('danger');
        setLoad(false);
      });
  }


  function setPrestids(prestation_id) {
    setPrestid(prestation_id);
    if (prestation_id.length > 0) {
      prestation_id[prestation_id.length - 1].qte = 1;
      prestation_id[prestation_id.length - 1].tva = 1;
      let t = [];
      let d = {
        ordre: prestationselect.length,
        id: prestation_id[0].id,
        libprestation: prestation_id[0].libprestation,
        qte: 1,
        tva: 1,
        prixprestation: prestation_id[0].prixprestation,
        categorie: prestation_id[0].categorie,
        remise: 0,
        vetuste: 0,
        ht: prestation_id[0].prixprestation,
        idprof: 0,
        simodif: true,

      };
      t.push(d);
      t = t.concat(prestationselect);
      setPrestidSelect(t);
      setPrestid([]);
    }
  }

  function setAddPrestation() {
    setShowAddprest(true);
  }



  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      {showAdd ? (
        <AddPrestation
          setShowAddprest={setShowAddprest}
          show={showAdd}
          setIsSave={setIsSave}
          setDataPrestation={setDataPrestation}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
        />
      ) : (
        <></>
      )}
      <Formik
                initialValues={{
                  nobon: nobon,
                  datet: datet,
                  dateecheance: dateecheance,
                  montptifourn: montptifourn,
                  ptifour: ptifour,
                  marque_id: marque_id,
                  clientid: clientid,
                  sitva: sitva,
                  reception_id: reception_id,
                  immatriculation: immatriculation,
                  entreprise: entreprise,
                  arrondi:arrondi,
                  chassis:chassis,
                  assure:assure,
                  nosinistre:nosinistre,
                  bondecommande: bondecommande,
                  autoentre:autoentre,
                  nomexpert:nomexpert,
                  famille_id: famille_id,
                  commentaire:commentaire
              
                }}
                 enableReinitialize
                validationSchema={Yup.object().shape({
                    immatriculation: Yup.string().required('Veuillez saisir cette donnée'),
                    datet: Yup.date().required('Veuillez saisir cette donnée'),
                    clientid: Yup.number().typeError('Aucune correspondance').required('Veuillez saisir cette donnée'),
                    famille_id: Yup.number().typeError('Aucune correspondance').required('Veuillez saisir cette donnée'),
                    marque_id: Yup.number().typeError('Aucune correspondance').required('Veuillez saisir cette donnée')
                })}
                onSubmit={async (values) => {
                  saveFacture(values);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, values, setFieldValue, setFieldTouched }) => (
            
      <Modal show={show} fullscreen={true} onHide={() => setAdds(false)}>
        <Modal.Header> {ids ? 'MODIFIER LE DOCUMENT' : 'EDITER UNE PROFORMAS / FACTURES'}
        {sidelete ? <></> : <BackspaceFill onClick={handleClose} color="red" size={30} />}
        </Modal.Header>
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Body>
          {sidelete ? (
              <Alert key="danger" transition="Fade" variant="warning">
                <TriangleHalf color="red" size={30} />
                <b>Veuillez enregister avant de quitter cette fenêtre</b>
              </Alert>
            ) : (
              <></>
            )}
            <div id="lightbody">
              <Row>
                <Col md={3}>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        Entête
                      </CardSubtitle>
                       <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={5}>N° facture:</Form.Label>
                            <Col sm={7}>
                          <Form.Control
                            id='nobon'
                            type="text"
                            placeholder="N° de pièce (pas obligatoire)"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={values.nobon}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={5}>Date édition: <Star color="red" size={10} /></Form.Label>
                            <Col sm={7}>
                          <Form.Control
                            type="date"
                            placeholder="Date édition"
                            aria-describedby="inputGroupPrepend"
                            id='datet'
                            size="sm"
                            value={values.datet}
                            onBlur={handleBlur}
                              onChange={handleChange}
                          />
                          
                        </Col>{errors.datet && <div id="feedback">{errors.datet}</div>}
                      </Form.Group>
                     {
                      types === 'Facture'? (
                        <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={5}>Date echéance: </Form.Label>
                        <Col sm={7}>
                      <Form.Control
                        type="date"
                        placeholder="Date echénace reglement"
                        aria-describedby="inputGroupPrepend"
                        id='dateecheance'
                        size="sm"
                        value={values.dateecheance}
                        onBlur={handleBlur}
                          onChange={handleChange}
                      />
                      
                    </Col>
                  </Form.Group>
                      )
                      : 
                      <></>
                     }
                 
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        information reception véhicule
                      </CardSubtitle>
                      <Form.Group as={Row} className="mb-3" >
                          <Typeahead
                            id="reception_id"
                            ref={typeaheadVehi}
                            labelKey={(option) =>
                              `N°${option.nofiche} / ${
                                option.vehicule.immatr_eng
                              } / ${dateformat(
                                option.date_entre_garage,
                                'dd-mm-yyyy'
                              )} / ${option.vehicule.chassis}`
                            }
                            filterBy={[
                              'nofiche',
                              'chassis',
                              'immatr_eng',
                              'date_entre_garage',
                            ]}
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected[0].id : 0;
                              setFieldValue('reception_id', value);
                              setFieldValue('famille_id', selected.length > 0 ? (selected[0].vehicule ? selected[0].vehicule.model_eng : 0) : 0);
                              setFieldValue('chassis', selected.length > 0 ?  (selected[0].vehicule ? selected[0].vehicule.chassis : 0) : 0);
                              setFieldValue('marque_id', selected.length > 0 ?  (selected[0].vehicule ? selected[0].vehicule.marque_id : 0) : 0);
                              setFieldValue('immatriculation', selected.length > 0 ? selected[0].vehicule.immatr_eng : '');
                              setFieldValue('clientid', selected.length > 0 ? selected[0].client.id : 0);
                          }}
                          
                          onInputChange={(text, event) => setFieldValue('reception_id', text)}
                          onBlur={(e) => setFieldTouched('reception_id', true)}
                          selected={reception.slice(
                            reception.findIndex((element) => element.id === values.reception_id),
                            reception.findIndex((element) => element.id === values.reception_id) +
                                   1
                           )}
                            options={reception}
                            placeholder="Sélectionner une reception de vehicule"
                            // onChange={(cli) => setRecepIds(cli)}
                          />
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                        {/* <InputGroup hasValidation> */}
                        <Form.Label column sm={3}>Client: <Star color="red" size={10} /></Form.Label>
                            <Col sm={9}>
                          <Typeahead
                               id="clientid"
                               ref={typeaheadClient}
                               multiple={false}
                               onChange={(selected) => {
                                   const value = selected.length > 0 ? selected[0].id : 0;
                                   setFieldValue('clientid', value);
                                   setFieldValue('sitva', selected.length > 0 ? selected[0].sitva : 0);
                               }}
                               
                               onInputChange={(text, event) => setFieldValue('clientid', text)}
                               onBlur={(e) => setFieldTouched('clientid', true)}
                               labelKey="raisonsocial"
                               options={client}
                               selected={client.slice(
                                client.findIndex((element) => element.id === values.clientid),
                                client.findIndex((element) => element.id === values.clientid) +
                                       1
                               )}
                          />
                          
                          
                        </Col>
                        {errors.clientid && <div id="feedback">{errors.clientid}</div>}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={6}>Immatriculation.: <Star color="red" size={10} /></Form.Label>
                            <Col sm={6}>
                          <Form.Control
                            id='immatriculation'
                            type="text"
                            placeholder=" immatriculation"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={values.immatriculation}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          
                        </Col>
                        {errors.immatriculation && <div id="feedback">{errors.immatriculation}</div>}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={4}>Chassis: </Form.Label>
                            <Col sm={8}>
                          <Form.Control
                          id='chassis'
                            type="text"
                            placeholder="Chassis"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={values.chassis}
                            onBlur={handleBlur}
                              onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={4}>Marques: <Star color="red" size={10} /></Form.Label>
                            <Col sm={8}>
                          <Typeahead
                            id="marque_id"
                            labelKey={(option) => `${option.libellemarq}`}
                            ref={typeaheadMarq}
                            filterBy={['libellemarq']}
                            options={marque}
                            selected={marque.slice(
                              marque.findIndex((element) => element.id === values.marque_id),
                              marque.findIndex((element) => element.id === values.marque_id) + 1
                            )}
                            
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected[0].id : 0;
                              setFieldValue('marque_id', value);
                              setState({'famille_id': 0});
                              if (selected.length > 0) {
                                setfamilleData(
                                  famille.filter((element) => element.marque_id == selected[0].id)
                                );
                              } else {
                                setfamilleData(familles);
                                setState({'famille_id': 0});
                              }
                          }}
                          
                          onInputChange={(text, event) => setFieldValue('marque_id', text)}
                          onBlur={(e) => setFieldTouched('marque_id', true)}
                            // onChange={(cli) => setMarqueIdF(cli)}
                          />
                         
                        </Col>{errors.marque_id && <div id="feedback">{errors.marque_id}</div>}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={4}>Models: <Star color="red" size={10} /></Form.Label>
                            <Col sm={8}>
                          <Typeahead
                            ref={typeaheadModel}
                            id="famille_id"
                            labelKey={(option) => `${option.libellemodel}`}
                            filterBy={['libellemodel']}
                            value={famille_id}
                            selected={famille.slice(
                              famille.findIndex((element) => element.id === values.famille_id),
                              famille.findIndex((element) => element.id === values.famille_id) + 1
                            )}
                            options={famille}
                            onChange={(selected) => {
                              const value = selected.length > 0 ? selected[0].id : 0;
                              setFieldValue('famille_id', value);
                          }}
                          
                          onInputChange={(text, event) => setFieldValue('famille_id', text)}
                          onBlur={(e) => setFieldTouched('famille_id', true)}
                            required
                          />
                          
                        </Col>{errors.famille_id && <div id="feedback">{errors.famille_id}</div>}
                      </Form.Group>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={9}>
                  <Card>
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        Options
                      </CardSubtitle>
                      <Row>
                        <Col md="3">
                        <Form.Label column sm={12}>Choix entreprise</Form.Label>
                        <Input
                                            id="entreprise"
                                            type="select"
                                            size="sm"
                                            value={values.entreprise}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            // onChange={(e) => setSisociete(e.target.value)}
                                        >{[
                                          { name: 'HR MOTORS', value: '1' },
                                          { name: 'CLUBAUTO', value: '2' },
                                          { name: 'UNITEDMOTORS', value: '3' },
                                    ].map((value, idx) => (
                                            <option value={value.value}>{value.name}</option>
                                        ))}
                          </Input>
                          
                    
                        </Col>
                        <Col md="2">
                        <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={12}>Petite fourniture</Form.Label>
                        <Col sm={12}>
                        <ButtonGroup>
                                {[
                                    { name: 'Non', value: '0' },
                                    { name: 'Oui', value: '1' },
                                    { name: 'fixe', value: '2' },
                                ].map((radio, idx) => (
                                  
                                  <label key={idx}>
                                    <Field type="radio" name='ptifour' value={radio.value} />
                                    {radio.name}
                                  </label>
                                ))}
                              </ButtonGroup>
                           
                            </Col>
                          </Form.Group>
                        </Col>

                        <Col md="3">
                        <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={12}>Type arrondi</Form.Label>
                        <Col sm={12}>
                        <ButtonGroup>
                                {[
                                      { name: 'Auto.', value: '0' },
                                      { name: 'Inf.', value: '1' },
                                      { name: 'Sup.', value: '2' },
                                ].map((radio, idx) => (
                                  
                                  <label key={idx}>
                                    <Field type="radio" name='arrondi' value={radio.value} />
                                    {radio.name}
                                  </label>
                                ))}
                              </ButtonGroup>
                           
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                        <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={12}>Voulez-vous la TVA?</Form.Label>
                        <Col sm={12}>
                        <ButtonGroup>
                                {[
                                     { name: 'TVA-oui', value: '0' },
                                     { name: 'TVA-non', value: '1' },
                                ].map((radio, idx) => (
                                  
                                  <label key={idx}>
                                    <Field type="radio" name='sitva' value={radio.value} />
                                    {radio.name}
                                  </label>
                                ))}
                              </ButtonGroup>
                           
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm='7'>
                        {values.ptifour == 2 ? (
                        <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={4}>Montant petite fourniture: </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                              id='montptifourn'
                              type="number"
                              placeholder="Montant petite fourniture"
                              aria-describedby="inputGroupPrepend"
                              size="sm"
                              min={0}
                              value={values.montptifourn}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Col>
                        </Form.Group>
                      ) : (
                        <></>
                      )}
                        </Col>


                      </Row>
                     
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        information complémentaire
                      </CardSubtitle>
                      <Row>

                        <Col md="4">
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={5}>Bon commande: </Form.Label>
                            <Col sm={7}>
                          <Form.Control
                          id='bondecommande'
                            type="text"
                            placeholder="Bon de commande"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={values.bondecommande}
                            onBlur={handleBlur}
                              onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                        </Col>
                        <Col md="4">
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={6}>Autorisation d'entrée: </Form.Label>
                            <Col sm={6}>
                              <Form.Control
                              id='autoentre'
                                type="text"
                                placeholder="Autorisation d'entrée"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={values.autoentre}
                                onBlur={handleBlur}
                              onChange={handleChange}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={5}>Autorisation sortie: </Form.Label>
                            <Col sm={7}>
                              <Form.Control
                              id='nomexpert'
                                type="text"
                                placeholder="Autorisation sortie"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={values.nomexpert}
                                onBlur={handleBlur}
                              onChange={handleChange}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={5}>Numéro de sinitre: </Form.Label>
                            <Col sm={7}>
                              <Form.Control
                              id='nosinistre'
                                type="text"
                                placeholder="Numéro de sinitre"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={values.nosinistre}
                                onBlur={handleBlur}
                              onChange={handleChange}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="8">
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={3}>Nom de l'assuré: </Form.Label>
                            <Col sm={9}>
                              <Form.Control
                              id='assure'
                                type="text"
                                placeholder="Nom de l'assuré"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={values.assure}
                                onBlur={handleBlur}
                              onChange={handleChange}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={2}>Commentaire:</Form.Label>
                            <Col sm={10}>
                            <Form.Control
                            id='commentaire'
                              as="textarea"
                              aria-label="With textarea"
                              value={values.commentaire}
                              rows="1"
                              size="sm"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        LISTE DES PRESTATIONS / PIECES A SELECTIONNEES
                      </CardSubtitle>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup hasValidation>
                          <Typeahead
                            id="my-typeahead-id"
                            labelKey={(option) => `${option.libprestation}`}
                            filterBy={['codeprestation', 'libprestation']}
                            placeholder="Veuilez sélectionner vos différentes prestations"
                            selected={prestation_id}
                            options={prestation}
                            onChange={(cli) => setPrestids(cli)}
                          />
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Ajouter au dictionnaire
                              </Tooltip>
                            }
                          >
                            <PlusCircle
                              color="#51cbce"
                              onClick={() => setAddPrestation()}
                              size={35}
                            />
                          </OverlayTrigger>
                        </InputGroup>
                      </Form.Group>
                      <ProductList
                        products={prestationselect}
                        onChangeProductQuantity={onChangeProductQuantity}
                        onRemoveProduct={onRemoveProduct}
                        onChangePrestation={onChangePrestation}
                        onChangeRemise={onChangeRemise}
                        onChangePrix={onChangePrix}
                        onChangeVetuste={onChangeVetuste}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Summary
              subTotal={subTotal}
              ptifour={parseInt(values.ptifour)}
              montptifourn={parseInt(values.montptifourn)}
              sitva={values.sitva}
              arrondi={values.arrondi}
            />
            <div className="ml-auto">
              <ButtonGroup size="sm">
              {sidelete ? <></> : 
                <Button color="secondary" onClick={handleClose}>
                  <BackspaceFill color="red" size={20} />
                </Button>}
                <Button type="submit" color="default">
                  {load ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <Save2Fill color="#ff7300" size={20} />
                  )}{' '}
                  Enregister
                </Button>
              </ButtonGroup>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
                )}
                </Formik>
    </>
  );
}

export default AddFacture;
