import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ButtonGroup,
  Container,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  Trash,
  PencilFill,
  FileExcel,
  PlusCircleDotted,
  Printer,
} from 'react-bootstrap-icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

import XLSX from 'sheetjs-style';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import 'assets/css/tableGar.css';
import dateformat from 'dateformat';
import common from '../../commonData';

function MyAwesomeTable({
  rows,
  isLoading,
  onDelete,
  setIds,
  title,
  setAddsClient,
}) {
  const [gridRef, setGridRef] = useState(null);

  const filterValue = [
    { name: 'raisonsocial', operator: 'contains', type: 'string', value: '' },
    { name: 'codecli', operator: 'contains', type: 'string', value: '' },
    {
      name: 'telcli',
      operator: 'contains',
      type: 'string',
      value: '',
    },
    { name: 'nocompte', operator: 'contains', type: 'string', value: '' },
    { name: 'email', operator: 'contains', type: 'string', value: '' },
    { name: 'bp', operator: 'contains', type: 'string', value: '' },
  ];

  function numberformat(data) {
    return new Intl.NumberFormat().format(data);
  }

  const columns = [
    {
      name: 'id',
      header: 'Id',
      defaultVisible: false,
      defaultWidth: 80,
      type: 'number',
    },
    {
      header: 'Action',
      defaultWidth: 100,
      type: 'string',
      editable: false,
      render: ({ value, data }) => (
        <div className="menudrops">
          <ButtonGroup aria-label="Basic example">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Modifier la ligne</Tooltip>
              }
            >
              <PencilFill
                id="actionbtn"
                onClick={() => setIds(data.id)}
                size={20}
                color="green"
              />
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Supprimer la ligne</Tooltip>
              }
            >
              <Trash
                id="actionbtn"
                onClick={() => onDelete(data.id)}
                color="red"
                size={20}
              />
            </OverlayTrigger>
          </ButtonGroup>
        </div>
      ),
    },
    {
      name: 'codecli',
      header: 'Code',
      defaultWidth: 150,
      type: 'string',
      textAlign: 'center',
      render: ({ data }) => (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip-disabled">
              Cliquer pour plus de détails
            </Tooltip>
          }
        >
          {
            <Link to={`/prestadmin/client/${data.id}/content`}>
              <p id="light">{data.codecli}</p>
            </Link>
          }
        </OverlayTrigger>
      ),
    },
    {
      name: 'raisonsocial',
      header: 'Dénommination',
      defaultWidth: 200,
      type: 'string',
    },
    {
      name: 'sisociete',
      header: 'Type client',
      defaultWidth: 120,
      type: 'string',
    },
    { name: 'telcli', header: 'Contacts', defaultWidth: 120, type: 'string' },
    {
      name: 'nocompte',
      header: 'N° compte',
      defaultWidth: 120,
      textAlign: 'start',
      type: 'string',
    },
    {
      name: 'email',
      header: 'Email',
      defaultWidth: 70,
      textAlign: 'start',
      type: 'string',
    },
    {
      name: 'typereglement',
      header: 'Type règlement',
      defaultWidth: 70,
      textAlign: 'start',
      type: 'number',
    },
    {
      name: 'bp',
      header: 'Boite P.',
      textAlign: 'center',
      defaultWidth: 80,
      type: 'number',
    },
    {
      name: 'remise',
      header: 'Remise (%)',
      textAlign: 'center',
      defaultWidth: 50,
      type: 'number',
      render: ({ data }) => (data.remise ? data.remise.pourcentage : 0),
    },
    {
      name: 'username',
      header: 'Crée par',
      defaultWidth: 120,
      type: 'string',
      render: ({ data }) => (data.user ? data.user.name : 'Neant'),
    },
    {
      name: 'created_at',
      header: 'Crée le',
      defaultWidth: 130,
      type: 'string',
      render: ({ data }) =>
        data.created_at
          ? dateformat(data.created_at, 'dd-mm-yyyy HH:MM')
          : 'néant',
    },
  ];
  const gridStyle = { minHeight: window.screen.height - 200 };

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }
  async function printListe() {
    if (gridRef.current.data.length <= 0) {
      return;
    }
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    });
    var width = doc.internal.pageSize.getWidth();
    const data = [];
    let mont = 0;
    let qte = 0;
    doc.addImage(common.logo, 'jpg', 14, 10, 30, 30);
    doc.setFontSize(16);
    doc.text('HR MOTORS GARAGE', 90, 14);
    doc.setFontSize(12);
    doc.setFillColor(0, 0, 0);
    doc.rect(65, 15, width - 80, 10, 'F');
    doc.setTextColor(255, 255, 255);
    doc.text('LISTE DES CLIENTS', 90, 22);
    doc.setTextColor(0, 0, 0);

    gridRef.current.data.forEach((row) => {
      data.push([
        row.codecli,
        row.raisonsocial,
        row.telcli,
        row.sisociete,
        row.bp,
        row.sitva,
        row.remise ? row.remise.pourcentage : 0,
        row.typereglement,
      ]);
    });
    doc.autoTable({
      styles: {
        fillColor: [0, 0, 0],
        textColor: [255, 255, 255],
        halign: 'center',
        fontSize: 10,
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        1: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        2: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        3: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        4: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        5: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        6: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        7: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
      },
      theme: 'grid',
      startY: 35,
      fontSize: 6,
      head: [
        [
          'Code',
          'Noms',
          'Contacts',
          'Type',
          'Boite postal',
          'Si exoneré',
          'Remise %',
          'Type règlement',
        ],
      ],
      body: data,
    });
    const pageCount = doc.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        'Page ' + String(i) + ' sur ' + String(pageCount),
        210 - 55,
        200,
        null,
        null,
        'right'
      );
    }
    doc.save('Listeclient.pdf');
  }

  //--------------- EXPORT VERS EXCEL ------------------

  const exportToExcel = async () => {
    const raw_data = gridRef.current.data;
    /* flatten objects */
    const rows = raw_data.map((row, index) => ({
      code: row.codecli,
      client: row.raisonsocial,
      telcli: row.telcli,
      sisociete: row.sisociete,
      bp: row.bp,
      sitva: row.sitva,
      remise: row.remise ? row.remise.pourcentage : 0,
      typereglement: row.typereglement,
    }));

    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes clients');
    /* fix headers */
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          'Code',
          'Noms',
          'Contacts',
          'Type',
          'Boite postal',
          'Si exoneré',
          'Remise %',
          'Type règlement',
        ],
      ],
      { origin: 'A1' }
    );
    /* calculate column width */
    const max_width = rows.reduce((w, r) => Math.max(w, r.code.length), 10);
    worksheet['!cols'] = [{ wch: max_width }];
    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, 'clients.xlsx');
  };

  async function saveAsExcel() {
    exportToExcel();
  }

  return (
    <div>
      <Navbar expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand href="#/admin/articles">{title}</Navbar.Brand>
          <Nav className="me-auto">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-disabled">
                  Ajouter un nouveau client
                </Tooltip>
              }
            >
              <Nav.Link
                onClick={() => setAddsClient(true)}
                title="Ajouter un nouveau client"
              >
                <PlusCircleDotted color="green" size={20} /> Ajouter client
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>
              }
            >
              <Nav.Link
                title="Exporter vers excel"
                onClick={() => saveAsExcel()}
              >
                <FileExcel color="#0080ff" size={20} /> Excel
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>
              }
            >
              <Nav.Link onClick={() => printListe()} title="Exporter vers pdf">
                <Printer color="#0080ff" size={20} /> Imprimer
              </Nav.Link>
            </OverlayTrigger>
          </Nav>
        </Container>
      </Navbar>
      <ReactDataGrid
        idProperty="id"
        handle={setGridRef}
        style={gridStyle}
        loading={isLoading}
        reorderColumns={false}
        pagination
        pageSizes={[200, 400, 600, 800, rows.length]}
        defaultLimit={400}
        showHoverRows={true}
        editable={true}
        showColumnMenuTool={false}
        defaultFilterValue={filterValue}
        columns={columns}
        dataSource={rows}
      />
    </div>
  );
}

export default MyAwesomeTable;
