import React,{useEffect,useState} from 'react'
import {useHistory,useParams} from 'react-router-dom'
import Axios from 'axios';
import dateformat from 'dateformat';
import {Spinner,ButtonGroup,Modal, Dropdown, Toast } from "react-bootstrap";
import { Button, Card,CardBody,Form, Row, Col} from "reactstrap";
import { PencilSquare, Printer, Trash,FileExcel, Bullseye, List, Eye, Pencil } from 'react-bootstrap-icons';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import commondata from '../../commonData'
import {MyAwesomeTable} from '../../tableDataGar'
import EditReception from '../receptions/editLight';
import ViewLight from '../receptions/viewLight';
import AddReception from '../receptions/addLight';
import AddBonSortie from '../receptions/addbonsortieLight';
import EditBonSortie from '../receptions/editbonsortieLight';

function ListeMarqueReception() {
  const history = useHistory();
  const [datas,setData]=useState([])
  const [dataselect,setDataSelect]=useState([])
  const [load,setLoad]=useState(true)
  const [add,setAdd] = useState(false)
  const [showAdd,setShowAdd] = useState(false)
  const [showEdit,setShowEdit] = useState(false)
  const [showView,setShowView] = useState(false)
  const [showBon,setShowBon] = useState(false)
  const [showBonEdit,setShowEditBon] = useState(false)
  const [id,setId]=useState(0)
  const [idrecep,setIdRecep]=useState(0)
  const [isSave,setIsSave]=useState(false)
  const [msgGravity,setMsgravity]=useState("success")
  const [msg,setMsg]=useState("")

  let { ids } = useParams();

  useEffect( ()=>{
   if (localStorage.getItem("user-info")) {
        (async function anyNameFunction() {
          await Axios.get(commondata.ipapi+'/api/marque/receptionbymarque/'+ids)
          .then(response=>{
            setData(response.data.data)
            setLoad(false)
          }).catch(errors => {
            setLoad(false)
          })
        })();
    }else{
      history.push("/login")
    }
  },[history])

  function  onDelete (id) {
    if(window.confirm("Voulez-vous supprimer cette ligne ?")){
      
      Axios.delete(commondata.ipapi+'/api/reception/destroy/'+id)
      .then(response=>{
        if(response.data.status==1){
            const del = datas.filter(d=>d.id !== id)
            window.location.reload()
            setData(del)
        }else{
          setMsg(response.data.msg)
          setMsgravity('danger')
          setIsSave(true)
          setLoad(false)
        }
      })
    }
  }
  
  function setAdds(val){
    setAdd(val)
    setShowAdd(true)
  }

  function setIds(id){
    setId(id)
    setShowEdit(true)
  }

  function setView(bool,id){
    setShowView(bool)
    setId(id)
  }

  function setIdReceps(d){
    setShowBon(true)
    setIdRecep(d)
    setId(d.idre)
  }
  function setUpdateBon(d){
    setShowEditBon(true)
    setDataSelect(d)
    setId(d.idre)
  }

  const Operation = ({data}) => {
    return (
      <div className='menudrop'>
      <Dropdown>
        <Dropdown.Toggle variant="secondary"  key={data.idre}  drop="start"  id="dropdown-basic"></Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={()=>setView(true,data.idre)} title="Voir les détails de cette ligne"><Eye color="#66615B" size={20} /> Voir les détails</Dropdown.Item>
          <Dropdown.Item onClick={()=>setIdReceps(data)}  title="Faire un bon de sortie"><Pencil  size={20}  color='#66615B'/> Bon de sortie</Dropdown.Item>
          <Dropdown.Item onClick={()=>setUpdateBon(data)}  title="Modifier ce bon de sortie"><Pencil  size={20}  color='#66615B'/> Modifier bon sortie</Dropdown.Item>
          <Dropdown.Item onClick={()=>setIds(data.idre)}  title="Modifier cette ligne"><PencilSquare  size={20}  color='#66615B'/> Modifier ligne</Dropdown.Item>
          <Dropdown.Item onClick={()=>onDelete(data.idre)} title="Supprimer cette ligne"><Trash color="red" size={20} /> Supprimer ligne</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      </div>
    )
  }


  const clt = ({ data }) => {
    return (
      data.raisonsocial || "Neant"
    )
  }
  const marque = ({ data }) => {
    return (
      data.libellemarq || "Neant"
    )
  }
  const model = ({ data }) => {
    return (
      data.libellemodel || "Neant"
    )
  }
  const immatr = ({ data }) => {
    return (
      data.nobon ? <p id="success">{data.immatr_eng}</p> : <p id="light">{data.immatr_eng}</p>
      //data.vehicule ? data.vehicule.immatr_eng : "Neant"
    )
  }
  const chassis = ({ data }) => {
    return (
      data.chassis || "Neant"
    )
  }
  const datecirc = ({ data }) => {
    return (
      dateformat(data.date_mise_circult,"dd-mm-yyyy") || "Neant"
    )
  }
  const datesor = ({ data }) => {
    return (
      dateformat(data.datebon,"dd-mm-yyyy") || 'Néant'
    )
  }
  const dateentr = ({ data }) => {
    return (
      dateformat(data.date_entre_garage,"dd-mm-yyyy")
    )
  }
  
  const nofiche = ({ data }) => {
    return (
       data.nofiche
    )
  }
  
  function dateDiff(date1, date2){
    var diff = {}                           // Initialisation du retour
    var tmp = date2 - date1;
 
    tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
    diff.sec = tmp % 60;                    // Extraction du nombre de secondes
 
    tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
    diff.min = tmp % 60;                    // Extraction du nombre de minutes
 
    tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
    diff.hour = tmp % 24;                   // Extraction du nombre d'heures
     
    tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
    diff.day = tmp;
     
    return diff;
  }
  const dateentrdiff = ({ data }) => {
    var diff = dateDiff(dateformat(data.datebon,"yyyy-mm-dd HH:MM"), dateformat(data.date_entre_garage,"yyyy-mm-dd HH:MM"))
    var ret
    return (
      
      ret =  diff.day+" jours, "+diff.hour+" heures, "+diff.min+" minutes et "+diff.sec+" secondes"

    )
  }


  const columns = [
      
      {
          id: 1, 
          field: 'nofiche', 
          label: 'N° fiche',
          cellRenderer:nofiche,
          width: '90px',
      }, 
      {
          id: 2, 
          field: 'raisonsocial', 
          label: 'Clients',
          cellRenderer: clt
      },
      {
        id: 3, 
        field: 'libellemarq', 
        label: 'Marques',
        cellRenderer: marque,
        width: '120px'
      },
      {
        id: 4, 
        field: 'libellemodel', 
        label: 'Models',
        cellRenderer: model,
        width: '120px'
      },
      {
        id: 5, 
        field: 'chassis', 
        label: 'Chassis',
        cellRenderer: chassis,
        width: '120px'
      },
      {
        id: 6, 
        field: 'immatr_eng', 
        cellRenderer: immatr,
        label: 'Immatriculation',
        width: '120px'
      },
      {
        id: 7, 
        field: 'date_mise_circul', 
        cellRenderer: datecirc,
        label: 'En circul. le',
        width: '120px'
      },
      {
        id: 8, 
        field: 'index_compt', 
        label: 'Index compt.',
        width: '120px'
      },
      {
        id: 9, 
        field: 'date_entre_garage', 
        label: 'Reçu le',
        cellRenderer: dateentr,
        width: '120px'
      },
      {
        id: 10, 
        field: 'datebon', 
        label: 'Sortie le',
        cellRenderer:datesor,
        width: '120px'
      },
      {
        id: 11, 
        field: 'nobon', 
        label: 'N° bon de sortie',
        width: '120px'
      },
      {
        id: 12, 
        field: 'point_choc', 
        label: 'Point choc',
        width: '120px'
      },
      {
        id: 13, 
        field: 'objet_visite', 
        label: 'Objet visite',
        width: '120px'
      },
      {
        id: 14, 
        field: 'created_at', 
        label: 'Crée le',
        width: '120px'
      },
      {
        id: 15,  
        label: 'Actions',
        cellRenderer: Operation,
        pinned: true,
        width: '70px',
        minResizeWidth: 90,
      }, 
  ];
    
  //-------------IMPRIMER LES LISTE ----------------
  function printListe(){
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts:true
    })
    var nofact
    var clt 
    var ttc
    var montpayer = 0
    var immtr
    var model
    var ttcg=0
    var tpaie=0
    
    const data = [];
    Axios.get(commondata.ipapi+'/api/reception/pointjour')
        .then(response=>{
            response.data.map((row) => {
                clt = row.client.raisonsocial
                immtr = row.immatr_eng
                model = row.model_eng
                row.proforma.map((l) => {
                    montpayer = 0
                    ttc = l.prix_ttc
                    ttcg += l.prix_ttc
                    nofact = l.nofact
                    l.reglement.map((d) => {
                          montpayer+=d.montant
                          tpaie += d.montant
                    })
                })
                data.push(
                    [row.nofiche,clt,nofact,immtr,model, new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(ttc), new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(montpayer), new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(ttc-montpayer)]
                )
              })
              data.push(
                ["Total", '','', '', '',new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(ttcg),new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(tpaie),new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(ttcg-tpaie)]
              )
              var width = doc.internal.pageSize.getWidth();
              doc.addImage(commondata.logo1, 'png', 14, 10, 30, 30);
              doc.setFontSize(16)
              doc.text('UNITEDMOTORS GARAGE',90,14)
              doc.setFontSize(12)
              doc.setFillColor(0, 0, 0);
              doc.rect(65, 15, width-80, 10,'F')
              doc.setTextColor(255,255,255)
              doc.text("LISTE DES RECEPTION DE VEHICULE",90,22)
              doc.setTextColor(0,0,0)
              var ladate=new Date()
              doc.text("Reception du: "+ladate.getDate()+"-"+(ladate.getMonth()+1)+"-"+ladate.getFullYear() ,65,32)
             
              doc.autoTable({
                styles: { fillColor: [0, 0, 0],textColor:[255,255,255],halign: 'center',fontSize:10 },
                columnStyles: {
                    0: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0], fontSize:8},
                    1: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    2: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    3: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    4: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    5: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    6: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                    7: { halign: 'center',fillColor: [255, 255, 255],textColor:[0,0,0],fontSize:8},
                },
                theme: 'grid',
                startY: 35,
                fontSize: 10,
                head: [["N° fiche", "Client", "N° facture", 'Immatr.','Model', "Montant TTC", "Mont. payer","Reste"]],
                body: data,
              })
              const pageCount = doc.internal.getNumberOfPages();
              for(var i = 1; i <= pageCount; i++) {
                  doc.setPage(i);
                  doc.setFontSize(10)
                  doc.text('Page ' + String(i) + ' sur ' + String(pageCount),210-55,200,null,null,"right");
              }
              doc.save('pointdujour-'+ladate.getDate()+"-"+(ladate.getMonth()+1)+"-"+ladate.getFullYear()+'.pdf');
              setLoad(false)
    })
    
    
  }
  function numFormatter(num) {
    if(num > 999 && num < 1000000){
        return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
    }else if(num > 1000000){
        return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
    }else if(num < 900){
        return num; // if value < 1000, nothing to do
    }
  }

  function getListeEnreparation(){
    setLoad(true)
    Axios.get(commondata.ipapi+'/api/reception/listeenreparation')
          .then(response=>{
            setData(response.data.data)
            setLoad(false)
          }).catch(errors => {
            setLoad(false)
          })
  }
  function getListeSortie(){
    setLoad(true)
    Axios.get(commondata.ipapi+'/api/reception/listesortieengin')
          .then(response=>{
            setData(response.data.data)
            setLoad(false)
          }).catch(errors => {
            setLoad(false)
          })
  }
  function setReload(){
    setLoad(true)
    Axios.get(commondata.ipapi+'/api/reception/index')
          .then(response=>{
            setData(response.data.data)
            setLoad(false)
          }).catch(errors => {
            setLoad(false)
          })
  }
  function getListeduJour(){
    setLoad(true)
    Axios.get(commondata.ipapi+'/api/reception/listereceptionjour')
          .then(response=>{
            setData(response.data.data)
            setLoad(false)
          }).catch(errors => {
            setLoad(false)
          })
  }
    return (     
      <>
      <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>Veuillez patienter.......<Spinner animation="grow" variant="success"/></Modal.Body>
      </Modal>
      <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} bg={msgGravity} autohide>
                <Toast.Header>
                    <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                    />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body>{msg}</Toast.Body>
      </Toast>
      { showView != false ? <ViewLight id={id} setShowView={setShowView}  show={showView} setIsSave={setIsSave} isSave={false} vue='marque'/> : <></> }
      { add != false ? <AddReception setShowAdd={setShowAdd}  show={showAdd} setIsSave={setIsSave} isSave={false} setData={setData} setMsg={setMsg} setMsgravity={setMsgravity}/> : <></> }
      { showEdit != false ? <EditReception id={id} setShowEdit={setShowEdit}  show={showEdit} setIsSave={setIsSave} setId={setId} setData={setData} setMsg={setMsg} setMsgravity={setMsgravity} vue='marque'/> : <></> }
      { idrecep != 0 ? <AddBonSortie id={idrecep} setShowBon={setShowBon}  show={showBon} setIsSave={setIsSave} setIdRecep={setIdRecep} setData={setData} setMsg={setMsg} setMsgravity={setMsgravity} vue='marque'/> : <></> }
      { dataselect ? <EditBonSortie data={dataselect} setShowEditBon={setShowEditBon}  show={showBonEdit} setIsSave={setIsSave} setDataSelect={setDataSelect} setData={setData} setMsg={setMsg} setMsgravity={setMsgravity} vue='marque'/> : <></> }
      
      <div className="content">
      <Card>
          <Row>
              <Col sm={11}>
                <Form className="ml-auto">
                  <ButtonGroup size="sm">
                    <Button><a id='btn-secondary'><FileExcel color="#807f7e" size={20}/> Excel</a></Button>
                    <Button><a id='btn-secondary'><Printer color="#807f7e" size={20}/> Imprimer</a></Button>
                  </ButtonGroup>
                </Form>
              </Col>
              <Col sm={1}>
                <Dropdown>
                  <Dropdown.Toggle variant="success" drop="up" id="dropdown-basic"></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => printListe()}><Bullseye color="#807f7e" size={20}/> Point du jour</Dropdown.Item>
                    {/* <Dropdown.Item href="#/prestadmin/enginhist" title="Historique des engins"><ListCheck  size={20}  color='green'/> Historique engin</Dropdown.Item> */}
                    <Dropdown.Item  onClick={() => printListe()}><List color="#807f7e" size={20}/> Fiche non facturée</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
        </Card>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
              <div id="tablecontent">
                <MyAwesomeTable columns={columns} rows={datas} isLoading={load}/>
              </div>
            </CardBody>
            </Card>
        </Col>
        </Row>
        </div>
        </>
    );
  }
  
  export default ListeMarqueReception;