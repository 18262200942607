import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';

import {
  Modal,
  Spinner,
  Toast,
} from 'react-bootstrap';
import {  Card, CardBody, Row, Col, CardTitle,CardText } from 'reactstrap';
import {
  TriangleHalf,
} from 'react-bootstrap-icons';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import commondata from '../../commonData';
import MyAwesomeTable from './gridtables';
// import EditReception from './editLight';
import ViewLight from './viewLight';
import AddReception from './addLight';
import AddBonSortie from './addbonsortieLight';
// import EditBonSortie from './editbonsortieLight';
import FilterModal from './filterModal';
import { printBl, dateDiff, printFiche } from './functions';

function Receptions() {
  const history = useHistory();
  const [datas, setData] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [load, setLoad] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showBon, setShowBon] = useState(false);
  const [showBonEdit, setShowEditBon] = useState(false);
  const [showAddVehicule, setShowAddVehicule] = useState(false);
  const [id, setId] = useState(0);
  const [idrecep, setIdRecep] = useState(0);
  const [dataselect, setDataSelect] = useState([]);
  const [isSave, setIsSave] = useState(false);
  const [msgGravity, setMsgravity] = useState('success');
  const [msg, setMsg] = useState('');
  const [title, setTitle] = useState('LISTE DES RECEPTIONS');
  const [total, setTotal] = useState(0);
  const [enreparat, setEnRepa] = useState(0);
  const [entre, setEntre] = useState(0);
  const [sortie, setSort] = useState(0);

  const log = commondata.search(commondata.userdroit, "SAD");
  const log1 = commondata.search(commondata.userdroit, "GRE");
  useEffect(() => {
    if (localStorage.getItem('user-info-g')) {
      localStorage.removeItem('datedebut');
      localStorage.removeItem('datefin');
      localStorage.removeItem('filteroption');
      (async function anyNameFunction() {
        await getReception();
      })();
    } else {
      history.push('/login');
    }
  }, [history]);


  async function getReception() {
    setLoad(true);
    Axios.get(commondata.ipapi + '/api/reception/index')
      .then((response) => {
        setDataAll(response.data.datas);
        setData(response.data.datas.data);
        setTotal(response.data.datas.total);
        setEnRepa(response.data.datas.immo);
        setEntre(response.data.datas.entrejour);
        setSort(response.data.datas.sortiejour);
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }



  function onDelete(id) {
    const log = commondata.search(commondata.userdroit, "SAD");
    const log1 = commondata.search(commondata.userdroit, "DER");
    if(log === undefined){
      if(log1 === undefined){
        setMsg("Vous n'avez pas le droit de supprimer cette information");
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
        return;
      }  
    }
    if (window.confirm('Voulez-vous supprimer cette ligne ?')) {
      setLoad(true);
      Axios.delete(commondata.ipapi + '/api/reception/destroy/' + id).then(
        (response) => {
          if (response.data.status === 1) {
            const del = datas.filter((d) => d.id !== id);
            setData(del);
            setLoad(false);
          } else {
            setMsg(response.data.msg);
            setMsgravity('danger');
            setIsSave(true);
            setLoad(false);
          }
        }
      );
    }
  }

  function setAdds(val) {
    const log = commondata.search(commondata.userdroit, "SAD");
    const log1 = commondata.search(commondata.userdroit, "ADR");
    if(log === undefined){
      if(log1 === undefined){
        setMsg("Vous n'avez pas le droit de supprimer cette information");
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
        return;
      }  
    }
    setId(0);
    setShowAdd(val);
  }

  function setIds(id) {
    const log = commondata.search(commondata.userdroit, "SAD");
    const log1 = commondata.search(commondata.userdroit, "MOR");
    if(log === undefined){
      if(log1 === undefined){
        setMsg("Vous n'avez pas le droit de supprimer cette information");
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
        return;
      }  
    }
    setId(id);
    setShowAdd(true);
  }

  function setView(bool, id) {
    setShowView(bool);
    setId(id);
  }

  function setBonLivModal(d,bool) {
    setShowBon(d);
    setIdRecep(d);
    if(bool){
      setDataSelect(d);
    }
  }
  function setUpdateBon(d) {
    setShowEditBon(d);
    setDataSelect(d);
  }

  function setShowFilterModals(bool) {
    setShowFilterModal(true)
  }
  
  const handleClose = () => {
    setShowFilterModal(false)
  };
  
  //-------------IMPRIMER LES LISTE ----------------
  function printListeJour() {
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    });
    var nofact;
    var clt;
    var ttc;
    var montpayer = 0;
    var immtr;
    var model;
    var ttcg = 0;
    var tpaie = 0;

    const data = [];
    Axios.get(commondata.ipapi + '/api/reception/pointjour').then(
      (response) => {
        response.data.map((row) => {
          clt = row.client.raisonsocial;
          immtr = row.vehicule.immatr_eng;
          model = row.vehicule.model.libellemodel;
          row.proforma.map((l) => {
            montpayer = 0;
            ttc = l.prix_ttc;
            ttcg += l.prix_ttc;
            nofact = l.nofact;
            l.reglement.map((d) => {
              montpayer += d.montant;
              tpaie += d.montant;
            });
          });
          data.push([
            row.nofiche,
            clt,
            nofact,
            immtr,
            model,
            new Intl.NumberFormat('de-DE', {
              style: 'decimal',
              currency: 'CFA',
            }).format(ttc),
            new Intl.NumberFormat('de-DE', {
              style: 'decimal',
              currency: 'CFA',
            }).format(montpayer),
            new Intl.NumberFormat('de-DE', {
              style: 'decimal',
              currency: 'CFA',
            }).format(ttc - montpayer),
          ]);
        });
        if (data.length > 0) {
          data.push([
            'Total',
            '',
            '',
            '',
            '',
            new Intl.NumberFormat('de-DE', {
              style: 'decimal',
              currency: 'CFA',
            }).format(ttcg),
            new Intl.NumberFormat('de-DE', {
              style: 'decimal',
              currency: 'CFA',
            }).format(tpaie),
            new Intl.NumberFormat('de-DE', {
              style: 'decimal',
              currency: 'CFA',
            }).format(ttcg - tpaie),
          ]);
        } else {
          return;
        }

        var width = doc.internal.pageSize.getWidth();
        commondata.ipapi === 'https://192.168.8.109:81'
          ? doc.addImage(commondata.logo, 'jpg', 12, 10, 30, 30)
          : doc.addImage(commondata.logo1, 'jpg', 12, 10, 30, 30);
        doc.setFontSize(16);
        commondata.ipapi === 'https://192.168.8.109:81'
          ? doc.text('CLUB AUTO ENTREPOT', 90, 15)
          : doc.text('HR MOTORS', 90, 15);
        doc.setFontSize(12);
        doc.setFillColor(0, 0, 0);
        doc.rect(65, 15, width - 80, 10, 'F');
        doc.setTextColor(255, 255, 255);
        doc.text('LISTE DES RECEPTION DE VEHICULE', 90, 22);
        doc.setTextColor(0, 0, 0);
        var ladate = new Date();
        doc.text(
          'Reception du: ' +
            ladate.getDate() +
            '-' +
            (ladate.getMonth() + 1) +
            '-' +
            ladate.getFullYear(),
          65,
          32
        );

        doc.autoTable({
          styles: {
            fillColor: [225, 206, 154],
            textColor: [255, 0, 0],
            halign: 'center',
            fontSize: 10,
          },
          columnStyles: {
            0: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 8,
            },
            1: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 8,
            },
            2: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 8,
            },
            3: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 8,
            },
            4: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 8,
            },
            5: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 8,
            },
            6: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 8,
            },
            7: {
              halign: 'center',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 8,
            },
          },
          theme: 'grid',
          startY: 35,
          fontSize: 10,
          head: [
            [
              'N° fiche',
              'Client',
              'N° facture',
              'Immatr.',
              'Model',
              'Montant TTC',
              'Mont. payer',
              'Reste',
            ],
          ],
          body: data,
        });
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.text(
            'Page ' + String(i) + ' sur ' + String(pageCount),
            210 - 55,
            200,
            null,
            null,
            'right'
          );
        }
        doc.save(
          'pointdujour-' +
            ladate.getDate() +
            '-' +
            (ladate.getMonth() + 1) +
            '-' +
            ladate.getFullYear() +
            '.pdf'
        );
        setLoad(false);
      }
    );
  }
  function numFormatter(num) {
    if (num > 99999 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
    } else if (num < 99999) {
      return num; // if value < 1000, nothing to do
    }
  }

  async function getListereparation(dat,p) {
    setLoad(true);
    switch (p) {
      case 0:
        var date2, date1, diff;
        var tab = [];
        dat.map((row) => {
          row.nobon ? (date1 = new Date(row.datebon)) : (date1 = new Date());
          date2 = new Date(row.date_entre_garage);
          diff = dateDiff(date2, date1);
          tab.push({
            id: row.id,
            nofiche: row.nofiche,
            client: row.client,
            typeclient: row.typeclient,
            couleur: row.couleur,
            couleurt: row.couleurt,
            marque: row.marque,
            model: row.model,
            vehiculeid: row.vehiculeid,
            proforma: row.proforma,
            chassis: row.chassis,
            immatr_eng: row.immatr_eng,
            date_mise_circul: row.date_mise_circul,
            index_compt: row.index_compt,
            created_at: row.created_at,
            datebon: row.datebon,
            date_entre_garage: row.date_entre_garage,
            duregarage: diff.day,
            nobon: row.nobon,
            user: row.user.name,
            objet_visite: row.objet_visite,
            point_choc: row.point_choc,
            sipayant: row.sipayant,
            position: row.position,
            users: row.user,

          });
        });
        setData(tab);
        setLoad(false);
       
        break;
    
      default:
        await Axios({
                  url: commondata.ipapi + '/api/reception/index',
                  method: 'GET',
              })
                  .then((res) => {
                      if (res.status !== 200) {
                          setMsg('Veuillez vérifier votre connexion internet');
                          setMsgravity('info');
                          setIsSave(false);
                          return;
                      }
                      if (res.data.status === 1) {
                          setData(res.data.datas.data);
                          setTotal(res.data.datas.total);
                          setEnRepa(res.data.datas.immo);
                          setEntre(res.data.datas.entrejour);
                          setSort(res.data.datas.sortiejour);
                          setLoad(false);
                      } else {
                          setIsSave(true);
                          setMsg(res.data.msg);
                          setMsgravity('danger');
                          setLoad(false);
                      }
      
                  })
                  .catch((error) => {
                      setIsSave(true);
                      setMsg('Erreur');
                      setMsgravity('danger');
                      setLoad(false);
                  });
        break;
    }
    
  }


  async function setInitialiseData(){
    localStorage.removeItem('datedebut');
    localStorage.removeItem('datefin');
    localStorage.removeItem('filteroption');
      await getListereparation(dataAll.data,1);
    setTitle('LISTE DES RECEPTIONS');
  }
 
  

  async function getListeEnreparation() {
    await getListereparation(dataAll.tabimmo,0);
    setTitle('LISTE DES VEHICULES IMMOBILISES');
  }
  async function getListeSortie() {
    await getListereparation(dataAll.tabsortie,0);
    setTitle('LISTE DES LIVRAISONS');
  }
  async function getListeSortieJour() {
    await getListereparation(dataAll.tabsortiejour,0);
    setTitle('LISTE DES LIVRAISONS DU JOUR');
  }
  async function setReload() {
    await getListereparation(dataAll.data,0);
    setTitle('LISTE DES RECEPTION');
  }
  async function getListeduJour() {
    await getListereparation(dataAll.tabentrejour,0);
    setTitle('RECEPTION DU JOUR EN COURS');
  }

  async function getGratuit() {
    await getListereparation(dataAll.gratuit,0);
    setTitle('LISTE DES FICHES GRATUITES');
  }
  async function getPayante() {
    await getListereparation(dataAll.payant,0);
    setTitle('LISTE DES FICHES PAYANTES');
  }
  async function printListeNonfat() {
    await getListereparation(dataAll.tabnonfacturer,0);
    setTitle('LISTE DES FICHES NON FACTUREES');
  }

  async function printListeFat() {
    await getListereparation(dataAll.tabfacturer,0);
    setTitle('LISTE DES FICHES FACTUREES');
  }
 
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="danger" />
        </Modal.Body>
      </Modal>
      <Toast
        id="toast"
        onClose={() => setIsSave(false)}
        show={isSave}
        delay={3000}
        className={'bg-' + msgGravity}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Info</strong>
        </Toast.Header>
        <Toast.Body className="dark">{msg}</Toast.Body>
      </Toast>
      {showView !== false ? (
        <ViewLight
          id={id}
          setShowView={setShowView}
          show={showView}
          setIsSave={setIsSave}
          isSave={false}
        />
      ) : (
        <></>
      )}
      {showAdd ? (
        <AddReception
        id={id}
          setAdds={setAdds}
          setShowAddVehicule={setShowAddVehicule}
          showAddVehicule={showAddVehicule}
          show={showAdd}
          setIsSave={setIsSave}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          setEnRepa={setEnRepa}
          enreparat={enreparat}
          setTotal={setTotal}
          total={total}
          getReception={getReception}
        />
      ) : (
        <></>
      )}
      {/* {showEdit ? (
        <EditReception
          id={id}
          setShowEdit={setShowEdit}
          setAdds={setAdds}
          show={showEdit}
          addVehicule={addVehicule}
          setAddVehicule={setAddVehicule}
          setShowAddVehicule={setShowAddVehicule}
          showAddVehicule={showAddVehicule}
          setAdd={setAdd}
          setIsSave={setIsSave}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          setEnRepa={setEnRepa}
          enreparat={enreparat}
          setTotal={setTotal}
          total={total}
          getReception={getReception}
        />
      ) : (
        <></>
      )} */}

      {showBon ? (
        <AddBonSortie
        data={idrecep}
          setBonLivModal={setBonLivModal}
          show={showBon}
          setIsSave={setIsSave}
          setIdRecep={setIdRecep}
          setData={setData}
          rows={datas}
          setEnRepa={setEnRepa}
          enreparat={enreparat}
          setSort={setSort}
          sortie={sortie}
          setEntre={setEntre}
          entre={entre}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          vue="reception"
        />
      ) : (
        <></>
      )}
      {/* {dataselect ? (
        <EditBonSortie
          data={dataselect}
          rows={datas}
          setUpdateBon={setUpdateBon}
          show={showBonEdit}
          setIsSave={setIsSave}
          setDataSelect={setDataSelect}
          setData={setData}
          setEnRepa={setEnRepa}
          enreparat={enreparat}
          setSort={setSort}
          sortie={sortie}
          setEntre={setEntre}
          entre={entre}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
          vue="reception"
        />
      ) : (
        <></>
      )} */}
  {showFilterModal ? (
      <FilterModal
        setShowFilterModals={setShowFilterModals}
        showFilterModal={showFilterModal}
        setMsg={setMsg}
        setIsSave={setIsSave}
        setMsgravity={setMsgravity}
        setLoad={setLoad}
        load={load}
        handleClose={handleClose}
        setData={setData}
        setDataAll={setDataAll}
        setTotal={setTotal}
        setEnRepa={setEnRepa}
        // setTotalliv={setTotalliv}
        setEntre={setEntre}
        setSort={setSort}
      />
    ) : (
      <></>
    )}
      {log != undefined || log1 != undefined ? (
      <div className="content">
         <Card className="card-stats" color="dark" text="light">
                                                    <CardBody>
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card
              className="card-stats"
              onClick={() => setReload(true)}
              title="Cliquer pour plus de détail"
            >
              <CardBody>
                <Row>
                  <Col md="3" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bus-front-12 text-warning" />
                    </div>
                  </Col>
                  <Col md="9" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total recetion engins</p>
                      <CardTitle tag="p">{numFormatter(total)}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card
              className="card-stats"
              onClick={() => getListeEnreparation()}
              title="Cliquer pour plus de détail"
            >
              <CardBody>
                <Row>
                  <Col md="3" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-settings text-success" />
                    </div>
                  </Col>
                  <Col md="9" xs="7">
                    <div className="numbers">
                      <p className="card-category">Véhicules immobilisés</p>
                      <CardTitle tag="p">{numFormatter(enreparat)}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card
              className="card-stats"
              onClick={() => getListeSortie()}
              title="Cliquer pour plus de détail"
            >
              <CardBody>
                <Row>
                  <Col md="3" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-delivery-fast text-danger" />
                    </div>
                  </Col>
                  <Col md="9" xs="7">
                    <div className="numbers">
                      <p className="card-category">Véhicules sorties</p>
                      <CardTitle tag="p">
                        {numFormatter(total - enreparat)}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card
              className="card-stats"
              onClick={() => getListeduJour()}
            >
              <CardBody>
                <Row>
                  <Col md="3" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bus-front-12 text-primary" />
                    </div>
                  </Col>
                  <Col md="9" xs="7">
                    <div className="numbers">
                      <p className="card-category">Entrées / Sorties (jour)</p>
                      <CardTitle tag="p">
                        {entre} / {sortie}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </CardBody>
        </Card>
        <div id="tablecontent">
          <MyAwesomeTable
            rows={datas}
            isLoading={load}
            onDelete={onDelete}
            setIds={setIds}
            setAdds={setAdds}
            setView={setView}
            setBonLivModal={setBonLivModal}
            setUpdateBon={setUpdateBon}
            printFiche={printFiche}
            printBl={printBl}
            dataAll={dataAll}
            setShowFilterModals={setShowFilterModals}
            titre={title}
            setInitialiseData={setInitialiseData}
            enreparat={enreparat}
            total={total}
            entre={entre}
            sortie={sortie}
          />
        </div>
      </div>
      
      ) : (
        <Card style={{ width: '25rem'}} className="centerCard">
          <CardBody>
            <CardTitle tag="h5">
              <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
            </CardTitle>
            <CardText>
              Vous n'êtes pas autoriser à utiliser cette section. Merci de
              contacter votre administrateur
            </CardText>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default Receptions;
