import React, { useEffect, useState } from 'react';
import {
  Form,
  InputGroup,
  Spinner,
  ButtonGroup,
  Modal,
  ToggleButton,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import {
  Card,
  Button,
  Input,
  Row,
  Col,
  Label,
  CardHeader,
  CardBody,
  CardSubtitle,
} from 'reactstrap';
import Axios from 'axios';
import { BackspaceFill, PlusCircle, Star } from 'react-bootstrap-icons';
import common from '../../commonData';
import dateformat from 'dateformat';
import { Typeahead } from 'react-bootstrap-typeahead';
import Table from 'views/tableaddedit';
const initialState = {
  carte_grise: '',
  visite_technique: '',
  assurance: '',
  patente: '',
  cric: '',
  manivelle: '',
  cd: '',
  p_audio: '',
  radio: '',
  extincteur: '',
  cle_roue: '',
  enjoliveurs: '',
  roue_secours: '',
  tapis_sol: '',
  axe_remorq: '',
  carte_transport: '',
  carte_station: '',
  vignette: '',
};
var count = 0;
function ViewReceptions({ id, setShowView, show, setIsSave, isSave }) {
  var ladate = new Date();
  const [validated, setValidated] = useState(false);
  const [client_id, setClientid] = useState(0);
  const [client, setDataClient] = useState([]);
  const [agent_id, setAgentId] = useState(0);
  const [agent, setAgent] = useState([]);
  const [client2, setClient2] = useState('');
  const [client2tel, setClient2Tel] = useState('');
  const [famille, setFamille] = useState('');
  const [marque, setMarque] = useState('');
  const [vehicule_id, setVehiculeid] = useState(0);
  const [vehicule, setVehiculeData] = useState([]);
  const [chassis, setChassis] = useState(0);
  const [datecircul, setDateCirc] = useState('');
  const [datesent, setDateEnt] = useState(
    dateformat(
      ladate.getMonth() +
        1 +
        '/' +
        ladate.getDate() +
        '/' +
        ladate.getFullYear(),
      'yyyy-mm-dd'
    )
  );
  const [poinchoc, setPointChoc] = useState('');
  const [indexc, setIndex] = useState(0);
  const [sirevision, setRevision] = useState(0);
  const [typeEnerg, setTypeEnerg] = useState(0);
  const [sipayent, setSipayant] = useState(0);
  const [motifgratuit, setMotif] = useState('');
  const [objet_visite, setObjet] = useState('');
  const [carburant, setCarburant] = useState('1/2');
  const [nofiche, setNofiche] = useState(0);
  const [motifretard, setMotifRetard] = useState([]);
  const [intervenant, setIntervenant] = useState([]);
  const [sireprisetravaux, setSireprisetravaux] = useState(false);
  const [
    {
      carte_grise,
      visite_technique,
      assurance,
      patente,
      cric,
      manivelle,
      cd,
      p_audio,
      radio,
      extincteur,
      cle_roue,
      enjoliveurs,
      roue_secours,
      tapis_sol,
      axe_remorq,
      carte_transport,
      carte_station,
      vignette,
    },
    setState,
  ] = useState(initialState);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/dashboard');
    }
    (async function anyNameFunction() {
      setLoad(true);
      const getCli = Axios.get(common.ipapi + '/api/client/indexmin');
      const getAgent = Axios.get(common.ipapi + '/api/agent/index');
      const getReceptionid = Axios.get(
        common.ipapi + '/api/reception/edit/' + id
      );
      const getReception = Axios.get(common.ipapi + '/api/vehicule/index');
      await Axios.all([getCli, getAgent, getReceptionid, getReception])
        .then(
          Axios.spread((...responses) => {
            setVehiculeData(responses[3].data.data);
            setVehiculeid(responses[2].data.vehicule_id);
            setTypeEnerg(responses[2].data.energie);
            setDataClient(responses[0].data);
            setClientid(responses[2].data.client_id);
            setAgent(responses[1].data.data);
            setAgentId(responses[2].data.agent.id);
            setNofiche(responses[2].data.nofiche);
            setCarburant(responses[2].data.carburant);
            setSireprisetravaux(responses[2].data.si_reprisetravaux == 0 ? false : true)
            // setAccess(JSON.parse(responses[2].data.accessoires))
            setState(JSON.parse(responses[2].data.accessoires));
            setClient2(responses[2].data.remipar);
            setClient2Tel(responses[2].data.telremipar);
            setPointChoc(responses[2].data.point_choc || '');
            setFamille(
              responses[2].data.vehicule.model
                ? responses[2].data.vehicule.model.libellemodel
                : ''
            );
            setMarque(responses[2].data.vehicule.marque.libellemarq);
            setChassis(responses[2].data.vehicule.chassis);
            setTypeEnerg(responses[2].data.vehicule.energie);
            setDateCirc(
              dateformat(
                responses[2].data.vehicule.date_mise_circul,
                'yyyy-mm-dd'
              )
            );
            setDateEnt(
              dateformat(responses[2].data.date_entre_garage, 'yyyy-mm-dd')
            );
            setIndex(responses[2].data.index_compt);
            setMotif(responses[2].data.motifgratuit || '');
            setRevision(responses[2].data.si_revision);
            setSipayant(responses[2].data.si_payant);
            setObjet(responses[2].data.objet_visite || '');
            setMotifRetard(
              responses[2].data.motifretardliv
                ? JSON.parse(responses[2].data.motifretardliv)
                : []
            );
            setIntervenant(
              responses[2].data.intervenant
                ? JSON.parse(responses[2].data.intervenant)
                : []
            );
            setLoad(false);
          })
        )
        .catch((errors) => {
          setLoad(false);
        });
    })();
  }, [history, id]);

  const handleClose = () => {
    setShowView(false);
    setIsSave(false);
  };
  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Motif retard de livraison',
      accessor: 'motif',
    },
    {
      Header: <></>,
      accessor: '[editButton]',
      Cell: (row) => <></>,
    },
  ];

  const columnIntervenant = [
    {
      Header: 'Agents',
      accessor: 'idagent',
    },
    {
      Header: 'Intervention',
      accessor: 'intervention',
    },
    {
      Header: 'Etat',
      accessor: 'etat',
    },
    {
      Header: <></>,
      accessor: '[editButton]',
      Cell: (row) => <></>,
    },
  ];
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>

      <Modal show={show} fullscreen={true}>
        <Modal.Header>
          {'FICHE RECEPTION DE VEHICULE'}
          <BackspaceFill onClick={handleClose} color="red" size={30} />
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <div id="lightbody">
              <Row>
                <Col md={4}>
                  <Card id="fixed-cards">
                    <CardBody>
                      <Form.Group as={Col} md="" controlId="fiche">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            N° de fiche: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="N° de fiche"
                            aria-describedby="inputGroupPrepend"
                            required
                            size="sm"
                            onChange={(e) => setNofiche(e.target.value)}
                            value={nofiche}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      {' '}
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        information client
                      </CardSubtitle>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup>
                          <InputGroup.Text id="inputGroupPrepend">
                            Nom client:
                          </InputGroup.Text>
                          <Typeahead
                            id="my-typeahead-id"
                            labelKey={(option) => `${option.raisonsocial}`}
                            filterBy={['raisonsocial']}
                            value={client_id}
                            selected={client.slice(
                              client.findIndex(
                                (element) => element.id === client_id
                              ),
                              client.findIndex(
                                (element) => element.id === client_id
                              ) + 1
                            )}
                            options={client}
                            inputProps={{ required: true }}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="agent">
                        <InputGroup>
                          <InputGroup.Text id="inputGroupPrepend">
                            Receptionné par: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Typeahead
                            id="my-typeahead-id"
                            labelKey={(option) =>
                              `${option.nom} ${option.prenoms}`
                            }
                            filterBy={['nom', 'prenoms']}
                            value={agent_id}
                            selected={agent.slice(
                              agent.findIndex(
                                (element) => element.id === agent_id
                              ),
                              agent.findIndex(
                                (element) => element.id === agent_id
                              ) + 1
                            )}
                            options={agent}
                            inputProps={{ required: true }}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="" controlId="Conducteur">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Conducteur:{' '}
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Conducteur"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            disabled
                            value={client2}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Tel. conducteur:
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Contact"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={client2tel}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Date entrée: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            placeholder="Date entrée"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={datesent}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                    </CardBody>
                  </Card>

                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        informations véhicule client
                      </CardSubtitle>
                      <Form.Group as={Col} md="12" controlId="immatr">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Vehicule: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Typeahead
                            id="my-typeahead-id"
                            labelKey={(option) => `${option.immatr_eng}`}
                            filterBy={['immatr_eng ', 'chassis']}
                            value={vehicule_id}
                            options={vehicule}
                            selected={vehicule.slice(
                              vehicule.findIndex(
                                (element) => element.id === vehicule_id
                              ),
                              vehicule.findIndex(
                                (element) => element.id === vehicule_id
                              ) + 1
                            )}
                            inputProps={{ required: true }}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Index compteur: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Form.Control
                            type="number"
                            placeholder="Index compteur"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={indexc}
                            disabled
                            required
                            min={0}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Mise en circulation le:
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            placeholder="Date de mise en circulation"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={datecircul}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Marques:
                          </InputGroup.Text>
                          <Input type="text" value={marque} disabled />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="famille">
                        <InputGroup>
                          <InputGroup.Text id="inputGroupPrepend">
                            Model:
                          </InputGroup.Text>
                          <Input type="text" value={famille} disabled />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Chassis:
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Chassis"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            minLength={5}
                            value={chassis}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="Energie">
                        <InputGroup hasValidation>
                          <ButtonGroup>
                            {[
                              { name: 'Essence', value: '0' },
                              { name: 'Gasoil', value: '1' },
                            ].map((radio, idx) => (
                              <ToggleButton
                                disabled
                                key={idx}
                                id={`radioenergie-${idx}`}
                                size="sm"
                                type="radio"
                                variant={
                                  idx % 2 ? 'outline-danger' : 'outline-danger'
                                }
                                value={radio.value}
                                checked={typeEnerg === radio.value}
                                onChange={(e) =>
                                  setTypeEnerg(e.currentTarget.value)
                                }
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                          <Form.Control.Feedback type="invalid">
                            Veuillez selectionnez la revision.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={8}>
                  <Card id="fixed-cards">
                    <CardHeader className="mb-2 text-muted" tag="h6">
                      informations supplémentaire
                    </CardHeader>
                    <CardBody>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Objet visite:
                          </InputGroup.Text>
                          <Form.Control
                            as="textarea"
                            placeholder="Objet de la visite"
                            aria-describedby="inputGroupPrepend"
                            rows={2}
                            value={objet_visite}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md=""
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Remarques:
                          </InputGroup.Text>
                          <Form.Control
                            as="textarea"
                            placeholder="Remarques"
                            aria-describedby="inputGroupPrepend"
                            rows={2}
                            value={poinchoc}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                      <Row>
                        <Col md="4">
                          <Form.Group as={Col} md="12" controlId="revision">
                            <InputGroup hasValidation>
                              Etat 1:
                              <ButtonGroup>
                                {[
                                  { name: 'Révision', value: '0' },
                                  { name: 'Autres', value: '1' },
                                ].map((radio, idx) => (
                                  <ToggleButton
                                    key={idx}
                                    id={`radiorevision-${idx}`}
                                    type="radio"
                                    size="sm"
                                    variant={
                                      idx % 2
                                        ? 'outline-danger'
                                        : 'outline-danger'
                                    }
                                    value={radio.value}
                                    checked={sirevision === radio.value}
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group as={Col} md="12" controlId="gratuit">
                            <InputGroup hasValidation>
                              Etat 2:
                              <ButtonGroup>
                                {[
                                  { name: 'Payant', value: '0' },
                                  { name: 'Gratuit', value: '1' },
                                ].map((radio, idx) => (
                                  <ToggleButton
                                    key={idx}
                                    id={`radiogratuit-${idx}`}
                                    size="sm"
                                    type="radio"
                                    variant={
                                      idx % 2
                                        ? 'outline-danger'
                                        : 'outline-danger'
                                    }
                                    value={radio.value}
                                    checked={sipayent === radio.value}
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </InputGroup>
                            <ButtonGroup check="true" inline="true">
                              <Input
                                key='index'
                                onChange={(e)=> setSireprisetravaux(!sireprisetravaux)}
                                checked={sireprisetravaux}
                                value={sireprisetravaux}
                                type="checkbox"
                                id={`inline-checkbox`}
                                disabled
                              />
                              <Label check>Reprise travaux</Label>
                            </ButtonGroup>
                          </Form.Group>
                          {sipayent === '1' ? (
                            <Form.Group
                              as={Col}
                              md=""
                              controlId="validationCustomUsername"
                            >
                              <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">
                                  Motif gratuit:
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  placeholder="Motif gratuit"
                                  aria-describedby="inputGroupPrepend"
                                  size="sm"
                                  value={motifgratuit}
                                  disabled
                                />
                              </InputGroup>
                            </Form.Group>
                            
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col md="4">
                          <Form.Group as={Col} md="12" controlId="revision">
                            <InputGroup hasValidation>
                              Niveau de carburant
                              <ButtonGroup>
                                {[
                                  { name: '1/4', value: '1/4' },
                                  { name: '1/3', value: '1/3' },
                                  { name: '1/2', value: '1/2' },
                                  { name: '1', value: '1' },
                                ].map((radio, idx) => (
                                  <ToggleButton
                                    key={idx}
                                    id={`niveau-${idx}`}
                                    type="radio"
                                    size="sm"
                                    variant={
                                      idx % 2
                                        ? 'outline-danger'
                                        : 'outline-danger'
                                    }
                                    value={radio.value}
                                    checked={carburant === radio.value}
                                    disabled
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        Eléments du véhicule
                      </CardSubtitle>
                      <Row>
                        <Col md="3">
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={carte_grise || 'carte_grise'}
                              checked={carte_grise ? true : false}
                              type="checkbox"
                              id="inline-checkbox-arte_grise"
                            />
                            <Label check>{'carte grise'} </Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={visite_technique || 'visite_technique'}
                              checked={visite_technique ? true : false}
                              type="checkbox"
                              id="inline-checkbox-visite_technique"
                            />
                            <Label check>{'visite technique'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={assurance || 'assurance'}
                              checked={assurance ? true : false}
                              type="checkbox"
                              id="inline-checkbox-assurance"
                            />
                            <Label check>{'assurance'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={patente || 'patente'}
                              checked={patente ? true : false}
                              type="checkbox"
                              id={`inline-checkbox-${patente}`}
                            />
                            <Label check>{'patente'} </Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={cric || 'cric'}
                              checked={cric ? true : false}
                              type="checkbox"
                              id="inline-checkbox-cric"
                            />
                            <Label check>{'cric'}</Label>
                          </ButtonGroup>
                        </Col>
                        <Col md={3}>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={manivelle || 'manivelle'}
                              checked={manivelle ? true : false}
                              type="checkbox"
                              id="inline-checkbox-manivelle"
                            />
                            <Label check>{'manivelle'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={cd || 'cd'}
                              checked={cd ? true : false}
                              type="checkbox"
                              id="inline-checkbox-cd"
                            />
                            <Label check>{'Lecteur cd'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={p_audio || 'p_audio'}
                              checked={p_audio ? true : false}
                              type="checkbox"
                              id="inline-checkbox-p_audio"
                            />
                            <Label check>{'poste audio'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={radio || 'radio'}
                              checked={radio ? true : false}
                              type="checkbox"
                              id="inline-checkbox-radio"
                            />
                            <Label check>{'Poste radio'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={extincteur || 'extincteur'}
                              checked={extincteur ? true : false}
                              type="checkbox"
                              id="inline-checkbox-extincteur"
                            />
                            <Label check>{'extincteur'}</Label>
                          </ButtonGroup>
                        </Col>
                        <Col md={3}>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={cle_roue || 'cle_roue'}
                              checked={cle_roue ? true : false}
                              type="checkbox"
                              id="inline-checkbox-cle_rou"
                            />
                            <Label check>{'cle roue'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={enjoliveurs || 'enjoliveurs'}
                              checked={enjoliveurs ? true : false}
                              type="checkbox"
                              id="inline-checkbox-enjoliveurs"
                            />
                            <Label check>{'enjoliveurs'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={roue_secours || 'roue_secours'}
                              checked={roue_secours ? true : false}
                              type="checkbox"
                              id="inline-checkbox-${roue_secours"
                            />
                            <Label check>{'roue secours'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              onChange={(e) => setState(e.currentTarget.value)}
                              value={tapis_sol || 'tapis_sol'}
                              checked={tapis_sol ? true : false}
                              type="checkbox"
                              id="inline-checkbox-tapis_sol"
                            />
                            <Label check>{'tapis sol'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={axe_remorq || 'axe_remorq'}
                              checked={axe_remorq ? true : false}
                              type="checkbox"
                              id="inline-checkbox-axe_remorq"
                            />
                            <Label check>{'axe remorq'}</Label>
                          </ButtonGroup>
                        </Col>
                        <Col md={3}>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={carte_transport || 'carte_transport'}
                              checked={carte_transport ? true : false}
                              type="checkbox"
                              id="inline-checkbox-carte_transport"
                            />
                            <Label check>{'carte transport'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={carte_station || 'carte station'}
                              checked={carte_station ? true : false}
                              type="checkbox"
                              id="inline-checkbox-carte_station"
                            />
                            <Label check>{'carte station'}</Label>
                          </ButtonGroup>
                          <ButtonGroup check="true" inline="true">
                            <Input
                              value={vignette || 'vignette'}
                              checked={vignette ? true : false}
                              type="checkbox"
                              id="inline-checkbox-vignette"
                            />
                            <Label check>{'vignette'}</Label>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        INETERVENANTS SUR LES TRAVAUX
                      </CardSubtitle>
                      <Table columns={columnIntervenant} data={intervenant} />
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardHeader>MOTIF DE RETARD DE LIVRAISON</CardHeader>
                      <Table columns={columns} data={motifretard} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup aria-label="Basic example" size="sm">
            <Button color="secondary" onClick={handleClose}>
              <BackspaceFill color="red" size={20} />
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ViewReceptions;
