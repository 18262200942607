import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  InputGroup,
  Tooltip,
  Spinner,
  ButtonGroup,
  Modal,
  ToggleButton,
  OverlayTrigger,
  FormControl,
  Alert,
  ListGroup,
  Navbar,
  Container,
} from 'react-bootstrap';
import {
  Button,
  Row,
  Col,
  Label,
  Card,
  CardSubtitle,
  CardBody,
} from 'reactstrap';

import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  BackspaceFill,
  PlusCircle,
  Save2Fill,
  Star,
  Triangle,
  TriangleHalf,
} from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import dateformat from 'dateformat';
import common from '../../commonData';
import AddPrestation from '../prestations/addPrestation';
import { Summary, ProductList } from './card';
var count = 0;
var sidelete = 0;
function UpdateFacture({
  ids,
  setShowEdit,
  show,
  setIsSave,
  setMsg,
  setMsgravity,
  setLoad,
  load,
  getFacture,
  types,
}) {
  const [validated, setValidated] = useState(false);
  const [isdel, setIsDel] = useState(false);
  const [type, setType] = useState('Facture');
  const [datet, setDate] = useState('');
  const [dateEcheance, setDateEcheance] = useState('');
  const [nobon, setNobon] = useState('');
  const [montptifourn, setMontPtiFourn] = useState(0);
  const [ptifour, setPtiFourniture] = useState('1');
  const [entreprise, setEntreprise] = useState('1');
  const [ligneData, setLigneData] = useState([]);
  const [client, setClient] = useState([]);
  const [clientid, setClientId] = useState([]);
  const [marque_id, setMarqueId] = useState(0);
  const [marque, setMarque] = useState([]);
  const [reception_id, setRecepId] = useState(0);
  const [reception, setRecept] = useState([]);
  const [prestation, setDataPrestation] = useState([]);
  const [prestation_id, setPrestid] = useState([]);
  const [remise, setRemise] = useState(0);
  const [immatriculation, setImmatr] = useState('');
  const [chassis, setChassis] = useState('');
  const history = useHistory();
  const [sitva, setTva] = useState(0);
  const [famille, setfamilleData] = useState([]);
  const [familles, setfamilleDatas] = useState([]);
  const [famille_id, setFamille] = useState(0);
  const [prestationInter, setPrestInter] = useState([]);
  const [showAdd, setShowAddprest] = useState(false);
  const [add, setShowAdd] = useState(false);
  const [assure, setAssure] = useState('');
  const [nosinistre, setNoSinistre] = useState('');
  const [bondecommande, setBoncommande] = useState('');
  const [autoentre, setAutoENtre] = useState('');
  const [nomexpert, setNomExpert] = useState('');
  const [commentaire, setComment] = useState('');
  const typeaheadVehi = useRef('');
  const typeaheadClient = useRef('');
  const typeaheadModel = useRef('');
  const typeaheadMarq = useRef('');
  const [arrondi, setArrondi] = useState('0');

  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/login');
    }
    (async function anyNameFunction() {
      setLoad(true);
      const getFact = Axios.get(
        common.ipapi + '/api/receptionfact/edit/' + ids
      );
      const getdata = Axios.get(common.ipapi + '/api/receptionfact/adddata');
      await Axios.all([getFact, getdata])
        .then(
          Axios.spread((...responses) => {
            setDateEcheance(responses[0].data.date_echeance ? dateformat(responses[0].data.date_echeance, 'yyyy-mm-dd') : '');
            setClient(responses[1].data[2]);
            setMarque(responses[1].data[0]);
            setfamilleData(responses[1].data[1]);
            setfamilleDatas(responses[1].data[1]);
            setRecept(responses[1].data[3]);
            setDataPrestation(responses[1].data[4]);
            setPrestInter(responses[1].data[4]);
            setTva(responses[0].data.sitva);
            setChassis(responses[0].data.chassis || '');
            setClientId(
              types === 'Facture'
                ? responses[0].data.clientf.id
                : responses[0].data.client.id
            );
            setMarqueId(responses[0].data.marque_id || 0);
            setFamille(responses[0].data.model_eng || '');
            setImmatr(responses[0].data.immatr_eng || '');
            setAssure(responses[0].data.assure || '');
            setBoncommande(responses[0].data.bondecommande || '');
            setNomExpert(responses[0].data.nomexpert || '');
            setRemise(responses[0].data.remise);
            setNoSinistre(responses[0].data.nosinistre || '');
            setRecepId(responses[0].data.reception_id || 0);
            setPtiFourniture(responses[0].data.sifournitur || 0);
            setMontPtiFourn(responses[0].data.petitfourn || 0);
            setLigneData(responses[0].data.ligne);
            setArrondi('' + responses[0].data.arrondi + '' || '0');
            setAutoENtre(responses[0].data.autoentre || '');
            setComment(responses[0].data.commentaire || '');
            setEntreprise('' + responses[0].data.entreprise_id + '' || '1');
            setNobon(
              types === 'Facture'
                ? responses[0].data.nofact
                : responses[0].data.noprof
            );
            setType(types === 'Facture' ? 'Facture' : 'Proforma');
            setDate(
              types === 'Facture'
                ? dateformat(responses[0].data.datefact, 'yyyy-mm-dd')
                : dateformat(responses[0].data.dateprof, 'yyyy-mm-dd')
            );
            setPrestid(responses[0].data.ligne);
            setLoad(false);
          })
        )
        .catch((errors) => {
          setLoad(false);
        });
    })();
  }, [history, ids]);

  function setClientIds(codeart) {
    if (types === 'Facture') {
      return;
    }
    setClientId(codeart.length > 0 ? codeart[0].id : 0);
    setTva(codeart.length > 0 ? codeart[0].sitva : 0);
  }
  function setFamilleId(d) {
    if (types === 'Facture') {
      return;
    }
    setFamille(d.length > 0 ? d[0].id : 0);
  }

  function setMarqueIdF(maq) {
    if (types === 'Facture') {
      return;
    }
    setMarqueId(maq.length > 0 ? maq[0].id : 0);
    setFamille(0);
    if (maq.length > 0) {
      setfamilleData(
        famille.filter((element) => element.marque_id == maq[0].id)
      );
    } else {
      setfamilleData(familles);
      setFamille(0);
    }
  }
  const handleClose = () => {
    setShowEdit(false);
    setIsSave(false);
  };

  function saveFacture(e) {
    setLoad(true);
    if (ligneData.length == 0) {
      setMsg('Veuillez saisir au moin une prestation');
      setMsgravity('danger');
      setIsSave(true);
      setLoad(false);
      return;
    }
    var formData = new FormData();
    formData.append('date', datet);
    formData.append('echeance',dateEcheance);
    formData.append('type', type);
    formData.append('numero', nobon);
    formData.append(
      'lignedata',
      types !== 'Facture' ? JSON.stringify(ligneData) : JSON.stringify([])
    );
    formData.append('user_id', common.userid);
    formData.append('chassis', chassis);
    formData.append('immatr_eng', immatriculation);
    formData.append('client_id', clientid);
    formData.append('model_eng', famille_id);
    formData.append('sitva', sitva);
    formData.append('reception_id', reception_id);
    formData.append('marque_id', marque_id);
    formData.append('remise', remise);
    formData.append('assure', assure);
    formData.append('nosinistre', nosinistre);
    formData.append('bondecommande', bondecommande);
    formData.append('nomexpert', nomexpert);
    formData.append('arrondi', arrondi);
    formData.append('autoentre', autoentre);
    formData.append('commentaire', commentaire);
    formData.append('sifournitur', ptifour);
    formData.append('entrepriseid', parseInt(entreprise));
    formData.append('montptifourn', montptifourn);
    Axios({
      url: common.ipapi + '/api/receptionfact/update/' + ids,
      method: 'POST',
      data: formData,
    })
      .then((res) => {
        if (res.data.status == 1) {
          setMsg(res.data.msg);
          setMsgravity('info');
          setIsSave(true);
          setIsDel(false);
          sidelete = 0;
          setLoad(false);
          handleClose();
          getFacture();
        } else {
          setMsg(
            "Une erreur c'est produite veuillez verifier les données saisies"
          );
          setMsgravity('danger');
          setIsSave(true);
          setLoad(false);
        }
      })
      .catch((error) => {
        setIsSave(true);
        setMsgravity('danger');
        setLoad(false);
      });
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      saveFacture();
    }
  };

  function setAddPrestation() {
    setShowAdd(true);
    setShowAddprest(true);
  }

  function setRecepIds(d) {
    if (types === 'Facture') {
      return;
    }
    setRecepId(d.length > 0 ? d[0].id : 0);
    setChassis(d.length > 0 ? d[0].vehicule.chassis : '');
    setFamille(
      d.length > 0 ? (d[0].vehicule.model ? d[0].vehicule.model.id : 0) : 0
    );
    setMarqueId(d.length > 0 ? d[0].vehicule.marque.id : '');
    setImmatr(d.length > 0 ? d[0].vehicule.immatr_eng : '');
    setClientId(d.length > 0 ? d[0].client.id : '');
  }

  function setPrestids(prestation_id) {
    setLoad(true);
    setPrestid(prestation_id);
    if (prestation_id.length > 0) {
      prestation_id[prestation_id.length - 1].qte = 1;
      prestation_id[prestation_id.length - 1].tva = 1;
      let t = [];
      let d = {
        ordre: ligneData.length,
        id: prestation_id[0].id,
        libprestation: prestation_id[0].libprestation,
        qte: 1,
        tva: 1,
        prixprestation: prestation_id[0].prixprestation,
        categorie: prestation_id[0].categorie,
        idprof: 0,
        remise: 0,
        vetuste: 0,
        simodif: true,
      };
      t.push(d);
      t = t.concat(ligneData);
      setLigneData(t);
      setPrestid([]);
    }

    setLoad(false);
  }

  const subTotal = ligneData;
  const onChangeProductQuantity = (index, event) => {
    if (types === 'Facture') {
      return;
    }
    const valueInt = event.target.value;
    const cloneProducts = [...ligneData];
    cloneProducts[index].simodif = true;
    cloneProducts[index].qte = valueInt;
    setLigneData(cloneProducts);
  };

  const onChangePrestation = (index, event) => {
    if (types === 'Facture') {
      return;
    }
    const value = event.target.value;
    const cloneProducts = [...ligneData];
    cloneProducts[index].libprestation = value;
    cloneProducts[index].simodif = true;
    setLigneData(cloneProducts);
  };

  const onChangeRemise = (index, event) => {
    if (types === 'Facture') {
      return;
    }
    const valueInt = event.target.value;
    const cloneProducts = [...ligneData];
    cloneProducts[index].remise = valueInt;
    cloneProducts[index].simodif = true;
    setLigneData(cloneProducts);
  };
  const onChangeVetuste = (index, event) => {
    if (types === 'Facture') {
      return;
    }
    const valueInt = event.target.value;
    const cloneProducts = [...ligneData];
    cloneProducts[index].vetuste = valueInt;
    setLigneData(cloneProducts);
  };
  const onChangePrix = (index, event) => {
    if (types === 'Facture') {
      return;
    }
    const valueInt = parseInt(event.target.value);
    const cloneProducts = [...ligneData];
    cloneProducts[index].prixprestation = valueInt;
    cloneProducts[index].simodif = true;
    setLigneData(cloneProducts);
  };

  const onRemoveProduct = (i, id, idprestation) => {
    if (types === 'Facture') {
      return;
    }
    if (window.confirm('Voulez-vous supprimer cette ligne ? ')) {
      setLoad(true);
      if (idprestation != undefined) {
        Axios.delete(
          common.ipapi + '/api/receptionfact/destroylineprest/' + id
        ).then((response) => {
          if (response.data.status == 1) {
            sidelete = 1;
            const filteredProduct = ligneData.filter((product, index) => {
              return index != i;
            });
            setMsgravity('info');
            setMsg('Ligne supprimée');
            setIsSave(true);
            setLoad(false);
            setLigneData(filteredProduct);
          } else {
            setMsg(response.data.msg);
            setIsSave(true);
            setLoad(false);
          }
        });
      } else {
        const filteredProduct = ligneData.filter((product, index) => {
          return index != i;
        });
        setLigneData(filteredProduct);
        setMsg('Ligne supprimée');
        setMsgravity('info');
        setIsSave(true);
        setLoad(false);
      }
    }
  };
  return (
    <>
      {add != false ? (
        <AddPrestation
          setShowAddprest={setShowAddprest}
          show={showAdd}
          setIsSave={setIsSave}
          setDataPrestation={setDataPrestation}
          setMsg={setMsg}
          setMsgravity={setMsgravity}
        />
      ) : (
        <></>
      )}
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>

      <Modal show={show} fullscreen={true} onHide={() => setShowAdd(false)}>
        <Modal.Header>
          MODIFICATION FACTURE{' '}
          {!isdel ? (
            <BackspaceFill onClick={handleClose} color="red" size={30} />
          ) : (
            <></>
          )}
        </Modal.Header>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            {sidelete ? (
              <Alert key="danger" transition="Fade" variant="info">
                <TriangleHalf color="red" size={30} />{' '}
                <b>Veuillez enregister avant de quitter cette fenêtre</b>
              </Alert>
            ) : (
              <></>
            )}
            <div id="lightbody">
              <Row>
                <Col md={3}>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        Entête
                      </CardSubtitle>
                      {/* <Form.Group as={Col} md="12" controlId="typefac">
                        <InputGroup hasValidation>
                          <ButtonGroup>
                            {[
                              { name: 'Proforma', value: 'Proforma' },
                              { name: 'Facture', value: 'Facture' },
                            ].map((radio, idx) => (
                              <ToggleButton
                                key={idx}
                                id={`radiorevision-${idx}`}
                                type="radio"
                                size="sm"
                                variant={
                                  idx % 2 ? 'outline-danger' : 'outline-danger'
                                }
                                disabled
                                value={radio.value}
                                checked={type === radio.value}
                                onChange={(e) => setType(e.currentTarget.value)}
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                        </InputGroup>
                      </Form.Group> */}
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            {types === 'Facture'
                              ? 'N° facture :'
                              : 'N° proforma :'}
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="N° de pièce"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={nobon}
                            onChange={(e) => setNobon(e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir le numero de facture
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Date édition: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            placeholder="Date édition"
                            aria-describedby="inputGroupPrepend"
                            required
                            size="sm"
                            value={datet}
                            onChange={(e) => setDate(e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir la de la facture
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      
                      <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Date écheance:
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            placeholder="Date écheance"
                            aria-describedby="inputGroupPrepend"
                            // required
                            size="sm"
                            value={dateEcheance}
                            onChange={(e) => setDateEcheance(e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir l'écheance de la facture
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        information reception véhicule
                      </CardSubtitle>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup hasValidation>
                          <Typeahead
                            id="my-typeahead-id"
                            ref={typeaheadVehi}
                            labelKey={(option) =>
                              `N°${option.nofiche} / ${
                                option.vehicule.immatr_eng
                              } / ${dateformat(
                                option.date_entre_garage,
                                'dd-mm-yyyy'
                              )} / ${option.vehicule.chassis}`
                            }
                            filterBy={[
                              'nofiche',
                              'chassis',
                              'immatr_eng',
                              'date_entre_garage',
                            ]}
                            value={reception_id}
                            selected={reception.slice(
                              reception.findIndex(
                                (element) => element.id == reception_id
                              ),
                              reception.findIndex(
                                (element) => element.id == reception_id
                              ) + 1
                            )}
                            options={reception}
                            placeholder="Sélectionner une reception de vehicule"
                            onChange={(cli) => setRecepIds(cli)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir le numéro de fiche
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Client : <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Typeahead
                            id="my-typeahead-id"
                            ref={typeaheadClient}
                            inputProps={{ required: true }}
                            labelKey={(option) => `${option.raisonsocial}`}
                            filterBy={['codecli ', 'raisonsocial']}
                            value={clientid}
                            options={client}
                            selected={client.slice(
                              client.findIndex(
                                (element) => element.id == clientid
                              ),
                              client.findIndex(
                                (element) => element.id == clientid
                              ) + 1
                            )}
                            onChange={(cli) => setClientIds(cli)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir le client
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="immatriculation">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Immatr. :
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder=" immatriculation"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={immatriculation}
                            onChange={(e) =>
                              setImmatr(
                                types === 'Facture'
                                  ? immatriculation
                                  : e.target.value.toUpperCase()
                              )
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="Chassis">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Chassis :
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Chassis"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={chassis}
                            onChange={(e) =>
                              setChassis(
                                types === 'Facture' ? chassis : e.target.value
                              )
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Marques:
                          </InputGroup.Text>
                          <Typeahead
                            id="my-typeahead-id"
                            labelKey={(option) => `${option.libellemarq}`}
                            ref={typeaheadMarq}
                            filterBy={['libellemarq']}
                            value={marque_id}
                            options={marque}
                            selected={marque.slice(
                              marque.findIndex(
                                (element) => element.id == marque_id
                              ),
                              marque.findIndex(
                                (element) => element.id == marque_id
                              ) + 1
                            )}
                            onChange={(cli) => setMarqueIdF(cli)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez selectionnez la marque.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="Enginm">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Model:
                          </InputGroup.Text>
                          <Typeahead
                            ref={typeaheadModel}
                            id="my-typeahead-id"
                            labelKey={(option) => `${option.libellemodel}`}
                            filterBy={['libellemodel']}
                            value={famille_id}
                            selected={famille.slice(
                              famille.findIndex(
                                (element) => element.id == famille_id
                              ),
                              famille.findIndex(
                                (element) => element.id == famille_id
                              ) + 1
                            )}
                            options={famille}
                            onChange={(cli) => setFamilleId(cli)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez renseigner le model.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={9}>
                  <Card>
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        Options
                      </CardSubtitle>
                      <Navbar bg="light" expand="lg">
                        <Container fluid>
                          <ListGroup variant="flush">
                            <ListGroup.Item>Entreprise</ListGroup.Item>
                            <ListGroup.Item>
                              <Form.Group as={Col} controlId="revision">
                                <InputGroup hasValidation>
                                  <ButtonGroup>
                                    {[
                                      { name: 'HR MOTORS', value: '1' },
                                      { name: 'CLUBAUTO', value: '2' },
                                      { name: 'UNITEDMOTORS', value: '3' },
                                    ].map((radio, idx) => (
                                      <ToggleButton
                                        key={idx}
                                        id={`niveau-${idx}`}
                                        type="radio"
                                        size="sm"
                                        variant={
                                          idx % 2
                                            ? 'outline-danger'
                                            : 'outline-danger'
                                        }
                                        value={radio.value}
                                        checked={entreprise === radio.value}
                                        onChange={(e) =>
                                          setEntreprise(e.currentTarget.value)
                                        }
                                      >
                                        {radio.name}
                                      </ToggleButton>
                                    ))}
                                  </ButtonGroup>
                                </InputGroup>
                              </Form.Group>
                            </ListGroup.Item>
                          </ListGroup>
                          <ListGroup variant="flush">
                            <ListGroup.Item>Petite fourniture :</ListGroup.Item>
                            <ListGroup.Item>
                              <Form.Group as={Col} controlId="typefac">
                                <InputGroup hasValidation>
                                  <ButtonGroup>
                                    {[
                                      { name: 'Non', value: '0' },
                                      { name: 'Oui', value: '1' },
                                      { name: 'fixe', value: '2' },
                                    ].map((radio, idx) => (
                                      <ToggleButton
                                        key={idx}
                                        id={`fourni-${idx}`}
                                        type="radio"
                                        size="sm"
                                        disabled= {types === 'Facture' ? true : false}
                                        variant={
                                          idx % 2
                                            ? 'outline-danger'
                                            : 'outline-danger'
                                        }
                                        value={radio.value}
                                        checked={ptifour === radio.value}
                                        onChange={(e) =>
                                          setPtiFourniture(e.target.value)
                                        }
                                      >
                                        {radio.name}
                                      </ToggleButton>
                                    ))}
                                  </ButtonGroup>
                                </InputGroup>
                              </Form.Group>
                            </ListGroup.Item>
                          </ListGroup>
                          <ListGroup variant="flush">
                            <ListGroup.Item>Type d'arrondi:</ListGroup.Item>
                            <ListGroup.Item>
                              <Form.Group as={Col} controlId="revision">
                                <InputGroup hasValidation>
                                  <ButtonGroup>
                                    {[
                                      { name: 'Auto.', value: '0' },
                                      { name: 'Inf', value: '1' },
                                      { name: 'Sup', value: '2' },
                                    ].map((radio, idx) => (
                                      <ToggleButton
                                        key={idx}
                                        id={`arrondi-${idx}`}
                                        type="radio"
                                        size="sm"
                                        disabled= {types === 'Facture' ? true : false}
                                        variant={
                                          idx % 2
                                            ? 'outline-danger'
                                            : 'outline-danger'
                                        }
                                        value={radio.value}
                                        checked={arrondi === radio.value}
                                        onChange={(e) =>
                                          setArrondi(e.currentTarget.value)
                                        }
                                      >
                                        {radio.name}
                                      </ToggleButton>
                                    ))}
                                  </ButtonGroup>
                                </InputGroup>
                              </Form.Group>
                            </ListGroup.Item>
                          </ListGroup>
                          <ListGroup variant="flush">
                            <ListGroup.Item>Option</ListGroup.Item>
                            <ListGroup.Item>
                              <ButtonGroup>
                                {[
                                  { name: 'tva-oui', value: '0' },
                                  { name: 'tva-non', value: '1' },
                                ].map((radio, idx) => (
                                  <ToggleButton
                                    key={idx}
                                    id={`radior-${idx}`}
                                    type="radio"
                                    size="sm"
                                    disabled= {types === 'Facture' ? true : false}
                                    variant={
                                      idx % 2
                                        ? 'outline-danger'
                                        : 'outline-danger'
                                    }
                                    value={radio.value}
                                    checked={sitva === radio.value}
                                    onChange={(e) =>
                                      setTva(e.currentTarget.value)
                                    }
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </ListGroup.Item>
                          </ListGroup>
                        </Container>
                      </Navbar>
                      {ptifour === '2' ? (
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustomUsername"
                        >
                          <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                              Montant petite fourn. :
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              min={0}
                              placeholder="Montant petite fourn."
                              aria-describedby="inputGroupPrepend"
                              size="sm"
                              value={montptifourn}
                              onChange={(e) => {
                                setMontPtiFourn(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Veuillez saisir le numéro de facture
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      ) : (
                        <></>
                      )}
                    </CardBody>
                  </Card>

                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        information complémentaire
                      </CardSubtitle>
                      <Row>
                        <Col md="4">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustomUsername"
                          >
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                Bon commande:
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                placeholder="Bon commande"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={bondecommande}
                                onChange={(e) => setBoncommande(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustomUse"
                          >
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                Autorisation d'entrée:
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                placeholder="Autorisation d'entrée"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={autoentre}
                                onChange={(e) => setAutoENtre(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustoms"
                          >
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                Autorisation sortie:
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                placeholder="Autorisation sortie"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={nomexpert}
                                onChange={(e) => setNomExpert(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustomUsername"
                          >
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                N° de sinistre :
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                placeholder="Numéro de sinitre"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={nosinistre}
                                onChange={(e) => setNoSinistre(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustomUsername"
                          >
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                Assuré :
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                placeholder="Nom de l'assuré"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={assure}
                                onChange={(e) => setAssure(e.target.value)}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <InputGroup>
                            <InputGroup.Text>Commentaire</InputGroup.Text>
                            <FormControl
                              as="textarea"
                              aria-label="With textarea"
                              value={commentaire}
                              rows="1"
                              size="sm"
                              onChange={(e) => setComment(e.target.value)}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        LISTE DES PRESTATIONS / PIECES A SELECTIONNEES
                      </CardSubtitle>
                      {types !== 'Facture' ? (
                        <Form.Group as={Col} md="12" controlId="client">
                          <InputGroup hasValidation>
                            <Typeahead
                              id="my-typeahead-id"
                              labelKey={(option) => `${option.libprestation}`}
                              filterBy={['codeprestation', 'libprestation']}
                              placeholder="Veuilez sélectionner vos différentes prestations"
                              selected={prestation_id}
                              options={prestation}
                              onChange={(cli) => setPrestids(cli)}
                            />
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  Ajouter au dictionnaire
                                </Tooltip>
                              }
                            >
                              <PlusCircle
                                color="#51cbce"
                                onClick={() => setAddPrestation()}
                                size={35}
                              />
                            </OverlayTrigger>
                          </InputGroup>
                        </Form.Group>
                      ) : (
                        <></>
                      )}
                      <ProductList
                        products={ligneData}
                        onChangeProductQuantity={onChangeProductQuantity}
                        onRemoveProduct={onRemoveProduct}
                        onChangePrestation={onChangePrestation}
                        onChangeRemise={onChangeRemise}
                        onChangePrix={onChangePrix}
                        onChangeVetuste={onChangeVetuste}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Summary
              subTotal={subTotal}
              ptifour={parseInt(ptifour)}
              montptifourn={parseInt(montptifourn)}
              sitva={sitva}
              arrondi={arrondi}
            />
            <div className="ml-auto">
              <ButtonGroup size="sm">
                {!isdel ? (
                  <Button color="secondary" onClick={handleClose}>
                    <BackspaceFill color="red" size={20} />
                  </Button>
                ) : (
                  <></>
                )}
                <Button type="submit" color="default">
                  <Save2Fill color="#ff7300" size={20} /> Enregister
                </Button>
              </ButtonGroup>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default UpdateFacture;
