import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form,
  InputGroup,
  ButtonGroup,
  Modal,
  ToggleButton,
} from 'react-bootstrap';
import { Button, Input, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { BackspaceFill, Save2Fill, Star } from 'react-bootstrap-icons';
import common from '../../commonData';
var count = 0;

function EditClient({
  id,
  setShowEdit,
  show,
  setIsSave,
  setMsg,
  setMsgravity,
  getClient,
  setLoad,
}) {
  const [validated, setValidated] = useState(false);
  const [codecli, setCodecli] = useState('');
  const [raisonsocial, setRaisonsocial] = useState('');
  const [telcli, SetTelcli] = useState('');
  const [sisociete, setSisociete] = useState('Societé');
  const [bp, setBp] = useState('');
  const [nocompte, setNocompte] = useState('');
  const [nocomptec, setNocomptec] = useState('');
  const [email, setEmail] = useState(null);
  const [pourcetage, setPourcetage] = useState('');
  const [typeregl, setTyperegl] = useState('Espèce');
  const [sitva, setTva] = useState('0');
  const history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/login');
    }
    (async function anyNameFunction() {
      setLoad(true);
      await Axios.get(common.ipapi + '/api/client/edit/' + id)
        .then((response) => {
          setCodecli(response.data.codecli);
          setRaisonsocial(response.data.raisonsocial || '');
          SetTelcli(response.data.telcli || '');
          setSisociete(response.data.sisociete);
          setTva('' + response.data.sitva + '');
          setTyperegl(response.data.typereglement);
          setBp(response.data.bp || '');
          setNocompte(response.data.nocompte || '');
          setNocomptec(response.data.nocomptec || '');
          setEmail(response.data.email);
          if (response.data.remise) {
            setPourcetage(response.data.remise.pourcentage);
          }
          setLoad(false);
        })
        .catch((errors) => {
          setLoad(false);
        });
    })();
  }, [history, id]);

  function saveClient(e) {
    setLoad(true);
    var formData = new FormData();
    formData.append('raisonsocial', raisonsocial);
    formData.append('telcli', telcli);
    formData.append('sisociete', sisociete);
    formData.append('app', 1);
    formData.append('bp', bp);
    formData.append('sitva', sitva);
    formData.append('nocompte', nocompte);
    formData.append('nocomptec', nocomptec);
    formData.append('email', email);
    formData.append('pourcentage', pourcetage);
    formData.append('typereglement', typeregl);
    formData.append('user_id', common.userid);
    Axios({
      url: common.ipapi + '/api/client/update/' + id,
      method: 'POST',
      headers: {
        'Content-Type': 'form-data',
      },
      data: formData,
    })
      .then((res) => {
        if (res.data.status == 1) {
          setMsg(res.data.msg);
          setMsgravity('info');
          getClient();
          setIsSave(true);
          setLoad(false);
          setShowEdit(false);
        } else {
          setMsg(res.data.msg);
          setMsgravity('danger');
          setIsSave(true);
          setLoad(false);
        }
      })
      .catch((error) => {
        setMsg(error);
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
      });
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      saveClient();
    }
  };

  const handleClose = () => {
    setShowEdit(false);
    setIsSave(false);
  };
  return (
    <>
      <Modal show={show} size="md">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header>
            MODIFIER UN CLIENT
            <BackspaceFill onClick={handleClose} color="red" size={30} />
          </Modal.Header>

          <Modal.Body>
            <Form.Group as={Col} md="12" controlId="client">
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  Raison social:
                  <Star color="red" size={10} />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Raison social"
                  aria-describedby="inputGroupPrepend"
                  required
                  size="sm"
                  value={raisonsocial}
                  onChange={(e) =>
                    setRaisonsocial(e.target.value.toUpperCase())
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Veuillez rensaigner le nom du client
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Row>
              <Col md={6}>
                <ButtonGroup>
                  {[
                    { name: 'Espèce', value: 'Espèce' },
                    { name: 'Chèque', value: 'Chèque' },
                  ].map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radiorevision-${idx}`}
                      type="radio"
                      size="sm"
                      variant={idx % 2 ? 'outline-danger' : 'outline-danger'}
                      value={radio.value}
                      checked={typeregl === radio.value}
                      onChange={(e) => setTyperegl(e.currentTarget.value)}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Col>
              <Col md={6}>
                <ButtonGroup>
                  {[
                    { name: 'tva-oui', value: '0' },
                    { name: 'tva-non', value: '1' },
                  ].map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radior-${idx}`}
                      type="radio"
                      size="sm"
                      variant={idx % 2 ? 'outline-danger' : 'outline-danger'}
                      value={radio.value}
                      checked={sitva === radio.value}
                      onChange={(e) => setTva(e.currentTarget.value)}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Col>
            </Row>

            <Form.Group as={Col} md="12" controlId="tel">
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  Telephone:
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Telephone"
                  aria-describedby="inputGroupPrepend"
                  required
                  size="sm"
                  value={telcli}
                  onChange={(e) => SetTelcli(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Veuillez renseigner le contact du client
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="type">
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  Type client:
                </InputGroup.Text>
                <Input
                  type="select"
                  value={sisociete}
                  onChange={(e) => setSisociete(e.target.value)}
                >
                  <option value="Assurance">Assurance</option>
                  <option value="Societé">societé</option>
                  <option value="Particulier">Particulier</option>
                </Input>
                <Form.Control.Feedback type="invalid">
                  Veuillez Sélectionner le type de client
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="bp">
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  N° compte tier
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Numéro de compte tier"
                  aria-describedby="inputGroupPrepend"
                  size="sm"
                  value={nocompte}
                  onChange={(e) => setNocompte(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="bp">
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  N° compte contribuable
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Numéro de compte contribuable"
                  aria-describedby="inputGroupPrepend"
                  size="sm"
                  value={nocomptec}
                  onChange={(e) => setNocomptec(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="bp">
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">Email</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="email"
                  aria-describedby="inputGroupPrepend"
                  size="sm"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="bp">
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  Boite postale
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Boite postale"
                  aria-describedby="inputGroupPrepend"
                  size="sm"
                  value={bp}
                  onChange={(e) => setBp(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="reduction">
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">
                  Pourcentage:
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Pourcentage"
                  aria-describedby="inputGroupPrepend"
                  size="sm"
                  value={pourcetage}
                  onChange={(e) => setPourcetage(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
            <Modal.Footer>
              <ButtonGroup size="sm">
                <Button color="secondary" onClick={handleClose}>
                  <BackspaceFill color="red" size={20} />{' '}
                </Button>
                <Button type="submit" color="default">
                  <Save2Fill color="#807f7e" size={20} /> Modifier
                </Button>
              </ButtonGroup>
            </Modal.Footer>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}

export default EditClient;
