import React,{useEffect,useState} from "react";
// react plugin used to create charts
// import { Line, Pie } from "react-chartjs-2";
import {useHistory,Link} from 'react-router-dom'
// reactstrap components
import { Card,Row, Col,Button} from "reactstrap";
// core components
import { PencilSquare, PlusCircleDotted, Trash } from 'react-bootstrap-icons';
import Tables from './tableData'
import {Spinner,Tab,Nav,ButtonGroup} from "react-bootstrap";
import common from './commonData'
import Axios from 'axios';

function Dashboard() {
  const [users, setUsers]= useState([])
  const history = useHistory();
  const [load,setLoad]=useState(true)

  useEffect( ()=>{
    if (localStorage.getItem("user-info")) {
      setLoad(true)
      const getUser = Axios.get(common.ipapi+'/api/user/indexAll')
      Axios.all([getUser]).then(Axios.spread((...responses) => {
        setUsers(responses[0].data)
        setLoad(false)
      })).catch(errors => {
          setLoad(false)
      })
      setLoad(true);
    }else{
      history.push("/login")
    }
  },[history])

  function filtre(userd,id){
    return userd.filter(function (el) {
      
      return el.id !== id;
    })
  }
  function  onDelete (id) {
    if(window.confirm("Voulez-vous supprimer ce utilisateur ?")){
         console.log(filtre(users,id))
    }
  }


  const Operation = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
      <ButtonGroup aria-label="Basic example">
            <Link to={`/admin/usersup/${data.id}`} id="actionbtn"><PencilSquare  size={20}  color='green'/></Link>
            <a href="#/admin/config" id="actionbtn" onClick={()=>onDelete(data.id)}><Trash color="red" size={20} /></a>
      </ButtonGroup>
    )
  }
  const Images = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
        <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            <Link to={`/admin/usersup/${data.id}`}>{<img alt="" className="imgart rounded" src={common.ipapi+"/"+data.photo}></img>}</Link>
        </div>
    )
  }
  const columns = [
      {
        id: 1, 
        cellRenderer: Images, 
        label: '#',
        width: '100px',
        pinned: true
    },
      {
          id: 2, 
          field: 'name', 
          label: 'Nom',
      },
      {
          id: 3, 
          field: 'email', 
          label: 'Email',
      },
      {
          id: 4, 
          field: 'created_at', 
          label: 'Crée le',
      },
      {
        id: 5,  
        label: 'Actions',
        cellRenderer: Operation,
        pinned: true,
        width: '70px',
        minResizeWidth: 90,
      }, 
  ]; 

  
  return (
    <>
      {
        load ?
          <Spinner animation="grow" variant="warning"/>
          :
          ""
      }
  
      <div className="content">
       
        <Row>
          <Col md="12">
            <Card>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Général</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Utilisateurs</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Droit & permissions</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                      Général
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Nav variant="pills" activeKey="1" className="justify-content-end" >
                          <Link to="/admin/usersadd"><Button variant="light"><PlusCircleDotted color="red" size={20} /> Nouvel utilisateur</Button></Link>
                        </Nav>
                        <Tables columns={columns} rows={users}/>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                      permissions
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Card>
          </Col>
        </Row>
        
      </div>
    </>
  );
}

export default Dashboard;
