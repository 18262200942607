import React from 'react';
import { X } from 'react-bootstrap-icons';
import { Card, CardBody } from 'reactstrap';

export function ProductList({
  products,
  onChangeProductQuantity,
  onRemoveProduct,
  onChangePrestation,
  onChangeRemise,
  onChangePrix,
  onChangeVetuste,
}) {
  return (
    <table className="table table-striped-hover table-sm">
      <thead>
        <tr>
          <th scope="col">DESIGNATION</th>
          <th scope="col">QUANTITÉ</th>
          <th scope="col">P. UNITAIRE</th>
          <th scope="col">REMISE</th>
          <th scope="col">VETUSTE</th>
          <th scope="col">CATEGORIES</th>
          <th scope="col">#</th>
        </tr>
      </thead>
      <tbody>
        {products.map((product, index) => {
          return (
            <tr key={index} hover="true">
              <td>
                <input
                  type="text"
                  className="prestation"
                  value={product.libprestation}
                  onChange={(event) => onChangePrestation(index, event)}
                />
              </td>
              <td>
                <input
                  type="number"
                  className="prix"
                  step="any"
                  min={0}
                  value={product.qte}
                  onChange={(event) => onChangeProductQuantity(index, event)}
                />
              </td>
              <td>
                <input
                  type="number"
                  className="prix"
                  step="any"
                  value={product.prixprestation}
                  onChange={(event) => onChangePrix(index, event)}
                />
              </td>

              <td>
                <input
                  type="number"
                  className="quantity"
                  step="any"
                  min={0}
                  value={product.remise}
                  onChange={(event) => onChangeRemise(index, event)}
                />
              </td>
              <td>
                <input
                  type="number"
                  className="quantity"
                  step="any"
                  min={0}
                  value={product.vetuste}
                  onChange={(event) => onChangeVetuste(index, event)}
                />
              </td>
              <td>
                <div>
                  {product.categorie == 'Pièce' ? (
                    <p id="ligh">{product.categorie}</p>
                  ) : (
                    <p id="succe">{product.categorie}</p>
                  )}{' '}
                </div>
              </td>
              <td>
                <X
                  color="red"
                  size={20}
                  onClick={() =>
                    onRemoveProduct(index, product.id, product.idprestation)
                  }
                ></X>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export function Summary({ subTotal, ptifour, montptifourn, sitva, arrondi }) {
  var qte = 0;
  var ht = 0;
  var tdebour = 0;
  var ptifourn = 0;
  var totalht = 0;
  var totalnet = 0;
  var totalttc = 0;
  var totaltva = 0;
  var remisss = 0;
  var vetuste = 0;
  subTotal.forEach((element) => {
    if (element.qte === '') {
      qte = 0;
    } else {
      qte = element.qte;
    }
    if (element.categorie != 'Debours') {
      element.remise !== 0 && element.vetuste !== 0
        ? (ht +=
            element.prixprestation * qte -
            element.prixprestation * qte * (element.remise / 100) -
            element.prixprestation * qte * (element.vetuste / 100))
        : element.remise === 0 && element.vetuste !== 0
        ? (ht +=
            element.prixprestation * qte -
            element.prixprestation * qte * (element.vetuste / 100))
        : element.remise !== 0 && element.vetuste === 0
        ? (ht +=
            element.prixprestation * qte -
            element.prixprestation * qte * (element.remise / 100))
        : (ht += element.prixprestation * qte);
      element.remise !== 0
        ? (remisss += element.prixprestation * qte * (element.remise / 100))
        : (remisss += 0);
      // element.vetuste !== 0 ? ht += (element.prixprestation*qte)-((element.prixprestation*qte)*(element.vetuste/100)) : ht += element.prixprestation*qte;
      element.vetuste !== 0
        ? (vetuste += element.prixprestation * qte * (element.vetuste / 100))
        : (vetuste += 0);
    } else {
      tdebour += element.prixprestation * qte;
    }
  });
  totalht = tdebour + ht + remisss + vetuste;
  totalnet = totalht - tdebour - remisss - vetuste;
  if (ptifour == 0) {
    ptifourn = 0;
    totaltva = totalnet * 0.18;
  } else if (ptifour == 2) {
    ptifourn = montptifourn;
    totaltva = ptifourn * 0.18 + totalnet * 0.18;
  } else {
    ptifourn = totalnet * 0.02;
    totaltva = ptifourn * 0.18 + totalnet * 0.18;
  }
  sitva == 1
    ? (totalttc = totalnet + ptifourn + tdebour)
    : (totalttc = totalnet + totaltva + ptifourn + tdebour);
  if (arrondi == 0) {
    totaltva = Math.round(totaltva);
    totalttc = Math.round(totalttc);
    totalht = Math.round(totalht);
    ptifourn = Math.round(ptifourn);
  } else if (arrondi == 1) {
    totaltva = Math.floor(totaltva);
    totalttc = Math.floor(totalttc);
    totalht = Math.floor(totalht);
    ptifourn = Math.floor(ptifourn);
  } else {
    totaltva = Math.ceil(totaltva);
    totalttc = Math.ceil(totalttc);
    totalht = Math.ceil(totalht);
    ptifourn = Math.ceil(ptifourn);
  }
  const total = totalttc;

  return (
    <p id="lightr">
      Total BRUT: <b>{formatCurrency(totalht)} </b>|| Remise:{' '}
      <b>{formatCurrency(remisss + vetuste)} </b>|| Total NET:{' '}
      <b>{formatCurrency(totalnet)} </b>|| Fourn.:{' '}
      <b>{formatCurrency(ptifourn)} </b>|| Total TVA:{' '}
      <b>{formatCurrency(totaltva)} </b>|| Total TTC:{' '}
      <b>{formatCurrency(total)}</b>
    </p>
  );
}
function formatCurrency(value) {
  return new Intl.NumberFormat().format(Math.round(value));
}
