import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Button,
} from 'react-bootstrap';
import {
  Trash,
  FileExcel,
  PlusCircleDotted,
  Printer,
  PrinterFill,
  Pencil,
  BorderWidth,
  PencilSquare,
  Search,
  ReplyAll
} from 'react-bootstrap-icons';
import { printRecap, printFiche } from './functions';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import XLSX from 'sheetjs-style';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import 'assets/css/tableGar.css';
import dateformat from 'dateformat';
import common from '../../commonData';
function MyAwesomeTable({
  rows,
  isLoading,
  onDelete,
  setIds,
  title,
  setAdds,
  setView,
  setUpdateBon,
  setBonLivModal,
  printBl,
  dataAll,
  setShowFilterModals,
  titre,
  setInitialiseData,
}) {
  window.moment = moment;
  const [gridRef, setGridRef] = useState(null);

  const filterValue = [
    { name: 'nofiche', operator: 'contains', type: 'string', value: '' },
    { name: 'marque', operator: 'contains', type: 'string', value: '' },
    { name: 'model', operator: 'contains', type: 'string', value: '' },
    { name: 'chassis', operator: 'contains', type: 'string', value: '' },
    { name: 'sipayant', operator: 'contains', type: 'string', value: '' },
    { name: 'client', operator: 'contains', type: 'string', value: '' },
    { name: 'typeclient', operator: 'contains', type: 'string', value: '' },
    { name: 'immatr_eng', operator: 'contains', type: 'string', value: '' },
    { name: 'date_entre_garage', operator: 'inrange', type: 'date', value: '' },
    { name: 'datebon', operator: 'inrange', type: 'date', value: '' },
    { name: 'nobon', operator: 'contains', type: 'string', value: '' },
    // { name: 'statut', operator: 'contains', type: 'string', value: '' },
    { name: 'position', operator: 'contains', type: 'string', value: '' },
    { name: 'duregarage', operator: 'gte', type: 'number', value: '' },
  ];

  const Nofiche = ({ data }) => {
    return (
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="tooltip-disabled">Consulter la fiche</Tooltip>}
      >
        {data.proforma.length > 0 ? (
          data.proforma[0].nofact !== null &&
            data.proforma[0].nofact !== 0 &&
            data.proforma[0].etat < 6 &&
            data.proforma[0].etat > 2 ? (
              <Button id="successs" onClick={() => setView(true, data.id)}>
                {data.nofiche}
              </Button>
            ) : (
              <Button id="info" onClick={() => setView(true, data.id)}>
                {data.nofiche}
              </Button>
            )
        ) : (
            <Button id="light" onClick={() => setView(true, data.id)}>
              {data.nofiche}
            </Button>
          )}
      </OverlayTrigger>
    );
  };
  const Immatr = ({ data }) => {
    return (
      <Link
        to={`/prestadmin/historyvehicule/${data.vehiculeid}`}
        id="actionbtns"
        title="Voir l'historique"
      >
        <div style={{ borderBottom: '4px solid ' + data.couleur }}>
          {data.immatr_eng}
        </div>
      </Link>
    );
  };
  const Couleur = ({ data }) => {
    return (
      <Link
        to="#"
        id="actionbtns"
        title="Voir l'historique"
      >
        <div style={{ background: data.couleur }}>
          {data.couleurt}
        </div>
      </Link>
    );
  };


  const Diffdate = ({ data }) => {
    return data.duregarage < 10 ? (
      data.nobon ? (
        <Button id="successs">{data.duregarage}</Button>
      ) : (
          <Button id="light">{data.duregarage}</Button>
        )
    ) : data.nobon ? (
      <Button id="warning">{data.duregarage}</Button>
    ) : (
          <Button id="orange">{data.duregarage}</Button>
        );
  };

  const Datecirc = ({ data }) => {
    return dateformat(data.date_mise_circul, 'dd-mm-yyyy') || 'néant';
  };
  const Datesor = ({ data }) => {
    return data.datebon ? dateformat(data.datebon, 'dd-mm-yyyy') : 'néant';
  };
  const Dateentr = ({ data }) => {
    return dateformat(data.date_entre_garage, 'dd-mm-yyyy') || 'néant';
  };
 
  const Actions = ({ data }) => {
    return (
      // <div className="menudrops">
      <div className="tooltips">
        <BorderWidth size={24} />
        <span className="tooltiptext">
          {data.datebon ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Modifier bon sortie</Tooltip>
              }
            >
              <Pencil
                id="actionbtn"
                size={25}
                color="green"
                onClick={() => setBonLivModal(data,1)}
              />
            </OverlayTrigger>
          ) : (
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">Bon de sortie</Tooltip>}
              >
                <Pencil
                  id="actionbtn"
                  size={25}
                  color="blue"
                  onClick={() => setBonLivModal(data,0)}
                />
              </OverlayTrigger>
            )}
          {data.datebon ? (
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Imprimer BL</Tooltip>}
            >
              <PrinterFill
                id="actionbtn"
                size={25}
                color="brown"
                onClick={() => printBl(data)}
              />
            </OverlayTrigger>
          ) : (
              ''
            )}
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Imprimer la fiche</Tooltip>}
          >
            <Printer
              size={25}
              id="actionbtn"
              color="black"
              onClick={() => printFiche(data)}
            />
          </OverlayTrigger>

          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Modifier la ligne</Tooltip>}
          >
            <PencilSquare
              id="actionbtn"
              onClick={() => setIds(data.id)}
              size={25}
              color="green"
            />
          </OverlayTrigger>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">Supprimer la ligne</Tooltip>
            }
          >
            <Trash
              id="actionbtn"
              onClick={() => onDelete(data.id)}
              color="red"
              size={25}
            />
          </OverlayTrigger>
        </span>
      </div>
    );
  };

  const columns = [
    {
      name: 'id',
      header: 'Id',
      defaultVisible: false,
      defaultWidth: 80,
      type: 'number',
    },
    {
      header: '#',
      defaultWidth: 40,
      type: 'string',
      textAlign: 'start',
      editable: false,
      render: ({ data }) => <Actions data={data} />,
    },
    {
      name: 'nofiche',
      header: 'N° fiche',
      defaultWidth: 80,
      type: 'string',
      textAlign: 'center',
      render: ({ data }) => <Nofiche data={data} />,
    },
    { name: 'client', header: 'Clients', defaultWidth: 200, type: 'string' },
    { name: 'marque', header: 'Marques', defaultWidth: 120, type: 'string' },
    { name: 'model', header: 'Models', defaultWidth: 120, type: 'string' },
    {
      name: 'immatr_eng',
      header: 'Immatriculation',
      defaultWidth: 90,
      type: 'string',
      render: ({ data }) => <Immatr data={data} />,
    },
    {
      name: 'date_entre_garage',
      header: 'Reçu le',
      textAlign: 'center',
      defaultWidth: 100,
      type: 'date',
      dateFormat: 'DD-MM-YYYY',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'DD-MM-YYYY',
          placeholder: index === 1 ? 'Date fin' : 'Date debut',
        };
      },
      render: ({ value, cellProps: { dateFormat } }) =>
        moment(value).format(dateFormat),
    },
 
    {
      name: 'datebon',
      header: 'Sortie le',
      textAlign: 'center',
      defaultWidth: 100,
      type: 'date',
      dateFormat: 'DD-MM-YYYY',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'DD-MM-YYYY',
          placeholder: index === 1 ? 'Date fin' : 'Date debut',
        };
      },
      render: ({ data }) => <Datesor data={data} />,
    },
    {
      name: 'duregarage',
      header: 'Tps Immo. (Jrs.)',
      defaultWidth: 75,
      type: 'number',
      textAlign: 'center',
      render: ({ data }) => <Diffdate data={data} />,
    },
    {
      name: 'nobon',
      header: 'N° bon de sortie',
      defaultWidth: 120,
      type: 'string',
    },
    // { name: 'statut', header: 'Statut', defaultWidth: 170, type: 'string' },
    { name: 'chassis', header: 'Chassis', defaultWidth: 170, type: 'string' },
    { name: 'typeclient', header: 'Type clts', defaultWidth: 100, type: 'string' },
    { name: 'position', header: 'Position', defaultWidth: 120, type: 'string' },
    { name: 'sipayant', header: 'Type', defaultWidth: 130, type: 'string' },
    {
      name: 'datecir',
      header: 'En circul. le',
      defaultWidth: 90,
      type: 'date',
      textAlign: 'center',
      render: ({ data }) => <Datecirc data={data} />,
    },
    {
      name: 'index_compt',
      header: 'Kilometrage',
      defaultWidth: 80,
      type: 'number',
    },
    {
      name: 'couleurt',
      header: 'Couleurs',
      defaultWidth: 90,
      type: 'string',
      render: ({ data }) => <Couleur data={data} />,
    },
    {
      name: 'point_choc',
      header: 'Remaques',
      defaultWidth: 120,
      type: 'string',
    },
    {
      name: 'objet_visite',
      header: 'Objet visite',
      defaultWidth: 120,
      type: 'string',
    },
    {
      name: 'username',
      header: 'Crée par',
      defaultWidth: 120,
      type: 'string',
      render: ({ data }) => data.user || 'Neant',
    },
    {
      name: 'created_at',
      header: 'Crée le',
      defaultWidth: 130,
      type: 'string',
      render: ({ data }) =>
        data.created_at
          ? dateformat(data.created_at, 'dd-mm-yyyy HH:MM')
          : 'néant',
    },
  ];
  const gridStyle = { minHeight: window.screen.height - 200 };

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }

  const [filterDate, setFilterDate] = useState({});
  const onFilterValueChange = useCallback((filterValue) => {
    setFilterDate(filterValue[2].value);
}, []);
  async function printListe() {
    if (gridRef.current.data.length <= 0) {
      return;
    }
    var datavar = [...gridRef.current.data.sort(GetSortOrder('date_entre_garage'))];
    var datedeb = dateformat(datavar[0].date_entre_garage, 'dd-mm-yyyy');
    var datefin = dateformat(datavar[datavar.length - 1].date_entre_garage, 'dd-mm-yyyy');
    var sortieJour=0,entreJour=0, enreparat=0;
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    });
    var width = doc.internal.pageSize.getWidth();
    // var width = doc.internal.pageSize.getWidth();
    const data = [];
    doc.addImage(common.logo1, 'jpg', 14, 5, 30, 30);
    doc.setFontSize(16);
    doc.setFontSize(20);
    doc.setTextColor(0, 0, 0);
    doc.setFillColor(225, 206, 154);
    doc.rect(65, 14, width - 80, 10, 'F');
    doc.text(titre, 90, 22);
    doc.setFontSize(12);

    datavar.forEach((row) => {
      var givenDate = formatDate(new Date(row.date_entre_garage));
      var currentDate = formatDate(new Date());
      if(givenDate === currentDate){
        entreJour++
      }
      if(row.datebon){
        givenDate = formatDate(new Date(row.datebon));
        if(givenDate === currentDate){
          sortieJour++;
        }
      }else{
        enreparat++;
      }
      data.push([
        {content: row.nofiche, styles: { fontStyle: 'bold'}},
        {content: row.sipayant},
        {content: row.client},
        {content: row.typeclient},
        {content: row.marque},
        {content: row.model},
        {content: row.immatr_eng, styles: { fontStyle: 'bold'} },
        {content: row.couleurt ? row.couleurt : "",styles: {fontStyle: 'bold',textColor: row.couleur ==='#FFF' || row.couleur === '#FFFFFF' || row.couleur ==='#fff' || row.couleur === '#ffffff' ? '#000' : '#FFF', fillColor: row.couleur !=='null' ? row.couleur : ""}},
        {content: row.position},
        {content: dateformat(row.date_entre_garage, 'dd-mm-yyyy')},
        {content: row.datebon ? dateformat(row.datebon, 'dd-mm-yyyy') : ""},
        {content: row.duregarage + 'Jr'},
        {content: row.nobon || ''},
      ]);
    });

    const datarecap = [];
    datarecap.push([
      new Intl.NumberFormat('de-DE').format(datavar.length),
      new Intl.NumberFormat('de-DE').format(enreparat),
      new Intl.NumberFormat('de-DE').format(datavar.length-enreparat),
      new Intl.NumberFormat('de-DE').format(entreJour),
      new Intl.NumberFormat('de-DE').format(sortieJour)

    ]);
    if (Object.keys(filterDate).length == 1) {
      if ((filterDate.start === undefined || filterDate.start === '') && filterDate.end !== '') {
          doc.text('Période du: ' + datedeb + ' Au : ' + filterDate.end, 90, 30);
      } else if ((filterDate.end === undefined || filterDate.end === '') && filterDate.start !== '') {
          doc.text('Période du: ' + filterDate.start + ' Au : ' + datefin, 90, 30);
      } else {
          doc.text('Période du: ' + datedeb + ' Au : ' + datefin, 90, 30);
      }
  } else if (Object.keys(filterDate).length == 2) {
      if (filterDate.start !== '' && filterDate.end !== '') {
          doc.text('Période du: ' + filterDate.start + ' Au : ' + filterDate.end, 90, 30);
      } else if (filterDate.start === '' && filterDate.end === '') {
          doc.text('Période du: ' + datedeb + ' Au : ' + datefin, 90, 30);
      } else if (filterDate.start !== '' && filterDate.end === '') {
          doc.text('Période du: ' + filterDate.start + ' Au : ' + datefin, 90, 30);
      } else if (filterDate.start === '' && filterDate.end !== '') {
          doc.text('Période du: ' + datedeb + ' Au : ' + filterDate.end, 90, 30);
      } else {
          doc.text('Période du: ' + datedeb + ' Au : ' + datefin, 90, 30);
      }
  } else {
      doc.text('Période du: ' + datedeb + ' Au : ' + datefin, 90, 30);
  }
  doc.autoTable({
      styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
          cellPadding: 1,
          // lineColor: [0, 0, 0],
          halign: 'center',
          fontSize: 8
      },
      columnStyles: {
          0: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12 },
          1: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12 },
          2: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12 },
          3: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12 },
          4: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12 }
      },
      
      theme: 'grid',
      startY: 35,
      fontSize: 9,
      head: [['TOTAL', 'IMMOBILISES', 'LIVRES','ENTREES JOUR','SORTIES JOURS']],
      body: datarecap
  });


    doc.autoTable({
      styles: {
        fillColor: [0, 0, 0],
        textColor: [255, 255, 255],
        halign: 'center',
        fontSize: 10,
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        1: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        2: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        3: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        4: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        5: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        6: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        7: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        8: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        9: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        10: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        11: {
          halign: 'right',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
        12: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
        },
      },
      theme: 'grid',
      startY: doc.lastAutoTable.finalY + 4,
      fontSize: 6,
      head: [
        [
          'N° fiche',
          'Prestation',
          'Clients',
          'Type clts',
          'Marques',
          'Models',
          'Immatr.',
          'Coul.',
          'Position',
          'Reçu le',
          'Sortie le',
          'Temps immo.',
          'Bon liv.',
        ],
      ],
      body: data,
    });
    const pageCount = doc.internal.getNumberOfPages();
    //var now = new Date();
  //var mois =  now.getMonth()+1
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        'Page ' + String(i) + ' sur ' + String(pageCount),
        210 - 55,
        200,
        null,
        null,
        'right'
      );

      doc.text(
        'Edité par ' + common.user + 'le: ' + dateformat(new Date(), 'dd-mm-yyyy'),
        16,
        200,
        null,
        null,
        'left'
    );
    }
    doc.save('liste reception.pdf');
  }

  //--------------- EXPORT VERS EXCEL ------------------

  const exportToExcel = async () => {
    const raw_data = gridRef.current.data;
    /* flatten objects */
    const rows = raw_data.map((row, index) => ({
      index: index+1,
      nofiche: row.nofiche,
      type: row.sipayant,
      client: row.client,
      marque: row.marque,
      model: row.model,
      immat: row.immatr_eng,
      chassis: row.chassis,
      couleur: row.couleurt ? row.couleurt : '',
      position: row.position,
      entre: dateformat(row.date_entre_garage, 'dd-mm-yyyy'),
      sortie: row.datebon ? dateformat(row.datebon, 'dd-mm-yyyy') : "",
      dure: row.duregarage + 'Jr',
      nobon: row.nobon,
      objet_visite: row.objet_visite,
      point_choc: row.point_choc,
   
    }));

    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes receptions');
    /* fix headers */
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          'Ordre',
          'N° fiche',
          'Prestation',
          'Clients',
          'Marques',
          'Models',
          'Immatr.',
          'Chassis',
          'Couleur',
          'Position',
          'Reçu le',
          'Sortie le',
          'Temps immo.',
          'Bon liv.',
          'Objet visite',
          'Remarque',
        ],
      ],
      { origin: 'A1' }
    );
    /* calculate column width */
    const max_width = rows.reduce((w, r) => Math.max(w, r.nofiche.length), 10);
    worksheet['!cols'] = [{ wch: max_width }];
    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, 'reception.xlsx');
  };

  async function saveAsExcel() {
    exportToExcel();
  }

  return (
    <div>
      <Navbar expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand href="#/prestadmin/receptions">{titre}</Navbar.Brand>
          <Nav className="me-auto">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Faire une reception</Tooltip>
              }
            >
              {setAdds !== undefined ? (
                <Nav.Link
                  onClick={() => setAdds(true, 1)}
                >
                  <PlusCircleDotted color="red" size={25} /> Créer
                </Nav.Link>
              ) : (
                  <></>
                )}
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>
              }
            >
              <Nav.Link
                onClick={() => saveAsExcel()}
                href="#/prestadmin/receptions"
              >
                <FileExcel color="#0080ff" size={25} /> Excel
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>
              }
            >
              <Nav.Link href="#/prestadmin/receptions" onClick={() => printListe()}>
                <Printer color="#0080ff" size={25} /> Imprimer
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  Recapitulatif général du garage année en cours
                </Tooltip>
              }
            >
              <Nav.Link
                href="#/prestadmin/receptions"
                onClick={() => printRecap(dataAll)}
              >
                <PrinterFill color="#0080ff" size={25} /> Recap.
              </Nav.Link>
            </OverlayTrigger>
            
                            
          </Nav>
          <Nav>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                 Recherche filtre
                </Tooltip>
              }
            >
              <Nav.Link
               href="#/prestadmin/receptions"
                onClick={() => setShowFilterModals(true)}
              >
                <Search color="#0080ff" size={25} />
              </Nav.Link>
            </OverlayTrigger>
            
            {
              localStorage.getItem('datedebut') !== null ? (
                <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                   Annuler le filtre
                  </Tooltip>
                }
              >
                <Nav.Link
                 href="#/prestadmin/receptions"
                  onClick={() => setInitialiseData()}
                >
                  <ReplyAll color="red" size={25} />
                </Nav.Link>
              </OverlayTrigger>
               ) : (
                <></>
              )
              
            }
           
          </Nav>
        </Container>
      </Navbar>
      <ReactDataGrid
        idProperty="id"
        handle={setGridRef}
        style={gridStyle}
        loading={isLoading}
        reorderColumns={false}
        pagination
        pageSizes={[200, 400, 600, 800, rows.length]}
        defaultLimit={400}
        showHoverRows={true}
        editable={true}
        showColumnMenuTool={false}
        defaultFilterValue={filterValue}
        onFilterValueChange={onFilterValueChange}
        columns={columns}
        dataSource={rows}
      />
    </div>
  );
}

export default MyAwesomeTable;
