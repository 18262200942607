import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  InputGroup,
  Modal,
  Tooltip,
  Spinner,
  ButtonGroup,
} from 'react-bootstrap';
import { Button, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import { Save, BackspaceFill, Star, Save2Fill } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import common from '../../commonData';
import dateformat from 'dateformat';
var count = 0;

function Facturer({
  factdata,
  setAddfact,
  show,
  setIsSave,
  setMsg,
  setMsgravity,
  getFacture,
}) {
  const [validated, setValidated] = useState(false);
  const [noprof, setNoprof] = useState(factdata.noprof);
  const [datep, setDateprof] = useState(
    dateformat(factdata.dateprof, 'yyyy-mm-dd')
  );
  const [factid, setFactid] = useState(factdata.id);
  const [client, setClient] = useState([]);
  const [clientid, setClientId] = useState(factdata.client_id);
  const typeaheadClient = useRef(null);
  const [datet, setDate] = useState('');
  const [nobon, setNobon] = useState('');
  const [load, setLoad] = useState(false);
  const [dateEcheance, setDateEcheance] = useState('');
  const history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/login');
    }
    (async function anyNameFunction() {
      setLoad(true);
      await Axios.get(common.ipapi + '/api/client/indexmin')
        .then((responses) => {
          setClient(responses.data);
          setLoad(false);
        })
        .catch((errors) => {
          setLoad(false);
        });
    })();
  }, [history, factdata]);

  const handleClose = () => {
    setAddfact(false);
    setIsSave(false);
  };

  // async function getFacture(){
  //   setLoad(true)
  //   await Axios.get(common.ipapi+'/api/receptionfact/index')
  //       .then(response=>{
  //         setDatas(response.data.data)
  //         setDataAll(response.data)
  //         count=0
  //         setLoad(false)
  //       }).catch(errors => {
  //         setLoad(false)
  //       })
  // }
  function setClientIds(d) {
    setClientId(d.length > 0 ? d[0].id : 0);
  }
  function saveFact(e) {
    if (window.confirm('Voulez-vous facturer cette ligne?')) {
      setLoad(true);
      var formData = new FormData();
      formData.append('date', datet);
      formData.append('echeance',dateEcheance);
      formData.append('numero', nobon);
      formData.append('etat', 3);
      formData.append('clientf_id', clientid);
      Axios({
        url: common.ipapi + '/api/receptionfact/updatefact/' + factid,
        method: 'POST',
        headers: {
          'Content-Type': 'form-data',
        },
        data: formData,
      }).then((response) => {
        if (response.data.status == 1) {
          setFactid(0);
          setNobon('');
          setDate('');
          setIsSave(true);
          setMsg(response.data.msg);
          setMsgravity('info');
          count++;
          getFacture();
          handleClose();
        } else {
          setMsg(response.data.msg);
          setIsSave(true);
          setMsgravity('danger');
          setLoad(false);
        }
      });
    }
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      saveFact();
    }
  };

  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>

      <Modal show={show} size="md">
        <Modal.Header>
          FACTURATION PROFORMA{' '}
          <BackspaceFill onClick={handleClose} color="red" size={30} />
        </Modal.Header>

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  N° proforma:
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="N° proforma"
                  aria-describedby="inputGroupPrepend"
                  required
                  size="sm"
                  value={noprof}
                  onChange={(e) => setNoprof(e.target.value)}
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  Veuillez saisir le code article
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  Date proforma:
                </InputGroup.Text>
                <Form.Control
                  type="date"
                  placeholder="Date proforma"
                  aria-describedby="inputGroupPrepend"
                  required
                  disabled
                  size="sm"
                  value={datep}
                  onChange={(e) => setDateprof(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Veuillez saisir le code article
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="client">
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  Client : <Star color="red" size={10} />
                </InputGroup.Text>
                <Typeahead
                  id="my-typeahead-id"
                  ref={typeaheadClient}
                  inputProps={{ required: true }}
                  labelKey={(option) => `${option.raisonsocial}`}
                  filterBy={['codecli ', 'raisonsocial']}
                  value={clientid}
                  options={client}
                  selected={client.slice(
                    client.findIndex((element) => element.id == clientid),
                    client.findIndex((element) => element.id == clientid) + 1
                  )}
                  onChange={(cli) => setClientIds(cli)}
                />
                <Form.Control.Feedback type="invalid">
                  Veuillez saisir le client
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  N° de pièce:
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="N° de pièce"
                  aria-describedby="inputGroupPrepend"
                  // required
                  size="sm"
                  value={nobon}
                  onChange={(e) => setNobon(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Veuillez saisir le numéro de la facture
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  Date édition:
                </InputGroup.Text>
                <Form.Control
                  type="date"
                  placeholder="Date édition"
                  aria-describedby="inputGroupPrepend"
                  required
                  size="sm"
                  value={datet}
                  onChange={(e) => setDate(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Veuillez saisir la date de la facture
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  Date écheance:
                </InputGroup.Text>
                <Form.Control
                  type="date"
                  placeholder="Date écheance"
                  aria-describedby="inputGroupPrepend"
                  required
                  size="sm"
                  value={dateEcheance}
                  onChange={(e) => setDateEcheance(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Veuillez saisir l'écheance de la facture
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup size="sm">
              <Button color="secondary" onClick={handleClose}>
                <BackspaceFill color="red" size={20} />
              </Button>
              <Button type="submit" color="default">
                <Save2Fill color="#807f7e" size={20} /> Enregister
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default Facturer;
