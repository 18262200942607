import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  InputGroup,
  Spinner,
  ButtonGroup,
  Modal,
  ToggleButton,
  FormControl,
  Alert,
  ListGroup,
  Navbar,
  Container,
} from 'react-bootstrap';
import { Button, Row, Col, Card, CardSubtitle, CardBody } from 'reactstrap';

import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { BackspaceFill, Star, TriangleHalf } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import dateformat from 'dateformat';
import common from '../../commonData';
import { Summary, ProductList } from './card';
var count = 0;
var sidelete = 0;
function ViewLight({ ids, show, setShows, setLoad, load, factOrProf }) {
  const [type, setType] = useState('Facture');
  const [datet, setDate] = useState('');
  const [nobon, setNobon] = useState('');
  const [montptifourn, setMontPtiFourn] = useState(0);
  const [ptifour, setPtiFourniture] = useState('1');
  const [entreprise, setEntreprise] = useState('1');
  const [ligneData, setLigneData] = useState([]);
  const [client, setClient] = useState([]);
  const [clientid, setClientId] = useState([]);
  const [marque_id, setMarqueId] = useState(0);
  const [marque, setMarque] = useState([]);
  const [reception_id, setRecepId] = useState(0);
  const [reception, setRecept] = useState([]);
  const [prestation, setDataPrestation] = useState([]);
  const [prestation_id, setPrestid] = useState([]);
  const [remise, setRemise] = useState(0);
  const [immatriculation, setImmatr] = useState('');
  const [chassis, setChassis] = useState('');
  const history = useHistory();
  const [sitva, setTva] = useState(0);
  const [famille, setfamilleData] = useState([]);
  const [famille_id, setFamille] = useState(0);
  const [add, setShowAdd] = useState(false);
  const [assure, setAssure] = useState('');
  const [nosinistre, setNoSinistre] = useState('');
  const [bondecommande, setBoncommande] = useState('');
  const [autoentre, setAutoENtre] = useState('');
  const [nomexpert, setNomExpert] = useState('');
  const [commentaire, setComment] = useState('');
  const typeaheadVehi = useRef('');
  const typeaheadClient = useRef('');
  const typeaheadModel = useRef('');
  const typeaheadMarq = useRef('');
  const [arrondi, setArrondi] = useState('0');

  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/login');
    }
    (async function anyNameFunction() {
      setLoad(true);
      const getFact = Axios.get(
        common.ipapi + '/api/receptionfact/edit/' + ids
      );
      const getdata = Axios.get(common.ipapi + '/api/receptionfact/adddata');
      await Axios.all([getFact, getdata])
        .then(
          Axios.spread((...responses) => {
            setClient(responses[1].data[2]);
            setMarque(responses[1].data[0]);
            setfamilleData(responses[1].data[1]);
            // setfamilleDatas(responses[1].data[1]);
            setRecept(responses[1].data[3]);
            setDataPrestation(responses[1].data[4]);
            // setPrestInter(responses[1].data[4]);
            setTva(responses[0].data.sitva);
            setChassis(responses[0].data.chassis || '');
            setClientId(
              factOrProf
                ? responses[0].data.clientf.id
                : responses[0].data.client.id
            );
            setMarqueId(responses[0].data.marque_id || 0);
            setFamille(responses[0].data.model_eng || '');
            setImmatr(responses[0].data.immatr_eng || '');
            setAssure(
              responses[0].data.assure == 'null' ? '' : responses[0].data.assure
            );
            setBoncommande(
              responses[0].data.bondecommande == 'null'
                ? ''
                : responses[0].data.bondecommande
            );
            setNomExpert(
              responses[0].data.nomexpert == 'null'
                ? ''
                : responses[0].data.nomexpert
            );
            setRemise(responses[0].data.remise);
            setNoSinistre(
              responses[0].data.nosinistre == 'null'
                ? ''
                : responses[0].data.nosinistre
            );
            setRecepId(responses[0].data.reception_id || 0);
            setPtiFourniture(responses[0].data.sifournitur || 0);
            setMontPtiFourn(responses[0].data.petitfourn || 0);
            setLigneData(responses[0].data.ligne);
            setArrondi('' + responses[0].data.arrondi + '' || '0');
            setAutoENtre(
              responses[0].data.autoentre == 'null'
                ? ''
                : responses[0].data.autoentre
            );
            setComment(
              responses[0].data.commentaire == 'null'
                ? ''
                : responses[0].data.commentaire
            );
            setEntreprise('' + responses[0].data.entreprise_id + '' || '1');
            setNobon(
              factOrProf ? responses[0].data.nofact : responses[0].data.noprof
            );
            setType(
              responses[0].data.datefact == null ? 'Proforma' : 'Facture'
            );
            setDate(
              factOrProf
                ? dateformat(responses[0].data.datefact, 'yyyy-mm-dd')
                : dateformat(responses[0].data.dateprof, 'yyyy-mm-dd')
            );
            setPrestid(responses[0].data.ligne);
            setLoad(false);
          })
        )
        .catch((errors) => {
          setLoad(false);
        });
    })();
  }, [history, ids, count]);

  const handleClose = () => {
    setShows(false);
  };

  const subTotal = ligneData;
  const onChangeProductQuantity = (index, event) => {
    const valueInt = event.target.value;
    const cloneProducts = [...ligneData];
    cloneProducts[index].simodif = true;
    cloneProducts[index].qte = valueInt;
    //}

    setLigneData(cloneProducts);
  };

  const onChangePrestation = (index, event) => {
    const value = event.target.value;
    const cloneProducts = [...ligneData];
    cloneProducts[index].libprestation = value;
    cloneProducts[index].simodif = true;
    setLigneData(cloneProducts);
  };

  const onChangeRemise = (index, event) => {
    const valueInt = event.target.value;
    const cloneProducts = [...ligneData];
    cloneProducts[index].remise = valueInt;
    cloneProducts[index].simodif = true;
    setLigneData(cloneProducts);
  };
  const onChangeVetuste = (index, event) => {
    const valueInt = event.target.value;
    const cloneProducts = [...ligneData];
    cloneProducts[index].vetuste = valueInt;
    setLigneData(cloneProducts);
  };
  const onChangePrix = (index, event) => {
    const valueInt = parseInt(event.target.value);
    const cloneProducts = [...ligneData];
    cloneProducts[index].prixprestation = valueInt;
    cloneProducts[index].simodif = true;
    setLigneData(cloneProducts);
  };

  const onRemoveProduct = (i, id, idprestation) => {};
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      <Form noValidate>
        <Modal show={show} fullscreen={true} onHide={() => setShowAdd(false)}>
          <Modal.Header>
            CONSULTATION FACTURE CLIENT
            <BackspaceFill onClick={handleClose} color="red" size={30} />
          </Modal.Header>
          <Modal.Body>
            {sidelete ? (
              <Alert key="danger" transition="Fade" variant="info">
                <TriangleHalf color="red" size={30} />{' '}
                <b>Veuillez enregister avant de quitter cette fenêtre</b>
              </Alert>
            ) : (
              <></>
            )}
            <div id="lightbodys">
              <Row>
                <Col md={3}>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        Entête
                      </CardSubtitle>
                      <Form.Group as={Col} md="12" controlId="typefac">
                        <InputGroup hasValidation>
                          <ButtonGroup>
                            {[
                              { name: 'Proforma', value: 'Proforma' },
                              { name: 'Facture', value: 'Facture' },
                            ].map((radio, idx) => (
                              <ToggleButton
                                key={idx}
                                id={`radiorevision-${idx}`}
                                type="radio"
                                size="sm"
                                variant={
                                  idx % 2 ? 'outline-danger' : 'outline-danger'
                                }
                                value={radio.value}
                                checked={type === radio.value}
                                disabled
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            N° facture :
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="N° de pièce"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={nobon}
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir le numero de facture
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustomUsername"
                      >
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Date édition: <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            placeholder="Date édition"
                            aria-describedby="inputGroupPrepend"
                            required
                            size="sm"
                            value={datet}
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir la de la facture
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        information reception véhicule
                      </CardSubtitle>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup hasValidation>
                          <Typeahead
                            id="my-typeahead-id"
                            ref={typeaheadVehi}
                            labelKey={(option) =>
                              `N°${option.nofiche} / ${
                                option.vehicule.immatr_eng
                              } / ${dateformat(
                                option.date_entre_garage,
                                'dd-mm-yyyy'
                              )} / ${option.vehicule.chassis}`
                            }
                            filterBy={[
                              'nofiche',
                              'chassis',
                              'immatr_eng',
                              'date_entre_garage',
                            ]}
                            value={reception_id}
                            defaultSelected={reception.slice(
                              reception.findIndex(
                                (element) => element.id == reception_id
                              ),
                              reception.findIndex(
                                (element) => element.id == reception_id
                              ) + 1
                            )}
                            options={reception}
                            placeholder="Sélectionner une reception de vehicule"
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir le numéro de fiche
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Client : <Star color="red" size={10} />
                          </InputGroup.Text>
                          <Typeahead
                            id="my-typeahead-id"
                            ref={typeaheadClient}
                            inputProps={{ required: true }}
                            labelKey={(option) => `${option.raisonsocial}`}
                            filterBy={['codecli ', 'raisonsocial']}
                            value={clientid}
                            options={client}
                            defaultSelected={client.slice(
                              client.findIndex(
                                (element) => element.id == clientid
                              ),
                              client.findIndex(
                                (element) => element.id == clientid
                              ) + 1
                            )}
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez saisir le client
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="immatriculation">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Immatr. :
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder=" immatriculation"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={immatriculation}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="Chassis">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Chassis :
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Chassis"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            value={chassis}
                            disabled
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Marques:
                          </InputGroup.Text>
                          <Typeahead
                            id="my-typeahead-id"
                            labelKey={(option) => `${option.libellemarq}`}
                            ref={typeaheadMarq}
                            filterBy={['libellemarq']}
                            value={marque_id}
                            options={marque}
                            defaultSelected={marque.slice(
                              marque.findIndex(
                                (element) => element.id == marque_id
                              ),
                              marque.findIndex(
                                (element) => element.id == marque_id
                              ) + 1
                            )}
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez selectionnez la marque.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="Enginm">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">
                            Model:
                          </InputGroup.Text>
                          <Typeahead
                            ref={typeaheadModel}
                            id="my-typeahead-id"
                            labelKey={(option) => `${option.libellemodel}`}
                            filterBy={['libellemodel']}
                            value={famille_id}
                            defaultSelected={famille.slice(
                              famille.findIndex(
                                (element) => element.id == famille_id
                              ),
                              famille.findIndex(
                                (element) => element.id == famille_id
                              ) + 1
                            )}
                            options={famille}
                            disabled
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez renseigner le model.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={9}>
                  <Card>
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        Options
                      </CardSubtitle>
                      <Navbar bg="light" expand="lg">
                        <Container fluid>
                          <ListGroup variant="flush">
                            <ListGroup.Item>Entreprise</ListGroup.Item>
                            <ListGroup.Item>
                              <Form.Group as={Col} controlId="revision">
                                <InputGroup hasValidation>
                                  <ButtonGroup>
                                    {[
                                      { name: 'HR MOTORS', value: '1' },
                                      { name: 'CLUBAUTO', value: '2' },
                                      { name: 'UNITEDMOTORS', value: '3' },
                                    ].map((radio, idx) => (
                                      <ToggleButton
                                        key={idx}
                                        id={`niveau-${idx}`}
                                        type="radio"
                                        size="sm"
                                        variant={
                                          idx % 2
                                            ? 'outline-danger'
                                            : 'outline-danger'
                                        }
                                        value={radio.value}
                                        checked={entreprise === radio.value}
                                        disabled
                                      >
                                        {radio.name}
                                      </ToggleButton>
                                    ))}
                                  </ButtonGroup>
                                </InputGroup>
                              </Form.Group>
                            </ListGroup.Item>
                          </ListGroup>
                          <ListGroup variant="flush">
                            <ListGroup.Item>Petite fourniture :</ListGroup.Item>
                            <ListGroup.Item>
                              <Form.Group as={Col} controlId="typefac">
                                <InputGroup hasValidation>
                                  <ButtonGroup>
                                    {[
                                      { name: 'Non', value: '0' },
                                      { name: 'Oui', value: '1' },
                                      { name: 'fixe', value: '2' },
                                    ].map((radio, idx) => (
                                      <ToggleButton
                                        key={idx}
                                        id={`fourni-${idx}`}
                                        type="radio"
                                        size="sm"
                                        variant={
                                          idx % 2
                                            ? 'outline-danger'
                                            : 'outline-danger'
                                        }
                                        value={radio.value}
                                        checked={ptifour === radio.value}
                                        disabled
                                      >
                                        {radio.name}
                                      </ToggleButton>
                                    ))}
                                  </ButtonGroup>
                                </InputGroup>
                              </Form.Group>
                            </ListGroup.Item>
                          </ListGroup>
                          <ListGroup variant="flush">
                            <ListGroup.Item>Type d'arrondi:</ListGroup.Item>
                            <ListGroup.Item>
                              <Form.Group as={Col} controlId="revision">
                                <InputGroup hasValidation>
                                  <ButtonGroup>
                                    {[
                                      { name: 'Auto.', value: '0' },
                                      { name: 'Inferieur', value: '1' },
                                      { name: 'Supérieur', value: '2' },
                                    ].map((radio, idx) => (
                                      <ToggleButton
                                        key={idx}
                                        id={`arrondi-${idx}`}
                                        type="radio"
                                        size="sm"
                                        variant={
                                          idx % 2
                                            ? 'outline-danger'
                                            : 'outline-danger'
                                        }
                                        value={radio.value}
                                        checked={arrondi === radio.value}
                                        disabled
                                      >
                                        {radio.name}
                                      </ToggleButton>
                                    ))}
                                  </ButtonGroup>
                                </InputGroup>
                              </Form.Group>
                            </ListGroup.Item>
                          </ListGroup>
                          <ListGroup variant="flush">
                            <ListGroup.Item>Option</ListGroup.Item>
                            <ListGroup.Item>
                              <ButtonGroup>
                                {[
                                  { name: 'tva-oui', value: '0' },
                                  { name: 'tva-non', value: '1' },
                                ].map((radio, idx) => (
                                  <ToggleButton
                                    key={idx}
                                    id={`radior-${idx}`}
                                    type="radio"
                                    size="sm"
                                    variant={
                                      idx % 2
                                        ? 'outline-danger'
                                        : 'outline-danger'
                                    }
                                    value={radio.value}
                                    checked={sitva === radio.value}
                                    disabled
                                  >
                                    {radio.name}
                                  </ToggleButton>
                                ))}
                              </ButtonGroup>
                            </ListGroup.Item>
                          </ListGroup>
                        </Container>
                      </Navbar>
                      {ptifour === '2' ? (
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustomUsername"
                        >
                          <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                              Montant petite fourn. :
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder="Montant petite fourn."
                              aria-describedby="inputGroupPrepend"
                              size="sm"
                              value={montptifourn}
                              disabled
                            />
                            <Form.Control.Feedback type="invalid">
                              Veuillez saisir le numéro de facture
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      ) : (
                        <></>
                      )}
                    </CardBody>
                  </Card>

                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        information complémentaire
                      </CardSubtitle>
                      <Row>
                        <Col md="4">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustomUsername"
                          >
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                Bon commande:
                              </InputGroup.Text>
                              <Form.Control
                                disabled
                                type="text"
                                placeholder="Bon commande"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={bondecommande}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustomUse"
                          >
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                Autorisation d'entrée:
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                disabled
                                placeholder="Autorisation d'entrée"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={autoentre}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustoms"
                          >
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                Autorisation sortie:
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                placeholder="Autorisation sortie"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={nomexpert}
                                disabled
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustomUsername"
                          >
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                N° de sinistre :
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                placeholder="Numéro de sinitre"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={nosinistre}
                                disabled
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustomUsername"
                          >
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                Assuré :
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                placeholder="Nom de l'assuré"
                                aria-describedby="inputGroupPrepend"
                                size="sm"
                                value={assure}
                                disabled
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <InputGroup>
                            <InputGroup.Text>Commentaire</InputGroup.Text>
                            <FormControl
                              as="textarea"
                              aria-label="With textarea"
                              value={commentaire}
                              rows="1"
                              size="sm"
                              disabled
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card id="fixed-cards">
                    <CardBody>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        LISTE DES PRESTATIONS / PIECES A SELECTIONNEES
                      </CardSubtitle>
                      <Form.Group as={Col} md="12" controlId="client">
                        <InputGroup hasValidation>
                          <Typeahead
                            id="my-typeahead-id"
                            labelKey={(option) => `${option.libprestation}`}
                            filterBy={['codeprestation', 'libprestation']}
                            placeholder="Veuilez sélectionner vos différentes prestations"
                            defaultSelected={prestation_id}
                            options={prestation}
                            // onChange={(cli) => setPrestids(cli)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <ProductList
                        products={ligneData}
                        onChangeProductQuantity={onChangeProductQuantity}
                        onRemoveProduct={onRemoveProduct}
                        onChangePrestation={onChangePrestation}
                        onChangeRemise={onChangeRemise}
                        onChangePrix={onChangePrix}
                        onChangeVetuste={onChangeVetuste}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Summary
              subTotal={subTotal}
              ptifour={ptifour}
              montptifourn={montptifourn}
              sitva={sitva}
              arrondi={arrondi}
            />
            <div className="ml-auto">
              <ButtonGroup size="sm">
                <Button color="secondary" onClick={handleClose}>
                  <BackspaceFill color="red" size={20} />
                </Button>
              </ButtonGroup>
            </div>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
}

export default ViewLight;
