import Axios from 'axios';
import dateformat from 'dateformat';
import jsPDF from 'jspdf';
import common from '../../commonData';
import { saveAs } from 'file-saver';
// import XlsxPopulate from "xlsx-populate";

export function dateDiff(date1, date2) {
  var diff = {}; // Initialisation du retour
  var tmp = date2 - date1;

  tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
  diff.sec = tmp % 60; // Extraction du nombre de secondes

  tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
  diff.min = tmp % 60; // Extraction du nombre de minutes

  tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
  diff.hour = tmp % 24; // Extraction du nombre d'heures

  tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
  diff.day = tmp;

  return diff;
}

//------- IMPRIMER LE BON DE LIVRAISON -----------------
export function printBl(data) {
  //alert(id.id)
  // let text = "Voulez-vous imprimer avec ou sans le prix ? \n Ok si vous voulez afficher le prix \n Annuler si vous ne voulez pas afficher le prix"
  // let res = window.confirm(text)
  var doc = new jsPDF();
  var width = doc.internal.pageSize.getWidth();
  const getEdit = Axios.get(common.ipapi + '/api/reception/edit/' + data.id);
  Axios.all([getEdit])
    .then(
      Axios.spread((...responses) => {
        const dataFactures = [];
        const travaux = [];
        const autreinfo = [];
        const satisfaction = [];
        const signature = [];
        var i = 0;
        let h = 0;
        common.ipapi === 'https://192.168.8.109:81'
          ? doc.addImage(common.logo, 'jpg', 12, 10, 30, 30)
          : doc.addImage(common.logo1, 'jpg', 12, 10, 30, 30);
        doc.setFontSize(16);
        common.ipapi === 'https://192.168.8.109:81'
          ? doc.text('CLUB AUTO', 90, 17)
          : doc.text('HR MOTORS', 90, 17);
        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(65, 19, width - 80, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text('BON DE LIVRAISON CLIENT', 90, 26);
        doc.setFontSize(8);
        doc.text(
          150,
          17,
          'Imprimer le: ' + dateformat(new Date(), 'dd-mm-yyyy')
        );
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text(65, 35, 'N° de bon: ' + responses[0].data.nobon);
        doc.text(
          110,
          35,
          'Date livraison: ' +
            dateformat(responses[0].data.datebon, 'dd-mm-yyyy')
        );
        //   doc.rect(14, 45, 180, 30);
        dataFactures.push([
          { content: 'N° de fiche' },
          { content: responses[0].data ? responses[0].data.nofiche : '' },
        ]);
        dataFactures.push([
          { content: 'Noms client' },
          {
            content: responses[0].data.client
              ? responses[0].data.client.raisonsocial
              : '',
          },
        ]);
        dataFactures.push([
          { content: 'Télephone' },
          {
            content: responses[0].data.client
              ? responses[0].data.client.telcli
              : '',
          },
        ]);
        dataFactures.push([
          { content: 'Marque & model' },
          {
            content: responses[0].data.vehicule
              ? responses[0].data.vehicule.marque.libellemarq +
                ' ' +
                responses[0].data.vehicule.model.libellemodel
              : '',
          },
        ]);
        dataFactures.push([
          { content: 'Immatriculation' },
          {
            content: responses[0].data.vehicule
              ? responses[0].data.vehicule.immatr_eng
              : '',
          },
        ]);
        dataFactures.push([
          { content: "Type d'énergie" },
          {
            content: responses[0].data.vehicule
              ? responses[0].data.vehicule.energie === 0
                ? 'Essense'
                : 'Gasoil'
              : '',
          },
        ]);

        travaux.push([
          { content: responses[0].data.objet_visite || 'Néant', colSpan: 2 },
        ]);

        autreinfo.push([
          { content: 'N° de fiche' },
          { content: responses[0].data ? responses[0].data.nofiche : '' },
        ]);
        autreinfo.push([{ content: 'N° de devis' }, { content: '' }]); //responses[0].data.proforma ?  responses[0].data.proforma[0].noprof : ""}])
        autreinfo.push([
          { content: 'N° bon de commande' },
          { content: responses[0].data.bondecommande || 'Néant' },
        ]);
        autreinfo.push([{ content: 'N° de facture' }, { content: '' }]); //responses[0].data.proforma ?  responses[0].data.proforma[0].nofact : ""}])
        autreinfo.push([{ content: 'Règlement' }, { content: '' }]); //responses[0].data.proforma ? responses[0].data.proforma[0].reglement.norecu : 0}])

        // satisfaction.push([{"content" : responses[0].data.objet_visite,"colSpan":2}])
        satisfaction.push([
          { content: '' },
          { content: '' },
          { content: '' },
          { content: '' },
          { content: '' },
        ]);
        signature.push([
          { content: '' },
          { content: '' },
          { content: '' },
          { content: '' },
          { content: '' },
        ]);

        doc.autoTable({
          styles: {
            fillColor: [255, 255, 255],
            textColor: [255, 0, 0],
            halign: 'center',
          },
          columnStyles: {
            0: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
              cellWidth: 50,
            },
            1: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
          },
          theme: 'grid',
          startY: 40,
          margin: { bottom: 35 },
          horizontalPageBreak: true,
          didDrawCell: function (data) {
            h = data.cell.y + 20;
          },
          head: [['INFORMATION CLIENT', '']],
          body: dataFactures,
        });
        doc.autoTable({
          styles: {
            fillColor: [255, 255, 255],
            textColor: [255, 0, 0],
            halign: 'center',
          },
          columnStyles: {
            0: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
              cellWidth: 50,
            },
            1: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
          },
          theme: 'grid',
          startY: 95,
          margin: { bottom: 35 },
          horizontalPageBreak: true,
          didDrawCell: function (data) {
            h = data.cell.y + 20;
          },
          head: [['TRAVAUX EFFECTUES', '']],
          body: travaux,
        });
        doc.autoTable({
          styles: {
            fillColor: [255, 255, 255],
            textColor: [255, 0, 0],
            halign: 'center',
          },
          columnStyles: {
            0: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
              cellWidth: 50,
            },
            1: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
          },
          theme: 'grid',
          startY: 125,
          margin: { bottom: 35 },
          horizontalPageBreak: true,
          didDrawCell: function (data) {
            h = data.cell.y + 20;
          },
          head: [['AUTRES INFORMATIONS', '']],
          body: autreinfo,
        });

        doc.autoTable({
          styles: {
            fillColor: [255, 255, 255],
            textColor: [255, 0, 0],
            halign: 'center',
          },
          columnStyles: {
            0: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
            1: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
            2: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
            3: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
            4: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
          },
          theme: 'grid',
          startY: 175,
          margin: { bottom: 35 },
          horizontalPageBreak: true,
          head: [
            [
              'Très satisfait',
              'Satisfait',
              'Peu satisfait',
              'Insatisfait',
              'Autre',
            ],
          ],
          body: satisfaction,
        });

        doc.autoTable({
          styles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            halign: 'center',
          },
          columnStyles: {
            0: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
              cellPadding: 20,
            },
            1: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
            2: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
            3: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
            4: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
          },
          theme: 'grid',
          startY: 205,
          drawRow: function (row, data) {
            row.height = 80;
          },
          margin: { bottom: 35 },
          horizontalPageBreak: true,
          head: [
            [
              'CHEF ATELIER',
              'DIR DES OPERAT',
              'RECEPTION',
              'COMPTABILITE',
              'VISA CLIENT',
            ],
          ],
          body: signature,
        });
        doc.setFontSize(8);
        doc.setLineWidth(1.5);
        doc.setDrawColor(225, 206, 154);
        doc.line(0, 270, width, 270);
        doc.text(
          'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
          105,
          275,
          null,
          'center'
        );
        doc.text(
          'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 05 44 00 00 00 /  Email: vicaso11@hotmail.fr ',
          105,
          280,
          null,
          'center'
        );
        doc.setLineWidth(0.5);
        doc.save('bl.pdf');
      })
    )
    .catch((errors) => {
      //   setLoad(false)
    });
}

//------- IMPRIMER LA FICHE DE RECEPTION -----------------
export function printFiche(data) {
  //alert(id.id)
  // let text = "Voulez-vous imprimer avec ou sans le prix ? \n Ok si vous voulez afficher le prix \n Annuler si vous ne voulez pas afficher le prix"
  // let res = window.confirm(text)
  var doc = new jsPDF();
  var width = doc.internal.pageSize.getWidth();
  const getEdit = Axios.get(common.ipapi + '/api/reception/edit/' + data.id);
  Axios.all([getEdit])
    .then(
      Axios.spread((...responses) => {
        const dataFactures = [];
        const travaux = [];
        const signature = [];
        var i = 0;
        let h = 0;
        common.ipapi === 'https://192.168.8.109:81'
          ? doc.addImage(common.logo, 'jpg', 12, 10, 30, 30)
          : doc.addImage(common.logo1, 'jpg', 12, 10, 30, 30);
        doc.setFontSize(16);
        common.ipapi === 'https://192.168.8.109:81'
          ? doc.text('CLUB AUTO', 90, 17)
          : doc.text('HR MOTORS', 90, 17);
        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(65, 19, width - 80, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text('FICHE DE RECEPTION ENGIN', 90, 26);
        doc.setFontSize(8);
        doc.text(
          150,
          17,
          'Imprimer le: ' + dateformat(new Date(), 'dd-mm-yyyy')
        );
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text(65, 35, 'N° fiche reception: ' + responses[0].data.nofiche);
        doc.text(
          120,
          35,
          'Date reception: ' +
            dateformat(responses[0].data.date_entre_garage, 'dd-mm-yyyy')
        );
        //   doc.rect(14, 45, 180, 30);
        dataFactures.push([
          { content: 'N° de fiche' },
          { content: responses[0].data ? responses[0].data.nofiche : '' },
        ]);
        dataFactures.push([
          { content: 'Noms client' },
          {
            content: responses[0].data.client
              ? responses[0].data.client.raisonsocial
              : '',
          },
        ]);
        dataFactures.push([
          { content: 'Télephone' },
          {
            content: responses[0].data.client
              ? responses[0].data.client.telcli
              : '',
          },
        ]);
        dataFactures.push([
          { content: 'Marque & model' },
          {
            content: responses[0].data.vehicule
              ? responses[0].data.vehicule.marque.libellemarq +
                ' ' +
                responses[0].data.vehicule.model.libellemodel
              : '',
          },
        ]);
        dataFactures.push([
          { content: 'Immatriculation' },
          {
            content: responses[0].data.vehicule
              ? responses[0].data.vehicule.immatr_eng
              : '',
          },
        ]);
        dataFactures.push([
          { content: "Type d'énergie" },
          {
            content: responses[0].data.vehicule
              ? responses[0].data.vehicule.energie === 0
                ? 'Essense'
                : 'Gasoil'
              : '',
          },
        ]);
        dataFactures.push([
          { content: 'Compteur kilo.' },
          { content: responses[0].data.index_compt },
        ]);

        travaux.push([
          { content: responses[0].data.objet_visite || 'Néant', colSpan: 2 },
        ]);
        signature.push([{ content: '' }, { content: '' }]);

        doc.autoTable({
          styles: {
            fillColor: [255, 255, 255],
            textColor: [255, 0, 0],
            halign: 'center',
          },
          columnStyles: {
            0: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
              cellWidth: 50,
            },
            1: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
          },
          theme: 'grid',
          startY: 40,
          margin: { bottom: 35 },
          horizontalPageBreak: true,
          didDrawCell: function (data) {
            h = data.cell.y + 20;
          },
          head: [['INFORMATION CLIENT', '']],
          body: dataFactures,
        });
        var finalY = doc.lastAutoTable.finalY;
        doc.autoTable({
          styles: {
            fillColor: [255, 255, 255],
            textColor: [255, 0, 0],
            halign: 'center',
          },
          columnStyles: {
            0: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
              cellWidth: 50,
            },
            1: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
          },
          theme: 'grid',
          startY: finalY + 5,
          margin: { bottom: 35 },
          horizontalPageBreak: true,
          didDrawCell: function (data) {
            h = data.cell.y + 20;
          },
          head: [['TRAVAUX EFFECTUES', '']],
          body: travaux,
        });
        var checkBox = new jsPDF.API.AcroFormCheckBox();
        checkBox.fieldName = 'CheckBox2';
        checkBox.Rect = [30, 130, 7, 7];
        checkBox.appearanceState = 'On';
        doc.addField(checkBox);

        doc.autoTable({
          styles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            halign: 'center',
          },
          columnStyles: {
            0: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
              cellPadding: 15,
            },
            1: {
              halign: 'left',
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontSize: 10,
            },
          },
          theme: 'grid',
          startY: 225,
          drawRow: function (row, data) {
            row.height = 35;
          },
          margin: { bottom: 25 },
          horizontalPageBreak: true,
          head: [['CHEF ATELIER', 'VISA CLIENT']],
          body: signature,
        });
        doc.setFontSize(8);
        doc.setLineWidth(1.5);
        doc.setDrawColor(225, 206, 154);
        doc.line(0, 270, width, 270);
        doc.text(
          'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
          105,
          275,
          null,
          'center'
        );
        doc.text(
          'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 05 44 00 00 00 /  Email: vicaso11@hotmail.fr ',
          105,
          280,
          null,
          'center'
        );
        doc.setLineWidth(0.5);
        doc.save('fich-' + responses[0].data.nofiche + '.pdf');
      })
    )
    .catch((errors) => {
      //   setLoad(false)
    });
}

export function printListe(datasource, title) {
  const doc = new jsPDF({
    orientation: 'l',
    unit: 'mm',
    format: 'a4',
    putOnlyUsedFonts: true,
  });
  var nofact;
  var clt;
  var ttc;
  var montpayer = 0;
  var immtr;
  var model;
  var ttcg = 0;
  var tpaie = 0;
  var date1, date2, diff;

  const data = [];
  datasource.map((row) => {
    clt = row.client || 'néant';
    immtr = row.immatr_eng || 'néant';
    model = row.model || 'néant';
    row.nobon ? (date1 = new Date(row.datebon)) : (date1 = new Date());
    date2 = new Date(row.date_entre_garage);
    diff = dateDiff(date2, date1);
    row.proforma.map((l) => {
      montpayer = 0;
      ttc = l.prix_ttc;
      ttcg += l.prix_ttc;
      nofact = l.nofact;
      l.reglement.map((d) => {
        montpayer += d.montant;
        tpaie += d.montant;
      });
    });
    data.push([
      row.nofiche,
      dateformat(row.date_entre_garage, 'dd-mm-yyyy'),
      diff.day,
      clt,
      nofact,
      immtr,
      model,
      new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        currency: 'CFA',
      }).format(ttc),
      new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        currency: 'CFA',
      }).format(montpayer),
      new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        currency: 'CFA',
      }).format(ttc - montpayer),
    ]);
    ttc = 0;
    montpayer = 0;
  });
  if (data.length > 0) {
    data.push([
      'Total',
      '',
      '',
      '',
      '',
      '',
      '',
      new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        currency: 'CFA',
      }).format(ttcg),
      new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        currency: 'CFA',
      }).format(tpaie),
      new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        currency: 'CFA',
      }).format(ttcg - tpaie),
    ]);
  } else {
    return;
  }

  var width = doc.internal.pageSize.getWidth();
  common.ipapi === 'https://192.168.8.109:81'
    ? doc.addImage(common.logo, 'jpg', 12, 10, 30, 30)
    : doc.addImage(common.logo1, 'jpg', 12, 10, 30, 30);
  doc.setFontSize(16);
  common.ipapi === 'https://192.168.8.109:81'
    ? doc.text('CLUB AUTO ENTREPOT', 90, 15)
    : doc.text('HR MOTORS', 90, 15);
  doc.setFontSize(12);
  doc.setFillColor(0, 0, 0);
  doc.rect(65, 17, width - 80, 10, 'F');
  doc.setTextColor(255, 255, 255);
  doc.text(title || 'LISTE DES RECEPTIONS', 90, 24);
  doc.setTextColor(0, 0, 0);
  var ladate = new Date();
  // doc.text("Reception du: "+ladate.getDate()+"-"+(ladate.getMonth()+1)+"-"+ladate.getFullYear() ,65,32)

  doc.autoTable({
    styles: {
      fillColor: [225, 206, 154],
      textColor: [255, 0, 0],
      cellPadding: 0.7,
      halign: 'center',
      fontSize: 10,
    },
    columnStyles: {
      0: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 8,
      },
      1: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 8,
      },
      2: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 8,
      },
      3: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 8,
      },
      4: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 8,
      },
      5: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 8,
      },
      6: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 8,
      },
      7: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 8,
      },
      8: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 8,
      },
      9: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 8,
      },
    },
    theme: 'grid',
    startY: 35,
    fontSize: 10,
    head: [
      [
        'N° fiche',
        'Date fiche',
        'Immo.(Jrs)',
        'Client',
        'N° facture',
        'Immatr.',
        'Model',
        'Montant TTC',
        'Mont. payer',
        'Reste',
      ],
    ],
    body: data,
  });
  const pageCount = doc.internal.getNumberOfPages();
  for (var i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      'Page ' + String(i) + ' sur ' + String(pageCount),
      210 - 55,
      200,
      null,
      null,
      'right'
    );
  }
  doc.save(
    'point-' +
      ladate.getDate() +
      '-' +
      (ladate.getMonth() + 1) +
      '-' +
      ladate.getFullYear() +
      '.pdf'
  );
}

export function printRecap(datasource) {
  var ladate = new Date();
  var trim = Math.ceil(ladate.getMonth() / 3);
  const doc = new jsPDF();
  const data = [];
  const datas = [];
  const datass = [];
  var dat = [];
  var month = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mais',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  var jours = [
    '',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ];

  data.push([
    {
      content:
        jours[ladate.getDay()] +
        ' ' +
        dateformat(
          ladate.getMonth() +
            1 +
            '/' +
            ladate.getDate() +
            '/' +
            ladate.getFullYear(),
          'dd-mm-yyyy'
        ),
    },
    { content: datasource.entrejour },
    { content: datasource.sortiejour },
  ]);
  data.push([
    { content: 'MOIS EN COURS (' + month[ladate.getMonth()] + ')' },
    { content: datasource.entremoisencour },
    { content: datasource.sortiemoisencour },
  ]);
  datasource.trim1 !== 0 || datasource.trim1s !== 0
    ? datass.push([
        { content: trim === 1 ? 'TRIMESTRE 1 (en cours)' : 'TRIMESTRE 1' },
        { content: datasource.trim1 },
        { content: datasource.trim1s },
      ])
    : dat.push([]);
  datasource.trim2 !== 0 || datasource.trim2s !== 0
    ? datass.push([
        { content: trim === 2 ? 'TRIMESTRE 2 (en cours)' : 'TRIMESTRE 2' },
        { content: datasource.trim2 },
        { content: datasource.trim2s },
      ])
    : dat.push([]);
  datasource.trim3 !== 0 || datasource.trim3s !== 0
    ? datass.push([
        { content: trim === 3 ? 'TRIMESTRE 3 (en cours)' : 'TRIMESTRE 3' },
        { content: datasource.trim3 },
        { content: datasource.trim3s },
      ])
    : dat.push([]);
  datasource.trim4 !== 0 || datasource.trim4s !== 0
    ? datass.push([
        { content: trim === 4 ? 'TRIMESTRE 4 (en cours)' : 'TRIMESTRE 4' },
        { content: datasource.trim4 },
        { content: datasource.trim4s },
      ])
    : dat.push([]);
  datass.push([
    { content: 'TOTAL' },
    {
      content:
        datasource.trim1 +
        datasource.trim2 +
        datasource.trim3 +
        datasource.trim4,
    },
    {
      content:
        datasource.trim1s +
        datasource.trim2s +
        datasource.trim3s +
        datasource.trim4s,
    },
  ]);

  datas.push([
    { content: 'FICHES FACTUREES' },
    { content: datasource.totalfacturer },
  ]);
  datas.push([
    { content: 'FICHES NON FACTUREES' },
    { content: datasource.total - datasource.totalfacturer },
  ]);
  datas.push([
    { content: 'VEHICULES IMMOBILISES' },
    { content: datasource.immo },
  ]);
  datas.push([{ content: 'FICHES TOTALS' }, { content: datasource.total }]);
  var width = doc.internal.pageSize.getWidth();
  common.ipapi === 'https://192.168.8.109:81'
    ? doc.addImage(common.logo, 'jpg', 10, 8, 30, 30)
    : doc.addImage(common.logo1, 'jpg', 10, 8, 30, 30);
  doc.setFontSize(16);
  common.ipapi === 'https://192.168.8.109:81'
    ? doc.text('CLUB AUTO ENTREPOT', 90, 15)
    : doc.text('', 90, 15);
  doc.setFontSize(12);
  doc.setFillColor(225, 206, 154);
  doc.rect(40, 17, width - 55, 10, 'F');
  doc.setTextColor(0, 0, 0);
  doc.text('RECAPITULATIF GENERAL DES ACTIVITES DU GARAGE', 60, 24);
  doc.setTextColor(0, 0, 0);

  doc.autoTable({
    styles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      halign: 'center',
      fontSize: 12,
    },
    columnStyles: {
      0: {
        halign: 'left',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
      1: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
      2: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
    },
    theme: 'grid',
    startY: 35,
    fontSize: 10,
    head: [['PERIODE', 'RECEPTION ', 'LIVRAISON']],
    body: data,
  });

  doc.autoTable({
    styles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      halign: 'center',
      fontSize: 12,
    },
    columnStyles: {
      0: {
        halign: 'left',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
      1: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
      2: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
    },
    theme: 'grid',
    startY: doc.lastAutoTable.finalY + 5,
    fontSize: 10,
    head: [['PERIODE', 'RECEPTION ', 'LIVRAISON']],
    body: datass,
  });

  doc.autoTable({
    styles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      halign: 'center',
      fontSize: 12,
    },
    columnStyles: {
      0: {
        halign: 'left',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
      1: {
        halign: 'center',
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
      },
    },
    theme: 'grid',
    startY: doc.lastAutoTable.finalY + 5,
    fontSize: 10,
    head: [['LIBELLE', 'QUANTITE']],
    body: datas,
  });
  doc.setFontSize(8);
  doc.setLineWidth(1.5);
  doc.setDrawColor(0, 0, 0);
  doc.line(0, 275, width, 275);
  doc.text(
    'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
    105,
    280,
    null,
    'center'
  );
  doc.text(
    'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 05 44 00 00 00 /  Email: vicaso11@hotmail.fr ',
    105,
    285,
    null,
    'center'
  );

  doc.text(
    'Abidjan le: ' +
      dateformat(
        ladate.getMonth() +
          1 +
          '/' +
          ladate.getDate() +
          '/' +
          ladate.getFullYear(),
        'dd-mm-yyyy'
      ),
    210 - 18,
    15,
    null,
    null,
    'right'
  );
  doc.save('recap-generale.pdf');
}

//--------------- EXPORT VERS EXCEL ------------------
function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : '';
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

export async function saveAsExcel(datas) {
  // var nofact
  // var clt
  // var ttc
  // var montpayer = 0
  // var immtr
  // var model
  // var date1,date2,diff;
  // if(datas.length<=0){
  //   return
  // }
  // const data = [];
  // datas.forEach((row) => {
  //   clt = row.client || 'néant'
  //   immtr = row.immatr_eng || 'néant'
  //   model = row.model || 'néant'
  //   row.nobon ? date1 = new Date(row.datebon) : date1 = new Date()
  //   date2 = new Date(row.date_entre_garage);
  //   diff = dateDiff(date2, date1);
  //   row.proforma.map((l) => {
  //     montpayer = 0
  //     ttc = l.prix_ttc
  //     nofact = l.nofact
  //     l.reglement.map((d) => {
  //           montpayer+=d.montant
  //     })
  //   })
  //     data.push(
  //       { nofiche: row.nofiche,date: dateformat(row.date_entre_garage,"dd-mm-yyyy"),datediff: diff.day,clt: clt, nofact: nofact, immtr: immtr,model: model, mont: new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(ttc), montpay: new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(montpayer),reste: new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(ttc-montpayer)}
  //     )
  // })
  // let header = ["N° fiche","Date fiche","Temps immo.(Jrs)", "Client", "N° facture", 'Immatr.','Model', "Montant TTC", "Mont. payer","Reste"];
  // XlsxPopulate.fromBlankAsync().then(async (workbook) => {
  //   const sheet1 = workbook.sheet(0);
  //   const sheetData = getSheetData(data, header);
  //   const totalColumns = sheetData[0].length;
  //   sheet1.cell("A1").value(sheetData);
  //   const range = sheet1.usedRange();
  //   const endColumn = String.fromCharCode(64 + totalColumns);
  //   sheet1.row(1).style("bold", true);
  //   sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
  //   range.style("border", true);
  //   return workbook.outputAsync().then((res) => {
  //     saveAs(res, "reception.xlsx");
  //   });
  // });
}
