import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  InputGroup,
  Modal,
  Spinner,
  ButtonGroup,
} from 'react-bootstrap';
import { Button, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Save2Fill, BackspaceFill } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import dateformat from 'dateformat';

import common from '../../commonData';
import { ProductList, Summary } from './card';
var count = 0;

function AddReglement({
  setShowAdd,
  show,
  setIsSave,
  setMsg,
  setMsgravity,
  setActualise, actualise 
}) {
  var ladate = new Date();
  const [proforma, setProforma] = useState([]);
  const [factureselect, setFactureSelect] = useState([]);

  const [states, setStates] = useState({
    sicheqVir: false,
    type: 'Espèce',
    date_regl: dateformat(
      ladate.getMonth() +
      1 +
      '/' +
      ladate.getDate() +
      '/' +
      ladate.getFullYear(),
      'yyyy-mm-dd'
    ),
    norecu:'',
    nocheq:'',
    montant:'',
    idprof:0,
    montantFact:''
  });
  const { sicheqVir,type,date_regl,norecu,nocheq,montant,idprof,montantFact } = states;
  const history = useHistory();
  const nofactref = useRef(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('user-info-g')) {
      history.push('/login');
    }
    (async function anyNameFunction() {
      setLoad(true);
      await Axios.get(common.ipapi + '/api/receptionfact/facturenonsolde')
        .then((response) => {
          setProforma(response.data);
          setLoad(false);
        })
        .catch((errors) => {
          setLoad(false);
        });
    })();
  }, [history]);

  const handleClose = () => {
    reset();
    setShowAdd(false);
    setIsSave(false);
  };
  const reset = () => {
    setStates({
      sicheqVir: false,
      type: 'Espèce',
      date_regl: dateformat(
        ladate.getMonth() +
        1 +
        '/' +
        ladate.getDate() +
        '/' +
        ladate.getFullYear(),
        'yyyy-mm-dd'
      ),
      norecu:'',
      nocheq:'',
      montant:'',
      idprof:0,
      montantFact:''
    });
    setFactureSelect([]);
    nofactref.current.clear();
  };

  function saveFacture(values) {
    setLoad(true);
    if (values.montant === 0) {
      setMsg('Impossible de faire un versement sur cette facture');
      setMsgravity('danger');
      setIsSave(true);
      setLoad(false);
      return;
    }
    const initialValue = 0;
    const sumWithInitial = factureselect.reduce(
      (accumulator, currentValue) => accumulator + currentValue.reste,
      initialValue,
    );
    if(sumWithInitial!=values.montant){
      setMsg('La somme imputée doit être égale au montant du reglément');
      setMsgravity('warning');
      setIsSave(true);
      setLoad(false);
      return;
    }

    var formData = new FormData();
    formData.append('date_regl', values.date_regl);
    formData.append('type', values.type);
    formData.append('norecu', values.norecu);
    formData.append('nocheq', values.nocheq);
    formData.append('user_id', common.userid);
    formData.append('montant', values.montant);
    formData.append('ligne', JSON.stringify(factureselect));
    Axios({
      url: common.ipapi + '/api/reglementprest/store',
      method: 'POST',
      data: formData,
    })
      .then((res) => {
        if (res.data.status === 1) {
          setMsg(res.data.msg);
          setMsgravity('info');
          setIsSave(true);
          reset();
          //count++;
          setActualise(!actualise);
          setLoad(false);
        } else {
          setMsg(
            "Une erreur c'est produite veuillez verifier les données saisies"
          );
          setMsgravity('warning');
          setIsSave(true);
          setLoad(false);
        }
      })
      .catch((error) => {
        setIsSave(true);
        setMsgravity('warning');
        setLoad(false);
      });
  }


  function setProfids(proforma) {
    if (proforma.length > 0) {
      let t = [];
      const del = factureselect.filter((d) => d.id === proforma[0].id);
      if (del.length === 0) {
        var sameClientID = true;
        factureselect.forEach(element => {
          if(element.clientid != proforma[0].clientf_id){
           sameClientID=false;
          }
        });
        if(sameClientID){
          let d = {
            ordre: factureselect.length+1,
            id: proforma[0].id,
            nofact: proforma[0].nofact,
            montant: proforma[0].prix_ttc,
            montantimput: proforma[0].prix_ttc - proforma[0].montant,
            reste:proforma[0].prix_ttc,
            clientid: proforma[0].clientf_id
          };
          t.push(d);
          t = t.concat(factureselect);
          setFactureSelect(t)
        }else{
          setMsg('Veuillez sélectionner les facture du même client');
          setMsgravity('danger');
          setIsSave(true);
          return;
        }
       ;
      }else{
        setMsg('Cette facture existe déjà dans la liste');
        setMsgravity('danger');
        setIsSave(true);
      }
      // setStates({idprof:0});
    }
  }
 
  const onChangeMontant = (index, event) => {
    var valueInt = parseInt(event.target.value);
    const cloneProducts = [...factureselect];
    event.target.value <= cloneProducts[index].montantimput
      ? (valueInt = parseInt(event.target.value))
      : (valueInt = montant);
    cloneProducts[index].reste = valueInt;
    setFactureSelect(cloneProducts);
  };
  const onRemoveProduct = (i) => {
    const filteredProduct = factureselect.filter((product, index) => {
      return index !== i;
    });
    setFactureSelect(filteredProduct);
    setMsg('Ligne supprimée');
    setMsgravity('success');
    setIsSave(true);
    setLoad(false);
  };
 
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>
      
      <Formik
                initialValues={{
                  sicheqVir: sicheqVir,
                  type: type,
                  date_regl: date_regl,
                  norecu: norecu,
                  nocheq: nocheq,
                  montant: montant,
                  idprof: idprof,
                  montantFact: montantFact
                }}
                 enableReinitialize
                validationSchema={Yup.object().shape({
                  date_regl: Yup.date().required('Veuillez saisir cette donnée'),
                  montant: Yup.number().typeError('Aucune correspondance').required('Veuillez saisir cette donnée'),
                })}
                onSubmit={async (values) => {
                  saveFacture(values);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, values, setFieldValue, setFieldTouched }) => (
            
      <Modal show={show} size="lg">
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
              FAIRE UN REGLEMENT <BackspaceFill onClick={handleClose} color="red" size={25} />
          </Modal.Header>
          <Modal.Body>
            <div id="lightbody">
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={5}>Montant:</Form.Label>
                        <Col sm={7}>
                          <Form.Control
                            id='montant'
                            type="text"
                            placeholder="Montant"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            min={0}
                            step="any"
                            value={values.montant}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col> {errors.montant && <div id="feedback">{errors.montant}</div>}
                      </Form.Group>
                     </Col> 
                    <Col md={6}>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={5}>Date édition:</Form.Label>
                        <Col sm={7}>
                          <Form.Control
                            type="date"
                            placeholder="Date édition"
                            aria-describedby="inputGroupPrepend"
                            id='date_regl'
                            size="sm"
                            value={values.date_regl}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                        {errors.date_regl && <div id="feedback">{errors.date_regl}</div>}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={5}>N° de reçu:</Form.Label>
                        <Col sm={7}>
                          <Form.Control
                            type="text"
                            placeholder="N° de reçu"
                            aria-describedby="inputGroupPrepend"
                            size="sm"
                            id='norecu'
                            value={values.norecu}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group as={Col} md="12" controlId="revision">
                        <InputGroup hasValidation>
                          
                          <ButtonGroup>
                            {[
                              { value: 'Espèce' },
                              {  value: 'Chèque' },
                              { value: 'Virement' },
                            ].map((radio, idx) => (
                              <label key={idx}>
                                    <Field type="radio" name='type' value={radio.value} />
                                    {radio.value}
                                  </label>
                            ))}
                          </ButtonGroup>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    {values.type =='Chèque' || values.type =='Virement' ? (
                      <Col md={6}>
                         <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={5}>N° chèque/virement:</Form.Label>
                        <Col sm={7}>
                            <Form.Control
                              id='nocheq'
                              type="text"
                              placeholder="N° chèque/virement"
                              aria-describedby="inputGroupPrepend"
                              size="sm"
                              value={values.nocheq}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Col>
              </Row>
              <hr></hr>
              <Form.Group as={Col} md="12" controlId="client">
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    Facture:
                  </InputGroup.Text>
                  <Typeahead
                    id="my-typeahead-id"
                    key={20}
                    ref={nofactref}
                    inputProps={{ required: true }}
                    labelKey={(proforma) =>
                      `N° facture: ${proforma.nofact} Montant: ${
                        proforma.prix_ttc - proforma.montant
                      }`
                    }
                    filterBy={['nofact']}
                    value={idprof}
                    options={proforma}
                    onChange={(proforma) => setProfids(proforma)}
                  />
                </InputGroup>
              </Form.Group>
              <ProductList
                ligne={factureselect}
                onChangeMontant={onChangeMontant}
                onRemoveProduct={onRemoveProduct}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup aria-label="Basic example" size="sm">
              <Button color="secondary" onClick={handleClose}>
                <BackspaceFill color="red" size={20} />
              </Button>
              <Button type="submit" color="default">
                <Save2Fill color="#ff7300" size={20} /> Enregister
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Form>
      </Modal>
       )}
       </Formik>
    </>
  );
}

export default AddReglement;
