import * as React from 'react';

import config from '../../../commonData';
import { Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Pencil, PlusCircleDotted, X } from 'react-bootstrap-icons';
export default function AccessibilityTooltips({ selected, handleClickOpen, setLoading, setLoad, load, setOpenSnak, setMsg, setSeverity }) {
    const toArray = (selected) => Object.keys(selected).map((id) => id * 1);
    const id = JSON.stringify(toArray(selected)).replace('[', '').replace(']', '');

    async function handleClickDeleteLigne(id) {
        let text = 'Voulez-vous supprimer cette ligne';
        let res = window.confirm(text);
        if (res === false) {
            return;
        }
        setLoading(true);
        const response = await fetch(config.ipapi + '/api/del-typedep/' + id, {
            method: 'DELETE'
        });
        const json = await response.json();
        if (json.status) {
            setOpenSnak(true);
            setMsg(json.msg);
            setSeverity('success');
            setLoad(!load);
        } else {
            setOpenSnak(true);
            setMsg(json.msg);
            setSeverity('error');
        }
        setLoading(false);
    }

    return (
        <Navbar expand="lg" bg="light" variant="light">
            <Container>
                <Nav className="me-auto">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-disabled">Ajouter un nouveau type (catégorie) de dépenses</Tooltip>}
                    >
                        <Nav.Link href="#/prestadmin/typedepense" onClick={() => handleClickOpen(1, 0)}>
                            <PlusCircleDotted color="green" size={20} /> Créer
                        </Nav.Link>
                    </OverlayTrigger>
                    {id != 0 ? (
                        <>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="tooltip-disabled">Modifier la ligne sélectionné</Tooltip>}
                            >
                                <Nav.Link href="#/prestadmin/typedepense" onClick={() => handleClickOpen(1, id)}>
                                    <Pencil color="green" size={20} /> Modifier
                                </Nav.Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="tooltip-disabled">Supprimer la ligne sélectionnée</Tooltip>}
                            >
                                <Nav.Link href="#/prestadmin/typedepense" onClick={() => handleClickDeleteLigne(id)}>
                                    <X color="green" size={20} /> Supprimer
                                </Nav.Link>
                            </OverlayTrigger>
                        </>
                    ) : (
                        <></>
                    )}
                </Nav>
            </Container>
        </Navbar>
    );
}
